export const localidades = {
    "Capital Federal": [
        "Agronomía",
        "Almagro",
        "Balvanera",
        "Barracas",
        "Belgrano",
        "Boedo",
        "Caballito",
        "Chacarita",
        "Coghlan",
        "Colegiales",
        "Constitución",
        "Flores",
        "Floresta",
        "La Boca",
        "La Paternal",
        "Liniers",
        "Mataderos",
        "Monte Castro",
        "Montserrat",
        "Nueva Pompeya",
        "Nuñez",
        "Palermo",
        "Parque Avellaneda",
        "Parque Chacabuco",
        "Parque Chas",
        "Parque Patricios",
        "Puerto Madero",
        "Recoleta",
        "Retiro",
        "Saavedra",
        "San Cristóbal",
        "San Nicolás",
        "San Telmo",
        "Versalles",
        "Villa Crespo",
        "Villa Devoto",
        "Villa General Mitre",
        "Villa Lugano",
        "Villa Luro",
        "Villa Ortúzar",
        "Villa Pueyrredón",
        "Villa Real",
        "Villa Riachuelo",
        "Villa Santa Rita",
        "Villa Soldati",
        "Villa Urquiza",
        "Villa del Parque",
        "Vélez Sarsfield",
    ],
    "Buenos Aires": [
        "Florida",
        "Villa Martelli",
        "Florida Oeste",
        "Munro",
        "Carapachay",
        "Villa Adelina",
        "Troncos Del Talar",
        "Boulogne",
        "Ricardo Rojas",
        "Don Torcuato",
        "Ingeniero Adolfo Sourdeaux",
        "Los Polvorines",
        "Villa De Mayo",
        "Grand Bourg",
        "Malvinas Argentinas",
        "Pablo Nogues",
        "General Pacheco",
        "El Talar",
        "Garin",
        "Maquinista F Savio",
        "Benavidez",
        "Delta",
        "Dique Lujan",
        "Lopez Camelo",
        "Ingeniero Maschwitz",
        "Rincon De Milberg",
        "Barrio El Cazador",
        "Belen De Escobar",
        "Casa Escobar",
        "Escobar",
        "Matheu",
        "Zelaya",
        "Loma Verde",
        "Pilar",
        "Villa Rosa",
        "Manzanares",
        "Fatima",
        "Fatima Estacion",
        "Manzone",
        "Villa Astolfi",
        "Km. 45",
        "Presidente Derqui",
        "Olivos",
        "La Lucila",
        "Vicente Lopez",
        "Martinez",
        "Acassuso",
        "San Isidro",
        "Beccar",
        "Victoria",
        "Virreyes",
        "San Fernando",
        "Delta San Fernando",
        "Carupa",
        "Tigre",
        "Arroyo Bicho Feo",
        "Arroyo Ceibito",
        "Arroyo Cucharas",
        "Arroyo Garcete",
        "Arroyo Lara",
        "Arroyo Las Animas",
        "Arroyo Llorones",
        "Arroyo Manzano",
        "Arroyo Merlo",
        "Arroyo Mosquito",
        "Arroyo Pereyra",
        "Arroyo Pito",
        "Arroyo Platitos",
        "Lobos Arroyo",
        "Sauce Rio",
        "Z Delta S Fernando",
        "Billinghurst",
        "Fernandez Moreno",
        "General San Martin",
        "Lourdes",
        "Migueletes",
        "San Martin",
        "Villa Libertad",
        "Villa Maipu",
        "San Andres",
        "Villa Zagala",
        "Tres De Febrero",
        "Malaver",
        "Villa Ballester",
        "Chilavert",
        "Jose Leon Suarez",
        "Loma Hermosa",
        "Pablo Podesta",
        "Remedios De Escalada - Tres De Febrero",
        "Campo De Mayo",
        "Jose Clemente Paz",
        "Bella Vista",
        "Muñiz",
        "San Miguel",
        "Manuel Alberti",
        "Trujui",
        "Alberti",
        "Tortuguitas",
        "Del Viso",
        "Nordelta Tigre",
        "Villa Lynch",
        "Villa Saenz Peña",
        "Villa Raffo",
        "Santos Lugares",
        "Caseros",
        "Martin Coronado",
        "Villa Bosch",
        "Ciudad Jardin Del Palomar",
        "El Palomar",
        "Ciudad Jardin El Palomar",
        "Hurlingham",
        "William Morris",
        "Villa Santos Tesei",
        "Villa Tesey",
        "Remedios De Escalada",
        "Villa Tessei",
        "Once De Setiembre",
        "Churruca",
        "El Libertador",
        "Ciudadela",
        "Jose Ingenieros",
        "Ramos Mejia",
        "Haedo",
        "Domingo Faustino Sarmiento",
        "Moron",
        "Castelar",
        "Villa Gobernador Udaondo",
        "Ituzaingo",
        "Barrio Parque Leloir",
        "Libertad",
        "General Rodriguez",
        "San Antonio De Padua",
        "Parque San Martin",
        "Merlo",
        "Agustin Ferrari",
        "Mariano Acosta",
        "Marcos Paz",
        "Villars",
        "Plomer",
        "9 De Abril",
        "El Durazno",
        "Kilometro 77",
        "Km 77",
        "La Choza",
        "La Reja",
        "Estacion General Hornos",
        "Cuartel V",
        "Enrique Fynn",
        "General Hornos",
        "General Las Heras",
        "Kilometro 79",
        "Lozano",
        "Parada Km 76",
        "Speratti",
        "Paso Del Rey",
        "Moreno",
        "Francisco Alvarez",
        "Las Malvinas",
        "Villa Insuperable",
        "Lomas Del Mirador",
        "Villa Luzuriaga",
        "San Justo",
        "Rafael Castillo",
        "Isidro Casnova",
        "Gregorio De La Ferrere",
        "Gregorio De Laferrere",
        "Gonzalez Catan",
        "Pontevedra",
        "Virrey Del Pino",
        "Isidro Casanova",
        "Tablada",
        "Ciudad De Eduardo Madero",
        "Ciudad Madero",
        "Aldo Bonzi",
        "Tapiales",
        "Mercado Central",
        "Villa Celina",
        "Ingeniero Budge",
        "La Salada",
        "Villa Transradio",
        "Ciudad Evita",
        "B Nuestra Señora De La Paz",
        "Barrio Santa Catalina",
        "M Paz Barrio Martin Fierro",
        "M Paz Barrio La Lonja",
        "M Paz Barrio La Milagrosa",
        "20 De Junio",
        "M Paz Barrio El Moro",
        "M Paz Barrio Urioste",
        "B Sarmiento Don Rolando",
        "Barrio Sarmiento Don Rolando",
        "M Paz Barrio El Martillo",
        "M Paz Barrio Bernasconi",
        "Barrio Los Aromos San Patricio",
        "Barrio El Zorzal",
        "Marcos Paz B El Zorzal",
        "Canning",
        "Aeropuerto Ezeiza",
        "La Union",
        "Ezeiza",
        "Union Ferroviaria",
        "El Jaguel",
        "Tristan Suarez",
        "Vicente Casares",
        "Maximo Paz",
        "Temperley",
        "Alejandro Petion",
        "Carlos Spegazzini",
        "Cañuelas",
        "Uribelarrea",
        "Colonia Santa Rosa",
        "Los Aromos",
        "Ruta 205 Kilometro 57",
        "Ruta 3 Kilometro 75 700",
        "Villa Adriana",
        "Banfield",
        "Valentin Alsina",
        "Villa Caraza",
        "Villa Diamante",
        "Gerli",
        "Lanus",
        "Monte Chingolo",
        "Villa Albertina",
        "Villa Fiorito",
        "Lomas De Zamora",
        "Turdera",
        "America Unida",
        "Llavallol",
        "Sourigues",
        "Luis Guillon",
        "Ezpeleta Oeste",
        "Monte Grande",
        "Esteban Echeverria",
        "Solano",
        "San Jose",
        "Jose Marmol",
        "Adrogue",
        "Almirante Brown",
        "Rafael Calzada",
        "Ministro Rivadavia",
        "Claypole",
        "Barrio Don Orione",
        "Burzaco",
        "Villa Vatteone",
        "Longchamps",
        "Villa La Florida",
        "Glew",
        "Pereyra",
        "Villa Numancia",
        "Gobernador Costa",
        "Villa España",
        "Platanos",
        "Guernica",
        "Zeballos",
        "Alejandro Korn",
        "San Vicente",
        "El Pampero",
        "Santa Rosa",
        "Piñeyro",
        "Avellaneda",
        "Dock Sud",
        "Sarandi",
        "Crucesita",
        "Villa Dominico",
        "Wilde",
        "Bernal Este",
        "Bernal Oeste",
        "Quilmes",
        "Don Bosco",
        "Quilmes Oeste",
        "Berazategui",
        "San Francisco Solano",
        "Ezpeleta Este",
        "Guillermo E Hudson",
        "Ranelagh",
        "Florencio Varela",
        "Bosques",
        "Km. 40",
        "Jose Maria Gutierrez",
        "Ingeniero Allan",
        "Villa Brown",
        "Centro Agricola El Pato",
        "Villa Elisa",
        "Arturo Segui",
        "City Bell",
        "Gonnet",
        "Manuel B Gonnet",
        "Joaquin Gorina",
        "La Plata",
        "Los Hornos",
        "Tolosa",
        "Estacion Moreno",
        "Lisandro Olmos Etcheverry",
        "Ringuelet",
        "Abasto",
        "Buchanan",
        "Jose Hernandez",
        "Melchor Romero",
        "Poblet",
        "El Carmen",
        "Arana",
        "Bartolome Bavio",
        "Bme B G Mansilla",
        "Bme Bavio Gral Mansilla",
        "General Mansilla",
        "Jose Ferrari",
        "Kilometro 92",
        "Cristino Benavidez",
        "Julio Arditi",
        "Magdalena",
        "Payro R",
        "Arbuco",
        "Kilometro 103",
        "Paraje Starache",
        "Roberto Payro",
        "Vieytes",
        "Luis Chico",
        "Monte Veloz",
        "Veronica",
        "Estacion Gomez",
        "B A Punta Indio",
        "Punta Indio",
        "Alvarez Jonte",
        "Colonia Beethoven",
        "El Rosario",
        "La Primavera",
        "La Talina",
        "Las Tahonas",
        "Los Santos Viejos",
        "Pancho Diaz",
        "Pipinas",
        "Rincon De Noario",
        "Berisso",
        "Beriso",
        "Ensenada",
        "Fuerte Barragan",
        "Escuela Naval Militar Rio Santiago",
        "Isla Santiago",
        "Punta Lara",
        "Los Talas",
        "Atalaya",
        "Empalme Magdalena",
        "Oliden",
        "Campo Lope Seco",
        "Coronel Brandsen",
        "Doyhenard",
        "Kilometro 44",
        "Kilometro 82",
        "La Posada",
        "Los Merinos",
        "B Las Mandarinas",
        "Barrio La Dolly",
        "Desvio Km 55",
        "G Obligado",
        "Kilometro 58",
        "Kilometro 70",
        "Samborombon",
        "Gomez",
        "Gomez  De La Vega",
        "Domselaar",
        "Altamirano",
        "Jeppener",
        "Alegre",
        "Cancha Del Pollo",
        "Cuartel 2",
        "Espartillar",
        "Ranchos",
        "Pinzon",
        "Urquiza",
        "Berdier",
        "Villa Trinidad",
        "Barrio Trocha",
        "Campo Buena Vista",
        "Chacra Exp Inta",
        "Francisco Ayerza",
        "H S Ant De La Lla",
        "Juan G Pujol",
        "La Cora",
        "Manantiales Grandes",
        "Pergamino",
        "Pueblo Otero",
        "Santa Rita",
        "Villa Centenario",
        "Villa Godoy",
        "Villa Progreso",
        "12 De Agosto",
        "Aguas Corrientes",
        "General Gelly",
        "Haras El Centinela",
        "M B Pergamino",
        "Mariano Benitez Pergamino",
        "Rancagua",
        "S Tsita Pergamino",
        "Cabo San Fermin",
        "Carabelas",
        "Ortiz Basualdo",
        "Plumacho",
        "Ricardo Cano",
        "C De Noviembre",
        "Haras San Jacinto",
        "Kilometro 36",
        "Piruco",
        "Rojas",
        "Guerrico",
        "Guido Spano",
        "Hunter",
        "La Nacion",
        "Las Saladas",
        "Los Indios",
        "Sol De Mayo",
        "Almacen Piatti",
        "C La Vanguardia",
        "Paraje Santa Rosa",
        "Pearson",
        "San Federico",
        "Manuel Ocampo",
        "El Socorro",
        "La Margarita",
        "La Sarita",
        "La Vanguardia",
        "Acevedo",
        "Gornatti",
        "Juana A De La Peña",
        "Manantiales",
        "Fontezuela",
        "Lierra Adjemiro",
        "Lopez Molinari",
        "Maguirre",
        "Mariano H Alfonzo",
        "Villa Da Fonte",
        "Caminera G Lopez",
        "Colon",
        "El Arbolito",
        "El Pelado",
        "Los Arcos",
        "El A. Pergamino",
        "Sarasa",
        "El Bagual",
        "Estancia Las Gamas",
        "Juncal",
        "San Urbano",
        "Cuatro De Febrero",
        "Almacen La Colina",
        "Arrecifes",
        "El Nacional",
        "La Delia",
        "La Nelida",
        "Villa Sanguinetti",
        "El Retiro",
        "Kilometro 187",
        "Las Cuatro Puertas",
        "Los Angeles",
        "Marcelino Ugarte",
        "Monroe",
        "Salto",
        "Tacuari",
        "Villa San Jose",
        "Arroyo Dulce",
        "Gahan",
        "Kenny",
        "La Invencible",
        "Ines Indart",
        "Almacen Castro",
        "Almacen El Cruce",
        "C La Invernada",
        "Colonia La Nena",
        "Colonia La Noria",
        "Colonia La Reina",
        "Colonia Laborderoy",
        "Colonia Los Toldos",
        "Colonia Stegman",
        "El Quemado",
        "La Violeta",
        "A El Descanso",
        "Arroyo De Luna",
        "Campo La Elisa",
        "Capitan Sarmiento",
        "Colegio San Pablo",
        "El Silencio",
        "Haras Los Cardales",
        "La Luisa",
        "Campo Crisol",
        "San Juan",
        "San Ramon",
        "Todd",
        "Viña",
        "Bagues",
        "C Los Tres Usaris",
        "Puente Castex",
        "S Antonio De Areco",
        "San Antonio De Areco",
        "E Santa Catalina",
        "Santa Coloma",
        "Villa Lia",
        "Flamenco",
        "Kilometro 102",
        "Puesto Del Medio",
        "Solis",
        "Campo La Nena",
        "Chenaut",
        "Duggan",
        "G Andonaeghi",
        "Gobernador Andonaeghi",
        "Vagues",
        "Arroyo Negro",
        "Atucha",
        "Zarate",
        "Alto Verde",
        "Escalada",
        "Otamendi",
        "Campana",
        "El Fenix",
        "Pasaje Talavera",
        "R 9 Kilometro 72",
        "A La Paciencia",
        "Aguila Rio",
        "Arroyo Aleli",
        "Arroyo Brasilero",
        "Arroyo Carabelitas",
        "Arroyo El Ahogado",
        "Arroyo Ibicuycito",
        "Arroyo Las Cruces",
        "Arroyo Los Platos",
        "Arroyo Los Tigres",
        "Arroyo Pesqueria",
        "Arroyo Tajiber",
        "Arroyo Zanjon",
        "Blondeau",
        "C N Alem 1A Sec",
        "C N Alem 2A Sec",
        "Colonia Delta",
        "Isla El Dorado",
        "La Horqueta",
        "Paraje Palaveroi",
        "Parana Bravo",
        "Pje Al Aguila Rio",
        "Rio Alferez N Page",
        "Rio Ceibo",
        "Rio Parana Guazu",
        "Rio Sauce",
        "Rio Talavera",
        "Las Palmas",
        "Lima",
        "Arroyo De La Cruz",
        "Capilla Del Señor",
        "Diego Gaynor",
        "Exaltacion De La Cruz",
        "Los Cardales",
        "Alto Los Cardales",
        "El Nuevo Rincon",
        "Kilometro 311",
        "La Lata",
        "San Nicolas",
        "San Nicolas De Los Arroyos",
        "La Emilia",
        "Campo Salles",
        "Erezcano",
        "General Rojo",
        "General Conesa",
        "Mutti",
        "La Querencia",
        "Sanchez",
        "Santa Teresa",
        "V Gral S Ex Schez",
        "Villa General Savio",
        "Barrio D. Antonio",
        "Costa Brava",
        "V Ramallo E Ffcc",
        "Villa Ramallo",
        "Aguirrezabala",
        "La Esperanza",
        "Ramallo",
        "El Jupiter",
        "El Paraiso",
        "Haras El Ombu",
        "Las Bahamas",
        "Empal V Constitu",
        "Copacabana",
        "E V Constitucion",
        "Las Flores",
        "La Buana Moza",
        "Oliviera Cesar",
        "R 9 Km 169 5",
        "San Pedro",
        "Villa Depietri",
        "Villa Igollo",
        "Villa Sarita",
        "Villaigrillo",
        "Isla De Los Laureles",
        "Isla Los Laureles",
        "La Matilde",
        "Paname",
        "Colonia Velez",
        "La Bolsa",
        "Perez Millan",
        "Algarrobo",
        "Arroyo Burgos",
        "El Descanso",
        "Ingeniero Moneta",
        "Kilometro 172",
        "Parada Km 158",
        "Santa Lucia",
        "Alsina",
        "Baradero",
        "Estacion Baradero",
        "Ireneo Portela",
        "Rio Tala",
        "Villa Teresa",
        "El Espinillo",
        "Gobernador Castro",
        "Kilometro 184",
        "Villa Leandra",
        "Vuelta De Obligado",
        "Doyle",
        "B General S Martin",
        "Barrio Carosio",
        "Barrio U O C R A",
        "Barrio V Ortega",
        "Junin",
        "P Nuevo Dto Junin",
        "V Belg. Dto Junin",
        "Villa Mayor",
        "Villa Ortega",
        "Villa Penotti",
        "Villa Talleres",
        "Villa Triangulo",
        "Villa York",
        "Agustina",
        "Fortin Tiburcio",
        "Laguna De Gomez",
        "Rafael Obligado",
        "Ascencion",
        "Ferre",
        "La Angelita",
        "La Beba",
        "La Trinidad",
        "General Arenales",
        "Arribeños",
        "Delgado",
        "Baigorrita",
        "Laplacette",
        "General Viamonte",
        "Los Toldos",
        "C San Francisco",
        "Chancay",
        "Colonia San Francisco",
        "El R Pd G Viamonte",
        "Kilometro 282",
        "La Delfina",
        "Paso Grande",
        "San Emilio",
        "San Roque",
        "Colonia Los Huesos",
        "Los Bosques",
        "Quirno Costa",
        "Zavalia",
        "La Oriental",
        "Las Parvas",
        "Saforcada",
        "Agustin Roca",
        "De Bruyn",
        "Edmundo Perkins",
        "Sauzales",
        "Vedia",
        "Desvio El Chingolo",
        "El Dorado",
        "Fortin Acha",
        "Kilometro 95",
        "Blandengues",
        "Leandro N Alem",
        "Alberdi",
        "Colonia Alberdi",
        "J Bautista Alberdi",
        "Juan Bautista Alberdi",
        "La Picasa",
        "Christophersen",
        "Dos Hermanos",
        "Iriarte",
        "Irala",
        "Morse",
        "Dussaud",
        "General Pinto",
        "Haras El Catorce",
        "La Suiza",
        "Las Chacras",
        "Pichincha",
        "Ingeniero Balbin",
        "El Peregrino",
        "Germania",
        "Gunther",
        "Mayor J Orellano",
        "Trigales",
        "Pazos Kanki",
        "Villa Francia",
        "Coronel Granada",
        "Los Callejones",
        "Porvenir",
        "Eduardo Costa",
        "Floren Ameghino",
        "Florentino Ameghino",
        "Halcey",
        "Volta",
        "Blaquier",
        "Balsa",
        "Estacion Lincoln",
        "Kilometro 321",
        "Lincoln",
        "Vigelencia",
        "Bermudez",
        "Santa Maria",
        "Triunvirato",
        "El Triunfo",
        "Fortin Vigilancia",
        "Arenaza",
        "E San Antonio",
        "Estacion Mitikili",
        "Haras Trujui",
        "Kilometro 352",
        "Kilometro 356",
        "Los Altos",
        "Roberts",
        "Encina",
        "La Zarateqa",
        "Necol E Fcgm",
        "Nueva Suiza",
        "Pasteur",
        "Bayauca",
        "La Ines",
        "Villa Saboya",
        "El Refugio",
        "La Adelaida",
        "La Constancia",
        "Tarragona",
        "Caqada Seca",
        "Cañada Seca",
        "Santa Regina",
        "Coronel Roseti",
        "El Alberdon",
        "Kilometro 396",
        "La Asturiana",
        "La Calma",
        "Las Dos Angelitas",
        "San Carlos",
        "Santa Paula",
        "C La Providencia",
        "Curapaligue",
        "Fray Cayetano Rod.",
        "R Diaz De Guzman",
        "Salguero",
        "Colonia Santa Ana",
        "La Ramada",
        "Santa Clara",
        "Tacurel",
        "El Arbol",
        "El Noy",
        "Los Guachos De Gue",
        "Vivero",
        "Pretot Freyre",
        "Coronel Charlone",
        "Fernando Marti",
        "General Villegas",
        "Tres Algarrobos",
        "Condarco",
        "Sansinena",
        "Villa Sauce",
        "America",
        "Estacion Rivadavia",
        "Gonzalez Moreno",
        "San Mauricio",
        "Rivadavia",
        "El Dia",
        "Emilio Bunge",
        "Gondra",
        "Piedritas",
        "Santa Eleodora",
        "Elordi",
        "Banderalo",
        "Quenuma",
        "Ingeniero Thompson",
        "Leubuco",
        "Salliquelo",
        "Thames",
        "Villa Maza",
        "Pellegrini",
        "De Bary",
        "Colonia La Gaviota",
        "La Carmen",
        "La Cumbre",
        "La Gaviota",
        "La Marianita",
        "La Suerte",
        "Las Gaviotas",
        "La Chapelle",
        "San Ignacio",
        "Santa Ines",
        "La Zanja",
        "Trenque Lauquen",
        "Sundblad",
        "Mira Pampa",
        "Fortin Olavarria",
        "Villa Sena",
        "30 De Agosto",
        "Corazzi",
        "Treinta De Agosto",
        "Girodias",
        "La Porteña",
        "Tres Lomas",
        "Garre",
        "Victorino De La Plaza",
        "Casbas",
        "Casey",
        "San Fermin",
        "Saturno",
        "Roosevelt Partido Rivadavia",
        "Primera Junta",
        "Berutti",
        "Fatralo",
        "Carhue",
        "Estacion Lago Epecuey",
        "Arturo Vattegne",
        "Anderson",
        "Palantelen",
        "El Nilo",
        "Guamini",
        "Laguna Del Monte",
        "Vuelta De Zapata",
        "Alamos",
        "Alfa",
        "Arroyo El Chingolo",
        "Arroyo Venado",
        "Colonia San Ramon",
        "El Trebaqon",
        "La Gregoria",
        "La Herminia",
        "Las Ctro Hermanas",
        "Las Mercedes",
        "Las Tres Flores",
        "S Rita Pdo Guamini",
        "Masurel",
        "Bonifacio",
        "La Manuela",
        "Luro",
        "C Baron Hirsch",
        "Rivera",
        "Mari Lauquen",
        "Arano",
        "Epumer",
        "Malabia",
        "Tres  Lagunas",
        "Yutuyaco",
        "Abel",
        "Barrio Obrero",
        "Guanaco",
        "Pehuajo",
        "Pueblo San Esteban",
        "Rovira",
        "Santa Cecilia Sud",
        "Ancon",
        "Curaru",
        "Gnecco",
        "Guirondo",
        "Inocencio Sosa",
        "Larramendy",
        "Magdala",
        "Marucha",
        "Nueva Plata",
        "Pedro Gamen",
        "Carlos Salas",
        "La Pradera",
        "Las Toscas",
        "Carlos Tejedor",
        "Drysdale",
        "Husares",
        "Ingeniero Beaugey",
        "Kilometro 386",
        "Timote",
        "Colonia Sere",
        "Capitan Castro",
        "La Cotorra",
        "Alagon",
        "El Santiago",
        "S Cecilia Centro",
        "Coraceros",
        "Henderson",
        "Maria Lucila",
        "Enrique Lavalle",
        "Asturias",
        "Mones Cazon",
        "Atahualpa",
        "La Carreta",
        "Mouras",
        "Salazar",
        "Villa Aldeanita",
        "Francisco Madero",
        "S Cecilia Norte",
        "Campo Aristimuqo",
        "El Recado",
        "Juan Jose Paso",
        "Esteban De Luca",
        "Francisco Magnano",
        "La Higuera",
        "Los Chaqares",
        "Chiclana",
        "Las Juanitas",
        "San Bernardo",
        "Laguna Alsina",
        "9 De Julio",
        "Altamira",
        "B Julio De Vedia",
        "Colonia Las Yescas",
        "Fauzon",
        "La Aurora",
        "Nueve De Julio",
        "Villa Diamantina",
        "E Provincial",
        "Laguna Del Cura",
        "Mulcahy",
        "Norumbega",
        "Tropezon",
        "12 De Octubre",
        "Desvio Km 234",
        "Patricios",
        "Dudignac",
        "Corbett",
        "Gerente Cilley",
        "Las Negras",
        "Morea",
        "Santos Unzue",
        "Del Valle",
        "Desvio Garbarini",
        "E Agri Salesiana",
        "Hale",
        "Huetel",
        "Villa Sanz",
        "Galo Llorente",
        "La Niqa",
        "La Niña",
        "La Yesca",
        "Carlos Maria Naon",
        "El Tejar",
        "Amalia",
        "Bacacay",
        "Cambaceres",
        "Dennehy",
        "French",
        "Carlos Casares",
        "San Juan De Nelson",
        "Santo Tomas",
        "C La Esperanza",
        "Colonia Mauricio",
        "El Jabali",
        "Gobernador Arias",
        "Mauricio Hirsch",
        "Moctezuma",
        "Smith",
        "Alfredo Demarchi",
        "Cnel Mart De Hoz",
        "Coronel Martinez De Hoz",
        "Kilometro 322",
        "La Adela",
        "Las Rosas",
        "P Martinez De Hoz",
        "Pueblo Martinez De Hoz",
        "Quiroga",
        "Ramon J Neild",
        "Reginaldo J Neild",
        "Bellocq",
        "C Santa Maria",
        "Cadret",
        "Centenario",
        "La Sofia",
        "Santa Maria Belloq",
        "Camaoti",
        "E San Claudio",
        "El Carpincho",
        "Hortensia",
        "Odorqui",
        "Ordoqui",
        "La Dorita",
        "Santo Tomas Chico",
        "Bolivar",
        "El Porvenir",
        "Lugano",
        "Miramar",
        "Santa Isabel",
        "Juan F Ibarra",
        "Mariano Unzue",
        "Pirovano",
        "La Torrecita",
        "Nueva Espaqa",
        "Urdampilleta",
        "V Lynch Pueyrredon",
        "Alfalad",
        "Andant",
        "Cnel M Freyre",
        "Daireaux",
        "La Armonia",
        "La Larga",
        "Los Coloniales",
        "Mauras",
        "Villa Carola",
        "Arboledas",
        "Herrera Vegas",
        "Iturregui",
        "Louge",
        "Mapis",
        "Paula",
        "Vallimanca",
        "Recalde",
        "Blancagrande",
        "Espigas",
        "La Protegida",
        "Arboleda",
        "Agote",
        "Kilometro 125",
        "Mercedes",
        "Seminario Pio Xii",
        "Comahue Oeste",
        "Espora",
        "La Valerosa",
        "La Verde",
        "San Eladio",
        "Tomas Jofre",
        "Ingeniero Williams",
        "Juan Jose Almeyra",
        "Kilometro 117",
        "Campo Peqa Lopez",
        "Gonzalez Risos",
        "Grisolia",
        "Kilometro 116",
        "Kilometro 83",
        "Kilometro 90",
        "Navarro",
        "Rincon Norte",
        "Anasagasti",
        "Esteban Diaz",
        "Las Marianas",
        "Gowland",
        "Manuel Jose Garcia",
        "Olivera",
        "Capdepont",
        "Haras La Elvira",
        "La Sara",
        "Roman Baez",
        "Suipacha",
        "Franklin",
        "General Rivas",
        "Goldney",
        "San Jacinto",
        "Castilla",
        "La California Arg",
        "Caqada Rica",
        "Chivilcoy",
        "Puente Batalla",
        "C La Magdalena",
        "Gobernador Ugarte",
        "Henry Bell",
        "Pres Quintana",
        "Indacochea",
        "La Rica",
        "San Sebastian",
        "Villa Moquehua",
        "Achupallas",
        "Haras El Carmen",
        "La Victoria Desvio",
        "Moll",
        "Ramon Biaus",
        "Colonia Zambungo",
        "Coronel Mon",
        "Coronel Segui",
        "Emilio Ayarza",
        "La Carlota",
        "La Dormilona",
        "Palemon Huergo",
        "Villa Maria",
        "Villa Ortiz",
        "Benitez",
        "Gorostiaga",
        "Andres Vaccorezza",
        "Emita",
        "Warnes",
        "Asamblea",
        "Bragado",
        "La Maria",
        "Comodoro Py",
        "Araujo",
        "Baudrix",
        "Colonia Palantelen",
        "La Limpia",
        "Maximo Fernandez",
        "C San Eduardo",
        "General O Brien",
        "Mecha",
        "Mechita",
        "Ing De Madrid",
        "Ingeniero De Madrid",
        "Olascoaga",
        "25 De Mayo",
        "La Tribu",
        "Laguna Las Mulitas",
        "Ortiz De Rosas",
        "Santiago Garbarini",
        "Veinticinco De Mayo",
        "Blas Duraqona",
        "Lucas Monteverde",
        "Mamaguita",
        "Pueblitos",
        "San Enrique",
        "Juan Vela",
        "N De La Riestra",
        "Norberto De La Riestra",
        "Ernestina",
        "La Gloria",
        "Pedernales",
        "Agustin Mosconi",
        "Colonia Inchausti",
        "Islas",
        "La Rabia",
        "La Rubia",
        "Martin Berraondo",
        "Valdes",
        "Pla",
        "La Loma",
        "Lujan",
        "Pueblo Nuevo",
        "Santa Elena",
        "Carlos Keen",
        "Torres",
        "Villa Ruiz",
        "Jauregui Jose Maria",
        "Villa Flandria",
        "Open Door",
        "Cortines",
        "Villa Espil",
        "Valdez",
        "La Florida",
        "Ruiz Solis",
        "S Andres De Giles",
        "San Alberto",
        "San Andres De Giles",
        "Villa San Alberto",
        "Azcuenaga",
        "Tuyuti",
        "Cucullu",
        "Heavy",
        "Kilometro 108",
        "Carmen De Areco",
        "Estrella Naciente",
        "La Central",
        "Parada Tatay",
        "San Ernesto",
        "Tatay",
        "Gouin",
        "Tres Sargentos",
        "Rawson",
        "San Patricio",
        "Chacabuco",
        "Gregorio Villafaqe",
        "Villafaqe",
        "Coliqueo",
        "Ingeniero Silveyra",
        "Cucha Cucha",
        "Membrillar",
        "O Higgins",
        "Parada Los Robles",
        "Larrea",
        "Blanca Grande",
        "C De Los Leones",
        "Cantera Aguirre",
        "Cantera Albion",
        "Cantera La Aurora",
        "Cantera San Luis",
        "Ctra La Federacion",
        "Ctra La Movediza",
        "Ctra Monte Cristo",
        "Desvio Aguirre",
        "El Gallo",
        "Empalme C Chato",
        "Empalme Cerro Chato",
        "La Numancia",
        "Los Leones",
        "Tandil",
        "Villa Daza",
        "Villa Dufau",
        "Villa Galicia",
        "Villa Italia",
        "Villa Laza",
        "Villa Leza",
        "La Pastora",
        "Aceilan",
        "Gardey",
        "Maria Ignacia",
        "Vela",
        "Claraz",
        "Barker",
        "La Azucena",
        "La Negra",
        "Villa Cacique",
        "Villa Cacique Barker",
        "Caminera Napaleofu",
        "Dos Naciones",
        "El Cheique",
        "El Hervidero",
        "Fulton",
        "La Azotea",
        "La Palma",
        "Las Suizas",
        "Lciado Matienzo",
        "Licenciado Matienzo",
        "Napaleofu",
        "San Pascual",
        "Bocayuba",
        "Iraola",
        "La  Aurora",
        "Juan N Fernandez",
        "Aneque Grande",
        "Arroyo Chico",
        "Haras La Lula",
        "San Cala",
        "De La Canal",
        "Egaqa",
        "Egaña",
        "Barrio Juarez",
        "Benito Juarez",
        "Caminera Juarez",
        "Chapar",
        "Cnel Rodolfo Bunge",
        "Estancia Chapar",
        "Haras El Cisne",
        "Kilometro 404",
        "La Calera - Bs As",
        "Molino Galileo",
        "Monte Crespo",
        "Pachan",
        "Parque Muqoz",
        "Alzaga",
        "Lopez",
        "San Manuel",
        "Tedin Uriburu",
        "Dolores",
        "El 60",
        "Esquina De Crotto",
        "Kilometro 212",
        "La Estrella",
        "Las Viboras",
        "Loma De Salomon",
        "Paraje La Vasca",
        "Parravichini",
        "Tres Leguas",
        "Tordillo",
        "Villa Roch",
        "Cevigne",
        "Costa Azul",
        "Faro San Antonio",
        "General Lavalle",
        "Costa Del Este",
        "S Clemente De Tuyu",
        "San Clemente Del Tuyu",
        "Las Toninas",
        "Santa Teresita",
        "Mar Del Tuyu",
        "Barrio Pedro Rocco",
        "La Victoria",
        "Mar De Ajo",
        "P Las Margaritas",
        "Punta Medanos",
        "S J De L Quinteros",
        "Villa Clelia",
        "La Reforma",
        "Las Achiras",
        "Pila",
        "San Antonio",
        "S Brdo Del Tuyu",
        "San Bernardo Del Tuyu",
        "Aguas Verdes",
        "La Posta",
        "La Proteccion",
        "La Lucila Del Mar",
        "Nueva Atlantis",
        "C 15 C De La Glo",
        "C D La Gloria C 15",
        "Castelli",
        "Centro Guerrero",
        "India Muerta",
        "La Corina",
        "La Corinco",
        "Parque Taillade",
        "San Jose De Gali",
        "Camaron Chico",
        "Don Vicente",
        "El Destino",
        "El Vence",
        "Guerrero",
        "La Alcira",
        "La Despierta",
        "La Larga Nueva",
        "La Piedra",
        "Las Chilcas",
        "Las Tortugas",
        "Lezama",
        "San Daniel",
        "General Guido",
        "Vecino",
        "Cari Larquea",
        "La Amorilla",
        "La Colorada",
        "La Mascota",
        "Monsalvo",
        "Santo Domingo",
        "Segurola",
        "Barrio El Hueco",
        "Chascomus",
        "Cra. Samborombon",
        "E San Rafael",
        "El Eucaliptus",
        "El Rincon",
        "La Alameda",
        "La Amalia",
        "La Amistad",
        "La Azotea Grande",
        "Las Bruscas",
        "Las Mulas",
        "Legaristi",
        "Pedro N Escribano",
        "San Rafael",
        "Vista Alegre",
        "Vitel",
        "Com Giribone",
        "Comandante Giribone",
        "Cuartel 8",
        "Don Cipriano",
        "El Carbon",
        "Escri P Nicolas",
        "Libres Del Sud",
        "Pessagno",
        "Vergara",
        "Adela",
        "C E Argentina",
        "Cuartel 6",
        "Gandara",
        "Haras San Ignacio",
        "Monasterio",
        "Ayacucho",
        "San Laureano",
        "Langueyu",
        "Las Sultanas",
        "Magallanes",
        "Solanet",
        "Udaquiola",
        "Cangallo",
        "Fair",
        "Maipu",
        "Labarden",
        "Claverie",
        "El Chaja",
        "Espadaqa",
        "Faro Querandi",
        "General Madariaga",
        "Gobos",
        "Goqi",
        "Goroso",
        "Hinojales",
        "Invernadas",
        "Isondu",
        "La Esp G Madariaga",
        "La Tablada",
        "Macedo",
        "Mar Azul",
        "Medaland",
        "Pasos",
        "Salada Chica",
        "Salada Grande",
        "Speroni",
        "Tio Domingo",
        "Ostende",
        "Mar De Las Pampas",
        "Villa Gesell",
        "Valeria Del Mar",
        "Carilo",
        "Parque Carilo",
        "Pinamar",
        "Juancho",
        "Colonia Ferrari",
        "General Piran",
        "Hogar M O Basualdo",
        "Las Armas",
        "Arroyo Grande",
        "Coronel Vidal",
        "E Agricola Rural",
        "El Vigilante",
        "Haras 1 De Mayo",
        "La Tobiana",
        "Mar Chiquita",
        "Sevigne",
        "Coronel Boerr",
        "El Gualicho",
        "Chapaleoufu",
        "Colamn",
        "Colman",
        "El Chalar",
        "Miranda",
        "Plaza Montero",
        "El C De Langueyu",
        "Galera De Torres",
        "La Espza P Las F",
        "Loma Negra",
        "Loma Partida",
        "Rauch",
        "Villa Burgos",
        "Villa Loma",
        "Villa San Pedro",
        "Rosas",
        "El Trigo",
        "Estrugamou",
        "La Porteqa",
        "Vilela",
        "Doctor Domingo Harosteguy",
        "Dr Dgo Harosteguy",
        "La Isabel",
        "Pardo",
        "S R De Minellono",
        "Cachari",
        "Laguna Medina",
        "Miramonte",
        "Funke",
        "Goyeneche",
        "Guardia Del Monte",
        "Kilometro 88",
        "Los Eucaliptos",
        "Monte",
        "S Miguel Del Monte",
        "San Miguel Del Monte",
        "Francisco Berra",
        "Gobernador Udaondo",
        "Kilometro 128",
        "Palmitas",
        "Bonnement",
        "Chas",
        "El Siasgo",
        "General Belgrano",
        "Haras Chacabuco",
        "Ibaqez",
        "La Chumbeada",
        "Newton",
        "Casalins",
        "Real Audiencia",
        "El Alba",
        "Estancia Vieja",
        "La Luz",
        "Peqaflor",
        "Puente El Ochenta",
        "Rincon De Vivot",
        "Villanueva",
        "Gorchs",
        "Kilometro 146",
        "Los Cerrillos",
        "Zenon Videla Dorna",
        "Abbott",
        "Kilometro 112",
        "Laguna De Lobos",
        "Lobos",
        "Salvador Maria",
        "Antonio Carboni",
        "Arevalo",
        "Elvira",
        "La Blanqueada",
        "Santa Alicia",
        "Santa Felicia",
        "Campo Sabate",
        "Haras El Salaso",
        "Juan Atucha",
        "La Paz",
        "La Rinconada",
        "Roque Perez",
        "Santiago Larre",
        "Barrientos",
        "Campo Funke",
        "Carlos Beguerie",
        "Juan Tronconi",
        "La Paz Chica",
        "El Araza",
        "Empalme Lobos",
        "Zapiola",
        "Barrio V Saladillo",
        "El Mangrullo",
        "Emiliano Reynoso",
        "Esther",
        "G Ortiz De Rosas",
        "Jose Sojo",
        "Juan Blaquier",
        "La Barrancosa",
        "La Campana",
        "La Razon",
        "Saladillo",
        "Toldos Viejos",
        "Saladillo Norte",
        "San Benito",
        "El Chumbiao",
        "El Parche",
        "Emma",
        "General Alvear",
        "H R De La Parva",
        "J M Micheo",
        "La Pampa",
        "Los Cuatro Caminos",
        "Del Carril",
        "Polvaredas",
        "Alvarez De Toledo",
        "Cazon",
        "Azul",
        "Caminera Azul",
        "Estacion Lazzarino",
        "La Mantequeria",
        "Las Cortaderas",
        "Lazzarino",
        "Shaw",
        "Vicente Pereda",
        "Ariel",
        "Arroyo De Los Huesos",
        "Arroyo Los Huesos",
        "B Naval Azopardo",
        "Francisco J Meeks",
        "Pablo Acosta",
        "Uballes",
        "Vaqa",
        "Altona",
        "Campo Rojas",
        "El Mirador",
        "El Sauce",
        "Tapalque",
        "Yerbas",
        "Ant De Los Heros",
        "Campodonico",
        "Covello",
        "San Gervacio",
        "Velloso",
        "Crotto",
        "Requena",
        "Chillar",
        "Martin Fierro",
        "S R De Anchorena",
        "16 De Julio",
        "B Vera Y Pintado",
        "C. Rodolfo Bunge",
        "Coronel Rodolfo Bunge",
        "El Luchador",
        "Kilometro 433",
        "La Nutria",
        "Ricardo Gaviqa",
        "Fortin Irene",
        "Las Nieves",
        "Nieves",
        "Parish",
        "Colonia Hinojo",
        "Colonia Nieves",
        "Hinojo",
        "Villa Monica",
        "S And De Tapalque",
        "Montecarlo",
        "Barrio La Luisa",
        "Calera Avellaneda",
        "Colonia Rusa",
        "Kilometro 333",
        "La Navarra",
        "Las Piedritas",
        "Olavarria",
        "Villa Fortabat",
        "C De Gregorini",
        "Duraqona",
        "Durañona",
        "Tnte Cnel Miqana",
        "Cierra Chica",
        "Empalme Querandies",
        "Santa Luisa",
        "Sierra Chica",
        "Alvaro Barros",
        "Cerro Aguila",
        "Cerro Negro",
        "Cerro Sotuyo",
        "Fortabat",
        "La Narcisa",
        "La Palmira",
        "La Providencia",
        "La Tomasa",
        "Sierras Bayas",
        "Fortin Lavalle",
        "Muqoz",
        "Muñoz",
        "Pourtale",
        "Rocha",
        "San Jorge",
        "Aldecon",
        "Chala Quilca",
        "Fortin Necochea",
        "General Lamadrid",
        "Las Bandurrias",
        "Las Martinetas",
        "Lastra",
        "Quilco",
        "San Quilco",
        "Santa Clementina",
        "Libano",
        "La Colina",
        "Los Pinos",
        "Parag?Il",
        "Voluntad",
        "Laprida",
        "Las Hermanas",
        "Villa Pueblo Nuevo",
        "Colonia San Miguel",
        "El Carretero",
        "El Triangulo",
        "Estacion Barrow",
        "Hueso Clavado",
        "La Tigra",
        "Las Vaquerias",
        "Reta",
        "Tres Arroyos",
        "Indio Rico",
        "Cristiano Muerto",
        "Genera Valdez",
        "Los Molles",
        "Orense",
        "Santa Catalina",
        "B Claromeco",
        "Balneario Claromeco",
        "Es S Fcisco Belloq",
        "Lin Calel",
        "San Francisco De Bellocq",
        "Villa Carucha",
        "El Bombero",
        "Irene",
        "M Cascallares",
        "Micaela Cascallares",
        "Oriente",
        "Balneario Orense",
        "Balneario Oceano",
        "Balneario Reta",
        "Copetonas",
        "Paso Del Medano",
        "A Gonz Chavez",
        "Adolfo Gonzales Chaves",
        "El Lucero",
        "Claudio C Molina",
        "De La Garma",
        "Pedro Lasalle",
        "Juan E Barra",
        "Mariano Roldan",
        "Pierini",
        "La Sortija",
        "San Mayol",
        "Vasquez",
        "S Fcisco D Bellocq",
        "Ochandio",
        "San Cayetano",
        "San Severo",
        "El Cristiano",
        "La Ballena",
        "La Feliciana",
        "Loma Del Indio",
        "Pueblo B Reta",
        "Coronel Pringles",
        "Krabbe",
        "Las Mostazas",
        "Pillahuinco",
        "Raulet",
        "Tejo Galeta",
        "Zoilo Peralta",
        "Despeqaderos",
        "El Divisorio",
        "El Pensamiento",
        "Lartigau",
        "Quiqihual Estacion",
        "Quiñihual Estacion",
        "Pontaut",
        "E Coronel Pringles",
        "La Reserva",
        "Stegmann",
        "Bathurst Estacion",
        "Coronel Suarez",
        "Sauce Corto",
        "Villa Arcadia",
        "D Orbigny",
        "Pueblo San Jose",
        "Huanguelen",
        "La Copeta",
        "La Nevada",
        "Ombu",
        "Otoqo",
        "Zentena",
        "Cascada",
        "Pasman",
        "Curamalan",
        "Piqeyro",
        "De Ferrari",
        "Pueblo Santa Maria",
        "Santa Trinidad",
        "B Emir R Juarez",
        "B Gastronomico",
        "B General Roca",
        "B Jose M Estrada",
        "B Primera Junta",
        "B Pueblo Nuevo",
        "B Tierra De Oro",
        "B Tiro Federal",
        "Barrio Caisamar",
        "Barrio Juramento",
        "Barrio Los Andes",
        "Barrio Pinares",
        "Barrio S Cayetano",
        "Barrio San Carlos",
        "Barrio San Jose",
        "El Soldado",
        "Laguna Del Soldado",
        "Mar Del Plata",
        "Mar Del Plata - Barrio Caisamar",
        "Mar Del Plata - Barrio Emir Ramon Juarez",
        "Mar Del Plata - Barrio Gastronomico",
        "Mar Del Plata - Barrio General Roca",
        "Mar Del Plata - Barrio Jose M Estrada",
        "Mar Del Plata - Barrio Juramento",
        "Mar Del Plata - Barrio Los Andes",
        "Mar Del Plata - Barrio Pinares",
        "Mar Del Plata - Barrio Primera Junta",
        "Mar Del Plata - Barrio Pueblo Nuevo",
        "Mar Del Plata - Barrio San Carlos",
        "Mar Del Plata - Barrio San Cayetano",
        "Mar Del Plata - Barrio San Jose",
        "Mar Del Plata - Barrio Tierra De Oro",
        "Mar Del Plata - Barrio Tiro Federal",
        "Mar Del Platas",
        "Punta Mogotes",
        "Villa Vignolo",
        "Barrio Batan",
        "Batan",
        "La Peregrina",
        "Lag De Los Padres",
        "Los Ortiz",
        "Mar Del Plata - Barrio Batan",
        "S Jose De Otamendi",
        "Sierra De Los Padres",
        "Comandante Nicanor Otamendi",
        "Dionisia",
        "La Ballenera",
        "La Colmena",
        "La Elma",
        "La Lucia",
        "La Madrecita",
        "Las Lomas",
        "Los Patos",
        "San Cornelio",
        "San Felipe",
        "Chapadmalal",
        "Haras Chapadmalal",
        "Las Piedrtas",
        "Mechongue",
        "Yrazoz",
        "B Parque Bristol",
        "Barrio Oeste",
        "El Centinela",
        "El Pito",
        "General Alvarado",
        "Pla Y Ragnoni",
        "Santa Irene",
        "Villa Copacabana",
        "C De V Chapadmalal",
        "La Caleta",
        "Los Acantilados",
        "Mar De Cobo",
        "Playa Serena",
        "S Clara Del Mar",
        "Santa Clara Del Mar",
        "Siempre Verde",
        "U Tur Chapadmalal",
        "Calfucura",
        "Camet",
        "Cobo",
        "Vivorata",
        "Campamento",
        "Com N Otamendi",
        "Nahuel Ruca",
        "San Julian",
        "San Valentin",
        "Barrio Chapadmalal",
        "Playa Chapadmalal",
        "Balcarce",
        "El Junco",
        "El Verano",
        "El Volante",
        "Haras Ojo Del Agua",
        "La Brava",
        "La Para",
        "Laguna Brava",
        "Los Cardos",
        "Bosch",
        "Ramos Otero",
        "Rincon De Baudrix",
        "San Simon",
        "Campo La Plata",
        "Campo Leite",
        "Campo Pelaez",
        "El Moro",
        "Las Nutrias",
        "San Agustin",
        "Hospital Necochea",
        "La Primitiva",
        "Medano Blanco",
        "Necochea",
        "Puerto Necochea",
        "Valenzuela Anton",
        "Villa Diaz Velez",
        "Costa Bonita B",
        "Haras El Moro",
        "Haras Nacional",
        "La Playa",
        "Quequen",
        "S Miguel Del Moro",
        "V Puerto Quequen",
        "Tamangueyu",
        "El Lenguaraz",
        "Kilometro 440",
        "Loberia",
        "Los Cerros",
        "Malecon Gardelia",
        "Maori",
        "Pieres",
        "La Dulce",
        "Nicanor Olivera",
        "Cooper",
        "Lumb",
        "Energia",
        "B Los Angeles",
        "Ramon Santamarina",
        "El Boqueron",
        "S De Los Padres",
        "Mar Del Sud",
        "S Eduardo Del Mar",
        "El Marquesado",
        "B La Baliza",
        "B Mar De Cobo",
        "B Mar Chiquita",
        "B Santa Elena",
        "B Playa Dorada",
        "B La Caleta",
        "B Frente Mar",
        "B Camet Norte",
        "B Atlantida",
        "Adela Corti",
        "B Juan B Justo",
        "B Loma Paraguaya",
        "B Parque Patagonia",
        "B Vista Alegre",
        "Bahia Blanca",
        "Barrio Almafuerte",
        "Barrio Avellaneda",
        "Barrio La Falda",
        "Barrio Noroeste",
        "Barrio Pedro Rico",
        "Barrio Rosario Sud",
        "Barrio San Blas",
        "Barrio San Martin",
        "Barrio San Roque",
        "Barrio Villa Muqiz",
        "Bordeu",
        "C Bella Vista",
        "Choique",
        "Coronel Maldonado",
        "J B Justo In White",
        "Kilometro 5",
        "Mco De Victoria",
        "Puerto Galvan",
        "V Domingo Pronsato",
        "V Olga Grumbein",
        "Villa Buenos Aires",
        "Villa Cerrito",
        "Villa Delfina",
        "Villa Floresta",
        "Villa Libre",
        "Villa Loreto",
        "Villa Mitre",
        "Villa Nocito",
        "Villa Obrera",
        "Villa Olga Grumbein",
        "Villa Sanchez Elia",
        "Villa Soldati",
        "Villa Bordeau",
        "Chasico",
        "Grunbein",
        "V General Arias",
        "Villa General Arias",
        "Pehuen Co",
        "Villa Del Mar",
        "Aldea Romana",
        "Arias",
        "Calderon",
        "Galvan",
        "Kilometro 11",
        "Kilometro 9 Sud",
        "V Harding Green",
        "Villa Herminia",
        "Garro",
        "Ingeniero White",
        "Pto Bahia Blanca",
        "Spurr",
        "Villa Rosas",
        "Villa Serra",
        "Aguara",
        "Cerri",
        "Colonia La Merced",
        "Cuatreros",
        "G Daniel Cerri",
        "General Daniel Cerri",
        "Kilometro 666",
        "Sauce Chico",
        "B A. Cmte Espora",
        "Comandante Espora",
        "Almirante Solier",
        "Balneario Parada",
        "Desvio Sandrini",
        "Kilometro 652",
        "La Martina",
        "Pehuenco",
        "Punta Alta",
        "Villa Laura",
        "Villa Maio",
        "Arroyo Pareja",
        "Isla Catarelli",
        "Puerto Belgrano",
        "Puerto Rosales",
        "Baterias",
        "Bajo Hondo",
        "La Virginia",
        "Las Oscuras",
        "Paso Mayor",
        "Alferez San Martin",
        "El Cortapie",
        "Empalme P Echada",
        "Lopez Lecube",
        "Nueva Roma",
        "Pelicura",
        "Piedra Ancha",
        "Venancio",
        "Cabildo",
        "Cochrane",
        "Coronel Falcon",
        "Corti",
        "Estomba",
        "Puan",
        "La Viticola",
        "Naposta",
        "Berraondo",
        "General Rondeau",
        "La Pochola",
        "San German",
        "Aldea San Andres",
        "La Colorada Chica",
        "Villa Iris",
        "Estela",
        "Rivadeo",
        "17 De Agosto",
        "Adela Saenz",
        "Felipe Sola",
        "Glorialdo",
        "Medanos",
        "6 De Octubre",
        "Argerich",
        "Colonia Ocampo",
        "Graciarena",
        "Juan Couste",
        "La Gleva",
        "La Rosa",
        "La Tosca",
        "Laguna Chasico",
        "Las Escobas",
        "Las Piedras",
        "Los Morros",
        "Lote 14",
        "Lote 17",
        "Lote 18",
        "Lote 19  C N Leven",
        "Lote 22",
        "Lote 23",
        "Lote 24",
        "Lote 6",
        "Lote 7",
        "Lote 8",
        "Medanos Negros",
        "Nicolas Levalle",
        "Paso Cramer",
        "Rio Salado",
        "Salinas",
        "Salinas Chicas",
        "C Cuarenta Y Tres",
        "C La Catalina",
        "La Blanca",
        "La Celina",
        "La Eva",
        "La Sombra",
        "Montes De Oca",
        "C La Graciela",
        "Colonia Barga",
        "Colonia El Guanaco",
        "Colonia Los Alamos",
        "Colonia Tapatta",
        "Hilario Ascasubi",
        "Ombucta",
        "Paso Alsina",
        "Puerto Coloma",
        "San Adolfo",
        "C Monte La Plata",
        "C Pueblo Ruso",
        "Kilometro 697",
        "La Celia",
        "Teniente Origone",
        "Isla Verde",
        "Mayor Buratovich",
        "Villa Rio Chico",
        "E Las Isletas",
        "Fortin Mercedes",
        "Fortin Viejo",
        "Pedro Luro",
        "Campo La Lima",
        "Coronel Dorrego",
        "Faro",
        "La Luna",
        "La Sirena",
        "Sauce Grande",
        "El Zorro",
        "Gil",
        "Kilometro 563",
        "Nicolas Descalzi",
        "B Sauce Grande",
        "Balneario Oriente",
        "Monte Hermoso",
        "Zubiarre",
        "Zubiaurre",
        "La Soberania",
        "San Roman",
        "Jose A Guisasola",
        "Aparicio",
        "Calvo",
        "Paraje La Aurora",
        "Fortin Chaco",
        "Fuerte Argentino",
        "Tornquist",
        "Garcia Del Rio",
        "Tres Picos",
        "Arquedas",
        "Colonia San Martin",
        "Colonia San Pedro",
        "Dufaur",
        "S Martin De Tours",
        "Saldungaray",
        "S. De La Ventana",
        "Sierra De La Ventana",
        "Abra De Hinojo",
        "Alta Vista",
        "Ducos",
        "Pigue",
        "Arroyo Corto",
        "A Aguas Blancas",
        "La Saudade",
        "Saavedra",
        "Goyena",
        "C Dr Gdor Udaondo",
        "Desvio San Alejo",
        "Viboras",
        "Azopardo",
        "C Hip Yrigoyen",
        "Colonia El Pincen",
        "Erize",
        "La Vascongada",
        "C La Vascongada",
        "Caqada Mariano",
        "Darregueira",
        "Tres Cuervos",
        "El Fisco De Fatima",
        "C Del N Americano",
        "C Phillipson N 1",
        "Campo Los Aromos",
        "Campo San Juan",
        "Colonia Phillipson N 1",
        "Delfin Huergo",
        "Esteban A. Gascon",
        "Monte Fiore",
        "Avestruz",
        "Canonigo Gorriti",
        "Colonia Lapin",
        "Bordenave",
        "La Rosalia",
        "C Lia Y Allende",
        "El Carancho",
        "El Madrigal",
        "El Veraneo",
        "Ex E Hogar Nro 5",
        "La Banderita",
        "La Chita",
        "La Escondida",
        "La Lonja",
        "La Magdalena",
        "La Moderna",
        "La Nilda",
        "La Paloma",
        "La Pampita",
        "La Sorpresa",
        "Las Acacias",
        "Las Dos Naciones",
        "Lote 10",
        "Lote 11",
        "Lote 12",
        "Lote 13",
        "Lote 19",
        "Lote 21",
        "Lote 3",
        "Maraco",
        "Maraco Chico",
        "Medano Colorado",
        "Quiqi Malal",
        "S. De Lihuel Calel",
        "Valle Argentino",
        "Valle Daza",
        "Juan A Pradere",
        "C M Esteverena",
        "Colonia La Celina",
        "Stroeder",
        "Igarzabal",
        "Villa Elena",
        "Villalonga",
    ],
    "Entre Ríos": [
        "A Baltazar Chico",
        "A Bravo Gutierrez",
        "A Bzo De La Tinta",
        "A Des D Gutierres",
        "A Desg Del Sauce",
        "A Gutierrez Chico",
        "A Paciencia Chico",
        "A Paciencia Grande",
        "A Perdido Ceibo",
        "A Perdido Mosquito",
        "A Sagastume Chico",
        "A Sagastume Grande",
        "Aero Club Canal",
        "Arroyo Baltazar",
        "Arroyo Boca Falsa",
        "Arroyo Brazo Chico",
        "Arroyo Carbon",
        "Arroyo Carboncito",
        "Arroyo Carpincho",
        "Arroyo Correntoso",
        "Arroyo Cuzco",
        "Arroyo De La Rosa",
        "Arroyo La Tinta",
        "Arroyo Malambo",
        "Arroyo Martinez",
        "Arroyo Negro",
        "Arroyo Pelado",
        "Arroyo Piedras",
        "Arroyo Qancay",
        "Arroyo Ss Chico",
        "Arroyo Ss Grande",
        "Arroyo Tiroles",
        "Brazo Largo",
        "Canal Nuevo",
        "Canal San Martin",
        "La Chilena Canal",
        "La Chilena Zanja",
        "Paranacito Rio",
        "Rio Paranacito",
        "Colonia El Potrero",
        "Colonia Stauwer",
        "Cuatro Bocas P",
        "Gualeguaychu",
        "La Zelmira",
        "Palavecino",
        "Pria Diamantino",
        "Pueblo Nuevo",
        "Puerto Unzue",
        "Rincon Del Gato",
        "Tres Esquinas",
        "Villa Antony",
        "Villa Eleonora",
        "Villa Lila",
        "Arroyo Del Cura",
        "Arroyo Venerato",
        "Costa Del Uruguay",
        "El Potrero",
        "Gualeyan",
        "Pehuajo Norte",
        "Pueblo G Belgrano",
        "Sarandi",
        "Villa Del Cerro",
        "A Schez Grande",
        "Arroyo Buen Pastor",
        "Arroyo Caballo",
        "Arroyo Principal",
        "Arroyo Salado",
        "Arroyo Schez Chico",
        "Arroyo Zapallo",
        "Britos",
        "Canal Principal",
        "Ceibal",
        "Ceibas",
        "Colonia Italiana",
        "Ctiva Brazo Largo",
        "E S Martin",
        "El Sauce",
        "Faustino M Parera",
        "Isla Del Ibicuy",
        "La Calera - Entre Rios",
        "La Chica",
        "La Cuadra",
        "La Florida",
        "Perdices",
        "Puente Paranacito",
        "Sagastume",
        "V Ftino M Parera",
        "Villa Paranacito",
        "Villa Romero",
        "C Gdor Basavilbaso",
        "Colonia Gdor Basavilbaso",
        "General Almada",
        "Aldea San Juan",
        "Aldea Santa Celia",
        "Costas De S Ant",
        "La Escondida",
        "Pastor Britos",
        "Rincon De Cinto",
        "San Antonio",
        "Urdinarrain",
        "Aldea San Antonio",
        "C Nueva Montevideo",
        "Colonia Nueva Montevideo",
        "Escriña",
        "Gilbert",
        "Glibertescriña",
        "Las Masitas",
        "Las Rosas",
        "Los Amigos",
        "Lucienville 1",
        "Lucienville 2",
        "Lucienville 3",
        "Lucienville 4",
        "Albardon",
        "Arroyo Cle Desvio",
        "Boca Gualeguay",
        "Buena Vista Paraje",
        "Chacras",
        "Colonia Duportal",
        "Cuatro Bocas",
        "Cuatro Manos",
        "Cuchilla",
        "Echague",
        "El Romance",
        "Gonzalez Calderon",
        "Gualeguay",
        "Hojas Anchas",
        "Kilometro 290",
        "Kilometro 303",
        "Kilometro 306",
        "Las Bateas",
        "Las Colas",
        "Lazo",
        "Primer Distrito",
        "Puente Pellegrini",
        "Puerto Barriles",
        "Puerto Ruiz",
        "Punta Del Monte",
        "Quinto Distrito",
        "R Del Nogoya Sur",
        "Saladero Alzua",
        "Saladero San Jose",
        "San Julian",
        "Santa Marta",
        "Septimo Distrito",
        "Aldea Asuncion",
        "General Galarza",
        "G Mansilla",
        "Gobernador Mansilla",
        "Sauce Sud",
        "Libertador Gral San Martin",
        "Anahi",
        "El Empalme Paraje",
        "Empalme Holt",
        "Fernandez",
        "Holt",
        "Ibicuy",
        "Is D L Lechiguanas",
        "Kilometro 389",
        "La Argentina",
        "Liber Gral S M",
        "Mazaruca",
        "Paranacito",
        "Paso Del Cisnero",
        "Puerto Constanza",
        "Puerto Ibicuy",
        "Puerto Perazzo",
        "Puerto San Juan",
        "Gobernador Echague",
        "Berisso",
        "Kilometro 340",
        "Kilometro 361",
        "Medanos",
        "Irazusta Estacion",
        "Alarcon",
        "Berisso Dvio Fcgu",
        "Cuchilla Redonda",
        "Enrique Carbo",
        "La Costa",
        "Talitas-Gualeg.",
        "Dos Hermanas",
        "Larroque",
        "Las Mercedes",
        "Pehuajo Sud",
        "S Nic. De Los As",
        "Pujol",
        "Estancias",
        "Bajada Grande",
        "Cno A Diamante K 1",
        "Corrales Nuevos",
        "Isla Lynch",
        "Oro Verde",
        "Paracao",
        "Parana",
        "Puerto Viejo",
        "Villa Sarmiento",
        "Villa Uranga",
        "A Spatzenkutter",
        "Aldea Brasilera",
        "Aldea S Francisco",
        "Aldea Salto",
        "Aldea Spatzenkutter",
        "Aldea Valle Maria",
        "Arroyo Jacinto",
        "Campo Riquelme",
        "Carrizal",
        "Colonia Ensayo",
        "Colonia Palmar",
        "Costa Grande",
        "Costa Grande Doll",
        "Doctor Garcia",
        "General Alvear",
        "Isla El Pillo",
        "Las Cuevas",
        "Los Gansos",
        "Molino Doll",
        "P De Las Pencas",
        "Paja Brava",
        "Pajonal",
        "Puente Del Doll",
        "Puerto Las Cuevas",
        "Puerto Lopez",
        "Rincon Del Doll",
        "San Francisco",
        "Valle Maria",
        "Aldea Santafecina",
        "C Adven. Del Plata",
        "Libertador S M",
        "Libertador San Martin",
        "Puigari",
        "S Adven Del Plata",
        "S Apeadero Fcgu",
        "V Lib San Martin",
        "Villa Aida",
        "Villa Libertador San Martin",
        "Diamante",
        "Ejido Diamante",
        "Puerto Diamante",
        "Colonia Avellaneda",
        "Distrito Espinillo",
        "Pres Avellaneda",
        "San Benito",
        "Sauce Pinto",
        "Colonia Centenario",
        "Cruc 7A Seccion",
        "El Ramblon",
        "Quebracho",
        "Ramblon",
        "Viale",
        "Strobel",
        "Almacen C Schubert",
        "Arroyo Las Tunas",
        "Arroyo Pancho",
        "Colonia Celina",
        "Colonia San Martin",
        "Curtiembre",
        "La Balsa",
        "Las Tunas",
        "Maria G Primera",
        "Paso De La Balza",
        "San Martin",
        "Tabossi",
        "Tres Lagunas",
        "Colonia Loma Negra",
        "La Balsa Parana",
        "Puerto Curtiembre",
        "Villa Urquiza",
        "Aldea Maria Luisa",
        "C Grapschental",
        "C Maria Luisa",
        "Colonia Reffino",
        "E El Carmen",
        "E El Cimarron",
        "E La Esperanza",
        "E Las Margaritas",
        "Escuela Alberdi",
        "Estacion G Racedo",
        "Kilometro 28",
        "Racedo",
        "V G L Etchevehere",
        "Villa Fontana",
        "Aldea Protestante",
        "Aldea Cuesta",
        "Aldea Eigenfeld",
        "Aldea Merou",
        "Aldea San Jose",
        "Aldea San Miguel",
        "Aldea Santa Rosa",
        "B Del Tigre A Fcgu",
        "Crespo",
        "Espinillo",
        "Kilometro 43",
        "Kilometro 45",
        "Los B Apead. Fcgu",
        "El Taller",
        "Segui",
        "C Ctario Cnia Nvo",
        "Colonia Crespo",
        "Colonia Nueva",
        "Crespo Norte",
        "Distrito Tala",
        "E El Tala",
        "Enrique Berduc",
        "Kilometro 147",
        "La Picada",
        "La Picada Norte",
        "P De Las Piedras",
        "Paso De La Arena",
        "Puente Carmona",
        "Ramon A Parera",
        "Ramon A.Parera",
        "Sauce Montrull",
        "Antonio Tomas",
        "Cerrito",
        "Colonia Argentina",
        "Colonia Rivadavia",
        "Colonia San Juan",
        "El Palenque",
        "Kilometro 131",
        "Moreno",
        "Pueblo Moreno",
        "Aldea Santa Maria",
        "Pueblo General Paz",
        "Dmento G Guemes",
        "Pueblo Brugo",
        "Alcete",
        "Hernandarias",
        "Puerto Viboras",
        "Puerto Villarruel",
        "Villa Hernandarias",
        "Vizcachera",
        "Colonia Berro",
        "C Hernandarias",
        "Paso Potrillo",
        "Piedras Blancas",
        "Puerto Algarrobo",
        "General Racedo",
        "Tezanos Pinto",
        "El Pingo",
        "Kilometro 116",
        "Arroyo Burgos",
        "Arroyo Maria",
        "Bcas Coloradas",
        "C Santa Luisa",
        "Maria G Segda Sur",
        "Maria Grande",
        "Santa Luisa",
        "Sosa",
        "Antonio Tomas Sud",
        "C Oficial N 4",
        "Hasenkamp",
        "La Colmena",
        "La Juliana",
        "La Virginia",
        "Los Naranjos",
        "Santa Sara",
        "Aldea San Rafael",
        "Alcaraz Norte",
        "Colonia La Gama",
        "Las Garzas",
        "Pueblo Bellocq",
        "Talitas",
        "Alcaraz Sud",
        "B Of Las Galarzas",
        "C La Providencia",
        "El Solar",
        "Alcaraz  2Do",
        "Alcaraz 1Ro",
        "Alcaraz P Arrua",
        "Colonia Alcarcito",
        "Colonia Higuera",
        "Colonia Ougrie",
        "Costa Del Payticu",
        "Estancia La Gama",
        "Alcaracito",
        "Bovril",
        "Bovril Dto Nro 12",
        "Colonia Avigdor",
        "Colonia Viraro",
        "El Corcovado",
        "Kilometro 160",
        "La Diligencia",
        "Primer Congreso",
        "Pueblo Ellison",
        "Sir Leonard",
        "Virano",
        "Arroyo Del Medio",
        "Don Gonzalo",
        "El Gramillal",
        "La Encierra",
        "Sauce De Luna",
        "Lucas Gonzalez",
        "Camps",
        "Algarrobito 1Ro",
        "Almirante Iglesias",
        "Boca Del Tigre",
        "Crucecitas Urquiza",
        "Cuarto Distrito",
        "D Crist 1R Seccion",
        "Distrito El Sauce",
        "El Tropezon",
        "Gobernador Febre",
        "La Corvina",
        "La Ilusion",
        "Laurencena",
        "Los Paraisos",
        "Lucas Noreste",
        "Montoya",
        "Nogoya",
        "Pueblito",
        "San Lorenzo",
        "Seccion Urquiza",
        "V Tres De Febrero",
        "Antelo",
        "Colonia Angela",
        "Cruc 3A Seccion",
        "Crucesitas",
        "El Pueblito",
        "Febre",
        "Kilometro 148",
        "La Florencia",
        "La Loma",
        "La Maruja A",
        "Montoya Victoria",
        "Sauce",
        "Kilmetro 165",
        "Paso Del Abra",
        "Puente Victoria",
        "Quebrachito",
        "Victoria",
        "Victoria Estaf 01",
        "Villa Libertad",
        "Costa Del Nogoya",
        "E Punta Alta",
        "Laguna Del Pescado",
        "Pueblito Norte",
        "Puerta De Crespo",
        "Puerto Esquina",
        "Rincon De Nogoya",
        "Sexto Distrito",
        "Tres Bocas",
        "Betbeder",
        "C Algarrabitos",
        "Campo Escales",
        "Hernandez",
        "20 De Setiembre",
        "Chiquero",
        "Colonia La Llave",
        "La Colina",
        "La Favorita",
        "La Llave",
        "Aranguren",
        "Chilcas Sud",
        "D Crist 2D Seccion",
        "Hinojal",
        "Colonia Rivas",
        "Don Cristobal",
        "General Ramirez",
        "Isletas",
        "Ramirez",
        "Rivas",
        "Las Tejas",
        "El Martillo",
        "Alberto Gerchunoff",
        "Basavilbaso",
        "C Lucienville",
        "Colonia N 1",
        "Colonia N 2",
        "Colonia N 3",
        "Colonia N 4",
        "Kilometro 231",
        "Linea 24",
        "Linea 25",
        "Novibuco Primero",
        "Tres Aldeas",
        "Kilometro 208",
        "Rocamora",
        "Altamirano Sud",
        "El Chaja",
        "Hipodromo",
        "Kilometro 180",
        "Kilometro 189",
        "Kilometro 192",
        "Kilometro 220",
        "La Ollita",
        "Las Gauchas",
        "Molino Bob",
        "Primer Cuartel",
        "Puente Obispo",
        "R De Las Guachas",
        "Sauce Norte",
        "Segundo Cuartel",
        "Arroyo Obispo",
        "Gobernador Sola",
        "Rosario Del Tala",
        "Cruc 8A Seccion",
        "Crucecitas 8A Seccion",
        "Estacion Sola",
        "Kilometro 183",
        "Sola",
        "Altamirano Norte",
        "E S Eduardo",
        "E S Eusebio",
        "E S Francisco",
        "Kilometro 200",
        "Kilometro 25",
        "Macia",
        "Raices",
        "Raices Al Este",
        "Raices Al Norte",
        "Durazno",
        "Guardamonte",
        "Federal",
        "Subcentral S Maria",
        "Arroyo Moreira",
        "Colonia Jorge Fink",
        "Jorge Fink",
        "Noreira",
        "Paso Sociedad",
        "Puntas De Moreira",
        "Villamil",
        "Carpinchoris",
        "El Embalsado",
        "Fortuna",
        "La Hierra",
        "Los Conquistadores",
        "San Gustavo",
        "Basualdo",
        "Colonia Basualdo",
        "Colonia Pairiri",
        "Costa Arroyo Garay",
        "Fronteras",
        "La Selva",
        "La Verbena",
        "Lomas Blancas",
        "P Diaz Colodrero",
        "Paraje Portillo",
        "Rincon De Tunas",
        "S J De La Frontera",
        "San Jaime",
        "San Jaime De La Frontera",
        "Atencio",
        "Catalotti",
        "Chircalito",
        "Correa",
        "La Esmeralda",
        "Las Lagunas",
        "Mac Keller",
        "Mesa",
        "Pajas Blancas",
        "S L S J Feliciano",
        "S.J. De Feliciano",
        "San Jose De Feliciano",
        "Tases",
        "Viboras",
        "Estacion Alcaraz",
        "Colonia Falco",
        "Conscrip Bernardi",
        "Conscripto Bernardi",
        "El Cimarron",
        "Villa Perper",
        "Arroyo Hondo",
        "Banderas",
        "C Buena Vista",
        "C Maximo Castro",
        "C Oficial N 11",
        "C Oficial N 3",
        "Centenario Parana",
        "Colonia Carrasco",
        "Colonia La Delia",
        "Colonia Las Gamas",
        "Curuzu Chali",
        "Ejido Sud",
        "El Carmen",
        "El Diecisiete",
        "Estancia San Juan",
        "Isla La Paz",
        "Islas Alcaraz",
        "La Paz",
        "Ombues",
        "P Puerto Augusto",
        "Paso Medina",
        "Piloto Avila",
        "Pto La Esmeralda",
        "Puerto Marquez",
        "Puerto Yunque",
        "Sarandi-Cora",
        "Tacuaras Yacare",
        "Yacare",
        "Yeso",
        "Yeso Oeste",
        "Arroyo Ceibo",
        "Bonaldi",
        "Calandria",
        "Colonia Fontanini",
        "Colonia Oficial N 13",
        "Colonia Oficial N 14",
        "El Gaucho",
        "El Rosario",
        "El Sarandi",
        "Estacas",
        "Floresta",
        "Gonzalez",
        "Isla Curuzu Chali",
        "Las Mulitas",
        "Las Toscas",
        "Manatiales",
        "Martinetti",
        "Mira Monte",
        "Montiel",
        "Palo A Pique",
        "San Geronimo",
        "San Juan",
        "San Ramirez",
        "San Victor",
        "Santa Ines",
        "Santa Maria",
        "Villa Boreilo",
        "El Colorado",
        "El Quebracho",
        "Paso Garibaldi",
        "Puerto Cadenas",
        "Santa Elena",
        "Paso Telegrafo",
        "La Amistad",
        "Libertad",
        "San Fernando",
        "Santa Cecilia",
        "Colonia Los Sauces",
        "Concordia",
        "Los Sauer",
        "Saladero Concordia",
        "T Norte Concordia",
        "C Ayui Grande",
        "C General Roca",
        "Camba Paso",
        "Colonia Navarro",
        "Colonia Yerua",
        "Cueva Del Tigre",
        "Embarc Ferrari",
        "Hervidero",
        "Juan B Monti",
        "Kilometro 6",
        "La Rosada",
        "Nueva Escocia",
        "Puerto Yerua",
        "Ruta 14 Km 443",
        "Villa Zorraquin",
        "Arroyo Grande",
        "Benito Legeren",
        "Calabacillas",
        "Clodomiro Ledesma",
        "Frig. Yuqueri",
        "Kilometro 11",
        "Kilometro 33",
        "Pedermar",
        "Pedernal",
        "San Gregorio",
        "Santa Isabel",
        "Ayui Parada",
        "Chaviyu C Flores",
        "Colonia Don Bosco",
        "Colonia La Gloria",
        "Gualeguaycito",
        "Isthilart",
        "Kilometro 37",
        "Kilometro 44",
        "Bella Union Paraje",
        "Bizcocho",
        "C Gualeguaycito",
        "C Santa Eloisa",
        "C Santa Juana",
        "Chaviyu Pda Fcgu",
        "Colonia Bizcocho",
        "Colonia Flores",
        "Colonia La Argentina",
        "Colonia La Paz",
        "Colonia Lamarca",
        "Colonia Santa Juana",
        "E La Floresta",
        "Estacion Santa Ana",
        "Estancia San Jose",
        "Federacion",
        "Guayaquil",
        "Lamarca",
        "Monte Chico",
        "Colonia Adela",
        "C La Matilde S Ana",
        "Santa Ana",
        "Arroyo El Mocho",
        "Arroyo La Virgen",
        "C San Antonio",
        "C San Bonifacio",
        "Campo Dominguez",
        "Cnia J J Urquiza",
        "Don Roberto",
        "El Duraznal",
        "El Gualeguay",
        "El Pago Apead Fcgu",
        "El Redomon",
        "El Refugio",
        "Kilometro 329",
        "Kilometro 333",
        "Kilometro 344",
        "La Alicia",
        "La Colorada",
        "La Emilia",
        "La Granja",
        "La Invernada",
        "La Nobleza",
        "La Odilia",
        "La Querencia",
        "Loma Negra",
        "Los Brillantes",
        "Los Charruas",
        "Nueva Vizcaya",
        "Osvaldo Magñasco",
        "Paso Del Gallo",
        "S J La Querencia",
        "San Buenaventura",
        "San Jorge",
        "San Pedro",
        "Tte P Brig Cainzo",
        "Yaros",
        "Osvaldo Magnasco",
        "Estacion Yerua",
        "Kilometro 373",
        "Kilometro 376",
        "Parada Yuqueri",
        "Yerua",
        "Yuqueri",
        "Benitez",
        "C La Esperanza",
        "C Oficial Nro 5",
        "Colonia Campos",
        "Colonia Curbelo",
        "Colonia Hebrea",
        "Colonia La Armonia",
        "Colonia La Mora",
        "Colonia La Quinta",
        "El Avestruz",
        "General Campos",
        "Kilometro 343",
        "Kilometro 355",
        "La Perla",
        "La Quinta",
        "Las Mochas",
        "Lucas Norte",
        "Mauricio Ribole",
        "Pueblo Ferre",
        "Puente De Lucas",
        "Walter Moss",
        "La Criolla",
        "Arroyo Palmar",
        "C Nueva Alemania",
        "Colonia Lopez",
        "Colonia San Jose",
        "Kilometro 353",
        "La Carlota",
        "San Salvador",
        "Villaguaycito",
        "Chacras 1A Seccion",
        "Chacras 2A Seccion",
        "Kilometro 161",
        "Kilometro 167",
        "Kilometro 173",
        "Cabi Monda",
        "Chajari",
        "Colonia Alemana",
        "Colonia Belgrano",
        "Colonia Frazer",
        "Colonia V Libertad",
        "Kilometro 84",
        "Mandisovi",
        "Monte Verde",
        "Surst",
        "C Santa Elvira",
        "Colonia Freitas",
        "Colonia San Ramon",
        "Estancia Salinas",
        "Florida",
        "La Soledad",
        "Villa Del Rosario",
        "Baygorria",
        "Estancia Lomatora",
        "La Amelia",
        "La Elena",
        "Parada Pucheta",
        "Tapebicua",
        "Curupi",
        "Empalme Neild",
        "Kilometro 284",
        "Kilometro 285",
        "Kilometro 288",
        "Las Pajitas",
        "Mojones Sur 2Do",
        "S Sec Lucas Al Sud",
        "Villaguay",
        "Campo De Villamil",
        "Colonia Vguaycito",
        "Laguna Larga",
        "Los Ombues",
        "Lucas Sud",
        "Mojones Norte",
        "Mojones Sud 1Ro",
        "Mojones Sud Primero",
        "Paso De La Laguna",
        "Raices Oeste",
        "Rincon De Mojones",
        "Rincon Lucas Norte",
        "Rincon Lucas Sud",
        "Las Moscas",
        "Villaguay Este",
        "C Belga Americana",
        "Colonia Leven",
        "Grupo Parrero",
        "La Amiguita",
        "La Joya",
        "Libaros",
        "Linea 19",
        "Linea 20",
        "Lionel",
        "Mac Dougall",
        "Achiras",
        "Baron Hirsch",
        "C Baron Hirsch",
        "Colonia Achiras",
        "Colonia Carmel",
        "Colonia Ida",
        "Colonia Miguel",
        "Colonia Perliza",
        "Colonia San Manuel",
        "Colonia Sonenfeld",
        "Desparramados",
        "Eben Horoscha",
        "Grupo Achiras",
        "Ing Mig Sajaroff",
        "Ing Miguel Sajaroff",
        "La Capilla",
        "Miguel J Perliza",
        "Pueblo Dominguez",
        "Rachel",
        "Rospina",
        "Villa Dominguez",
        "Colonia Sagastume",
        "Caraguata",
        "Colonia Lucrecia",
        "Estacion Urquiza",
        "Geribebuy",
        "Gobernador Urquiza",
        "Mangrullo",
        "Santa Rosa",
        "Villa San Marcial",
        "Santa Anita",
        "V Ortiz Pereira",
        "Villa Errecaborde",
        "Aldea San Jorge",
        "Belez",
        "C Carlos Calvo",
        "Campo Moreno",
        "Clara",
        "Colonia Belez",
        "Colonia Espindola",
        "Colonia Feimberg",
        "Colonia Guiburg",
        "Colonia La Rosada",
        "Colonia San Jorge",
        "Colonia Sandoval",
        "Colonia Velez",
        "San Vicente",
        "Spindola",
        "Vergara",
        "Villa Clara",
        "C La Blanquita",
        "C La Morenita",
        "C San Ernesto",
        "Colonia La Pampa",
        "Jubileo",
        "Kilometro 325",
        "La Estrella",
        "La Pampa",
        "Arroyo Molino",
        "Balneario Pelay",
        "Colonia Elisa",
        "Colonia Perfeccion",
        "Colonia Ubajay",
        "Concep Del Uruguay",
        "Concepcion Del Uruguay",
        "Estacion Uruguay",
        "Kilometro 108",
        "Kilometro 112",
        "Kilometro 115",
        "La Barraca",
        "La Goya",
        "La Sesteada",
        "La Tigresa",
        "Paso Del Molino",
        "San Justo",
        "C Oficial N 6",
        "Centella",
        "Colonia Cupalen",
        "Colonia Elia",
        "Colonia Luca",
        "Colonia Santa Ana",
        "Colonia Sauce",
        "Cupalen",
        "Kilometro 270",
        "La Maria Luisa",
        "Palacio San Jose",
        "Pto Campinchuelo",
        "Talita",
        "Tomas Rocamora",
        "Villa San Justo",
        "Villa Udine",
        "Caseros",
        "1 De Mayo",
        "C Ensanche Mayo",
        "C Gral Urquiza",
        "C San Cipriano",
        "C Santa Teresita",
        "Centenario La Paz",
        "Colonia Carmelo",
        "Colonia Crucesitas",
        "Kilometro 268",
        "Kilometro 283",
        "Kilometro 293",
        "Pronunciamiento",
        "San Cipriano",
        "Arroyo Caraballo",
        "C Tres De Febrero",
        "Colonia Caraballo",
        "Colonia San Miguel",
        "Kilometro 305",
        "P De Gualeguaychu",
        "Villa Elisa",
        "C De Las Ovejas",
        "C Nueva San Miguel",
        "C San Ignacio",
        "Colonia El Carmen",
        "Colonia Las Pepas",
        "Colonia Santa Rosa",
        "Colonia Vazquez",
        "Arroyo Baru",
        "C Emilio Gouchon",
        "C Santa Elena",
        "Colonia Ambis",
        "Colonia Bailina",
        "Colonia E.Gouchon",
        "Colonia F Sillen",
        "Hambis",
        "Kilometro 311",
        "Kilometro 337",
        "La Clarita",
        "La S Diez Casas",
        "Pueblo Cazes",
        "San Miguel",
        "San Miguel N' 2",
        "Colonia Hocker",
        "C 5 Ensche De Mayo",
        "C Arroyo Urquiza",
        "Colonia 1 De Mayo",
        "Colonia Pereira",
        "E Bella Vista",
        "E C El Toropi",
        "E C La Tapera",
        "E C Peribebuy",
        "E C San Pedro",
        "E C Santa Eloisa",
        "E C Santa Juana",
        "E Cnia L Primavera",
        "E Cnia Santa Elena",
        "E Cnia Sta Teresa",
        "E Colonia El Ombu",
        "E Los Vascos",
        "Ejido Colon",
        "Estancia El Toropi",
        "Fabrica Colon",
        "Genacito",
        "Herrera",
        "Kilometro 242",
        "Kilometro 244",
        "Kilometro 253",
        "Liebig",
        "Nicolas Herrera",
        "Villa Mantero",
        "Villa San Miguel",
        "Colonia Hughes",
        "Arroyo Urquiza",
        "C Nueva Al Sud",
        "Colon",
        "Kilometro 114",
        "Kilometro 310",
        "Kilometro 322",
        "Kilometro 324",
        "Kilometro 336",
        "Kilometro 49",
        "Kilometro 56",
        "Kilometro 88",
        "La Suiza",
        "P Nacel Palmar",
        "Puerto Colorado",
        "Puntas Del Palmar",
        "Calera",
        "Las Achiras",
        "Pueblo Liebig'S",
        "Puerto Almiron",
        "San Anselmo",
        "Yatay",
        "Villa San Jose",
        "C San Francisco",
        "Colonia Mabragaña",
        "Kilometro 99",
        "Perucho Verna",
        "San Jose",
        "El Brillante",
        "Berduc",
        "Juan Jorge",
        "Kilometro 86",
        "Kilometro 89",
        "Mart Leguizamon",
        "Palmar",
        "Pos Pos",
        "Pueblo Colorado",
        "Aldea San Gregorio",
        "Arroyo Concepcion",
        "C La Constancia",
        "C Saenz Valiente",
        "Colonia La Matilde",
        "E La Calera",
        "E Los Monigotes",
        "Isla San Jose",
        "Kilometro 50",
        "Palmar Yatay",
        "Saxto Dto Colon",
        "Ubajay",
        "Don Horacio",
        "Estancia Itaembre",
        "La Milagrosa",
        "Chamorro",
    ],
    "Santa Fe": [
        "Rosario",
        "Villa Angelica",
        "Loma Verde",
        "La Fraternidad",
        "Las Malvinas",
        "Charigue",
        "Albarellos",
        "Monte Flores",
        "Villa Amelia",
        "Coronel Bogado",
        "Juan B. Molina",
        "Stephenson",
        "Juan B Molina",
        "Cañada Rica",
        "Coronel Dominguez",
        "La Carolina",
        "La Vanguardia",
        "Los Muchachos",
        "Pereyra Lucena",
        "Sargento Cabral",
        "Uranga",
        "Alvarez",
        "Soldini",
        "Acebal",
        "Campo Rueda",
        "Carmen Del Sauce",
        "Pavon Arriba",
        "Francisco Paz",
        "Santa Teresa",
        "Peyrano",
        "Colonia Valdez",
        "La Celia",
        "La Othilia",
        "Maximo Paz",
        "Rodolfo Alcorta",
        "Alcorta",
        "Pueblo Alcorta",
        "Cepeda",
        "Empalme Villa Constitucion",
        "Pavon",
        "Theobad",
        "Arminda",
        "Bernard",
        "Erasto",
        "Estacion Erasto",
        "Maizales",
        "Piñero",
        "Pueblo Muñoz",
        "Perez",
        "Pujato",
        "Campo Calvo",
        "Villa Porucci",
        "Colonia Clodomira",
        "Coronel Arnold",
        "Fuentes",
        "Zavalla",
        "Pueblo Nuevo",
        "Villa Gobernador Galvez",
        "Villa San Diego",
        "Alvear",
        "Fighiera",
        "Pueblo Esther",
        "General Lagos",
        "Arroyo Seco",
        "Posta De San Martin",
        "Funes",
        "Roldan",
        "Correa",
        "San Geronimo",
        "San Jeronimo Sud",
        "Carcaraña",
        "Colonia El Carmen",
        "Semino",
        "Chañar Ladeado",
        "La Salada",
        "Campo Medina",
        "Ibarlucea",
        "Kilometro 323",
        "Lucio V. Lopez",
        "Luis Palacios",
        "Salto Grande",
        "Vicente Echeverria",
        "Campo Horquesco",
        "Colonia Medici",
        "Larguia",
        "Totoras",
        "Clason",
        "Los Leones",
        "San Genaro",
        "San Genaro Norte",
        "Villa Biota",
        "Campo Castro",
        "Centeno",
        "Villa Guastalla",
        "Casas",
        "Lucio V Lopez",
        "Lucio V.Lopez",
        "Granadero Baigorria",
        "Las Bandurrias",
        "Capitan Bermudez",
        "Juan Ortiz",
        "Kilometro 319",
        "Villa Cassini",
        "A D G S Lorenzo",
        "Borghi",
        "F Mtar S Lorenzo",
        "Fray Luis Beltran",
        "Granaderos",
        "Tte H Bouchard",
        "Villa Garibaldi",
        "Villa Margarita",
        "Candelaria Sud",
        "Casilda",
        "Colonia Candelaria",
        "Colonia La Costa",
        "Campo Pesoa",
        "Chabas",
        "La Merced",
        "Sanford",
        "Villada",
        "Barlett",
        "Villa Mugueta",
        "Bigand",
        "Bombal",
        "Los Molinos",
        "Arequito",
        "La Viuda",
        "Los Nogales",
        "Pueblo Arequito",
        "C Toscana Primera",
        "C Toscana Segunda",
        "Campo Crenna",
        "S.J. De La Esquina",
        "San Jose De La Esquina",
        "Arteaga",
        "C Lago Di Como",
        "Colonia Los Vascos",
        "Las Quintas",
        "Pino De S Lorenzo",
        "San Lorenzo",
        "Ricardone",
        "Cerana",
        "Cullen",
        "El Transito",
        "Pueblo Kirston",
        "Puerto Gral San Martin",
        "Jesus Maria",
        "Timbues",
        "Timbues Jose Maria",
        "Campo Paletta",
        "Oliveros",
        "Rincon De Grondona",
        "Gaboto",
        "Maciel",
        "Puerto Gaboto",
        "Campo Galloso",
        "Monje",
        "Aldao",
        "Andino",
        "C Tres Marias",
        "Campo Raffo",
        "Serodino",
        "Carrizales",
        "Clarke",
        "Diaz",
        "Campo Garcia",
        "Campo Moure",
        "Carcel M Coronda",
        "Colonia Corondina",
        "Coronda",
        "Larrechea",
        "Arijon",
        "Arocena",
        "Desvio Arijon",
        "Puente Colastine",
        "San Fabian",
        "Barrio Caima",
        "Barrancas",
        "Puerto Aragon",
        "B De Irigoyen",
        "Bernardo De Irigoyen",
        "Campo Brarda",
        "Campo Carignano",
        "Campo Genero",
        "Casalegno",
        "Irigoyen",
        "Colonia Piaggio",
        "Galvez",
        "Campo Gimenez",
        "Gessler",
        "Loma Alta",
        "Oroño",
        "Campo Rodriguez",
        "Lopez",
        "Rigby",
        "S Martin De Tours",
        "San Eugenio",
        "Colonia Belgrano",
        "Granadero B Bustos",
        "Wildermuth",
        "Campo Quiqones",
        "S C De Buena Vista",
        "Santa Clara",
        "Santa Clara De Buena Vista",
        "C Bella Italia",
        "El Bayo",
        "Fassi",
        "Pueblo Terragni",
        "Rafaela",
        "Sierra Pereyra",
        "Tres Colonias",
        "Bella Italia",
        "Capilla San Jose",
        "Presidente Roca",
        "Zanetti",
        "Colonia Fidela",
        "Coronel Fraga",
        "Pueblo Marini",
        "Pueblo Ramona",
        "Vila",
        "Castellones",
        "Susana",
        "Angelica",
        "Kilometro 85",
        "Lehmann",
        "Ataliva",
        "Campo Daratti",
        "Galisteo",
        "C Reina Margarita",
        "Humberto Primero",
        "Reina Margarita",
        "San Miguel",
        "C Castellanos",
        "Colonia Castellanos",
        "Adolfo Alsina",
        "Capivara",
        "Colonia Maua",
        "Constanza",
        "Ituzaingo",
        "Virginia",
        "C San Antonio",
        "Colonia San Antonio",
        "Colonia Berlin",
        "Colonia Ortiz",
        "Cuatro Casas",
        "Doce Casas",
        "Moises Ville",
        "Mutchnik",
        "Veinticuatro Casas",
        "Walvelberg",
        "Estacion Saguier",
        "Casablanca",
        "Colonia Aldao",
        "Colonia Bicha",
        "Colonia Bigand",
        "Eusebia",
        "Hugentobler",
        "R. De Tacurales",
        "Aurelia",
        "Aurelia Norte",
        "Aurelia Sud",
        "Egusquiza",
        "Pueblo San Antonio",
        "Saguier",
        "Cabaqa El Cisne",
        "Raquel",
        "Sunchales",
        "Villa San Jose",
        "Colonia Tacurales",
        "Tacural",
        "Bealistock",
        "Bossi",
        "Colonia Bossi",
        "Las Palmeras",
        "Nueva Lehmann",
        "Palacios",
        "Zadockhan",
        "El Descanso",
        "La Cortadera",
        "Ambrosetti",
        "Ceres",
        "Nueva Ceres",
        "Clavel Blanco",
        "La Elsa",
        "Curupaity",
        "La Rubia",
        "Monigotes",
        "La Geraldina",
        "Villa Trinidad",
        "C Malhman Sud",
        "Colonia Mackinlay",
        "Colonia Rosa",
        "San Guillermo",
        "Colonia Maunier",
        "Colonia Milessi",
        "Colonia Ripamonti",
        "Dos Rosas",
        "Monte Obscuridad",
        "Suardi",
        "Hersilia",
        "C La Victoria",
        "El Charabon",
        "Colonia Castelar",
        "Plaza San Francisco",
        "San Jose Frontera",
        "Bauer Y Sigel",
        "Campo Torquinston",
        "Estacion Josefina",
        "J Manuel Estrada",
        "Colonia Josefina",
        "Colonia Cello",
        "S Clara De Saguier",
        "Santa Clara De Saguier",
        "Campo Clucellas",
        "Campo Romero",
        "Campo Zurbriggen",
        "Clucellas",
        "Clucellas Estacion",
        "Estacion Clucellas",
        "Eustolia",
        "Kilometro 113",
        "Estrada",
        "Kilometro 501",
        "Zenon Pereyra",
        "Colonia Santa Rita",
        "La Udine",
        "C Palo Labrado",
        "Capilla Santa Rosa",
        "Colonia Ceferina",
        "Colonia Gorchs",
        "Desvio Boero",
        "Porteña",
        "La Trinchera",
        "Monte Grande",
        "Frontera",
        "Sastre",
        "Crispi",
        "Colonia Margarita",
        "Garibaldi",
        "Cristolia",
        "E Maria Juana",
        "Mangore",
        "Maria Juana",
        "Pueblo Maria Juana",
        "Los Sembrados",
        "San Vicente",
        "Avena",
        "S M De Las Escobas",
        "San Martin De Las Escobas",
        "C Santa Anita",
        "Colonia La Yerba",
        "Las Petacas",
        "San Jorge",
        "Schiffner",
        "Carlos Pellegrini",
        "Caqada Rosquin",
        "Cañada Rosquin",
        "Kilometro 443",
        "Esmeralda",
        "Kilometro 465",
        "Kilometro 483",
        "Traill",
        "Candioti",
        "Gobernador Candioti",
        "Costa Del Toba",
        "Santa Felicia",
        "Cañada De Gomez",
        "Cicarelli",
        "Las Trojas",
        "Villa La Ribera",
        "Berretta",
        "Granja San Manuel",
        "San Estanislao",
        "San Ricardo",
        "Maria Luisa Correa",
        "Villa Eloisa",
        "Bustinza",
        "Campo La Riviere",
        "Campo Santa Isabel",
        "Las Parejas",
        "Kilometro 49",
        "Armstrong",
        "Campo La Amistad",
        "Campo La Paz",
        "Tortugas",
        "Campo Charo",
        "Campo Gimbatti",
        "La California",
        "Las Liebres",
        "Las Rosas",
        "Montes De Oca",
        "Bouquet",
        "Iturraspe",
        "C San Francisco",
        "Maria Susana",
        "Piamonte",
        "Landeta",
        "Los Cardos",
        "El Trebol",
        "Candelaria Norte",
        "Tais",
        "Los Quirquinchos",
        "Barrio Belgrano",
        "E Bell Ville",
        "El Carmen",
        "Ordoñez",
        "Estacion Teodelina",
        "S M De V Tuerto",
        "Venado Tuerto",
        "Villa Estela",
        "La Inglesita",
        "Murphy",
        "La Chispa",
        "Chapuy",
        "S F De S Fe",
        "San Francisco De Santa Fe",
        "Otto Bemberg",
        "R Fournier",
        "Rastreador Fournier",
        "Santa Isabel",
        "Campo Quirno",
        "Encadenadas",
        "Las Encadenadas",
        "Villa Cañas",
        "C Santa Lucia",
        "Colonia Morgan",
        "Fray Mamerto Esquiu",
        "Maria Teresa",
        "E Christophersen",
        "Runciman",
        "San Gregorio",
        "La Morocha",
        "La Gama",
        "San Eduardo",
        "Sancti Spiritu",
        "Carmen",
        "Kilometro 57",
        "Maggiolo",
        "Kilegruman",
        "Latan Hall",
        "Firmat",
        "Cora",
        "Durham",
        "P. Miguel Torres",
        "Pueblo Miguel Torres",
        "Villa Divisa De Mayo",
        "Chovet",
        "Carlos Dose",
        "Cañada Del Ucle",
        "C La  Catalana",
        "Colonia Hansen",
        "Berabevu",
        "Berabevu 1",
        "C La  Palencia",
        "C La  Pellegrini",
        "C Santa Natalia",
        "Campo Nuevo",
        "Colonia Fernandez",
        "Colonia Gomez",
        "Colonia Piamontesa",
        "Colonia Valencia",
        "Cuatro Esquinas",
        "Santa Natalia",
        "Godeken",
        "Cafferata",
        "El Cantor",
        "C Gral B O Higins",
        "C La Palestina",
        "Piedras Anchas",
        "Wheelwright",
        "Urquiza",
        "Hughes",
        "Merceditas",
        "Santa Emilia",
        "Labordeboy",
        "Melincue",
        "Carreras",
        "El Jardin",
        "Elortondo",
        "Villa Constitucion",
        "Godoy",
        "Oratorio Morante",
        "Rueda",
        "Tres Esquinas",
        "Theobald",
        "Barranquitas",
        "Kilometro 9",
        "Piquete",
        "Pueblo Candioti",
        "San Jose",
        "Santa Fe",
        "Villa Don Bosco",
        "Villa Maria Selva",
        "Villa Yapeyu",
        "Alto Verde",
        "Arroyo Leyes",
        "C Nueva Narciso",
        "C San Joaquin",
        "Calchines",
        "Campo Crespo",
        "Campo Del Medio",
        "Campo Iturraspe",
        "Cayasta",
        "Colastine",
        "Colastine Norte",
        "Colonia Mascias",
        "El Laurel",
        "El Pozo",
        "La Guardia",
        "La Noria",
        "Los Cerrillos",
        "R S Fe La Vieja",
        "Recreo Sur",
        "Rincon Norte",
        "Rincon Potreros",
        "San Joaquin",
        "San Jose Del Rincon",
        "Santa Rosa",
        "Villa Viveros",
        "C Los Zapallos",
        "Colonia Los Zapallos",
        "Helvecia",
        "San Javier",
        "Colonia California",
        "Colonia Francesa",
        "Colonia San Roque",
        "Colonia Teresa",
        "El Ceibo",
        "El Gusano",
        "El Para",
        "Los Cardenales",
        "Ombu Norte",
        "Empalme San Carlos",
        "El Tropezon",
        "Franck",
        "San Carlos Norte",
        "Campo Magnin",
        "Sa Pereyra",
        "San Geronimo Norte",
        "San Mariano",
        "Santa Maria",
        "S Jero Del Sauce",
        "San Jeronimo Del Sauce",
        "Colonia Matilde",
        "Coronel Rodriguez",
        "Estacion Matilde",
        "Las Higueritas",
        "San Carlos Centro",
        "San Carlos Sud",
        "Angel Gallardo",
        "Ascochingas",
        "Campo Lehman",
        "Constituyentes",
        "Kilometro 28",
        "Kilometro 35",
        "Monte Vera",
        "Nueva Pompeya",
        "Pompeya",
        "San Pedro Sur",
        "Setubal",
        "Yamandu",
        "San Jeronimo Norte",
        "Colonia San Jose",
        "Santo Tome",
        "Villa Lujan",
        "Bajo Las Tunas",
        "Sauce Viejo",
        "Iriondo",
        "Recreo",
        "San Agustin",
        "Campo S Domingo",
        "Kilometro 41",
        "Laguna Paiva",
        "Reynaldo Cullen",
        "Campo Andino",
        "El Galpon",
        "La Clorinda",
        "Los Hornos",
        "San Pedro",
        "San Pedro Norte",
        "S Rosa D Calchines",
        "Santa Rosa De Calchines",
        "Cululu",
        "Hipatia",
        "Ingeniero Boasi",
        "Manucho",
        "Progreso",
        "Rinlon De Avila",
        "Tomas Alva Edison",
        "Sarmiento",
        "Colonia Clara",
        "La Clara",
        "Pericota",
        "Providencia",
        "R. Del Quebracho",
        "Rincon Del Quebracho",
        "Santo Domingo",
        "Soledad",
        "Souto Mayor",
        "La Pelada",
        "Maria Luisa",
        "C Adolfo Alsina",
        "Desvio Arauz",
        "Elisa",
        "Arroyo Aguiar",
        "Nelson",
        "Las Tunas",
        "Aromos",
        "C Campo Botto",
        "Emilia",
        "Esther",
        "Lassaga",
        "Llambi Campbell",
        "Rio Salado",
        "Cayastacito",
        "La Sementera",
        "Mariano Saavedra",
        "Asuncion Maria",
        "Avichuncho",
        "E La Constancia",
        "Esquina Grande",
        "Estancia Prusia",
        "Fortin Almagro",
        "La Capilla",
        "San Justo",
        "Vera Mujica",
        "C Ariacaiquin",
        "Cacique Ariacaiquin",
        "Jose Macias",
        "Los Saladillos",
        "Mascias",
        "Abipones",
        "Colonia El Ochenta",
        "Colonia Silva",
        "La Rosa",
        "Marcelino Escalada",
        "Ramayon",
        "Villa Lastenia",
        "Gobernador Crespo",
        "Campo Zavalla",
        "Colonia Dolores",
        "Colonia La Mora",
        "Colonia La Penca",
        "La Brava",
        "San Martin Norte",
        "Arrascaeta",
        "C Manuel Menchaca",
        "Campo Berraz",
        "El Sombrerero",
        "Kilometro 95",
        "La Julia",
        "Las Tres Marias",
        "Los Olivos",
        "Miguel Escalada",
        "Nare",
        "Nueva Ukrania",
        "Paikin",
        "Petronila",
        "Rincon De S Ant",
        "Villa Saralegui",
        "El Sombrerito",
        "C Sol De Mayo",
        "Colonia Angeloni",
        "Colonia Tres Reyes",
        "Luciano Leiva",
        "Pueblo S Bernardo",
        "Videla",
        "Calchaqui",
        "Kilometro 213",
        "La Hosca",
        "Los Galpones",
        "Alejandra",
        "El Pajaro Blanco",
        "Los Corralitos",
        "Los Osos",
        "Colonia La Blanca",
        "La Criolla",
        "Colonia La Negra",
        "Fives Lille",
        "Guaranies",
        "La Camila",
        "La Oriental",
        "Pedro Gomez Cello",
        "Vera Y Pintado",
        "C La Nicolasa",
        "Campo Coubert",
        "Colonia La Maria",
        "Espin",
        "La Guampita",
        "Margarita",
        "La Cigueña",
        "E Los Palmares",
        "Estancia Las Gamas",
        "Estancia Pavenhan",
        "La Gallareta",
        "La Sarnosa",
        "Los Palmares",
        "Pavenhan",
        "Vuelta Del Pirata",
        "Campo San Jose",
        "El Amargo",
        "El Mariano",
        "El Triangulo",
        "Estancia Achala",
        "Fortin Argentina",
        "Fortin Cacique",
        "Fortin Charrua",
        "Fortin Tacuru",
        "Fortin Tostado",
        "Independencia",
        "La Bombilla",
        "Las Arenas",
        "Los Charabones",
        "Sin Pereza",
        "Tostado",
        "Antonio Pini",
        "Cabeza De Chancho",
        "El Nochero",
        "F 6 De Caballeria",
        "Fortin Atahualpa",
        "Kilometro 389",
        "Kilometro 421",
        "Kilometro 468",
        "Padre P Iturralde",
        "San Bernardo",
        "Santa Margarita",
        "Tres Pozos",
        "Villa Minetti",
        "Campo Belgrano",
        "La Delia",
        "La Feliciana",
        "Los Tableros",
        "Campo Garay",
        "Logroño",
        "Pozo Borrado",
        "G Perez De Denis",
        "Gregoria Perez De Denis",
        "El Aguara",
        "Los Molles",
        "San Cristobal",
        "Aguara Grande",
        "Portugalete",
        "La Lucila",
        "Maria Eugenia",
        "Ñanducita",
        "Aguara",
        "Colonia El Simbol",
        "La Cabral",
        "La Polvareda",
        "Las Avispas",
        "Nueva Italia",
        "Santurce",
        "Huanqueros",
        "Kilometro 235",
        "La Verde",
        "Laguna Verde",
        "Esteban Rams",
        "Saladero M Cabral",
        "Colonia Pujol",
        "Esperanza",
        "La Orilla",
        "Paso Vinal",
        "Pueblo Abc",
        "Pujato Norte",
        "Rincon Del Pintado",
        "Cavour",
        "Colonia La Nueva",
        "Humboldt",
        "Humboldt Chico",
        "Rivadavia",
        "Grutly",
        "Grutly Norte",
        "Pilar",
        "Felicia",
        "Nuevo Torino",
        "S Jose Del Rincon",
        "Cabal",
        "Kilometro 302",
        "Aldea Chaleco",
        "Av Ejercito Parana",
        "Quintas Al Sud",
        "R 138 Kilometro 1",
        "Tiro Federal",
        "El 38",
        "El Bonete",
        "P Kilometro 12",
        "Vera",
        "C Monte La Viruela",
        "Colmena",
        "Desvio Km 282",
        "Desvio Km 392",
        "Garabato",
        "Guaycuru",
        "Kilometro 320",
        "Kilometro 392",
        "La Blanca",
        "La Selva",
        "La Zulema",
        "Las Delicias",
        "Los Amores",
        "Los Claros",
        "Los T D Km 366",
        "Ogilive",
        "Paraje Tragnaghi",
        "Pozo De Los Indios",
        "Pueblo Golondrina",
        "Toba",
        "Intiyaco",
        "Ñandu",
        "Colonia Duran",
        "Colonia El Toba",
        "Colonia Sager",
        "El Diecisiete",
        "Fortin Chilcas",
        "Fortin Olmos",
        "Paraje 29",
        "San Roque",
        "Santa Lucia",
        "Campo Huber",
        "La Loma",
        "Las Palmas",
        "Los Cuervos",
        "Romang",
        "Caraguatay",
        "C El Veinticinco",
        "Campo Ubajo",
        "Colonia Yaguarete",
        "La Esmeralda",
        "Las Anintas",
        "Las Catalinas",
        "Las Garsitas",
        "Reconquista",
        "Tres Bocas",
        "Avellaneda",
        "El C De Avellaneda",
        "El Timbo",
        "Ewald",
        "Moussy",
        "Colonia San Manuel",
        "El Araza",
        "La Potasa",
        "La Sarita",
        "Nicanor E Molinas",
        "V Manuel Segundo",
        "Arroyo Del Rey",
        "El Tajamar",
        "Florida",
        "Kilometro 17",
        "Kilometro 30",
        "La Josefina",
        "San Alberto",
        "Tartagal",
        "La Lola",
        "Puerto Reconquista",
        "R Dest Aer Militar",
        "Berna",
        "Campo El Araza",
        "Campo Furrer",
        "Desvio Dr Barros P",
        "La Diamela",
        "C Santa Catalina",
        "Campo Garabato",
        "Campo Ramseyer",
        "Colonia Althuaus",
        "Colonia Ella",
        "El Ricardito",
        "La Catalina",
        "Malabrigo",
        "Capilla G Norte",
        "Guadalupe Norte",
        "Arroyo Ceibal",
        "C Siete Provincias",
        "Campo Grande",
        "Distrito 3 Isletas",
        "El Ceibalito",
        "El Tapialito",
        "Flor De Oro",
        "Ing Chanourdie",
        "Las 7 Provincias",
        "Los Lapachos",
        "Santa Ana",
        "Lanteri",
        "Las Garzas",
        "Kilometro 408",
        "Los Bordos",
        "Polco",
        "Puerto Ocampo",
        "Villa Ocampo",
        "Villa Adela",
        "Villa Ana",
        "Los Laureles",
        "La Clarita",
        "Campo Yaguarete",
        "Ing Garmendia",
        "Ingeniero Germania",
        "Las Toscas",
        "Yaguarete",
        "S Ant De Obligado",
        "San Antonio De Obligado",
        "Tacuarendi",
        "Kilometro 23",
        "Kilometro 54",
        "Obraje I Muerto",
        "Obraje San Juan",
        "Potrero Guasuncho",
        "Villa Guillermina",
        "Campo Hardy",
        "El Rabon",
        "Puerto Piracuacito",
        "Golondrina",
        "Capilla S Antonio",
        "Hospital Rural",
        "La Colonia",
        "Mojon De Fierro",
        "Monte Agudo",
        "Gato Colorado",
        "Teodelina",
        "Diego De Alvear",
        "Solale",
        "Rufino",
        "Villa Rosello",
        "Amenabar",
        "Lazzarino",
        "Aron Castellanos",
        "Santa Regina",
        "Miguel Salas",
        "Rosales",
        "C La Mag De Oro",
        "Gavilan",
        "Julio Arg Roca",
        "Santa Cristina",
        "Colonia Argentina",
        "General Pueyrredon",
        "Vicuqa Mackenna",
        "C La Carmensita",
        "Laguna Oscura",
        "El Olivo",
        "El Tigre",
        "La Elina",
        "Lote 2 La Elina",
    ],
    Córdoba: [
        "Cruz Alta",
        "P De S Lorenzo",
        "Aromito",
        "Colonia Alpina",
        "Colonia Montefiore",
        "La Marina",
        "Campo El Mataco",
        "Colonia Geraldina",
        "Colonia Marina",
        "San Francisco",
        "Esmeraldita",
        "Plaza S Francisco",
        "Luxardo",
        "Colonia Anita",
        "Colonia Eugenia",
        "Colonia Iturraspe",
        "Colonia Prodamonte",
        "Freyre",
        "Santa Rita",
        "Colonia Valtelina",
        "C Nuevo Piamonte",
        "Colonia Lavarello",
        "La Paquita",
        "Altos De Chipion",
        "C La Trinchera",
        "Colonia Udine",
        "La Vicenta",
        "Seeber",
        "Brinkmann",
        "Colonia Botturi",
        "Cotagaita",
        "Colonia Vignaud",
        "C Dos Hermanos",
        "Campo Beiro",
        "Colonia Beiro",
        "Colonia San Pedro",
        "Colonia Tacurales",
        "Los Desagues",
        "Maunier",
        "Morteros",
        "Quebracho Herrado",
        "C Prosperidad",
        "C Santa Maria",
        "Campo Calvo",
        "Campo La Luisa",
        "Colonia La Morocha",
        "Luis Sauze",
        "Monte Redondo",
        "Colonia Cristina",
        "Colonia El Milagro",
        "Colonia El Trabajo",
        "Cristina",
        "Devoto",
        "El Trabajo",
        "Jeanmaire",
        "Kilometro 531",
        "Mariana",
        "La Francia",
        "C San Bartolome",
        "Campo Boero",
        "Colonia San Bartolome",
        "Villa Vieja",
        "C Del Banco Nacion",
        "E La Chiquita",
        "E La Morocha",
        "El Fuertecito",
        "La Tordilla Norte",
        "San Pedro",
        "Arbol Chato",
        "Capilla S Antonio",
        "El Florida",
        "El Tio",
        "Kilometro 581",
        "Los Algarrobitos",
        "Colonia San Rafael",
        "La Frontera",
        "Las Delicias",
        "P De Los Gallegos",
        "Pozo Del Chaja",
        "V C Del Tio",
        "Villa Concepcion Del Tio",
        "Arroyito",
        "Arroyo De Alvarez",
        "La Curva",
        "C La Tordilla",
        "Colonia Coyunda",
        "La Coyunda",
        "La Tordilla",
        "Villa Vaudagna",
        "C A De Alvarez",
        "Colonia Cortadera",
        "Colonias",
        "La Represa",
        "Plaza Bruno",
        "Quebrachitos",
        "Transito",
        "Villa Del Transito",
        "B Belgrano Ortiz",
        "Campo Faggiano",
        "Flora",
        "Saira",
        "Bell Ville",
        "Cuatro Caminos",
        "San Vicente",
        "Los Patos",
        "Justiniano Posse",
        "Campo General Paz",
        "Ordoñez",
        "Pueblo Viejo",
        "Idiazabal",
        "C La Leoncita",
        "Capilla De S Ant",
        "Cintra",
        "Colonia Maschi",
        "El Paraiso",
        "Isleta Negra",
        "Las Overias",
        "Las Palmeras",
        "Los Tasis",
        "Los Ucles",
        "San Antonio De Litin",
        "San Antonio Viejo",
        "Los Molles",
        "Chilibroste",
        "San Eusebio",
        "El Overo",
        "Monte Castillo",
        "Noetinger",
        "San Jose",
        "San Jose Del Salteño",
        "Monte Leña",
        "San Marcos Sud",
        "Las Lagunitas",
        "Morrison",
        "Ballesteros",
        "El Triangulo",
        "Las Merceditas",
        "San Carlos",
        "Ballesteros Sud",
        "C La Muriucha",
        "Colonia Calchaqui",
        "El Panal",
        "Marcos Juarez",
        "Pueblo Argentino",
        "C 25 Los Surgentes",
        "Los Surgentes",
        "P Carlos Sauveran",
        "Pueblo Rio Tercero",
        "General Baldissera",
        "Camilo Aldao",
        "Enfermera Kelly",
        "Inriville",
        "Saladillo",
        "Monte Buey",
        "C Veinticinco",
        "General Roca",
        "Barrio La Fortuna",
        "Colonia El Chaja",
        "La Reduccion",
        "Leones",
        "Boca P 25",
        "Chateaubriand",
        "Rabiola",
        "Km 57",
        "Arias",
        "Cavanagh",
        "Desvio Km 57",
        "Pueblo Gambande",
        "Guatimozin",
        "Villa Fredickson",
        "Villa Regules",
        "V Divisa De Mayo",
        "Cap Gral Bernardo O Higins",
        "Colonia Progreso",
        "Corral De Bustos",
        "O'Higgins",
        "Colonia Italiana",
        "Canals",
        "Colonia La Lola",
        "Colonia Bremen",
        "El Dorado",
        "El Porvenir",
        "La Italiana",
        "Santa Maria",
        "Colonia Bismar",
        "General Viamonte",
        "Viamonte",
        "Colonia Bismarck",
        "Pueblo Italiano",
        "W. Escalante",
        "Wenceslao Escalante",
        "Laborde",
        "Colonia Barge",
        "Matacos",
        "Monte Maiz",
        "Isla Verde",
        "Cortaderas",
        "Alejo Ledesma",
        "Bajo Del Burro",
        "C Ballesteros",
        "Colonia Ledesma",
        "Benjamin Gould",
        "San Meliton",
        "La Carlota",
        "Assunta",
        "Barreto",
        "Pedro E Funes",
        "Santa Eufemia",
        "Chazon",
        "Santa Victoria",
        "Ucacha",
        "Campo Sol De Mayo",
        "Pascanas",
        "Etruria",
        "Los Cisnes",
        "Olmos",
        "Colonia Maipu",
        "Alejandro",
        "Alejandro Roca",
        "Doce De Agosto",
        "Mtles Grandes",
        "Tambo Nuevo",
        "Caminiaga",
        "Cerro Colorado",
        "Paso Viejo",
        "Lozada",
        "Malagueño",
        "Rafael Garcia",
        "Malvinas Argentinas",
        "Piquillin",
        "El Eje",
        "Santa Rosa",
        "Esquina",
        "Diego De Rojas",
        "La Puerta",
        "Villa Fontana",
        "Saldan",
        "S. De Ycho Cruz",
        "V Rio Ycho Cruz",
        "Ycho Cruz Sierras",
        "Cabalango",
        "Cordoba",
        "Cordoba - Alberdi",
        "Cordoba - Alta Cordoba",
        "Cordoba - Bella Vista",
        "Cordoba - Centro",
        "Cordoba - Guemes",
        "Cordoba - Independencia",
        "Cordoba - Nueva Cordoba",
        "Cordoba - Olivos",
        "Cordoba - Parque Sarmiento",
        "Cordoba - Paso De Los Andes",
        "Cordoba - Providencia",
        "Cordoba - San Martin",
        "Cordoba - Ayacucho",
        "Cordoba - Cda General Bustos",
        "Cordoba - Centro America",
        "Cordoba - General Bustos",
        "Cordoba - H Yrigoyen",
        "Cordoba - Hipolito Yrigoyen",
        "Cordoba - La Fraternidad",
        "Cordoba - Alto Alberdi",
        "Cordoba - Estadio Chateau Carreras",
        "Cordoba - General Deheza",
        "Cordoba - Las Dalias",
        "Cordoba - Las Violetas",
        "Cordoba - Los Platanos",
        "Cordoba - Los Robles",
        "Cordoba - Parque Republica",
        "Cordoba - San Salvador",
        "Cordoba - Tranviario",
        "Cordoba - A Alberdi / Marechal",
        "Cordoba - Chateau Carreras",
        "Cordoba - Gral Urquiza",
        "Cordoba - Parque Don Bosco",
        "Cordoba - Quebrada Las Rosas",
        "Cordoba - Res Ch Carreras",
        "Cordoba - Sale Sitram",
        "Cordoba - Villa Paez",
        "Cordoba - Villa Silburu",
        "Cordoba - Villa Urquiza",
        "Cordoba - Alto Gralpaz",
        "Cordoba - Bajada De Piedra",
        "Cordoba - Gral Paz / Juniors",
        "Cordoba - Juniors",
        "Cordoba - Pueyrredon",
        "Cordoba - Renacimiento",
        "Cordoba - Villa Cabrera",
        "Cordoba - Yapeyu",
        "Cordoba - Yapeyu / A Gral Paz",
        "Cordoba - 1 De Mayo",
        "Cordoba - 1 De Mayo / Miralta",
        "Cordoba - Amp Empalme",
        "Cordoba - El Mirador",
        "Cordoba - Empalme Amp",
        "Cordoba - Ferriviario Mitre",
        "Cordoba - Los Ceibos",
        "Cordoba - Maldonado",
        "Cordoba - Mauller",
        "Cordoba - Parque San Vicente",
        "Cordoba - Renacimiento/1 De Mayo",
        "Cordoba - San Vicente",
        "Cordoba - San Vicente/Mauller",
        "Cordoba - El Condor",
        "Cordoba - Las Magnolias",
        "Cordoba - Las Margaritas",
        "Cordoba - Las Margaritas/A S Martin",
        "Cordoba - Lomas De San Martin",
        "Cordoba - Los Paraisos",
        "Cordoba - M De Sobremonte",
        "Cordoba - M Sobremonte",
        "Cordoba - Poeta Lugones",
        "Cordoba - San Martin Anexo",
        "Cordoba - San Martin N",
        "Cordoba - A Centeno",
        "Cordoba - Alborada",
        "Cordoba - Alejandro Centeno",
        "Cordoba - Alto V Cabrera",
        "Cordoba - Alto Verde",
        "Cordoba - C Del Cerro",
        "Cordoba - Cerro Chico",
        "Cordoba - Cerro Las Rosas",
        "Cordoba - Col Del Cerro",
        "Cordoba - Colinas Del Cerro",
        "Cordoba - Las Rosas",
        "Cordoba - Providencia - Villa Cabrera",
        "Cordoba - Santa Cecilia",
        "Cordoba - Tablada Park",
        "Cordoba - Urca",
        "Cordoba - V Centenario",
        "Cordoba - Villa Los Angeles",
        "Cordoba - Villa Marta",
        "Cordoba - Aeronatico",
        "Cordoba - Aeronautico",
        "Cordoba - Ameghino Norte",
        "Cordoba - Avenida",
        "Cordoba - E Flores",
        "Cordoba - El Trebol",
        "Cordoba - Lamadrid",
        "Cordoba - Las Flores",
        "Cordoba - Los Granados",
        "Cordoba - Obrero",
        "Cordoba - San Francisco",
        "Cordoba - San Roque",
        "Cordoba - Santa Ana",
        "Cordoba - Est Flores",
        "Cordoba - Estacion Flores",
        "Cordoba - Industrial",
        "Cordoba - Ivifa",
        "Cordoba - Parque Capital",
        "Cordoba - Amp Residencial America",
        "Cordoba - General Savio",
        "Cordoba - Guayaquil",
        "Cordoba - Leandro N Alem",
        "Cordoba - Los Patricios Norte",
        "Cordoba - Nueva Italia",
        "Cordoba - Patricios",
        "Cordoba - Patricios O Y N",
        "Cordoba - Res America",
        "Cordoba - San Nicolas",
        "Cordoba - Villa Azalais",
        "Cordoba - Yofre Norte",
        "Cordoba - Yofre Norte/Sud",
        "Cordoba - Palmar",
        "Cordoba - Palmar / Amp Palmar",
        "Cordoba - Villa Los Pinos",
        "Cordoba - Yofre Sud",
        "Cordoba - Ampliacion San Pablo",
        "Cordoba - Carcano",
        "Cordoba - Celso",
        "Cordoba - Cerveceros",
        "Cordoba - Cnel Olmedo",
        "Cordoba - Colon",
        "Cordoba - Coronel Olmedo",
        "Cordoba - Crisol N",
        "Cordoba - Crisol Norte",
        "Cordoba - Ferroviario Mitre",
        "Cordoba - J Espinosa",
        "Cordoba - Jardin Del Pilar",
        "Cordoba - Jardin Espinosa",
        "Cordoba - Jardin/Res San Carlos",
        "Cordoba - Jose Ignacio Diaz Seccion 2",
        "Cordoba - Lourdes",
        "Cordoba - Oña/B Masse",
        "Cordoba - Palamaro Onofrio",
        "Cordoba - Res San Carlos/B Masse",
        "Cordoba - San Pablo/R S Carlos",
        "Cordoba - Talleres Sud",
        "Cordoba - V San Carlos",
        "Cordoba - Villa Revol",
        "Cordoba - Altos De Velez Sarsfield",
        "Cordoba - Cañaveral",
        "Cordoba - Ipona/ S Fernando",
        "Cordoba - J Hipodromo",
        "Cordoba - Jardin",
        "Cordoba - Kennedy",
        "Cordoba - Los Cerveceros",
        "Cordoba - Los Olmos",
        "Cordoba - Parque Latino",
        "Cordoba - Parque Los Molinos",
        "Cordoba - Parque Velez Sarsfield",
        "Cordoba - Pque Atlantica",
        "Cordoba - Res Country Club",
        "Cordoba - Resid Velez Sarsfield",
        "Cordoba - S Fernan/ Gral Artigas",
        "Cordoba - Suarez",
        "Cordoba - Velez Sarsfield",
        "Cordoba - Villa Eucaristica",
        "Cordoba - A Carbo/Comercial",
        "Cordoba - Cabildo",
        "Cordoba - Colina Los Pinos",
        "Cordoba - Comercial",
        "Cordoba - Los Olmos Sur",
        "Cordoba - San Luis De Francia",
        "Cordoba - Santa Isabel 1",
        "Cordoba - Santa Isabel 2",
        "Cordoba - Santa Isabel 3",
        "Cordoba - Villa El Libertador",
        "Cordoba - Saldan",
        "Cordoba - Villa Allende Parque",
        "Cordoba - Villa Rivera Indarte",
        "Cordoba - 28",
        "Cordoba - Guiñazu",
        "Cordoba - J L De Cabrera",
        "Cordoba - J L De La Cabrera",
        "Cordoba - Jorge Newbery",
        "Cordoba - Liceo 2",
        "Cordoba - Newbery/J L Cabrera",
        "Cordoba - Parque Liceo",
        "Cordoba - Parque Liceo 1 2 3",
        "Cordoba - Pque Liceo",
        "Cordoba - Remedios De Escalada",
        "Cordoba - 25 De Mayo",
        "Cordoba - Alte Brown",
        "Cordoba - Avellaneda",
        "Cordoba - Dean Funes",
        "Cordoba - Ferreyra",
        "Cordoba - Ituzaingo Anexo",
        "Cordoba - Los Algarrobos",
        "Cordoba - Los Fresnos",
        "Cordoba - Los Huallacanes",
        "Cordoba - Los Mistoles",
        "Cordoba - Los Molles",
        "Cordoba - Los Sauces",
        "Cordoba - Los Talas",
        "Cordoba - Marcelo T De Alvear",
        "Cordoba - Rucci",
        "Cordoba - San Javier",
        "Cordoba - San Lorenzo Sud",
        "Cordoba - Villa Ofelia",
        "Cordoba - Alto Hermoso",
        "Cordoba - Arguello",
        "Cordoba - Arguello Sur",
        "Cordoba - Arguello/Los Nogales",
        "Cordoba - El Mirador De La Recta",
        "Cordoba - El Refugio",
        "Cordoba - Granja De Funes",
        "Cordoba - Granja Funes",
        "Cordoba - Hermoso",
        "Cordoba - Lasalle",
        "Cordoba - Los Carolinos",
        "Cordoba - Parque Modelo",
        "Cordoba - Portal Del Jacaranda",
        "Cordoba - Portal Jacaranda",
        "Cordoba - Quintas De Arguello",
        "Cordoba - Residencial San Jose",
        "Cordoba - Villa Alborada",
        "Cordoba - Villa Arguello",
        "Cordoba - Villa Belgrano",
        "Cordoba - Villa Claret",
        "Cordoba - Villa Solferino",
        "Cordoba - Villa Warcalde",
        "Villa Allende",
        "Cordoba - 9 De Julio",
        "Cordoba - Arguello Norte",
        "Cordoba - Autodromo",
        "Cordoba - Boulevares",
        "Cordoba - Brigadier San Martin",
        "Cordoba - Cerro Norte",
        "Cordoba - El Rincon",
        "Cordoba - Granja Funes-Los Boulevares",
        "Cordoba - La Tablada",
        "Cordoba - Los Boulevares",
        "Cordoba - Mercantil",
        "Cordoba - Rivera Indarte",
        "Cordoba - Silvano Funes",
        "Cordoba - Suquia",
        "Cordoba - U O C R A",
        "Cordoba - Uocra",
        "Cordoba - Villa 9 De Julio",
        "Cordoba - Villa Cornu",
        "Cordoba - Villa Cornui",
        "Cordoba - Villa Serrana",
        "Cordoba - Walker",
        "Cordoba - Comando 3 Cuerpo De Ejercito",
        "Los Aguirres",
        "La Cumbre",
        "San Esteban",
        "Expediciàn Co",
        "Bajo De Fernandez",
        "Bajo Grande",
        "Capilla De Cosme",
        "Colonia Cosme Sud",
        "Colonia Tirolesa",
        "E De Artilleria",
        "El Gateado",
        "El Quebrachal",
        "General Las Heras",
        "La Cocha",
        "Lagunilla",
        "Las Playas Lozada",
        "Los Cedros",
        "Los Olivares",
        "Paso Del Sauce",
        "Villa Esquiu",
        "Yocsina",
        "Tirolesa",
        "Villa Cerro Azul",
        "Agua De Oro",
        "Mendiolaza",
        "Unquillo",
        "Candonga",
        "Rio Ceballos",
        "Santa Ana",
        "Salsipuedes",
        "El Manzano",
        "La Granja",
        "Asconchinga",
        "La Pampa",
        "La Paz",
        "San Jorge",
        "San Miguel",
        "Monte Ralo",
        "Alto De Fierro",
        "Alto Del Durazno",
        "Bouwer",
        "Coronel Olmedo",
        "Duarte Quiros",
        "La Lagunilla",
        "Monte Gde R Garcia",
        "San Antonio Norte",
        "Laboulaye",
        "Alto Alegre",
        "Buena Vista",
        "Despeqaderos",
        "Despeñaderos",
        "San Antonio",
        "Barrio Dean Funes",
        "Ferreyra (Cordoba)",
        "Toledo",
        "Santiago Temple",
        "Santiago Temple (Cordoba)",
        "C Sagrada Familia",
        "Capilla De Dolores",
        "Constitucion",
        "Higuerillas",
        "Kilometro 658",
        "Kilometro 691",
        "La Celina",
        "Los Chañaritos",
        "Monte Cristo",
        "Pozo De La Loma",
        "Charcas Norte",
        "Las Cabras",
        "Los Guindos",
        "Los Mansillas",
        "Pedro E Vivas",
        "Rio Primero",
        "Tala Sud",
        "Mi Granja",
        "Capilla La Espza",
        "Castellanos",
        "Comechingones",
        "El Crispin",
        "Espinillo",
        "Isla Del Cerro",
        "Isla Larga",
        "La Buena Parada",
        "La Estrella",
        "Las Acacias",
        "Las Piguas",
        "Punta Del Agua",
        "Buey Muerto",
        "El Alcalde",
        "Esperanza",
        "Las Higuerillas",
        "Pueblo Pianelli",
        "Puesto De Afuera",
        "Quebrachos",
        "Rangel",
        "Tala Norte",
        "El Carrizal",
        "El Espinal",
        "La Cienaga",
        "Los Alvarez",
        "Los Chaqares",
        "Pozo De La Esquina",
        "S Rosa De Rio 1:",
        "Santa Rosa De Rio Primero",
        "Villa Santa Rosa",
        "C Ancha S. Rosa",
        "C Las Cuatro Esqs",
        "Colonia El Fortin",
        "Corral De Gomez",
        "Las Averias",
        "Las Gramillas",
        "M De Toro Pujio",
        "Pozo La Piedra",
        "Tordilla Norte",
        "La Quinta",
        "Balneario Guglieri",
        "C La Argentina",
        "Colonia Caqadon",
        "Colonia Yareta",
        "Costa Del Castaqo",
        "El Bagual",
        "El Tostado",
        "Kilometro 294",
        "Kilometro 316",
        "La Mostaza",
        "La Para",
        "Las Hileras",
        "Las Saladas",
        "Lomas Del Trozo",
        "Los Aviles",
        "Los Castaqos",
        "Los Cerros",
        "Los Miguelitos",
        "Plaza De Mercedes",
        "Pzo D Los Troncos",
        "San Ramon",
        "Soledad",
        "Villa Mar Chiquita",
        "Campo Coyunda",
        "El Tesoro",
        "Kilometro 271",
        "P Rio Plujunta",
        "Playa Grande",
        "Primavera",
        "San Rafael",
        "Balnearia",
        "Jeronimo Cortes",
        "Plujunta",
        "La Cautiba",
        "La Serranita",
        "Barrio Muller",
        "Miramar",
        "Vacas Blancas",
        "Recreo Victoria",
        "Estacion General Paz",
        "Guiñazu",
        "Juarez Celman",
        "Mayu Sumaj",
        "Arguello",
        "V S. Cruz Del Lago",
        "Los Molinos",
        "Cas Ffousth E Fcgb",
        "Dique San Roque",
        "Kilometro 608",
        "R.Indarte",
        "San Roque",
        "Villa Warcarde",
        "La Calera",
        "La Calera - Cba",
        "Cuesta Blanca",
        "V Parque Siquiman",
        "Villa Carlos Paz",
        "Villa Parque Siquiman",
        "Villa Santa Cruz Del Lago",
        "Copina",
        "Puesto Guzman",
        "S Ant De Arredondo",
        "San Antonio De Arredondo",
        "Tala Huasi",
        "V Cuesta Blanca",
        "Villa Costa Azul",
        "Agua De Tala",
        "Angostura",
        "Batan",
        "Buen Retiro",
        "C Banco Pcia Bs As",
        "Casa Nueva",
        "Cuchilla Nevada",
        "Dos Rios",
        "El Durazno",
        "El Peruel",
        "El Pilcado",
        "El Potrero",
        "El Vergel",
        "Estancia Dos Rios",
        "Guasta",
        "La Caqada",
        "Los Gigantes",
        "Mallin",
        "Tanti",
        "Tanti Lomas",
        "Tanti Nuevo",
        "Villa Flor Serrana",
        "V Suiza Argentina",
        "Villa Suiza Argentina",
        "Bialet Masse",
        "Las Casitas",
        "Los Puentes",
        "Parque Siquiman",
        "Villa Rio Ycho Cruz",
        "Distrito Pueblo",
        "Casa Grande",
        "Domingo Funes",
        "S. M De Punilla",
        "San Buenaventura",
        "Santa Maria De Punilla",
        "Villa Bustos",
        "Villa Caeiro",
        "H Flia Dgo Funes",
        "Sanatorio Santa Maria",
        "Cosquin",
        "El Perchel",
        "Kilometro 592",
        "Molinari",
        "Pampa De Olaen",
        "Villa Ahora",
        "Villa Anisacate",
        "D Las Vaquerias",
        "Irigoyen",
        "Kilometro 579",
        "La Cantera",
        "La Usina",
        "Los Helechos",
        "Piedra Grande",
        "Valle Hermoso",
        "Villa El Descanso",
        "Villa La Bolsa",
        "El Callejon",
        "El Cuadrado",
        "El Puente",
        "El Vallecito",
        "Gr De San Antonio",
        "La Falda",
        "La Primavera",
        "La Quebrada",
        "Las Playas",
        "Piedras Grandes",
        "Rio Grande",
        "Alto De San Pedro",
        "Huerta Grande",
        "Piedra Movediza",
        "Piedras Blancas",
        "S Rosa Huerta Gde",
        "Casa Serrana",
        "Villa Giardino",
        "Barrio De Galli",
        "Cascadas",
        "Cruz Chica",
        "El Pingo",
        "Alto Castro",
        "Dolores S Esteban",
        "Dolores San Esteban",
        "El Baldecito",
        "El Vado",
        "Las Pampillas",
        "Los Cocos",
        "Los Mogotes",
        "San Ignacio",
        "Sauce Arriba",
        "Cajon Del Rio",
        "Capilla Del Monte",
        "Caq De Los Mogotes",
        "Corimayo",
        "El Aguila Blanca",
        "El Zapato",
        "La Piedra Movediza",
        "Las Gemelas",
        "Las Vaquerias",
        "Los Terrones",
        "Ongamira",
        "Punilla",
        "Quebrada De Nona",
        "Suncho Huico",
        "Uritorco",
        "Alta Gracia",
        "Bosque Alegre",
        "Canteras Alta Grac",
        "La Isla",
        "La Isolina",
        "La Paisanita",
        "La Punta Del Agua",
        "Las Higueritas",
        "Potrero De Tutzer",
        "V C Pellegrini",
        "Villa Del Prado",
        "Villa Los Aromos",
        "Villa Santa Maria",
        "Golpe De Agua",
        "La Falda Del Carm",
        "La Falda Del Carmen",
        "La Granadilla",
        "Los Paraisos",
        "San Clemente",
        "San Nicolas",
        "Anisacate",
        "Bajo Chico",
        "Bajo Del Carmen",
        "C San Igancio",
        "C. De Anizacate",
        "Colonia San Isidro",
        "Colonia Toro Pujio",
        "Dos Arroyos",
        "F De Los Reartes",
        "Fabrica Militar",
        "J De La Quintana",
        "Jose De La Quintana",
        "La Betania",
        "Los Algarrobos",
        "Obregon",
        "Potrero De Funes",
        "Potrero De Garay",
        "Puesto Mulita",
        "Rio Los Molinos",
        "Sierras Morenas",
        "Solar Los Molinos",
        "Tercera",
        "V C De America",
        "V La Rancherita",
        "Villa Ciudad De America",
        "Villa Satite",
        "Calmayo",
        "Cerro Blanco",
        "Potrero De Lujan",
        "San Agustin",
        "Soconcho",
        "Dique Los Molinos",
        "Los Sarmientos",
        "Atos Pampa",
        "La Cumbrecita",
        "Los Reartes",
        "V General Belgrano",
        "Villa Alpina",
        "Villa General Belgrano",
        "Arroyo Seco",
        "Atumi Pampa",
        "Caqada Del Durazno",
        "Carahuasi",
        "Colonia Alemana",
        "Colonia La Calle",
        "El P De La Montaqa",
        "El Portezuelo",
        "El Sauce",
        "La Choza",
        "S R De Calamuchita",
        "Santa Rosa De Calamuchita",
        "Sarlaco",
        "El Carmen",
        "Rincon De Luna",
        "Rio Del Durazno",
        "Rio Grande  Amboy",
        "Santa Monica",
        "Villa El Corcovado",
        "Vista Alegre",
        "Y Calamuchita",
        "Yacanto Calamuchita",
        "Amboy",
        "C De Las Chacras",
        "Las Sierritas",
        "Mar Azul",
        "Villa Amancay",
        "Villa El Torreon",
        "Villa Lago Azul",
        "Villa San Javier",
        "Canteras Km 428",
        "Caqada Del Simbol",
        "Cerro De La Cruz",
        "Corito",
        "Dean Funes",
        "El Portillo",
        "Ingeniero Bertini",
        "Kilometro 430",
        "Kilometro 832",
        "La Isabela",
        "La Mesada",
        "Las Pencas",
        "Los Puestitos",
        "P De Los Rodriguez",
        "Puesto De Cerro",
        "Puesto Del Medio",
        "Sajon",
        "San Bernardo",
        "San Pedro De Toyos",
        "Sauce Chiquito",
        "Sauce Punco",
        "Toro Muerto",
        "Yerba Buena",
        "Calasuya",
        "Calchin",
        "Chuqa Huasi",
        "Copacabana",
        "El Carr Chuqaguasi",
        "El Pertigo",
        "El Rodeito",
        "El Tala",
        "Ischilin",
        "La Batalla",
        "La Caqada S Cruz",
        "La Colonia",
        "La Higuerita",
        "La Posta Chuqaguas",
        "La Zanja",
        "Las Aguaditas",
        "Las Caqas",
        "Las Crucecitas",
        "Las Palmas",
        "Las Palomitas",
        "Lobera",
        "Los Brinzes",
        "Los Cejas",
        "Los Coquitos",
        "Los Ruices",
        "Santa Cruz",
        "Todos Los Santos",
        "Totrilla",
        "Villa Colimba",
        "Alto De Flores",
        "El Ojo De Agua",
        "El Paso",
        "Iti Huasi",
        "Las Juntas",
        "Majadilla",
        "Tulumba",
        "Alto Verde",
        "Camarones",
        "El Cerrito",
        "El Rosario",
        "La Laguna",
        "Sevilla",
        "Aguada Del Monte",
        "Aguadita",
        "Campo Alegre",
        "Caspichuma",
        "Caspicuchana",
        "Invernada",
        "Jarillas",
        "Jume",
        "La Totorilla",
        "Las Jarillas",
        "Loma Blanca",
        "Movado",
        "Navarro",
        "Pozo Nuevo",
        "Rodeito",
        "Santo Domingo",
        "Socorro",
        "La Aguada",
        "Macha",
        "Avellaneda",
        "Barranca Yaco",
        "El Coro",
        "El Talita",
        "Juan Garcia",
        "Kilometro 807",
        "Kilometro 827",
        "La Majada",
        "Las Lomitas",
        "Las Manzanas",
        "Los Pedernales",
        "Los Pozos",
        "Sarmiento",
        "Villa Gutierrez",
        "El Baqado",
        "El Molino",
        "El Veinticinco",
        "Isla De S Antonio",
        "Kilometro 859",
        "Kilometro 865",
        "Kilometro 881",
        "La Barranca",
        "La Botija",
        "La Florida",
        "La Ruda",
        "Las Chacras",
        "Las Toscas",
        "Los Cadillos",
        "Los Morteros",
        "Los Socabones",
        "Orcosuni",
        "Puesto De Arriba",
        "Quilino",
        "Villa Quilino",
        "Acollarado",
        "Agua Hedionda",
        "Arbol Blanco",
        "El Tuscal",
        "Kilometro 907",
        "Kilometro 931",
        "Lucio V Mansilla",
        "S Jose D L Salinas",
        "San Jose De Las Salinas",
        "Totoralejos",
        "Tuscal",
        "Caqada De Mayo",
        "Chuqa",
        "Chuña",
        "El Chanchito",
        "El Jume",
        "El Mojoncito",
        "El P Los Cabrera",
        "El Quebracho",
        "El Ranchito",
        "Jaime Peter",
        "Kilometro 450",
        "La Aura",
        "La Caqada Angosta",
        "Las Canteras",
        "Las Tuscas",
        "Los Tartagos",
        "Puesto De Batalla",
        "Jesus Maria",
        "A De Las Piedras",
        "Algarrobo",
        "Bajo De Olmos",
        "C De Rio Pinto",
        "Cabindo",
        "Campo La Piedra",
        "Candelaria",
        "Caqada De Jume",
        "Caqada De Mateo",
        "Caqadas Hondas",
        "Cañada De Rio Pinto",
        "Cerro Negro",
        "Corral De Barranca",
        "Cruz Del Quemado",
        "Dr Nicasio S Oroqo",
        "El Algarrobo",
        "La Porteqa",
        "Las Palmitas",
        "Los Cometierra",
        "Los Miquiles",
        "Miquilos",
        "Pozo Conca",
        "Pozo Correa",
        "Quiscasacate",
        "Rio De Los Sauces",
        "Rio De Los Talas",
        "Rio Pinto",
        "San Lorenzo",
        "San Pellegrino",
        "Santa Catalina",
        "Santa Sabina",
        "Santa Teresa",
        "Sinsacate",
        "Villa Albertina",
        "Villa Cerro Negro",
        "Caroya",
        "Colonia Caroya",
        "Atahona",
        "Campo Ramallo",
        "Las Bandurrias N",
        "Miguelito",
        "Obispo Trejo",
        "Pozo Del Moro",
        "Puesto De Pucheta",
        "Ramallo",
        "Caqada Honda",
        "La Posta",
        "Maquinista Gallini",
        "Puesto De Fierro",
        "San Salvador",
        "Cabeza De Buey",
        "Campo Alvarez",
        "Caqada De Luque",
        "Cañada De Luque",
        "Chalacea",
        "Desvio Chalacea",
        "E Las Mercedes",
        "El Bosque",
        "Estancia Bottaro",
        "Estancia El Taco",
        "Estancia Las Rosas",
        "Kilometro 364",
        "La Dora",
        "Los Mistoles",
        "Santa Lucia",
        "Tintizaco",
        "Totoral",
        "Bajo Hondo",
        "Camoati",
        "Campo Grande",
        "Candelaria Sud",
        "El Guanaco",
        "El Rincon",
        "El Vence",
        "L A Providencia",
        "La Esperanza",
        "La Maza",
        "La Palma",
        "La Penca",
        "Las Aromas",
        "Las Arrias",
        "Las Masitas",
        "Providencia",
        "Puesto De Luna",
        "Tajamares",
        "La Rinconada",
        "Candelaria Norte",
        "Villa Del Totoral",
        "Villa General Mitre",
        "Villa Totoral (Cordoba)",
        "Carrizal Tama",
        "C Los Morales",
        "Kilometro 394",
        "Las Peqas",
        "Las Peñas",
        "Chacras Viejas",
        "Puesto San Jose",
        "Simbolar",
        "Agua Del Tala",
        "Beuce",
        "Caqada Del Tala",
        "Chacras Del Sauce",
        "Chipitin",
        "Durazno",
        "E El Nacional",
        "El Desmonte",
        "El Guindo",
        "El Pantano",
        "El Sebil",
        "Guallascate",
        "La Costa",
        "La Plaza",
        "Laguna Brava",
        "Laguna De Gomez",
        "Las Horquetas",
        "Las Quintas",
        "Loma De Piedra",
        "Los Alamos",
        "Miraflores",
        "Pisco Huasi",
        "Pozo Solo",
        "Puesto Viejo",
        "S Jose D L Dormida",
        "San Gabriel",
        "San Jose De La Dormida",
        "Carnero Yaco",
        "Casas Vejas",
        "Chaqar Viejo",
        "Chile C Al Agauda",
        "Chilli Corral",
        "Churqui",
        "Churqui Caqada",
        "Corral Viejo",
        "El  Pantanillo",
        "El Rodeo",
        "La Piedra Blanca",
        "Ladera Yacus",
        "Las Trancas",
        "Lo Machado",
        "Los Cajones",
        "Los Cerrillos",
        "Los Quebrachos",
        "Los Troncos",
        "Paso Del Silverio",
        "Pozo De Juancho",
        "Rayo Cortado",
        "Rio Pedro",
        "Rojas",
        "Santa Elena",
        "Vanguardia",
        "Balbuena",
        "Baqado Del Fuerte",
        "Caqa Cruz",
        "Caqada De Coria",
        "El Gabino",
        "El Gallego",
        "El Jordan",
        "El Laurel",
        "El Prado",
        "El Puesto",
        "El Silverio",
        "Eufrasio Loza",
        "La Pintada",
        "Las Cardas",
        "Pocito Del Campo",
        "San Igancio",
        "Santanilla",
        "Villa De Maria",
        "Villa Maria De Rio Seco",
        "Yanacato",
        "El Algarrobal",
        "El Mangrullo",
        "El Simbol",
        "El Tule",
        "Estancia Patiqo",
        "Gutemberg",
        "La Cruz",
        "La Quintana",
        "La R.Ada Candelari",
        "Las Flores",
        "Los Hoyos",
        "Los Justes",
        "Los Pocitos",
        "Puerta De Los Rios",
        "Punta Del Monte",
        "Pzo De Los Arboles",
        "Rio Dulce",
        "Rio San Miguel",
        "Rio Viejo",
        "Serrezuela",
        "Puesto De Vera",
        "Balde Salado",
        "Cuatro Esquinas",
        "Dique Los Sauces",
        "El Consuelo",
        "El Fraile",
        "Hunquillal",
        "Los Barriacitos",
        "Pozo Del Medio",
        "San Cristobal",
        "El Cienago",
        "Esquina Grande",
        "Cruz Del Eje",
        "Barrial",
        "Canteras De Quilpo",
        "El Brete",
        "El Simbolar",
        "Esq Del Alambre",
        "Guanaco Muerto",
        "La Lilia",
        "La Virginia",
        "Las Tapias",
        "Los Chaqaritos",
        "Medio Naranja",
        "Palo Parado",
        "Pozo Del Simbol",
        "Puesto Del Gallo",
        "Calabalumba",
        "Chacha Del Rey",
        "Charbonier",
        "El Frances",
        "El Salto",
        "Escobas",
        "La Fronda",
        "La Gramilla",
        "Los Guevara",
        "Los Paredones",
        "Los Piquillines",
        "Los Sauces",
        "Quebrada De Luna",
        "San Marcos Sierra",
        "Santa Isabel",
        "Aguas De Ramon",
        "Barrialitos",
        "Bella Vista",
        "Cachiyullo",
        "Chacras",
        "Chacras Del Potrer",
        "Desaguadero",
        "El Caracol",
        "Estacion Soto",
        "Kilometro 541",
        "La Puert V De Soto",
        "Las Caqadas",
        "Las Lomas",
        "Las Tinajeras",
        "Los Pantalles",
        "Mandala",
        "Palo Quemado",
        "Paloma Pozo",
        "Paso De Montoya",
        "Pdras Amontonadas",
        "Pichanas",
        "Piedras Anchas",
        "Ramblon",
        "Rio Seco",
        "Sendas Grandes",
        "Tala Del Rio Seco",
        "Tasacuna",
        "Totora Guasi",
        "Villa De Soto",
        "Agua De Crespin",
        "Baqado De Soto",
        "Bañado De Soto",
        "Canteras Iguazu",
        "Carrizal",
        "Casas Viejas",
        "El Barrial",
        "El Guaico",
        "El Rio",
        "Guasapampa",
        "La Mesilla",
        "Las Totoritas",
        "Mesa De Mariano",
        "Piedra Blanca",
        "Pozo Seco",
        "Represa De Morales",
        "Rumiguasi",
        "Tres Arboles",
        "Tuclame",
        "C La Candelaria",
        "Capilla La Candelaria",
        "Characato",
        "Cruz De Caña",
        "Majada De Santiago",
        "Oro Grueso",
        "Rara Fortuna",
        "Cienaga Del Coro",
        "La Playa",
        "Ramirez",
        "Rumiaco",
        "Tosno",
        "C De Las Gatiadas",
        "Chaqariaco",
        "E De Guadalupe",
        "El Sunchal",
        "El Vallesito",
        "La Bismutina",
        "La Estancia",
        "Los Barriales",
        "Mina La Bismutina",
        "Mogote Verde",
        "Ninalquin",
        "Pajonal",
        "Paso Grande",
        "S De Las Paredes",
        "San Carlos Minas",
        "Sapansoto",
        "Sierra De Abregu",
        "Sunchal",
        "Talaini",
        "Totoritas",
        "Tres Esquinas",
        "Tres Lomas",
        "Cerro Bola",
        "La Argentina",
        "Las Cortaderas",
        "Los Ojos De Agua",
        "Ojo De Agua",
        "Pocho",
        "Alto Del Tala",
        "Casa Blanca",
        "Cuchillo Yaco",
        "La Esquina",
        "Las Rosas",
        "Piedritas Rosadas",
        "Pitoa",
        "S De Los Quevedos",
        "Salsacate",
        "Tres Chaqares",
        "Villa Taninga",
        "Viso",
        "Arcoyo",
        "Arroyo",
        "Caqada Del Puerto",
        "La Sierrita",
        "Mina Araujo",
        "Potrero De Marques",
        "Puerta De La Q",
        "Rio Hondo",
        "Sagrada Familia",
        "San Geronimo",
        "Tala Caqada",
        "Tala Cañada",
        "Ambul",
        "Caqada De Pocho",
        "Caqada De Salas",
        "Chamico",
        "La Aguadita",
        "La Mudana",
        "La Tablada",
        "Loma Redonda",
        "Mussi",
        "Pusisuna",
        "Taruca Pampa",
        "Amilgancho",
        "Ampata",
        "El Duraznillo",
        "El Plumerillo",
        "Estacion 69",
        "La Lata",
        "Medano",
        "Pozo De Avila",
        "Pozo Escondido",
        "Punta Del Negro",
        "San Guillermo",
        "San Martin",
        "Trampa Del Tigre",
        "Monte De Los Gauchos",
        "La Bolsa",
        "Piedra Pintada",
        "Rio Cuarto",
        "Alpa Corral",
        "Cuatro Vientos",
        "El Bañado Rio Cuarto",
        "Las Cañitas",
        "Las Higueras",
        "Rodeo Viejo",
        "Villa El Chacay",
        "Reduccion",
        "Carnerillo",
        "Colonia Santa Paula",
        "Bengolea",
        "Charras",
        "Olaeta",
        "General Cabrera",
        "Coronel Baigorria",
        "Alcira",
        "Alcira E Gigena",
        "Alcira Estacion",
        "Alcira Estacion Gigena",
        "Alpapuca",
        "Bajada Nueva",
        "Capilla De Tegua",
        "Dos Lagunas",
        "El Barreal",
        "El Chiquillan",
        "El Espinillal",
        "Gigena",
        "Gigena Alcira",
        "La Ramoncita",
        "Laguna Clara",
        "Tegua",
        "Elena",
        "Los Medanos",
        "La Dormida",
        "Berrotaran",
        "Caqada Del Sauce",
        "Cañada Del Sauce",
        "Las Gamas",
        "Paso Cabral",
        "Puerta Colorada",
        "Arroyo Santana",
        "Arroyo Toledo",
        "Caqada De Alvarez",
        "El Manantial",
        "Las Peqas Sud",
        "Paso Sandialito",
        "Sierra Blanca",
        "Villa La Coba",
        "Cañada De Alvarez",
        "La Calera Calamuchit",
        "Las Caleras",
        "Campo San Antonio",
        "Cerro San Lorenzo",
        "El Cano",
        "Estacion Achiras",
        "Guindas",
        "Los Cerros Negros",
        "Permanentes",
        "R De Los Caballos",
        "Rodeo Las Yeguas",
        "Chucul",
        "Las Peqas Norte",
        "Los Condores",
        "Modesto Acuqa",
        "Arroyo S Catalina",
        "Arsenal J M Rojas",
        "Holmberg",
        "Las Ensenadas",
        "Las Vertientes",
        "Chaqaritos",
        "Laguna Seca",
        "Sampacho",
        "Soria",
        "La Colorada",
        "S Nicolas Punilla",
        "Achiras",
        "La Barranquita",
        "Dominguez",
        "La Aurora",
        "Los Cuadros",
        "Piquillines",
        "Porvenir",
        "Uspara",
        "Volcan Estanzuela",
        "Chajan",
        "G Fernandez",
        "San Lucas Norte",
        "Suco",
        "Malena",
        "L Jagueles",
        "Los Jagueles",
        "Zapoloco",
        "Carolina",
        "Colonia Orcovi",
        "La Mercantil",
        "Las Cinco Cuadras",
        "San Basilio",
        "Yatay",
        "Adela Maria",
        "Adelia Maria",
        "E Punta De Agua",
        "Bulnes",
        "La Cascada",
        "C La Celestina",
        "Colonia Dean Funes",
        "Coronel Moldes",
        "Fragueyro",
        "La Gilda",
        "Las Acequias",
        "San Ambrosio",
        "B Del Libertador",
        "Colonia Luque",
        "F Militar Rio 3:",
        "Los Potreros",
        "Rio Tercero",
        "C Santa Catalina",
        "Colonia Santa Catalina",
        "Las Bajadas",
        "Monsalvo",
        "Los Tres Pozos",
        "Corralito",
        "Almafuerte",
        "Embalse",
        "Villa Segunda",
        "Cerros Asperos",
        "U Nuclear Embalse",
        "Villa Del Tala",
        "Villa Quillinzo",
        "Lutti",
        "Tigre Muerto",
        "Villa Del Dique",
        "El Torreon",
        "Valle Dorado",
        "Villa Del Parque",
        "Villa Naturaleza",
        "Villa Rumipal",
        "Barrio La Feria",
        "Chaquinchuna",
        "Corral De Ceballos",
        "La Cañada",
        "La Concepcion",
        "La Ventana",
        "Las Palomas",
        "Nicho",
        "San Anotnio",
        "Tabanillo",
        "Villa Dolores",
        "Acostilla",
        "Altautina",
        "Balde De La Mora",
        "Balde De La Orilla",
        "Balde Lindo",
        "Baqado De Paja",
        "Cartaberol",
        "Chua",
        "Concepcion",
        "Condor Huasi",
        "El Bordo",
        "El P De La Pampa",
        "La A De Las Animas",
        "La Alegria",
        "La Compasion",
        "La Cortadera",
        "La Finca",
        "La Jarilla",
        "La Linea",
        "La Loma",
        "La Patria",
        "La Trampa",
        "Las Barrancas",
        "Las Encrucijadas",
        "Las Oscuras",
        "Lomitas",
        "Los Callejones",
        "Los Dos Pozos",
        "Medanitos",
        "Pozo De La Pampa",
        "Pueblo Sarmiento",
        "Quebracho Solo",
        "S Miguel  Chancani",
        "S Miguel S Vicente",
        "S P. De S Alberto",
        "Villa Sarmiento",
        "Arboles Blancos",
        "Capilla De Romero",
        "Caqada Grande",
        "Conlara",
        "Isla",
        "La Angostura",
        "La Celia",
        "Los Manguitos",
        "Manguitas",
        "P De Las Sierras",
        "Pozo Del Chaqar",
        "Pozo Del Molle",
        "Punta De Agua",
        "Salto",
        "Tilquicho",
        "Zapata",
        "Alto De Las Mulas",
        "Chuchiras",
        "Come Tierra",
        "Diez Rios",
        "Guanaco Boleado",
        "Huasta",
        "La Poblacion",
        "La Ramada",
        "La Travesia",
        "Luyaba",
        "Rio De Jaime",
        "Rodeo De Piedra",
        "San Isidro-Cba",
        "San Javier",
        "Yacanto",
        "Rincon Del Este",
        "Santa Lucinda",
        "Talita",
        "Villa Luisa",
        "Alto Lindo",
        "Balcarce",
        "Balde",
        "El Recuerdo",
        "Estancia",
        "Los Espinillos",
        "Villa Elena",
        "Los Hornillos",
        "Dique La Viqa",
        "El Alto",
        "Hornillos",
        "Las Calles",
        "Las Cebollas",
        "Las Conanitas",
        "Las Rabonas",
        "Villa Clodomira",
        "Villa De Las Rosas",
        "Nono",
        "Rio Arriba",
        "Arroyo La Higuera",
        "Arroyo Los Patos",
        "Caqada Larga",
        "El Bajo",
        "El Corte",
        "Mina Clavero",
        "Nido Del Aguila",
        "Niqa Paula",
        "Quebrada Del Horno",
        "San Sebastian",
        "Casa De Piedra",
        "Cienaga De Allende",
        "El Mirador",
        "J Bautista Alberdi",
        "La Guardia",
        "Mogigasta",
        "V Cura Brochero",
        "Villa Cura Brochero",
        "Alto Grande",
        "Pachango",
        "Panaholma",
        "Tasma",
        "La Herradura",
        "Las Cuatro Esqs",
        "Las Pichanas",
        "Monte De Los Lazos",
        "Ramon J Carcano",
        "Villa Aurora",
        "Villa Emilia",
        "Villa Maria",
        "Arroyo Del Pino",
        "Cayuqueo",
        "Kilometro 267",
        "Sanabria",
        "Villa Nueva",
        "Ana Zumaran",
        "Arroyo Algodon",
        "Las Mojarras",
        "C Silvio Pellico",
        "Colonia Silvio Pellico",
        "La Playosa",
        "Corral Del Bajo",
        "India Muerta",
        "Carrilobo",
        "Arroyo Cabral",
        "Colonia Yucat Sud",
        "Luca",
        "Dalmacio V Sarsfi",
        "Dalmacio Velez Sarsfield",
        "Las Perdices",
        "General Deheza",
        "Ausonia",
        "Ferreyra",
        "Carlomagno",
        "La Reina",
        "Los Reyunos",
        "Maria",
        "Pasco",
        "Sarmica",
        "Ticino",
        "Hernando",
        "Los Zorros",
        "La Victoria",
        "Las Isletillas",
        "Monte Del Frayle",
        "Pampayasta Sud",
        "C La Primavera",
        "C Santa Margarita",
        "Colonia Hamburgo",
        "El Porteqo",
        "General Fotherigham",
        "Tancacha",
        "Villa Ascasubi",
        "Cap S Ant De Yucat",
        "Colonia Santa Rita",
        "S Antonio De Yucat",
        "San Antonio De Yucat",
        "Tio Pujio",
        "Corral De Guardia",
        "Las Varillas",
        "Trinchera",
        "Colonia Angelita",
        "Las Varas",
        "Campo Bandillo",
        "Laspiur",
        "Satur M Laspiur",
        "Saturnino M Laspiur",
        "C General Deheza",
        "Corral De Mulas",
        "La Pobladora",
        "Sacanta",
        "El Araqado",
        "El Arañado",
        "El Jumial",
        "Pozo Del Avestruz",
        "Villa San Esteban",
        "Alicia",
        "La Tigra",
        "El Florentino",
        "El Fortin",
        "La Rosarina",
        "Overa Negra",
        "La Palestina",
        "Rio Segundo",
        "Caqada De Machado",
        "Costa Sacate",
        "Rincon",
        "Capilla Del Carmen",
        "El Corralito",
        "Villa Del Rosario",
        "Calchin Oeste",
        "Las Junturas",
        "Luque",
        "Matorrales",
        "Colazo",
        "Pilar",
        "Pampayasta Norte",
        "Laguna Larga",
        "Pampayasta Sur",
        "Oliva",
        "G Fotheringham",
        "General Fotheringham",
        "James Craik",
        "Oncativo",
        "Campo Rossiano",
        "Colonia Almada",
        "Colonia Garzon",
        "Impira",
        "Manfredi",
        "Colonia La Beba",
        "E Agri Salesiana",
        "Estacion Ascension",
        "Arenales",
        "E G Arenales",
        "Ham",
        "La Huayqueria",
        "Arribeqos",
        "Colonia Los Hornos",
        "Desvio Km 95",
        "La Pinta",
        "San Marcelo",
        "C Los Bosques",
        "Campo Coliqueo",
        "Campo La Tribu",
        "La Tribu",
        "Los Huesos",
        "Villa Rossi",
        "La Cesira",
        "Nicolas Bruzone",
        "Italo",
        "Guardia Vieja",
        "El Rastreador",
        "Huanchilla",
        "Melo",
        "San Joaquin",
        "Serrano",
        "Jovita",
        "Santa Magdalena",
        "Leguizamon",
        "Rossi",
        "General Lavalle",
        "General Levalle",
        "Rio Bamba",
        "Vicuña Mackenna",
        "Tosquita",
        "General Soler",
        "La Cautiva",
        "Washington",
        "Villa Huidobro",
        "San Pedro Norte",
        "San Pablo",
        "El Tajamar",
        "L 5 Caleufu  E 120",
        "La Juanita",
        "3 Hnos Quetrequen",
        "El Tordillo",
        "San Hilario",
        "San Juan Simson",
        "Santa Gracia",
        "Lote 11 E 107",
        "Lote 15",
        "C La Margarita",
        "C San Basilio",
        "Casimiro Gomez",
        "Jardon",
        "La Margarita",
        "La Pomona",
        "La Pvera Chamaico",
        "Cochequingan",
        "El Cinco",
        "La Cherindu",
        "La Elenita",
        "Hipolito Bouchard",
        "Onagoity",
        "Colonia Dorotea",
        "Huinca Renanco",
        "De La Serna",
        "Del Campillo",
        "Mattaldi",
        "Pincen",
        "Ranqueles",
        "Lecueder",
        "Villa Valeria",
        "Cañada Verde",
        "Centenario",
        "E 30 De Octubre",
        "El Piche",
        "Gloria A Dios",
        "La Aroma",
        "La Bolivia",
        "La Cora",
        "La Felisa",
        "La Maria Esther",
        "Nueva Esperanza",
        "Cañadon De Los Mogotes",
    ],
    "Santiago del Estero": [
        "Achaval Rodriguez",
        "Arrufo",
        "Colonia Dos Rosas",
        "E San Francisco",
        "Hugentobler",
        "Los Porongos",
        "Campo Botto",
        "Colonia Ana",
        "Chañar Sunichaj",
        "Colonia Mackinlay",
        "El Aibal",
        "El Aspirante",
        "El Oso",
        "El Ucle",
        "Fortin La Viuda",
        "Kilometro 735",
        "La Blanca",
        "La Carolina",
        "La Centella",
        "La Esmeralda",
        "La Recompensa",
        "La Romelia",
        "La Union",
        "La Victoria",
        "Las Palmas",
        "Las Viboritas",
        "Los Encantos",
        "Maravilla",
        "Nueva Trinidad",
        "Palmas",
        "San Jose",
        "San Sebastian",
        "Santa Ana",
        "Selva",
        "Tres Lagunas",
        "Tres Pozos",
        "Argentina",
        "Casares",
        "Palo Negro",
        "Malbran",
        "Alarcon",
        "Arbol Negro",
        "Azucena",
        "C Ramon Laplace",
        "Capilla",
        "Cleveland",
        "Colonia Ermelinda",
        "Colonia Paula",
        "Doña Lorenza",
        "El Destino",
        "El Galpon",
        "El Solitario",
        "Guardia Vieja",
        "Kilometro 764",
        "La Casimira",
        "Las Abras De S Ant",
        "Las Almas",
        "Las Delicias",
        "Libertad",
        "Los Milagros",
        "Martin Paso",
        "Monte Crecido",
        "Pinto",
        "Pinto - V G Mitre",
        "Pinto - Villa Genaral Mitre",
        "Punta Del Garabato",
        "Punta Del Monte",
        "Quebrachitos",
        "San Agustin",
        "San Rufino",
        "Santa Paula",
        "Tome Y Traiga",
        "C S. Rosa Aguirre",
        "El Huaico",
        "Estancia El Carmen",
        "Las Abras",
        "Las Mostazas",
        "Las Ramaditas",
        "Martinez",
        "Retiro",
        "Villa Union",
        "Yaco Misqui",
        "La Milka",
        "Villani",
        "Desvio Pozo Dulce",
        "El Cañon",
        "El Crucero",
        "El Jardin",
        "El Once",
        "El Setenta",
        "El Simbol",
        "Guardia Escolta",
        "La Aida",
        "La Dora",
        "La Isleta",
        "La Libia",
        "La Magdalena",
        "La Santafecina",
        "Las Chilcas",
        "Las Isletas",
        "Las Mochas",
        "Las Teresas",
        "Pozo Dulce",
        "San German",
        "Fortin Inca",
        "Bandera",
        "Colonia Alsina",
        "Don Pietro",
        "El Agricultor",
        "El Candelero",
        "El Tobiano",
        "Isla Baja",
        "La Alemana",
        "La Dolores",
        "La Esperanza",
        "La Eulalia",
        "La Francisca",
        "La Hiedra",
        "La Huerta",
        "La Pampa",
        "La Panchita",
        "La Rosilla",
        "La Simona",
        "La Susana",
        "La Teresa",
        "Las Aguadas",
        "Las Gamas",
        "Los Paraisos",
        "Nueva Aurora",
        "Sanavirones",
        "Santa Catalina",
        "Selva Blanca",
        "C Independencia",
        "Fortin Alerta",
        "Kilometro 293",
        "Portalis",
        "Barrio Algarrobo",
        "E Las Salinas",
        "El Simbolar",
        "Estancia Del Medio",
        "La Celina",
        "Nueva Esperanza",
        "Oscuro",
        "San Antonio",
        "Santa Irene",
        "Tacuaritas",
        "Uruguay",
        "Justino Solari",
        "Kilometro 261",
        "Maria Del Carmen",
        "Maria Idalina",
        "Solari Mno I Loza",
        "Estacion Durazno",
        "Belgica",
        "Campo La Angelita",
        "Cnel Manuel L Rico",
        "Colonia El Peligro",
        "Coronel Manuel Leoncio Rico",
        "Desvio Km 1342",
        "El Aerolito",
        "El Cabure",
        "El Silencio",
        "Kilometro 1297",
        "Kilometro 1314",
        "Kilometro 1362",
        "Kilometro 1380",
        "Kilometro 1391",
        "La Angelita",
        "La Armonia",
        "Las Perforaciones",
        "Lavalle",
        "Los Morteros",
        "Los Pirpintos",
        "Los Tigres",
        "Pamp D L Guanacos",
        "Pampa De Los Guanacos",
        "Pozo Vil",
        "Puesto Cordoba",
        "Puesto Del Medio",
        "San Horacio",
        "San Pedro",
        "Santa Maria",
        "Urundel",
        "Santa Elena",
        "9 De Julio",
        "Agua Buena",
        "Atahualpa",
        "Botija",
        "Castellin",
        "Colombia",
        "El Guanaco",
        "El Indio",
        "El Palmar",
        "El Palomar",
        "El Paraiso",
        "El Valla",
        "Fierro",
        "Kilometro 1183",
        "Kilometro 1210",
        "Kilometro 1255",
        "La Aguada",
        "La Argentina",
        "La China",
        "La Firmeza",
        "La Ilusion",
        "La Paloma",
        "La Pinta",
        "La Providencia",
        "La Sara",
        "La Tranquilidad",
        "La Virtud",
        "Las Carpas",
        "Las Flores",
        "Lorena",
        "Los Magos",
        "Los Tobas",
        "Lote 33",
        "Madre De Dios",
        "Monte Quemado",
        "Nueva York",
        "Obraje Los Tigres",
        "P Independencia",
        "P Kilometro 77",
        "Paaj Pozo",
        "Palo Blanco",
        "Pampa Bolsa",
        "Pampa Cabure",
        "Pampa El Fosforito",
        "Pampa El Mangrullo",
        "Pampa El Mollar",
        "Pampa Pelado",
        "Pampa Pereyra",
        "Pampa Quimili",
        "Pampa Ralera",
        "Pampa Virgen",
        "Paraje Ojo De Agua",
        "Paraje Santa Cruz",
        "Pozo Hondo",
        "S Teresa D Carball",
        "San Luis",
        "San Martin",
        "San Telmo",
        "Santa Agueda",
        "Santa Rosa",
        "Santa Rosa Copo",
        "Taco Pozo",
        "Urutau",
        "Colonia Malgratti",
        "La Flecha",
        "Loro Blanco",
        "Pampa Oculta",
        "Punta Rieles",
        "Sachayoj",
        "General Capdevilla",
        "Pueblo Puca",
        "Gancedo",
        "Los Quebrachitos",
        "Campo Moreno",
        "Colonia La Tota",
        "El Arbolito",
        "El Bravo",
        "El Porongal",
        "El Puma",
        "El Saladillo",
        "La Cuchilla",
        "Los Fortines",
        "Viboras",
        "Zuberbuhler",
        "Agua Salada",
        "Calderon",
        "Campo Del Infierno",
        "Campo El Rosario",
        "Dos Represas",
        "E Nueva Esperanza",
        "El Urunday",
        "El Veinte",
        "Girardet",
        "Huchupayana",
        "Roversi",
        "Taco Fura",
        "Tres Mojones",
        "Aibal",
        "Anchilo",
        "Barrio Obrero",
        "Bella Vista",
        "Campo Del Cielo",
        "Campo Limpio",
        "Cartavio",
        "Cañada Limpia",
        "Cejolao",
        "Colonia España",
        "Colonia Media",
        "Dolores",
        "Dos Eulacias",
        "Dos Hermanas",
        "El Aibalito",
        "El Descanso",
        "El Fisco",
        "El Noventa",
        "El Ojo De Agua",
        "El Veintisiete",
        "Estancia La Elsita",
        "Jdin D L Delicias",
        "Juncal Grande",
        "Kilometro 48",
        "La Loma",
        "Laguna Baya",
        "Los Pensamientos",
        "Los Puentes",
        "Maria",
        "Minerva",
        "Nogales",
        "P Obraje M Ange",
        "Paraje El Prado",
        "Paraje Gauna",
        "Paraje La Pampa",
        "Paraje Lilo Viejo",
        "Paraje Milagro",
        "Paraje V Yolanda",
        "Pirhuas",
        "Proviru",
        "Puesto De Mena",
        "Puma",
        "Quimili",
        "Rumi",
        "Saldivar",
        "San Nicolas",
        "Santa Justina",
        "Tinajeraloj",
        "Villa Guañuna",
        "Villa Matilde",
        "Aerolito",
        "Agustina Libarona",
        "Alhuampa",
        "Doble Tero",
        "Donadeu",
        "Dos Eulalias",
        "El Colorado",
        "El Fisco De Fatima",
        "El Tanque",
        "Genoveva",
        "Granadero Gatica",
        "Haase",
        "Hernan Mejia Mira",
        "Kilometro 606",
        "Kilometro 719",
        "La Curva",
        "La Marta",
        "Las Porteñas",
        "Los Gatos",
        "Los Pecariel",
        "Los Porteños",
        "Lote F",
        "Magdalena",
        "Morayos",
        "Octavia",
        "Otumpa",
        "Pozo Del Toba",
        "San Alberto",
        "San Miguel",
        "E Pampa Muyoj",
        "El Prado",
        "Milagro",
        "Monte Alto",
        "Obraje M Angelica",
        "Tintina",
        "Villa Yolanda",
        "Central Dolores",
        "El 21",
        "El Hoyo",
        "Hualo Cancana",
        "Huilla Catina",
        "Kilometro 694",
        "La Chejchilla",
        "La Guardia",
        "Lilo Viejo",
        "Patay",
        "Pozo Castaño",
        "Quilumpa",
        "Saladillo",
        "Aibalito",
        "Alberdi",
        "Campo Gallo",
        "E La Agustina",
        "Florida",
        "Kilometro 20",
        "La America",
        "La Fortuna",
        "Monte Verde",
        "Obraje Iriondo",
        "Pozo Muerto",
        "Pozo Salado",
        "San Ramon",
        "Santa Cruz",
        "Yunta Pozo",
        "Agua Blanca",
        "Bahia Blanca",
        "Campo Alegre",
        "Campo Del Aguila",
        "Campo Verde",
        "Chainima",
        "Chañar Pozo",
        "Cuquero",
        "Dos Varones",
        "El Cambiado",
        "El Colmenar",
        "El Corrido",
        "El Oscuro",
        "El Rosario",
        "El Traslado",
        "El Valle",
        "El Valle D Oriente",
        "Hazan Villa",
        "La Cañada",
        "La Defensa",
        "Las Aguilas",
        "Los Carrizos",
        "Maidana",
        "Monte Rico",
        "Nuevo Libano",
        "Nuevo Lujan",
        "Palermo",
        "Parana",
        "Rivadavia",
        "San Carlos",
        "San Juan",
        "Vinal Pozo",
        "El Mistol",
        "Kilometro 499",
        "Nasalo",
        "Noge",
        "Pozo Colorado",
        "Puna",
        "Quebracho Pintado",
        "Retiro San Pablo",
        "Tobas",
        "Vilelas",
        "28 De Marzo",
        "Añatuya",
        "Barrio La Leñera",
        "Barrio V Fernandez",
        "Binal Esquina",
        "Coronel Barros",
        "Cuatro Bocas",
        "El Mataco",
        "Kilometro 515",
        "La Encalada",
        "La Estancia",
        "Lote 15",
        "Puni Tajo",
        "Simbol Bajo",
        "Suncho Pozo",
        "Tinap Jerayoj",
        "Villa Abregu",
        "El Malacara",
        "Los Linares",
        "Miel De Palo",
        "Pozo Herrera",
        "Kilometro 450",
        "Los Juries",
        "Obraje Mailin",
        "Tres De Marzo",
        "El Cuadrado",
        "Kilometro 477",
        "Km 443 Taboada",
        "La Balanza",
        "La Nena",
        "La Reconquista",
        "Lote 42",
        "Tomas Young",
        "Agua Dulce",
        "Averias",
        "Gualamba",
        "Kilometro 433",
        "Kilometro 454",
        "Los Pocitos",
        "Lote 27 E 286",
        "Tacañitas",
        "El Mojon",
        "E De Zootecnia B",
        "Los Pinos B",
        "San Bernardo B",
        "Acos",
        "Alto Del Pusto",
        "Alto Verde",
        "Amimpa",
        "Animas",
        "Arboles Grandes",
        "Arboles Verdes",
        "Barrancas",
        "Barranqueras",
        "Barrosa",
        "Casa Santa",
        "Dos Pozos",
        "E Cap D F Pereyra",
        "E Cristobal Colon",
        "El Campo",
        "El Nial",
        "El Palancho",
        "El Rodeo",
        "El Sauzal",
        "Embalse Rio Hondo",
        "Escuela 151",
        "Escuela 158",
        "Escuela 172",
        "Escuela 178",
        "Escuela 179",
        "Escuela 180",
        "Escuela 182",
        "Escuela 184",
        "Escuela 187",
        "Escuela 189",
        "Escuela 191",
        "Escuela 245",
        "Escuela 302",
        "Escuela 303",
        "Escuela 306",
        "Escuela 316",
        "Escuela 343",
        "Escuela 346",
        "Escuela 72",
        "Escuela 74",
        "Escuela 75",
        "Escuela 90",
        "Escuela 92",
        "Escuela 96",
        "Esquina",
        "Kilometro 12",
        "La Brama",
        "La Concepcion",
        "La Costa Palampa",
        "La Estrella",
        "La Madrid",
        "La Soledad",
        "Laguna Larga",
        "Lamadrid",
        "Las Animas",
        "Las Lomitas",
        "Las Parritas",
        "Las Zanjitas",
        "Los Cercos",
        "Los Panchillos",
        "Los Ruiz",
        "Los Saracho",
        "Los Sauces",
        "Los Soraire",
        "Los Sotelo",
        "Palma Larga",
        "Palo Seco",
        "Palos Quemados",
        "Pampa Muyo",
        "Pampa Rosa",
        "Paso De Los Nievas",
        "Paso Grande",
        "Pozo Del Arbolito",
        "Pozo El Quebracho",
        "Pozo Verde",
        "Puesto Belen",
        "Puesto De Galvanes",
        "Puesto Los Barraza",
        "Puesto Los Galvez",
        "R Provincial 333",
        "R Provincial 334",
        "Rio Hondito",
        "Rumi Cocha",
        "Ruta Nacional 157",
        "S Ant De Quisca",
        "Sol De Mayo",
        "Tala Caida",
        "Tala Sacha",
        "Villa Pujio",
        "Arbolitos",
        "Añil",
        "Bajo Hondo",
        "Blanco Pozo",
        "Bustamante",
        "Ceja Pozo",
        "Don Bartolo",
        "Ea La Verde",
        "El Carbon",
        "El Churqui",
        "El Molar",
        "El Parana",
        "El Rincon",
        "Gaspar Suarez",
        "Humaita",
        "Huturungo",
        "Isca Yacu",
        "Isca Yacu Semaul",
        "Kilometro 781",
        "Kilometro 784",
        "Kilometro 794",
        "Las Cejas",
        "Los Ralos",
        "Poleo Pozo",
        "Pozo Del Alto",
        "Pozo Lerdo",
        "Pozo Lindo",
        "Puerta Grande",
        "San Javier",
        "Suncho Pujio",
        "Superint. Ledesma",
        "Tacanas",
        "Tenene",
        "Tres Flores",
        "Tusca Pozo",
        "Uclar",
        "Utrunjos",
        "Viteaca",
        "La Libertad",
        "Lapachitos",
        "Los Godoy",
        "Los Hardoy",
        "Pozo Lapacho",
        "Santa Luisa",
        "Santillan",
        "Tuscal Redondo",
        "Virginia",
        "Anta Chica",
        "Arenales",
        "Bobadal",
        "Colonia Nro 2",
        "E Adolfo Alsina",
        "E Alberto Soldati",
        "E Alvarez Condarco",
        "E C Molina",
        "E Camp El Plumeril",
        "E Cap Candelaria",
        "E Manuel Cossio",
        "E Mariano Salas",
        "E P De Arriba",
        "E Pedro Araoz",
        "E Salvador Alonso",
        "El Bachi",
        "El Bobadal",
        "El Durazno",
        "El Gramillar",
        "El P Del Medio",
        "Ensenada",
        "Escuela 112",
        "Escuela 118",
        "Escuela 149",
        "Escuela 150",
        "Escuela 152",
        "Escuela 166",
        "Escuela 167",
        "Escuela 173",
        "Escuela 177",
        "Escuela 205",
        "Escuela 208",
        "Escuela 211",
        "Escuela 242",
        "Escuela 246",
        "Escuela 250",
        "Escuela 262",
        "Escuela 275",
        "Escuela 276",
        "Escuela 278",
        "Escuela 292",
        "Escuela 30",
        "Escuela 310",
        "Escuela 313",
        "Escuela 314",
        "Escuela 326",
        "Escuela 331",
        "Escuela 339",
        "Escuela 34",
        "Escuela 344",
        "Escuela 347",
        "Escuela 353",
        "Escuela 366",
        "Escuela 368",
        "Escuela 369",
        "Escuela 375",
        "Escuela 386",
        "Escuela 4",
        "Escuela 5",
        "Escuela 57",
        "Escuela 60",
        "Escuela 61",
        "Escuela 62",
        "Escuela 7",
        "Escuela 83",
        "Escuela 91",
        "Escuela Leo Huasi",
        "Ingenio Esperanza",
        "Isla Mota",
        "La Costosa",
        "La Luna",
        "La Maravilla",
        "La Melada",
        "Las Chacras",
        "Las Puertas",
        "Los Chorrillos",
        "Lujan",
        "Monte Cristo",
        "Monte Potrero",
        "Montesino",
        "Paja Colorada",
        "Paso De La Patria",
        "Piedra Buena",
        "Puerta Alegre",
        "Rosario",
        "San Arturo",
        "San Federico",
        "San Felix",
        "Santa Clara Sud",
        "Santo Domingo",
        "Sesteadero",
        "Tinajeros",
        "Traslado",
        "Urizar",
        "Uturuno",
        "V Desierto De Luz",
        "V S R De Nva Trin",
        "Villa Colmena",
        "Villa El Bache",
        "Villa El Retiro",
        "Villa La Soledad",
        "Villa La Tuna",
        "Villa Maria",
        "Villa Mercedes",
        "Villa Monte Cristo",
        "Villa San Antonio",
        "G Piedrabuena",
        "Gobernador Piedrabuena",
        "Agua Azul",
        "Campo Amarillo",
        "Campo Grande",
        "Copo Viejo",
        "El Cajon",
        "Guanacuyoj",
        "La Aloja",
        "La Juliana",
        "La Tala",
        "Las Lajas",
        "Las Lomas",
        "Los Cerros",
        "Los Molles",
        "Los Moyas",
        "Pampa Pozo",
        "Pozo Betbeder",
        "Rapelli",
        "S M De Las Chacras",
        "Simbol Pozo",
        "Simbolar",
        "Taco Punco",
        "Tres Varones",
        "Yuchancito",
        "Duraznito",
        "La Matilde",
        "Rosario De La Fron",
        "La Banda",
        "7 De Abril",
        "Agua Amarga",
        "Algarrobal Viejo",
        "Bajo Grande",
        "Belgrano",
        "Brandan",
        "Buen Lugar",
        "Churqui Esquina",
        "Corral Quemado",
        "El Balde",
        "El Baldecito",
        "El Cerrito",
        "El Diablo",
        "El Florido",
        "El Olivar",
        "El Potrero",
        "El Quemado",
        "El Real",
        "El Sauce",
        "Guarcan",
        "Hahuancuyos",
        "Junalito",
        "Kilometro 645",
        "Kilometro 651",
        "La Codicia",
        "La Florida",
        "La Fragua",
        "La Mesada",
        "Las Quebradas",
        "Loma Grande",
        "Media Luna",
        "Mojon",
        "Molleoj",
        "Palma Pozo",
        "Peludo Warcuna",
        "Puesto Del Simbol",
        "Puesto Nuevo",
        "Quebrada Esquina",
        "San Cristobal",
        "San Gregorio",
        "San Isidro",
        "Sansioj",
        "Santa Felisa",
        "Sepultura",
        "Señora Pujio",
        "Siete Arboles",
        "Simbol Huasi",
        "Taco Bajada",
        "Tres Bajos",
        "Tres Quebrachos",
        "Zanja",
        "Horcones",
        "La Plata",
        "Las Mojarras",
        "Barrio V Cohesa",
        "Barrio Villa Cohesa",
        "Contreras E",
        "Contreras Establecimiento",
        "El Puestito",
        "El Vinalar",
        "Santiago Del Estero",
        "Stiago Del Estero",
        "Upianita",
        "Villa Constantina",
        "Los Nuñez",
        "Abrita Chica",
        "Abrita Grande",
        "Anchanga",
        "Ancocha",
        "Aragones",
        "Arbol Solo",
        "Brea Puñuna",
        "Buena Vista",
        "Cancinos",
        "Candelaria",
        "Caneinos",
        "Cardozos",
        "Catorce Quebrachos",
        "Cañada Del Medio",
        "Chanchillos",
        "Chauchillas",
        "Chilcas La Loma",
        "Chilquita I",
        "Chuiqui",
        "Coropampa",
        "Dique Los Quiroga",
        "El Carmen",
        "El Dean",
        "El Fraile",
        "El Peral",
        "Hoyo Con Agua",
        "Hoyon",
        "Huachana",
        "Isla De Aragones",
        "Kenti Taco",
        "La Darsena",
        "La Esquina",
        "La Perlita",
        "Leiva",
        "Lezcanos",
        "Lomitas",
        "Los Quiroga",
        "Manogasta",
        "Mirandas",
        "Morales",
        "Naranjito",
        "Negra Muerta",
        "Ovejeros",
        "Pocitos",
        "Pozo Cercado",
        "Pozo Grande",
        "Puente Del Salado",
        "Puesto De Diaz",
        "Ramaditas",
        "Rodeo De Soria",
        "Rodeo De Valdez",
        "S Ant D Ls Caceres",
        "San Dionisio",
        "Sauzal",
        "Sumamao",
        "Tipiro",
        "Toro Human",
        "Villa Jimenez",
        "Barrio Huaco Hondo",
        "Alta Gracia",
        "Alto Bello",
        "Arroyo Tala",
        "Cabra",
        "Cañada",
        "Cerrillos",
        "Conzo",
        "Cortadera",
        "Cuichicaña",
        "El Tala",
        "Escuela 1050",
        "Escuela 20",
        "Escuela 665",
        "Escuela 708",
        "Famatina",
        "Farol",
        "Favorina",
        "Guampacha",
        "Guardia Del Norte",
        "Ichagon",
        "Kilometro 55",
        "La Calera",
        "La Ensenada",
        "La Punta",
        "Laguna",
        "Maquijata",
        "Mate Pampa",
        "Palma Flor",
        "Pampa Muyoj",
        "Piedritas",
        "Puerta Chiquita",
        "Puerta Del Cielo",
        "Quebrachos",
        "Remes",
        "Rodeo",
        "San Justo",
        "San Lorenzo",
        "Santos Lugares",
        "Sayaco",
        "Sinchi Caña   E",
        "Tronco Juras",
        "Tunas Punco",
        "Villa La Punta",
        "Viva Mercedes",
        "Vizcacheral",
        "Yeso Alto",
        "25 De May-Barnegas",
        "Beltranloreto",
        "Campo De Amor",
        "Chaves",
        "El Nerio",
        "El Señuelo",
        "Envidia",
        "La Cortadera",
        "La Laura",
        "La Polvareda",
        "La Viuda",
        "Laprida",
        "Las Dos Flores",
        "Los Cerrillos",
        "Porongal",
        "S Rosa De Coronel",
        "San Benito",
        "San Ignacio",
        "San Manuel",
        "San Pastor",
        "San Roque",
        "Shishi Pozo",
        "Tres Bajadas",
        "Villa Elvira",
        "Abrita",
        "Arraga",
        "Buey Rodeo",
        "Campo Nuevo",
        "Chañar Pujio",
        "Costa Rica",
        "Cruz Pozo",
        "E 14 De Setiembr",
        "El Marne",
        "Ezcurra",
        "Ingeniero Ezcurra",
        "Kilometro 112",
        "Kilometro 117",
        "Kilometro 118",
        "Kilometro 120",
        "Kilometro 135",
        "Kilometro 153",
        "La Abrita",
        "La Higuera",
        "La Porteña",
        "La Sarita",
        "La Vuelta",
        "Maco",
        "Maco Yanda",
        "Maguito",
        "Maquita",
        "Maquitis",
        "Maquito",
        "Nanda",
        "Naquito",
        "Pueblo Nuevo",
        "Puestito De S Ant",
        "Quebracho Herrado",
        "San Andres",
        "San Vicente",
        "Santa Rosa  Arraga",
        "Sauce Solo",
        "Silipica",
        "Simbol",
        "Trozo Pozo",
        "V De La Barraca",
        "Villa Zanjon",
        "Vta D La Barranca",
        "Yanda",
        "Zanjon",
        "Nueva Francia",
        "Albardon",
        "Albardon Chuña",
        "Ayuncha",
        "Bajadita",
        "Burra Huañauana",
        "Burra Huañuna",
        "Cañada Rica",
        "Chuña Albardon",
        "Codo",
        "Collera Hurcuna",
        "Diente Del Arado",
        "Dormida",
        "El Mulato",
        "El Pinto",
        "El Remanso",
        "Jumi Pozo",
        "Kilometro 88",
        "La Dormida",
        "La Noria",
        "La Ramadita",
        "La Revancha",
        "Loreto",
        "Los Angeles",
        "Morampa",
        "Paaj Muyo",
        "Pozo Ciego",
        "Puesto De Juanes",
        "Ramadita",
        "Rio Nambi",
        "S Barbara Ferreira",
        "San Jeronimo",
        "San Pablo",
        "Sandia Huajchu",
        "Santa Barbara",
        "Santa Isabel",
        "Tacanitas",
        "Tala Atun",
        "Taquetuyoj",
        "Tontola",
        "Toro Charquina",
        "Totora Pampa",
        "Totoras",
        "Tuscajoj",
        "Yacuchiri",
        "Yalan",
        "Yolohuasi",
        "Yulu Huasi",
        "Guanaco Sombriana",
        "Isla Verde",
        "Monte Redondo",
        "Aguada",
        "Anjuli",
        "Bahoma",
        "Cañada De La Costa",
        "Cañada Honda",
        "Chañar Pzo D Abajo",
        "Colonia Tinco",
        "El Manantial",
        "Escuela 1080",
        "Escuela 109",
        "Espinal",
        "Estancia Vieja",
        "Galeano",
        "Huascho  Patilla",
        "I De Los Castillos",
        "La Grama",
        "Las Orellanas",
        "Las Palmeras",
        "Las Tigreras",
        "Loro Huasi",
        "Los Castillos",
        "Los Fierros",
        "Los Quebrachos",
        "Los Robles",
        "Manantiales",
        "Mansupa",
        "P. Sitaria Pocitos",
        "Tacamampa",
        "Tala",
        "Terma De Rio Hondo",
        "Termas De Rio Hondo",
        "Tunales",
        "Villa Balnearia",
        "La Donosa",
        "Los Decimas",
        "Perez De Zurita",
        "Tinco Colonia",
        "Yuto Yaca",
        "Yutu Yaco",
        "Amapola",
        "Bajo Verde",
        "Cañada Tala Pozo",
        "El Alambrado",
        "El Puesto",
        "El Retiro",
        "Loma Del Medio",
        "Palma Redonda",
        "Pozo Del Campo",
        "Puesto Del Retiro",
        "Tala Pozo",
        "Taquello",
        "Vinara",
        "A Dl Martirizado",
        "Abra De La Cruz",
        "Alpa Puca",
        "Amicha",
        "Antilo",
        "Barrialito",
        "Bauman",
        "Bebidas",
        "Bejan",
        "Chañar Pocito",
        "Doña Luisa",
        "El Barrial",
        "Mistol Muyoj",
        "Patillo",
        "Pozo Huascho",
        "Puesto De Vieyra",
        "Punta Pozo",
        "Quera",
        "San Cosme",
        "Villa Rio Hondo",
        "Alto Alegre",
        "Barrio Jardin",
        "Beltran Loreto",
        "Brea Chimpana",
        "Buenos Aires",
        "Chañar Laguna",
        "Crrillos De San Is",
        "El Escondido",
        "El Milagro",
        "El Recreo",
        "Escuela 879",
        "Frias",
        "Guatana",
        "Inti Huasi",
        "Jumeal O Jumial",
        "Kilometro 1073",
        "Kilometro 3",
        "La Laguna",
        "Las Palomitas",
        "Las Tejas",
        "Las Trincheras",
        "Monteagudo",
        "Palo Lindo",
        "Palo Parado",
        "Pozancones",
        "Pozo De La Puerta",
        "Pta De Las Piedras",
        "Remansito",
        "San Patricio",
        "Serrano Muerto",
        "Taco Quinka",
        "Troncos Quemados",
        "Villa Adela",
        "Villa Coinor",
        "25 De Mayo",
        "25 De Mayo Sud",
        "Cerro Rico",
        "El Salvador",
        "Puesto Los Gomez",
        "Abras Del Medio",
        "Ancajan",
        "Canario",
        "Choya",
        "Divisadero",
        "El Bajo",
        "El Cadillo",
        "El Rajo",
        "El Tacial",
        "El Veinticinco",
        "El Venticinco",
        "Esperanza",
        "Kilometro 10",
        "Kilometro 18",
        "La Represa",
        "Mojoncito",
        "Oncajan",
        "Pocito De La Loma",
        "Pueda Ser",
        "Puesto",
        "Rodeito",
        "S Ant De Las Flor",
        "San Delfin",
        "San Romano",
        "Santa Lucia",
        "El Quillin",
        "Kilometro 1098",
        "Kilometro 1121",
        "La Quebrada",
        "Mangrullo",
        "Rumi Esquina",
        "Tonzu",
        "Ayapaso",
        "Estanzuela",
        "Ichipuca",
        "Las Minas",
        "Lindero",
        "El Abra",
        "La Abra",
        "Las Peñas",
        "Abra De Quimil",
        "Agujereado",
        "Ahi Veremos",
        "Codillo",
        "El Porvenir",
        "Iliages",
        "Jumial",
        "La Chilca",
        "Las Juntas",
        "Las Maravillas",
        "Las Talitas",
        "Loma De Yeso",
        "Los Cobres",
        "Los Correas",
        "Medio Mundo",
        "Moron",
        "Palmitas",
        "Pozancon",
        "Pozo Cabado",
        "Providencia",
        "Tableado",
        "Tibilar",
        "Tres Cerros",
        "Villa Guasayan",
        "Villares",
        "El Sesteadero",
        "Molles",
        "Montuoso",
        "El Veinticinco De Mayo",
        "Cuyoj",
        "El Bosque",
        "El Cruce Km 659",
        "Kilometro 1033",
        "Kilometro 659",
        "Kilometro 661",
        "Kilometro 665",
        "La Capilla",
        "La Granja",
        "La Isla",
        "Las Colonias",
        "Las Hermanas",
        "Las Salinas",
        "Las Zanjas",
        "Los Naranjos",
        "Nueva Antaje",
        "Rincon",
        "Rubia Moreno",
        "Tramo 20",
        "Valdivia",
        "Aguas Coloradas",
        "Areas",
        "Babilonia",
        "Bandera Bajada",
        "Callejon Bajada",
        "Canteros",
        "Cardon Esquina",
        "Casa Verde",
        "Caspi Corral",
        "Cañada Escobar",
        "Cejas",
        "Chañar Bajada",
        "Chañar Esquina",
        "Chile",
        "Churqui",
        "Cuqueños",
        "Dique Chico",
        "Dique Figueroa",
        "El Bañadero",
        "El Chañar",
        "El Pirucho",
        "Esteco",
        "Esteros",
        "Fortuna",
        "Hoyo Cerco",
        "Huñajcito",
        "Jumi Viejo",
        "La Lomada",
        "La Manga",
        "La Paciencia",
        "La Paz",
        "La Rivera",
        "Las Palmitas",
        "Los Arias",
        "Los Pereyra",
        "Majancito",
        "Manga Bajada",
        "Montevideo",
        "Moradito",
        "Norqueoj",
        "Nueva Granada",
        "Nuevo Simbolar",
        "Palizas",
        "Porongos",
        "Potrero Bajada",
        "Pozo Del Castaño",
        "Pozo Del Simbol",
        "Pozo Limpio",
        "Quebrachal",
        "Quebracho Yacu",
        "Ranchitos",
        "Reparo",
        "Rio Muerto",
        "S Dgo Pellegrini",
        "S Domingo Robles",
        "S J Del Boqueron",
        "San Jorge",
        "San Jose Del Boqueron",
        "Santa Rita",
        "Sauce Esquina",
        "Soria Bajada",
        "T Del Boqueron",
        "Tacanillas",
        "Tajamar",
        "Tarpuna",
        "Tramo 16",
        "Tusca Bajada",
        "Vaca Huañuna",
        "Villa Hipolita",
        "Villa Nueva",
        "Villa Palmar",
        "Villa Robles",
        "Vinal Viejo",
        "Acosta",
        "Alto Pozo",
        "Antaje",
        "Ardiles",
        "Ardiles D La Costa",
        "Banegas",
        "Chaupi Pozo",
        "Colonias",
        "Corvalanes",
        "El Aibe",
        "El Cebolliin",
        "El Cercado",
        "El Puente",
        "Guaycuru",
        "Kiska Hurmana",
        "La Colonia",
        "La Cuarteada",
        "La Falda",
        "La Germania",
        "Loma Negra",
        "Los 12 Quebrachos",
        "Los Alderetes",
        "Los Diaz",
        "Los Gallardos",
        "Los Guerreros",
        "Los Herreros",
        "Los Puestos",
        "Los Puntos",
        "Media Flor",
        "Quishca",
        "Quita Punco",
        "Romanos",
        "Sarmiento",
        "Sinquen Punco",
        "Suri Pozo",
        "Taperas",
        "Turena",
        "Vilmer",
        "Algarrobales",
        "Gramilla",
        "I De Los Sotelos",
        "Sotelillos",
        "Sotelos",
        "Boca Del Tigre",
        "Cachico",
        "Cashico",
        "Casilla Del Medio",
        "Charco Viejo",
        "El Añil",
        "El Charco",
        "El Guayacan",
        "Pozuelos",
        "Toro Pozo",
        "Tres Cruces",
        "Beltran",
        "Blanca",
        "Buey Muerto",
        "El Crece",
        "Higuera Chaqui",
        "Janta",
        "La Barrosa",
        "La Invernada",
        "Las Colinas",
        "Mirca",
        "Morcillo",
        "Pumitayoj",
        "San Guillermo",
        "San Pascual",
        "San Salvador",
        "Santa Ines",
        "Taco Pujio",
        "Totorilla Nuevo",
        "Tramo Veintiseis",
        "Yanta",
        "Ingeniero Forres",
        "Mili",
        "Morello",
        "3 Jazmines",
        "Anchoriga",
        "Aspa Sinchi",
        "Atoj Pozo",
        "Barranca Colorada",
        "Barrial Alto",
        "Boqueron",
        "Brea Pozo",
        "Brea Pozo Viejo",
        "Charquina",
        "Chilpa Macho",
        "Chilpa Mayo",
        "Colonia Pinto",
        "El Dorado",
        "Gallegos",
        "Garceano",
        "Kilometro 437",
        "Kilometro 473",
        "Laguna Blanca",
        "Linton",
        "Majadas",
        "Majadas Sud",
        "Medellin",
        "Pampa Atun",
        "Penal Provincial",
        "Perchil Bajo",
        "Puestito",
        "Puesto Del Rosario",
        "Robles",
        "Tacoyoj",
        "Tio Chacra",
        "Tres Jazmines",
        "Tulum",
        "Tulun",
        "Villa Elena",
        "Cañada San Ramon",
        "Codo Viejo",
        "Collera Huircuna",
        "Estacion Atamisqui",
        "Piruitas",
        "Sauces",
        "Tio Pozo",
        "Burro Pozo",
        "Carbon Pozo",
        "Chilca Albardon",
        "Chilquita",
        "Chilquitas",
        "Collera Huiri",
        "El Peru",
        "Escalera",
        "Higuerillas",
        "Hornillos",
        "Juanillo",
        "Kilometro 436",
        "Los Mollares",
        "Pampallajta",
        "Pineda",
        "Pueblito",
        "S Ant D L Caceres",
        "Salinas",
        "Saucen",
        "Saucioj",
        "Soconcho",
        "Ventura Pampa",
        "Villa Atamisqui",
        "Huajia",
        "Cañas Paso",
        "Chilca Juliana",
        "Chileno",
        "Guerra",
        "Lechuzas",
        "Mal Paso",
        "Mistol Pozo",
        "P Del Saladillo",
        "Peralta",
        "Polvareda",
        "Sabagasta",
        "Salavina",
        "Taco Totarayol",
        "Tagan",
        "Tio Alto",
        "Tolozas",
        "Toropan",
        "Totora",
        "Vaca Human",
        "Varas Cuchuna",
        "Veron",
        "Anca",
        "Anga",
        "Bordo Pampa",
        "Cardajal",
        "Chira",
        "El Cincuenta",
        "El Pueblito",
        "Hutcu Chacra",
        "Kilometro 364",
        "Kilometro 390",
        "La Golondrina",
        "La Gringa",
        "La Paliza",
        "La Protegida",
        "La Puerta Del Mte",
        "Los Caños",
        "Los Laterales",
        "Los Telares",
        "Malota",
        "Manchin",
        "Paso Del Saladillo",
        "Pozo Del Monte",
        "Quimili Paso",
        "Rami Yacu",
        "Remansos",
        "Rumi Jaco",
        "San Fernando",
        "Santa Brigida",
        "Taco Isla",
        "Troncal",
        "Yacu Hurmana",
        "Ardiles D L Costa",
        "Cachi",
        "Cavadito",
        "Cavado",
        "Chaguar Puncu",
        "El Cebollin",
        "El Cuello",
        "El Vizcacheral",
        "Fernandez",
        "Jimenez",
        "Jume Esquina",
        "La Bota",
        "La Cruz",
        "La Petronila",
        "La Primitiva",
        "La Ramada",
        "Los Alderete",
        "Maderas",
        "Maria Delicia",
        "Nueva Industria",
        "Rio De Gallo",
        "Sainquen Punco",
        "San Cayetano",
        "Sepulturas",
        "Trancas",
        "Tres Chañares",
        "Vila Isla",
        "Yaso",
        "Cazadores",
        "Collujlioj",
        "Concepcion",
        "Coraspino",
        "Diaspa",
        "El Empachado",
        "El Juncal",
        "Garza",
        "Guaipi",
        "La Overa",
        "Lapa",
        "Molle",
        "Nueve Mistoles",
        "Percas",
        "Pozo",
        "Pozo Moro",
        "Pozo Mosoj",
        "Quimilloj",
        "Rosiyulloj",
        "San Enrique",
        "San Jose De Flores",
        "San Marcos",
        "Taboada Estacion",
        "Taco Huaco",
        "Taco Suyo",
        "Yacano",
        "Ave Maria",
        "Caloj",
        "Chuña Palma",
        "Codo Pozo",
        "Collun-Lioj",
        "Conchayos",
        "Consulñoj",
        "Coro Abra",
        "Guardia",
        "Guiñao",
        "Lugones",
        "Novillo",
        "Paaj Rodeo",
        "Paso Mosoj",
        "Pozo Marcado",
        "Puesto Los Marcos",
        "Punta Corral",
        "R Provincial 130",
        "Ruta Nacional 34",
        "Ruta Provincial 11",
        "Ruta Provincial 17",
        "Ruta Provincial 40",
        "Ruta Provincial 5",
        "Ruta Provincial 8",
        "Sauce Bajada",
        "Zapi Pozo",
        "Brealoj",
        "Colonia Isla",
        "El Trece",
        "Guañagasta",
        "Herrera",
        "Mailin",
        "Mallin Viejo",
        "Moconza",
        "Represa",
        "Rincon De La Espza",
        "S Antonio De Copo",
        "Salviaioj Gaitan",
        "Taco Atun",
        "Blanca Pozo",
        "Bracho",
        "Colonia Dora",
        "Libanesa",
        "Puente Negro",
        "Sunchituyoj",
        "Tacon Esquina",
        "Icaño",
        "La Costa",
        "Lago Muyoj",
        "Oro Pampa",
        "Tiestituyos",
        "Toro Pampa",
        "Tronco Blanco",
        "Yacasnioj",
        "Real Sayana",
        "Abra Grande",
        "Cerrillo",
        "El Diamante",
        "Huyamampa",
        "La Aurora",
        "Los Herreras",
        "Los Marcos",
        "Pampa Mayo",
        "Simbol Cañada",
        "Bayo Muerto",
        "Clodomira",
        "Condor Huasi",
        "El Favorito",
        "Favorita",
        "Kilometro 629",
        "Palmares",
        "Rumios",
        "S Francisco Lvlle",
        "S Rosa De Vitervo",
        "San Felipe",
        "Alejito",
        "Ampa",
        "Azogasta",
        "Carretero",
        "Codo Bajada",
        "Corral Grande",
        "Cruz Chula",
        "Cruz Loma",
        "Dos Hermanos",
        "Guaype",
        "Iuchan",
        "Kilometro 443",
        "Kilometro 546",
        "La Reduccion",
        "Laspa",
        "Laureles",
        "Lincho",
        "Lojlo",
        "Padua",
        "Puente Rio Salado",
        "Punco",
        "Repecho Diaz",
        "Repecho Montenegro",
        "Rodeo Bajada",
        "San Francisco",
        "San Simon",
        "Sin Descanso",
        "Suncho Corral",
        "Suri",
        "Tres Hermanas",
        "El Pertigo",
        "Floresta",
        "Huacanitas",
        "Kiska Loro",
        "La Potocha",
        "Las Randas",
        "Las Tinajas",
        "Lote S",
        "Mistol Pampa",
        "Punta De Rieles",
        "Segundo Pozo",
        "Stayle",
        "Surihuaya",
        "Tabeanita",
        "Tabiana",
        "Villa Brana",
        "Villa Fanny",
        "Weisburd",
        "Yuchan",
        "Alza Nueva",
        "Amama",
        "Armonia",
        "Celestina",
        "Colonia San Juan",
        "Dolores Central",
        "El Bragado",
        "El Negrito",
        "Jumial Grande",
        "Jumialito",
        "Mercedes",
        "Nueva Alza",
        "Nueva Colonia",
        "Paciencia",
        "Palomar",
        "Quimilioj",
        "Quisña Loro",
        "S J Dto Figueroa",
        "Trinidad",
        "Uturunco",
        "Villa Figueroa",
        "Yacu Hichacuna",
        "C El Simbolar",
        "Colonia El Simbolar",
        "Cruz Grande",
        "Huritu Huasi",
        "Industria Nueva",
        "Kilometro 613",
        "La Brea",
        "La Tapa",
        "S M Dto Figueroa",
        "Villa Isla",
        "Villa Tolojna",
        "Casa Alta",
        "Colonia Paz",
        "Colonia Siegel",
        "El Canal",
        "El Chinchillar",
        "Kilometro 494",
        "Lagunilla",
        "Ledesma",
        "Llajta Mauca",
        "Lote 29",
        "Melero",
        "Puente Bajada",
        "Rincon Esperanza",
        "Roldan",
        "S M Del Matara",
        "Soledad",
        "Taruy",
        "Tiun Punco",
        "Villa Esquina",
        "Villa Matara",
        "Matara",
        "Cobas",
        "El Aybal",
        "Estela",
        "La Pedrera",
        "B De Los Espinosa",
        "Cachi Yaco",
        "Graciela",
        "Los Bordos",
        "Pozo Del Tigre",
        "S F Del Chaqar",
        "S M De Sobremonte",
        "San Francisco Del Chañar",
        "Agua Pintada",
        "Cantera Los Vieras",
        "Cruz Mojada",
        "El Divisadero",
        "El Estanque",
        "El Ialita",
        "El Tambero",
        "Estancia Gorosito",
        "La Chacra",
        "La Estacada",
        "La Selva",
        "La Tuna",
        "Las Piedras Anchas",
        "Las Sierras",
        "Molinos",
        "R De Las Manzanas",
        "Corral Del Rey",
        "El Quebracho",
        "La Chicharra",
        "Las Cortaderas",
        "Las Mercedes",
        "Los Pozos",
        "P De Los Alamos",
        "Pozo De Las Ollas",
        "Pozo De Molina",
        "Racedo",
        "San Bartolo",
        "San Juancito",
        "Agua Turbia",
        "Amiman",
        "Bajo Las Piedras",
        "Balbuena",
        "Cajon",
        "Caleras",
        "Campo Rico",
        "Cantamampa",
        "Caranchi Yaco",
        "Carrera Vieja",
        "Cañada De La Cruz",
        "Chacras",
        "Chañar Yaco",
        "Chañares Altos",
        "Corral De Carcos",
        "Cortaderas",
        "Cuchi Corral",
        "El 49",
        "El Aguila",
        "El Arbol De Piedra",
        "El Cachi",
        "El Fuerte",
        "El Jume",
        "El Pilar",
        "El Segundo",
        "El Unco",
        "Espinillo",
        "Fivialtos",
        "Horcos Tucucuna",
        "Jacimampa",
        "Jume",
        "La Clemira",
        "La Primavera",
        "La Resbalosa",
        "La Rinconada",
        "La Totorilla",
        "La Trampa",
        "La Tusca",
        "La Verde",
        "Laguna Del Suncho",
        "Las Cañas",
        "Las Horquetas",
        "Las Talas",
        "Lescano",
        "Llama Pampa",
        "Loma Colorada",
        "Los Algarrobos",
        "Los Arbolitos",
        "Los Chañares",
        "Los Remansos",
        "Los Sunchos",
        "Manfloa",
        "Miramonte",
        "Mistol Loma",
        "Molle Pozo",
        "Ojo De Agua",
        "Parada Km 101",
        "Paso Reducido",
        "Pozo Del Chañar",
        "Pozo Del Macho",
        "Pozo Escondido",
        "Pozo Redondo",
        "Primavera",
        "Progreso De Jume",
        "Rey Viejo",
        "Rio Saladillo",
        "Taco Misqui",
        "Tala Yacu",
        "Tigre Muerto",
        "Villa Ojo De Agua",
        "Wiñano",
        "Ylumampa",
        "Agua Caliente",
        "Aguadita",
        "Algarrobo",
        "Alpapuca",
        "Ambargasta",
        "Ancoche",
        "Antuco",
        "Chañaritos",
        "Chilca",
        "Chuchi",
        "Corralito",
        "El Cerro",
        "Gibialto",
        "Gramillal",
        "Guardia De La Esq",
        "Hilumampa",
        "Huascan",
        "La Aguadita",
        "La Cuesta",
        "La Pintada",
        "La Puerta",
        "Las Cienagas",
        "Las Parvas",
        "Las Rosas",
        "Lomitas Blancas",
        "Mistoles",
        "Naranjitos",
        "Oncan",
        "Pampa Grande",
        "Portezuelo",
        "Quebrachito",
        "Remanso",
        "Rosada",
        "Rumi Huasi",
        "S Domingo Chico",
        "Villa Quebrachos",
        "Yumampa",
        "Casa De Dios",
        "Cañitas",
        "Costa Vieja",
        "El 25  Sumampa",
        "El Molle",
        "El Naranjo",
        "Kenti Tacko",
        "La Bella Criolla",
        "La Colina",
        "La Porfia",
        "La Yerba",
        "Los Paredones",
        "Medanos",
        "Pajaro Blanco",
        "Punita Norte",
        "Punita Sud",
        "Rio Viejo",
        "San Mateo",
        "Siempre Verde",
        "Sumampa",
        "Sumampa Viejo",
        "Taco Palta",
        "Tronco Quemado",
        "Amoladeras",
        "Baez",
        "Buena Esperanza",
        "Campo Del Cisne",
        "Colonia Mercedes",
        "Coronel Fernandez",
        "El Algarrobo",
        "El Bordito",
        "El Viñalito",
        "Kilometro 301",
        "La Grana",
        "La Granada",
        "La Oscuridad",
        "La Palma",
        "Lagunitas",
        "Laguno Del Suncho",
        "Las Cruces",
        "Las Islas",
        "Limache",
        "Los Cruces",
        "Pozo Del Algarrobo",
        "Pozo Del Garabato",
        "Puesto De Arriba",
        "Punta Del Agua",
        "Quenti Taco",
        "S Ramon Quebrachos",
        "Sol De Julio",
        "Tenti Taco",
        " C.Christiernson",
        "La Griteria",
        "Navarro",
        "Oratorio",
        "Palo A Pique",
        "Paso De Oscares",
        "Polvaredas",
        "Puerta Del Monte",
        "Rama Paso",
        "Ramirez De Velazco",
        "Cerrito",
        "Kilometro 49",
        "Piedra Blanca",
        "San Pedro Km 49",
        "Balde",
        "Catita",
        "El Baqado",
        "El Centenario",
        "El Gacho",
        "El Mistolito",
        "El Portezuelo",
        "Garzon",
        "Kilometro 1008",
        "La Peqa",
        "La Quinta",
        "La Valentina",
        "Quiros",
        "Altillo Del Medio",
        "Los Barrialitos",
    ],
    Catamarca: [
        "Santa Cecilia",
        "Santa Maria",
        "El Cruce",
        "A A La Hoyada",
        "A A Pta De Balasto",
        "Ampajango",
        "Andalhuala",
        "Banda",
        "Buey Muerto",
        "Campitos",
        "Carpinchango",
        "Casa De Piedra",
        "Cerrillos",
        "Cienaga",
        "Corral Viejo",
        "El Arroyo",
        "El Balde",
        "El Cajon",
        "El Calchaqui",
        "El Cerrito",
        "El Desmonte",
        "El Medanito",
        "El Recreo",
        "El Trapiche",
        "El Zarzo",
        "Entre Rios",
        "Estancia Vieja",
        "Famabalastro",
        "Famatanca",
        "Julipao",
        "La Campana",
        "La Hoyada",
        "La Loma",
        "La Ollada",
        "La Ovejeria",
        "La Puntilla",
        "La Quebrada",
        "La Soledad",
        "Las Mojarras",
        "Los Pozuelos",
        "Los Saltos",
        "Medanito",
        "Ovejeria",
        "Pajanguillo",
        "Palo Seco",
        "Paloma Yaco",
        "Pie Del Medano",
        "Punta De Balasto",
        "S Ant Del Cajon",
        "San Jose",
        "San Jose Banda",
        "San Jose Norte",
        "Toroyaco",
        "Totorilla",
        "Yapes",
        "Agua Salada",
        "Anchillos",
        "El Arbolar",
        "Fuerte Quemado",
        "Pichao",
        "Quisca Chica",
        "Albigasta",
        "Ancastillo",
        "Anjuli",
        "Candelaria",
        "El Barrialito",
        "El Centenario",
        "Garzon",
        "La Renovacion",
        "Las Iguanas",
        "Las Palmitas",
        "Las Tejas",
        "Los Cordobeses",
        "Palo Parado",
        "Puesto De Los Morales",
        "San Juancito",
        "Tapso",
        "Achalco",
        "Barranquitas",
        "Bebida",
        "Bella Vista",
        "El Alto",
        "El Rodeito",
        "El Rosario",
        "El Simbol",
        "El Vallecito",
        "Guayamba",
        "Iloga",
        "Inacillo",
        "Infanzon",
        "Kilometro 1093",
        "La Calera",
        "La Calera Dl Sauce",
        "La Estancia",
        "La Estanzuela",
        "La Huerta",
        "Las Cañas",
        "Las Justas",
        "Las Lomitas",
        "Las Tapias",
        "Las Trancas",
        "Las Trillas",
        "Los Cisternas",
        "Los Morteros",
        "Los Ortices",
        "Los Pedrazas",
        "Mina Dal",
        "Nogalito",
        "Orellano",
        "Oyola",
        "Pozo Grande",
        "Pueblito",
        "Rio De Avila",
        "Rio De La Plata",
        "San Jeronimo",
        "San Vicente",
        "Sauce Huacho",
        "Soledad",
        "Sucuma",
        "Suruipiana",
        "Taco",
        "Talega",
        "Tintigasta",
        "Vilisman",
        "Balde Pozo",
        "Chillimo",
        "Florida",
        "Zorro Huarcuna",
        "Chacabuco",
        "El Tala",
        "La Brea",
        "La C De Los Padres",
        "La Chacarita",
        "Las Varitas",
        "Loma Cortada",
        "Peqon",
        "San Fernando Del Valle De Catamarca",
        "Sauce",
        "Villa Cubas",
        "Acostilla",
        "Agua Del Simbol",
        "Agua Los Matos",
        "Alto Del Rosario",
        "Amana",
        "Ancasti",
        "Anquincila",
        "Brea",
        "Cabrera",
        "Calacio",
        "Calera",
        "Caqada De Ipizca",
        "Caqada De Paez",
        "Caqada Larga",
        "Casa Armada",
        "Casa De La Cumbre",
        "Casa Vieja",
        "Chacritas",
        "Concepcion",
        "Corral De Piedra",
        "Corralito",
        "El Arbolito",
        "El Arenal",
        "El Barreal",
        "El Cercado",
        "El Cevilarcito",
        "El Chaqaral",
        "El Chorro",
        "El Mojon",
        "El Mollar",
        "El Potrer D Ls Cba",
        "El Pozo",
        "El Quebrachal",
        "El Saltito",
        "El Sauce",
        "El Sauce Ipizca",
        "El Totoral",
        "El Zapallar",
        "Estancia",
        "Guanaco",
        "Higuera Dl Alumbre",
        "Ipizca",
        "L Cuchil Del Aybal",
        "La Aguadita",
        "La Barrosa",
        "La Bebida",
        "La Estancita",
        "La Falda",
        "La Higuerita",
        "La Mesada",
        "La Peqa",
        "La Tigra",
        "Las B Casa Armada",
        "Las Barrancas",
        "Las Chacras",
        "Las Cuchillas",
        "Las Tunas",
        "Loma Sola",
        "Los Bulacios",
        "Los Huaycos",
        "Los Mogotes",
        "Los Molles",
        "Los Piquillines",
        "Majada",
        "Navaguin",
        "Ojo De Agua",
        "Peqaflor",
        "Potrero",
        "Quebracho",
        "Rio Los Molinos",
        "San Antonio",
        "San Martin",
        "Santa Gertrudis",
        "Sauce De Los Cejas",
        "Tacana",
        "Taco De Abajo",
        "Totoral",
        "Yerba Buena",
        "San Fdo Del Valle De Catamar",
        "Aguadita",
        "Anto. De La Sierra",
        "Antofagasta De La Sierra",
        "Balsa",
        "Cumbre Del Laudo",
        "Gentile",
        "Huaycama",
        "Mina Inca Huasi",
        "Ros. Del Sumalao",
        "Sumalao",
        "Tacahuasi",
        "Toro Muerto",
        "Vega Curutu",
        "Vega Tamberia",
        "Pozo Del Mistol",
        "Agua Colorada",
        "Antapoca",
        "Chaqarcito",
        "Collagasta",
        "Estanque",
        "La Estrella",
        "Las Esquinas",
        "Las Tejas De V Vie",
        "Las Tejas De Valle Viejo",
        "Mota Botello",
        "Ocho Vados",
        "Pampa",
        "Pomancillo",
        "Portezuelo",
        "Ros Del Sumalao",
        "S Ant De P Blanca",
        "San Antonio Fray M Esquiu",
        "San Isidro",
        "Santa Cruz",
        "Santa Rosa",
        "Sebila",
        "Sierra Brava",
        "Tala",
        "Tiorco",
        "Tres Puentes",
        "Valle Viejo",
        "Villa Macedo",
        "Zanja",
        "S.Ant. M Esquiu",
        "Piedra Blanca",
        "El Hueco",
        "Colpes",
        "El Manzano",
        "La Carrera",
        "La Puerta",
        "Los Varelas",
        "V Las Pirquitas",
        "Villa Las Pirquitas",
        "Agua Verde",
        "Ambato",
        "Biscotal",
        "Casa Viejas",
        "Chamorro",
        "Chavarria",
        "Chuchucaruana",
        "Corralita",
        "El Arbol Solo",
        "El Atoyal",
        "El Biscote",
        "El Bolson",
        "El Molinito",
        "El Nogal",
        "El Parque",
        "El Pie D L Cuesta",
        "El Polear",
        "El Potrerillo",
        "El Realito",
        "El Rodeo",
        "El Rodeo Grande",
        "El Tabique",
        "Faldeo",
        "Galpon",
        "Humaya",
        "Isla Larga",
        "La Aguada",
        "La Aguita",
        "La Caqada",
        "La Piedra",
        "La Salvia",
        "Lampaso",
        "Las Aguitas",
        "Las Barras",
        "Las Burras",
        "Las Chacritas",
        "Las Juntas",
        "Las Lajas",
        "Las Pampitas",
        "Las Piedras Bcas",
        "Los Castillos",
        "Los Guindos",
        "Los Loros",
        "Los Narvaez",
        "Los Navarros",
        "Los Puestos",
        "Los Talas",
        "Molle Quemado",
        "Singuil",
        "V Quintin Ahumada",
        "Villa Dolores",
        "La Tercena",
        "Amadores",
        "Balcosna",
        "Balcosna De Afuera",
        "Barro Negro",
        "Bastidor",
        "Cerviqo",
        "Chiflon",
        "El Bastidor",
        "El Cevil",
        "El Chamico",
        "El Ciflon",
        "El Contador",
        "El Duraznillo",
        "El Garabato",
        "El Retiro",
        "Huacra",
        "La Bajada",
        "La Banda",
        "La Esquina",
        "La Merced",
        "La Viqa De Abajo",
        "Las Huertas",
        "Las Tranquitas",
        "Los Galpones",
        "Los Ovejeros",
        "Los Pintados",
        "Monte Potrero",
        "Palo Labrado",
        "Posta",
        "Pozo Del Algarrobo",
        "Pozo Del Campo",
        "Rafael Castillo",
        "S Ant De Panclin",
        "Salcedo",
        "Santa Ana",
        "Santa Barbara",
        "Sauce Mayo",
        "Sumampa",
        "Superi",
        "Talaguada",
        "Tierra Verde",
        "Villa Collantes",
        "Yocan",
        "La Higuera",
        "San Antonio De Paclin",
        "Baqado De Ovanta",
        "Bañado De Ovanta",
        "Cabana",
        "La Viqa",
        "La Viña",
        "Alijilan",
        "Almigaucho",
        "Alta Gracia",
        "Amancala",
        "Ampolla",
        "Cachi",
        "Caridad",
        "Cortaderas",
        "Dos Pocitos",
        "Dos Troncos",
        "El Carmen",
        "El Potrero",
        "El Quebrachito",
        "El Saucecito",
        "La Maravilla",
        "La Victoria",
        "Las Caqas",
        "Las Cayas",
        "Las Pampas",
        "Los Altos",
        "Los Bastidores",
        "Los Estantes",
        "Los Pocitos",
        "Los Troncos",
        "Los Zanjones",
        "Manantiales",
        "Mistol Ancho",
        "Monte Redondo",
        "Naipa",
        "Ovanta",
        "Pampa Chacra",
        "Puerta Grande",
        "Puesto De La Viuda",
        "Puesto Del Medio",
        "Quebrachal",
        "Quebrachos Blancos",
        "Quimilpa",
        "Retiro",
        "Salauca",
        "San Luis",
        "Santos Lugares",
        "Yaquicho",
        "C Nueva Coneta",
        "Colonia Nueva Coneta",
        "Coneta",
        "Miraflores",
        "Colonia Del Valle",
        "Capayan",
        "El Carrizal",
        "El Milagro",
        "Huillapima",
        "La Cañada",
        "Lampasillo",
        "Las Palmas",
        "Los Chañaritos",
        "San Pablo",
        "San Pedro Capayan",
        "Balde La Punta",
        "Balde Nuevo",
        "Buena Vista",
        "Chaqaritos",
        "Chumbicha",
        "El Baqado",
        "Kilometro 128",
        "Kilometro 99",
        "La Horqueta",
        "La Paraguaya",
        "Las Latillas",
        "Los Angeles",
        "Los Bazan",
        "Los Pinos",
        "San Geronimo",
        "San Lorenzo",
        "Sisiguasi",
        "Telaritos",
        "Trampasacha",
        "Polcos",
        "La Falda De S Ant",
        "La Falda De San Antonio",
        "El Bañado",
        "E La Invernada",
        "Aconquija",
        "Algarrobal",
        "Alto De La Junta",
        "Alumbrera",
        "Amanao",
        "Andalgala",
        "Arima",
        "Aserr El Pilcio",
        "Carapunco",
        "Chaqaryaco",
        "Chilca",
        "Condor Huasi",
        "Distrito Espinillo",
        "El Colegio",
        "El Espinillo",
        "El Lindero",
        "El Molle",
        "El Pucara",
        "El Suncho",
        "Huaco",
        "Huaschaschi",
        "Huazan",
        "La Laguna",
        "Las Estancias",
        "Los Rastrojos",
        "Mallin 1",
        "Mallin 2",
        "Mollecito",
        "Pilciao",
        "Rio Potrero",
        "Rodeo Grande",
        "V Coronel Arroyo",
        "Villavil",
        "Agua D Ls Palomas",
        "Agua De Las Palomas",
        "Choya",
        "Villa Vil",
        "Chaquiago",
        "El Alamito",
        "La Puerta De S J",
        "La Puerta De San Jose",
        "Culampaja",
        "Las Cuevas",
        "Loro Huasi",
        "Agua De Dionisio",
        "Agua Del Campo",
        "Aguas Calientes",
        "Ampujaco",
        "Angostura",
        "Asampay",
        "Barranca Larga",
        "Belen",
        "Cachijan",
        "Cachuan",
        "Carrizal",
        "Carrizal De Abajo",
        "Carrizal De La Cos",
        "Casa Grande",
        "Chiquerito",
        "Chucolay",
        "Cortadera",
        "Cotagua",
        "Cueva Blanca",
        "Duraznillo",
        "Durazno",
        "El Campillo",
        "El Durazno",
        "El Medio",
        "El Molino",
        "El Portezuelo",
        "El Tio",
        "El Tolar",
        "Huasayaco",
        "Huasi Cienaga",
        "La Barranca Larga",
        "La Capellania",
        "La Costa",
        "La Cuesta",
        "La Ramada",
        "La Represa",
        "La Toma",
        "La Totora",
        "Laguna Colorada",
        "Las Bayas",
        "Las Manzas",
        "Loconte",
        "Londres Este",
        "Londres Oeste",
        "Los Colorados",
        "Los Morteritos",
        "Luna Aguada",
        "Minas Agua Tapada",
        "Nac. De Abajo",
        "Nac. De Arriba",
        "Nac. De S Antonio",
        "Nac. Del Bolson",
        "Nacimiento",
        "Ojo De La Cortader",
        "Palo Blanco",
        "Pampa Cienaga",
        "Papa Chacra",
        "Piedra Larga",
        "Potrerito",
        "Pozuelos",
        "Puerto Blanco",
        "Puerto Chipi",
        "Puerto De La Pampa",
        "Puerto Potrero",
        "Rincon Grande",
        "Rodeo Gervan",
        "Rumimonion",
        "San Buenaventura",
        "Shincal",
        "Talamayo",
        "Vicuqa Pampa",
        "Viscote",
        "Zarcito",
        "Zarza",
        "Farallon Negro",
        "Los Nacimientos",
        "Pozo De Piedra",
        "San Fernando",
        "Alto El Bolson",
        "Condor H De Belen",
        "Corral Quemado",
        "Hualfin",
        "Jacipunco",
        "Laguna Blanca",
        "La Cienaga",
        "Londres",
        "El Eje",
        "Termas Villa Vil",
        "Malvinas Arg",
        "Caqada De Cuevas",
        "Cno A P Del Agua",
        "El Quebracho",
        "Las Heras",
        "Los Cerrillos",
        "Malagueqo",
        "Mi Valle",
        "Nueva Andalucia",
        "Marull",
        "Toro Pujio",
        "Comechingones",
        "Las Ensenadas",
        "Los Huesos",
        "Mesillas",
        "Pampa De Achala",
        "V Independencia",
        "Villa Gracia",
        "Acheral",
        "Agua Escondida",
        "Alto Bello",
        "Angelina",
        "Baviano",
        "Bqado D Divisadero",
        "Buen Retiro",
        "Caballa",
        "Campo Bello",
        "Campo Blanco",
        "Caqada",
        "Cerrillada",
        "Cerro Colorado",
        "Chañaritos",
        "Chichagasta",
        "Corralitos",
        "Divisadero",
        "El Abra",
        "El Aybal",
        "El Barrial",
        "El Bello",
        "El Cacho",
        "El Chañar",
        "El Cienego",
        "El Moreno",
        "El Puestito",
        "El Puesto",
        "El Quimilo",
        "El Saladillo",
        "El Salto",
        "El Valle",
        "Empalme San Carlos",
        "Garay",
        "Jesus Maria",
        "Jumeal",
        "Kilometro 1017",
        "Kilometro 38",
        "Kilometro 955",
        "Kilometro 969",
        "Kilometro 997",
        "La Antigua",
        "La Buena Estrella",
        "La Colonia",
        "La Dorada",
        "La Florida",
        "La Granja",
        "La Isla",
        "La Montosa",
        "La Parada",
        "La Zanja",
        "Lagunita",
        "Laja",
        "Las Cortaderitas",
        "Las Palomas",
        "Las Toscas",
        "Las Zanjas",
        "Liebre",
        "Los Alamos",
        "Los Cadillos",
        "Los Caudillos",
        "Los Chaqares",
        "Los Corrales",
        "Los Nogales",
        "Maria Dora",
        "Maria Elena",
        "Mollegasta",
        "Montegasta",
        "Navigan",
        "Ollita",
        "Olmos",
        "Olta",
        "P De Los Morales",
        "P Los Chaqaritos",
        "Palo Cruz",
        "Pampa Pozo",
        "Parada Km 62",
        "Plumero",
        "Portillo Chico",
        "Pozancones",
        "Pozos Cavados",
        "Pto Espinal",
        "Puesto De Fadel",
        "Puesto De Vera",
        "Puesto Sabatte",
        "Punta Del Pozo",
        "Ramblones",
        "Recreo",
        "Rio Chico",
        "Rio De Bazanes",
        "Rio De Don Diego",
        "Rio De La Dorada",
        "San Francisco",
        "San Manuel",
        "San Miguel",
        "San Nicolas",
        "San Rafael",
        "San Roque",
        "Sancho",
        "Santa Lucia",
        "Santo Domingo",
        "Sicha",
        "Tacopampa",
        "Tajamares",
        "Tinajera",
        "Tula",
        "Villa Ofelia",
        "Villa Sotomayor",
        "Esquiu",
        "La Guardia",
        "El Medano",
        "Espza D L Cerrillo",
        "La Libertad",
        "Las Peqas",
        "S Ant De La Paz",
        "San Antonio De La Paz",
        "Icaqo",
        "Parana",
        "Bajo Lindo",
        "El Quicho",
        "Iglesia Vieja",
        "La Batea",
        "Las Abras",
        "Las Aleras",
        "Los Eslabones",
        "Los Valdes",
        "Quilmes",
        "La Pintada",
        "Piedrita Blanca",
        "El Pajonal",
        "Poman",
        "Rincon",
        "Rosario De Colana",
        "Saugil",
        "Sijan",
        "Mutquin",
        "Saujil",
        "Lorohuasi",
        "Capihuas",
        "Los Olivares",
        "Amuschina",
        "Andolucas",
        "La Plaza",
        "Suriyaco",
        "Tuyubil",
        "El Pueblito",
        "Salado",
        "Copacabana",
        "S J De Tinogasta",
        "Agua Grande",
        "Aguada",
        "Anchoca",
        "Andalucia",
        "Anillaco",
        "Apocango",
        "Banda De Lucero",
        "Baqos Termales",
        "Cantera Rota",
        "Casa De Alto",
        "Castaqar",
        "Cerdas",
        "Cerro Negro",
        "Chanero",
        "Chavero",
        "Cienaguita",
        "Cordobita",
        "Costa De Reyes",
        "El Cachiyuyo",
        "El Peqon",
        "El Tambillo",
        "Estancito",
        "Guanchicito",
        "Guanchin",
        "Guincho",
        "Junta",
        "Kilometro 1006",
        "Kilometro 999",
        "La C D Ls Zondones",
        "La Candelaria",
        "La Caqada Larga",
        "La Chilca",
        "La Majada",
        "La Palca",
        "La Puntilla De S J",
        "La Ramadita",
        "Las Higueritas",
        "Las Losas",
        "Las Papas",
        "Las Retamas",
        "Loma Grande",
        "Los Balverdis",
        "Los Chanampa",
        "Los Gonzales",
        "Los Guaytimas",
        "Los Palacios",
        "Los Potrerillos",
        "Los Quinteros",
        "Los Rincones",
        "Los Valdez",
        "Los Valveros",
        "Matambre",
        "Medano",
        "Mesada D Ls Zarate",
        "Negro Muerto",
        "Palacios",
        "Pan De Azucar",
        "Pantanos",
        "Pastos Amarillos",
        "Peqas Blancas",
        "Planchada",
        "Plaza De San Pedro",
        "Pocitos",
        "Quebrada Honda",
        "Quemadita",
        "Quemado",
        "Quiquero",
        "Qusto",
        "Rio Abajo",
        "Rio Colorado",
        "Rio De Los Indios",
        "Rodeo",
        "Santo Tomas",
        "Suncho",
        "Tala Zapata",
        "Talar",
        "Talita",
        "Tambu",
        "Tinogasta",
        "Totora",
        "Troya",
        "Vallecito",
        "Villa Seleme",
        "Vinquis",
        "Las Peladas",
        "Medanitos",
        "Tamberia",
        "Taton",
        "Villa San Roque",
        "Fiambala",
        "Lavalle",
        "La Perlita",
        "Los Frances",
        "San Bernardo",
        "El Fuerte",
        "Paso San Isidro",
        "El Vizcacheral",
        "El Altillo",
        "El Condado",
        "Las Aguaditas",
        "Las Padercitas",
        "Padercitas",
        "Parecitas",
        "Pastos Largos",
        "Punta De Agua",
        "El Horno",
        "Balde De Escudero",
        "Algarrobos Grandes",
        "Alto Negro",
        "Bella Estancia",
        "C Del Cantaro",
        "Caqada De Vilan",
        "Charlone",
        "El Dichoso",
        "El Ramblon",
        "Estancia San Roque",
        "La Alameda",
        "Las Galeras",
        "Los Chancaros",
        "Moyarcito",
        "Romance",
        "S Rosa Del Gigante",
        "San Agustin",
        "Tres Lomas",
        "Tres Puertas",
    ],
    Corrientes: [
        "Guayquiraro",
        "Arroyo Sarandi",
        "Arroyo Soro",
        "Tres Bocas",
        "Arroyo Vega",
        "Buena Vista",
        "Cabral",
        "Campo Bordon",
        "Campo Cafferata",
        "Campo De Carlos",
        "Campo Morato",
        "Campo Romero",
        "Campo San Jacinto",
        "Chacras Norte",
        "Chacras Sec. Ejido",
        "Chacras Sud",
        "E Cafferatta",
        "E Laguna Limpia",
        "E Marquez Lui",
        "El Carmen",
        "El Parque",
        "Esquina",
        "Estancia El Carmen",
        "Inga",
        "Jesus Maria",
        "La Casualidad",
        "La Emilia",
        "La Isabel",
        "La Morocha",
        "Los Flotadores",
        "Malezal",
        "Ombu Solo",
        "Pueblito",
        "San Agustin",
        "San Antonio",
        "San Gustavo",
        "San Jacinto",
        "San Juan",
        "San Vicente",
        "Santa Catalina",
        "Santa Librada",
        "Santa Rita",
        "Sarandi",
        "Villa Cristia",
        "Malvinas Norte",
        "Malvinas Sur",
        "San Roque",
        "Abra Guazu",
        "Alejandria",
        "Arroyo Saturno",
        "Boranza",
        "Colonia Beron De Astrada",
        "Coronel Abraham Schweizer",
        "Cuña Suegra",
        "El Coquito",
        "El Porvenir",
        "El Yapu",
        "Estero Grande",
        "Estero Sauce",
        "Estero Yatay",
        "La Florencia",
        "La Nena",
        "La Palmera",
        "Libertador",
        "Los Algarrobos",
        "Los Eucaliptos",
        "Los Medios",
        "Los Paraisos",
        "Paraje Poton",
        "Paso Algarrobo",
        "Paso Cejas",
        "Rincon De Sarandy",
        "San Lorenzo",
        "San Luis",
        "San Martin",
        "Santa Ana",
        "Santa Isabel",
        "Toro Chipay",
        "Los Laureles",
        "Malvinas",
        "Malvinas Centro",
        "Paraje El Carmen",
        "Lesca",
        "T Oeste Concordia",
        "Arroyo Timboy",
        "Arroyo Totoras",
        "Chacras 3A Seccion",
        "Chacras 4A Seccion",
        "Chircal",
        "Colonia Barrientes",
        "Cuatro Bocas",
        "El Ceibo",
        "El Chircal",
        "La Florida",
        "Monte Caseros",
        "Mota Piedritas",
        "Paso Esterito",
        "Paso Vallejos",
        "Saenz Valiente",
        "San Francisco",
        "Talleres",
        "Villa La Florida",
        "Buen Retiro",
        "Camba Cua",
        "Casuarina",
        "Este Argentino",
        "Independencia",
        "Juan Pujol",
        "Kilometro 120",
        "Kilometro 134",
        "La Flor",
        "Mira Flores",
        "Mota",
        "Naranjito",
        "Parada Labougle",
        "Piedrita",
        "Pilincho",
        "San Fermin",
        "San Fernando",
        "San Salvador",
        "Santa Magdalena",
        "Santo Domingo",
        "Tacuabe",
        "Timboy",
        "Colonia Libertad",
        "El P C Libertad",
        "Estacion Libertad",
        "Kilometro 182",
        "La Palma",
        "S J E Libertad Dp",
        "S L E Libertad Dp",
        "S M E Libertad",
        "San Isidro",
        "Santa Lea",
        "Santa Marta",
        "Kilometro 104",
        "Mocoreta",
        "P J De Dios",
        "San Andres",
        "San Gregorio",
        "C Ensanche Sauce",
        "C Ofic N 1 L Flori",
        "Ctiva G San Martin",
        "Las Catorce",
        "Santa Juana",
        "5Ta Sec. Ombucito",
        "Arbol Solo",
        "E La Carolina",
        "El Progreso",
        "Kilometro 268",
        "La Colorada",
        "La Constancia",
        "La Haydee",
        "La Verde",
        "Los Manantiales",
        "Los Pinos",
        "Madariaga",
        "Mirador",
        "Nueva Esperanza",
        "Nueva Palmira",
        "Ombucito",
        "Palmar",
        "Palmita",
        "Paso De Los Libres",
        "Paso Rosario",
        "Quiyati",
        "Recreo",
        "Reduccion",
        "San Carlos",
        "San Felipe",
        "San Joaquin",
        "San Paladio",
        "San Pedro",
        "Santa Elisa",
        "Tres Hojas",
        "Tristan Chico",
        "Ñatiu",
        "C Arocena Ina",
        "E El Porvenir",
        "E La Arboleda",
        "E La Loma Alta",
        "E Los Milagros",
        "E Pozo Cuadrado",
        "E San Solano",
        "Estancia San Juan",
        "Estancia Soledad",
        "Mirunga",
        "San Miguel",
        "Yapeyu",
        "Cabred",
        "Guaviravi",
        "Kilometro 204",
        "S Fguavirari",
        "S R Parada Pucheta",
        "San Ignacio",
        "Santa Emilia",
        "Bompland",
        "Bonpland",
        "Kilometro 235",
        "Paso Ledesma",
        "Rincon De Yaguary",
        "Colonia Egido",
        "Kilometro 279",
        "Paraje Guayabo",
        "Paso De La Legua",
        "Zenon Roca",
        "Aguara Cua",
        "Apipe Grande",
        "Boqueron",
        "C San Antonio",
        "Caa Carai",
        "Caa Garay",
        "Cambireta",
        "Centinela",
        "Colonia Urdaniz",
        "Costa Guazu",
        "Dos Hermanos",
        "E San Javier",
        "El Plata",
        "Empedrado Limpio",
        "Garcitas",
        "Isla Apipe Chico",
        "Ituzaingo",
        "La Celeste",
        "La Hileorica",
        "Las Animas",
        "Las Delicias",
        "Loma Alta",
        "Loma Negra",
        "Loma Poy",
        "Los Gemelos",
        "Los Tres Hermanos",
        "Ombu",
        "Paso Tirante",
        "Puerto Naranjito",
        "Puerto Ubajay",
        "Puerto Valle",
        "Punta Mercedes",
        "Rincon Chico",
        "Salinas",
        "San Javier",
        "San Jeronimo",
        "San Jose",
        "San Julian",
        "Sangara",
        "Santa Maria",
        "Santa Tecla",
        "Tres Arboles",
        "Ulajay",
        "Uriburu",
        "Villa P Argentina",
        "Villa Permanente",
        "Vizcaino",
        "Aguapey",
        "Ojo De Agua",
        "Porvenir",
        "Santo Tomas",
        "Arroyo Tomas",
        "Colonia Martires",
        "Lote 117",
        "N Del Isabel",
        "Picada Portuguesa",
        "C General Uriburu",
        "C Jose R Gomez",
        "Caaby Poy",
        "Cambal",
        "Colonia San Mateo",
        "Cuay Chico",
        "Don Maximo",
        "E Buena Vista",
        "Estancia Casurina",
        "Estancia Durruti",
        "Estancia El Ombu",
        "Estancia San Mateo",
        "Galarza Cue",
        "Gomez Cue",
        "Guay Grande",
        "Isla San Mateo",
        "Ita Cua",
        "Kilometro 442",
        "Kilometro 459",
        "La Criolla",
        "Nuevo Paraiso",
        "P Las Tacuaritas",
        "Paso Concepcion",
        "Puerto Hormiguero",
        "Puerto Las Lajas",
        "Rincon Mercedes E",
        "San Gabriel",
        "Santo Tome",
        "Tablada",
        "Topador",
        "Tres Taperas",
        "C Desiderio Sosa",
        "Caa Garay Gdor V",
        "Carabi Poy",
        "Cau Garay",
        "Caza Pava",
        "Gdor Ing V. Viraso",
        "Gdor Ing Valentin Viraso",
        "Gobernador Virasoro",
        "Ibera",
        "Isla Grande",
        "Kilometro 470",
        "Kilometro 475",
        "Kilometro 479",
        "Kilometro 489",
        "Kilometro 506",
        "Kilometro 517",
        "Las Ratas",
        "San Alonso",
        "San Justo",
        "Sosa",
        "Tareiri",
        "Vuelta Del Ombu",
        "Colonia Garabi",
        "2 De Julio",
        "Altamira",
        "Alvear",
        "Arroyo Mendez",
        "Batay",
        "Cambara",
        "Concepcion",
        "Cuay Grande",
        "El Paraiso",
        "Esfadal",
        "Espinillar",
        "Estancia La Loma",
        "Estancia Las Tunas",
        "Florida",
        "Kilometro 393",
        "Kilometro 394",
        "Kilometro 396",
        "La Blanqueada",
        "La Chiquita",
        "La Elsa",
        "La Elva",
        "La Loma",
        "La Loma Torrent",
        "La Magnolia",
        "Las Mercedes",
        "Las Palmas",
        "Las Palmiras",
        "Las Palmitas",
        "Los Arboles",
        "Morica",
        "Pancho Cue",
        "Piracu",
        "Pirayu",
        "Tambo Nuevo",
        "Tingui",
        "Torrent",
        "Tres Capones",
        "Bacacay",
        "Costa Guaviravi",
        "Estingana",
        "La Cruz",
        "Los Tres Cerros",
        "Tres Cerros",
        "Yurucua",
        "Garruchos",
        "Campo Richardson",
        "Colonia Azara",
        "Monte Hermoso",
        "Ntes Del Tunar",
        "Pindapoy",
        "Santa Rosa",
        "Colonia Liebig S",
        "Playadito",
        "Pueblo Salto",
        "Villa Blanquita",
        "Corrientes",
        "Arroyo Pelon",
        "Arroyo Ponton",
        "Arroyo Solis",
        "C Maria Esther",
        "Caqada Quiroz",
        "Colonia Alvarez",
        "Colonia Matilde",
        "Costa",
        "Costa Rio Parana",
        "El Pelon",
        "El Pollo",
        "Ing 1: Correntino",
        "Isla Ibatay",
        "Juan Ramon Vidal",
        "Kilometro 13",
        "Laguna Paiva",
        "Laguna Soto",
        "Lomas San Cayetano",
        "Palmera",
        "Pampin",
        "Paso Lovera",
        "Paso Pesoa",
        "Raleda Sud",
        "San Cayetano",
        "Tala Cora",
        "Villa San Isidro",
        "Villa Solari",
        "Aguirre Cue",
        "Aguirre Lomas",
        "Albardones",
        "Alta Mora",
        "Bargone",
        "Bregain Cue",
        "Briganis",
        "Broja Cue",
        "Campo Grande",
        "Carabajal",
        "Caruso A Fcgu",
        "Cavia Cue",
        "Cerrudo Cue",
        "Colonia Llano",
        "Costa Grande",
        "Desaguadero",
        "El Ponton",
        "El Vasco",
        "Esquivel Cue",
        "Garabata",
        "Garrido",
        "Gdor J E Torren",
        "Herlitzka",
        "Kilometro 31",
        "Kilometro 42",
        "Kilometro 49",
        "Kilometro 55",
        "Kilometro 57",
        "Kilometro 61",
        "Kilometro 76",
        "Kilometro 84",
        "Kilometro 89",
        "Kilometro 95",
        "La Eloisa",
        "Laguna Alfonso",
        "Lomas De Galarza",
        "Lomas De Gonzalez",
        "Lomas Esquivel",
        "Maloya",
        "Monte Grande",
        "Obraje Del Vasco",
        "Oratorio",
        "Pueblito Espinosa",
        "R. De Las Mercedes",
        "Riachuelito",
        "Riachuelo Bardeci",
        "S Luis Del Palmar",
        "San Luis Del Palmar",
        "Santa Teresa",
        "Santos Lugares",
        "Sombrero",
        "Tiquino",
        "Tres Cruces",
        "Tripoli",
        "Vecindad",
        "Algarrobal Puisoye",
        "Cerrito",
        "Colonia Juan Pujol",
        "Costa Santa Lucia",
        "Fernandez",
        "Frontera",
        "La Flecha",
        "La Parada",
        "Lomas De Aguirre",
        "Lomas De Vallejos",
        "Lomas De Vergara",
        "Lomas Ramirez",
        "Lomas Vazquez",
        "Los Vences",
        "Maloyita",
        "Naranjaty",
        "Obraje Cue",
        "Ombu Lomas",
        "Palmar Grande",
        "Puisoye",
        "Punta Grande",
        "Rincon Zalazar",
        "Rodeito",
        "Saldana",
        "Tacuaral",
        "Talaty",
        "Tolatu",
        "Vergara",
        "Vergara Lomas",
        "Zapallar",
        "Aguay",
        "Algarrobales",
        "Altamora Parada",
        "Ayala Cue",
        "C Tacuaralito",
        "Caa Cati",
        "Capillita",
        "Colonia Amadei",
        "Colonia Danuzzo",
        "Colonia Durazno",
        "Colonia Florencia",
        "Colonia San Martin",
        "Costas",
        "El Salvador",
        "General Paz",
        "La Jaula",
        "Loma Villanueva",
        "Lomas Redondas",
        "N Sra Del Ros D Ca",
        "Paso Florentin",
        "Paso Gallego",
        "Rincon De Vences",
        "Romero",
        "Rosadito",
        "San Jose Caacati",
        "Timbo Cora",
        "Villa San Ramon",
        "Zapallos",
        "Arroyo San Juan",
        "C M Aberastury",
        "Costa Toledo",
        "Paso De La Patria",
        "Puerto Araza",
        "Puerto Gonzalez",
        "Laguna Brava",
        "4Ta Sec. E Grande",
        "Albardon",
        "Bedoya",
        "Chilecito",
        "Ensenada Grande",
        "Ensenadita",
        "Mandinga",
        "Matilde",
        "Paraje Iribu Cua",
        "Ramada Paso",
        "San Cosme",
        "Socorro",
        "Soledad",
        "Tuyuti",
        "Villa Cue",
        "Villaga Cue",
        "Yacarey",
        "Yahape",
        "Abra",
        "Corsa Cue",
        "Curuzu",
        "Guayu",
        "Isla Ibate",
        "Itati",
        "La Palmira",
        "La Union",
        "Mbalguiapu",
        "San Francisco Cue",
        "Yagua Rocau",
        "Arroyo Ceibal",
        "C De A S Lorenzo",
        "C Nueva Valencia",
        "Carabajal Este",
        "Colonia Arrocera",
        "Costa De Arroyo San Lorenzo",
        "Costa De Empedrado",
        "Dos Ombues",
        "El Sombrero",
        "Garrido Cue",
        "Kilometro 485",
        "Kilometro 492",
        "Kilometro 494",
        "Kilometro 501",
        "Kilometro 504",
        "Kilometro 512",
        "Kilometro 516",
        "Manuel Derqui",
        "Matadero S Catalin",
        "Pehuaho",
        "Pueblito San Juan",
        "R. De S Lorenzo",
        "R. Del Sombrero",
        "Real Cue",
        "Riachuelo",
        "Riachuelo Sud",
        "Rincon De Ambrosio",
        "Sec. Primera S J",
        "Bartolome Mitre",
        "Bernachea",
        "Colonia Brougnes",
        "Empedrado",
        "Kilometro 451",
        "Kilometro 462",
        "Kilometro 476",
        "Lomas De Empedrado",
        "M De Invierno",
        "Ocanto Pue",
        "Pago Poi",
        "Ramones",
        "Villa San Juan",
        "Angua",
        "Arroyo Ambrosio",
        "Carman",
        "Casuarinas",
        "Cnia O J Bautista",
        "Guazu Cora",
        "Jardin Florido",
        "Kilometro 406",
        "Km 425",
        "La Querencia",
        "Lago Arias",
        "Lauretti",
        "Lomas",
        "Lomas Saladas",
        "Los Lirios",
        "Mediodia",
        "Muchas Islas",
        "Pago Arias",
        "Paraje Augua",
        "Paso Naranjito",
        "Pastores",
        "Pindoncito",
        "Rincon San Pedro",
        "Saladas",
        "San Emilio",
        "San Nicolas",
        "Sosa Cue",
        "Bajo Guazu",
        "Batel",
        "Colonia Dora Elena",
        "Colonia Lucero",
        "Colonia Santa Rosa",
        "Pindo",
        "Tabay",
        "Tatacua",
        "Batara",
        "C Jacobo Finh",
        "Caiman",
        "Capilla Cue",
        "Carambola",
        "Colonia La Habana",
        "Colonia Tatacua",
        "Costa Del Batel",
        "E San Roberto",
        "E Santa Maria",
        "El Buen Retiro",
        "El Yaqueri",
        "Iguate Pora",
        "La Angelita",
        "La Aurora",
        "La Pepita",
        "Los Angeles",
        "Lujambio",
        "Montevideo",
        "Nuevo Porvenir",
        "Paraje Florida",
        "Paso Iribu Cua",
        "Paso Lucero",
        "San Nicanor",
        "Sauce",
        "Tajibo",
        "Talita Cue",
        "Tartaguito",
        "Tres Hermanas",
        "Virgen Maria",
        "Yaguaru",
        "Pago Alegre",
        "Pago De Los Deseos",
        "Arroyito",
        "Campo Cardozo",
        "Campo Fernandez",
        "Cardozo Phi",
        "Chacras",
        "Costa San Lorenzo",
        "El Pago",
        "Francisco Gauna",
        "La Herminia",
        "Manantiales",
        "Mburucuya",
        "Pasito",
        "Paso Aguirre",
        "Potrero Grande",
        "Toros Cora",
        "Veloso",
        "Estacion Saladas",
        "Kilometro 431",
        "Bella Vista",
        "Carrizal",
        "Cebollas",
        "E Agronomica",
        "El Toro Pi",
        "Las Garzas",
        "Lomas Este",
        "Macedo",
        "Martin",
        "Progreso",
        "Romero Cuazu",
        "Villa Rollet",
        "Yagua Rincon",
        "Yuqueri",
        "Carrizal Norte",
        "Colonia 3 De Abril",
        "Colonia Progreso",
        "Raices",
        "Abalo",
        "C C Echeverria",
        "C General Ferre",
        "C Gobernador Ruiz",
        "C San Eugenio",
        "Colonia Lujan",
        "Colonia San Jose",
        "Crucecitas S Lucia",
        "Ferro",
        "La Pastoril",
        "Monte Florido",
        "Quinta Teresa",
        "San Eugenio",
        "Santa Lucia",
        "Villa Aquino",
        "Villa Cordoba",
        "Algarrobo",
        "C De Los Milagros",
        "Cruz De Los Milagros",
        "Desmochado",
        "Barrio V Cordoba",
        "Barrio Villa Cordoba",
        "Colonia Mendez Bar",
        "La Bolsa",
        "Lavalle",
        "Rincon De Soto",
        "Saladero S Antonio",
        "G Juan E Martinez",
        "Gobernador Juan E Martinez",
        "9 De Julio",
        "Algarrobal",
        "Algarrobo Paraje",
        "Arroyo Gonzalez",
        "Arroyo Paiso",
        "Bajo Grande",
        "Batal",
        "Bonete",
        "Colonia Vedoya",
        "Costa Batel",
        "Crucecitas",
        "El Socorro",
        "Gobernador Martinez",
        "Kilometro 410",
        "Kilometro 416",
        "La Armonia",
        "La Celia",
        "La Lolita",
        "La Matilde",
        "Laguna Sirena",
        "Las Lagunas",
        "Las Matreras",
        "Leon Cua",
        "Lomas Floridas",
        "Los Angeles Del B",
        "Luis Gomez",
        "Pueblo De Julio",
        "Puente Batel",
        "Puerta Ifran",
        "S Lucia 9 De Julio",
        "Saldana 9 De Julio",
        "Salinas Gandes",
        "Vedoya",
        "Yataity Calle",
        "Yatay",
        "Kilometro 374",
        "Kilometro 387",
        "La Luisa",
        "Manuel F Mantilla",
        "Manuel Florencio Mantilla",
        "Pedro R Fernandez",
        "San Diego",
        "San Rafael",
        "Santa Sinforosa",
        "Santiago Alcorta",
        "Seriano Cue",
        "Yacare",
        "Alamo",
        "Balengo",
        "Caayobay",
        "Capita Mini",
        "Caraya",
        "Isla Alta",
        "Kilometro 382",
        "Kilometro 402",
        "Laguna Avalos",
        "Laurel",
        "Matrera",
        "Mojon",
        "Naranjito S Roque",
        "Palmira",
        "Pirra Puy",
        "Rosado Grande",
        "San Sebastian",
        "Timbo",
        "Yazuca",
        "Boliche Lata",
        "Colonia Pando",
        "8 De Diciembre",
        "Arroyo Carancho",
        "C Mercedes Cossio",
        "C Rolon Cossio",
        "Campo Araujo",
        "Campo Escalada",
        "Casualidad",
        "Colonia Pucheta",
        "Colonia Sauce",
        "Corona",
        "Curtiembre",
        "El Rosario",
        "Goya",
        "Granja Amelia",
        "Isla Sola",
        "Ita Curubi",
        "Laguna Pucu",
        "Lujan",
        "Maruchitas",
        "Paso Coronel",
        "Paso Santa Rosa",
        "Ranegas",
        "Remanso",
        "Rincon De Gomez",
        "Rolon Jacinto",
        "San Dionisio",
        "Santillan",
        "Tartaria",
        "Batelito",
        "Colonia Carolina",
        "Colonia Del Carmen",
        "Colonia La Carmen",
        "Colonia Porvenir",
        "El Carrizal",
        "Maruchas",
        "Mora",
        "Paso Rubio",
        "Puerto Goya",
        "Rincon De Pago",
        "Villa Rolon",
        "Ifran",
        "Isabel Victoria",
        "Isabel Victoria - Ifran",
        "Manchita",
        "Masdeu Escuela 197",
        "Punta Ifran",
        "Buena Esperanza",
        "El Tatare",
        "El Transito",
        "Fanegas",
        "Invernada",
        "La Carlina",
        "La Concepcion",
        "La Diana",
        "La Elvira",
        "Los Ceibos",
        "Pago Redondo",
        "Paraje San Isidro",
        "Paso Bandera",
        "Paso Los Angeles",
        "Paso San Juan",
        "Puente Machuca",
        "San Alejo",
        "San Manuel",
        "San Marcos",
        "Arroyo Castillo",
        "Casillas",
        "Curuzu Cuatia",
        "E Los Paraisos",
        "Espinillo",
        "Estancia San Julio",
        "La Cautiva",
        "Labori",
        "Lobory",
        "Paraiso",
        "Paso Ancho",
        "Paso De Las Pidras",
        "Paso Lopez",
        "Rincon",
        "Siete Arboles",
        "Tierra Colorada",
        "Tunitas",
        "Vaca Cua",
        "Yaguary",
        "Abo Nezu",
        "Arroyo Seco",
        "Colonia Chircal",
        "El Cerro",
        "Esperanza",
        "Estrella",
        "La Florentina",
        "La Fortuna",
        "Los Tres Amigos",
        "Maria",
        "Nina",
        "Nueva Granada",
        "Palmitas",
        "Perugorria",
        "Puente Avalos",
        "Tala Paso",
        "Aristia",
        "Arrroyo Seco",
        "Barrancas",
        "Buena Ventura",
        "Caabi Poi",
        "Campo Maidana",
        "Campo Poy",
        "Cavi Poy",
        "Cañaditas",
        "Dos Hermanas",
        "E Rincon Grande",
        "El Tesoro",
        "El Tigre",
        "Ferret",
        "La Delicia",
        "La Estrella",
        "La Fe",
        "La Garcia",
        "La Leonor",
        "Las Cuchillas",
        "Las Taperas",
        "Limas Cue",
        "Martin Garcia",
        "Paso Bermudez",
        "Paso De Mula",
        "Pujol Bedoya",
        "Puntas De F Gomez",
        "Puntas Del Tigre",
        "Rincon De Animas",
        "Rincon Del Tigre",
        "San Luis Cue",
        "Saucesito",
        "Soto",
        "Villa Ortiz",
        "Villa Soto",
        "Villa Tesaro",
        "Arroyo Casco",
        "Arroyo Garay",
        "Capirari",
        "Capit J Madariaga",
        "Capitan Joaquin",
        "Caz Correntinos",
        "Chaquito",
        "Emilio R Coni",
        "Guaycuru",
        "Linda Vista",
        "Minuanes",
        "Pago Largo",
        "Abeli",
        "Acuña",
        "Arroyo Horqueta",
        "Baibiene",
        "El Loto",
        "Ibaviyu",
        "La Blanca",
        "La Floresta",
        "La Leontina",
        "Las Lomas",
        "Las Violetas",
        "San Celestino",
        "Arroyo Grande",
        "Callejon",
        "Capi Vari",
        "Capiguari",
        "E Cerro Verde",
        "E Gonzalez Cruz",
        "E Santa Cruz",
        "Estancia Aguaceros",
        "Estancia Ita Caabo",
        "Estancia La Calera",
        "Estancia La Maria",
        "Estancia Mandure",
        "Estancia Rosario",
        "Estancia Tunas",
        "Ibira Pita",
        "Ita Cora",
        "Ita Pucu",
        "Itati Rincon",
        "Kilometro 287 Fcgu",
        "Kilometro 296",
        "La Belermina",
        "Mercedes",
        "Pasaje Santa Juana",
        "Paso Mesa",
        "Pay Ubre Chico",
        "Piedra Ita Pucu",
        "Rincon Tranquera G",
        "Salto Ita Jhase",
        "Tacural",
        "Alen Cue",
        "Alfonso Loma",
        "Alfonso Lomas",
        "Carlos Pellegrin",
        "San Roquito",
        "Tacural Mercedes",
        "Uguay",
        "Felipe Yofre",
        "Caa Guazu",
        "El Cerrito",
        "El Pilar",
        "La Carlota",
        "Las Elinas",
        "Las Rosas",
        "Paimbre",
        "Paso Pucheta",
        "San Eduardo",
        "Tarangullo",
        "Tatare",
        "C C Pellegrini",
        "Colonia Carlos Pellegrini",
        "Chavarria",
        "Estero Peru",
        "San Guillermo",
        "Yapuca",
        "Yatay Cora",
        "El Remanso",
        "La Agripina",
        "Mariano I Losas",
        "Mno I L Est Solar",
        "Solari",
        "Teblenari",
        "Barranqueras",
        "Blanco Cue",
        "Colonia Branchi",
        "Ibahay",
        "Ita Ibate",
        "P Barranquitas",
        "Puesto Lata",
        "Tilita",
        "Angostura",
        "Arerungua",
        "Beron De Astrada",
        "Colonia",
        "Colonia Romero",
        "E La Carmencha",
        "E San Antonio",
        "El Palmar",
        "Estancia Mbota",
        "Isla Tacuara",
        "Martinez Cue",
        "Mbarigui",
        "Palmar Arerungua",
        "Paso Potrero",
        "Puesto De Isla",
        "Ruiz Cue",
        "Tacuaracarendy",
        "Toro I",
        "Toro Pichay",
        "Valencia",
        "Villa Lujan",
        "Arroyo Balmaceda",
        "Bastidores",
        "Catalan Cue",
        "Infante",
        "La Angela",
        "La Pachina",
        "Lapacho",
        "Lomas San Juan",
        "Timbo Paso",
        "Yta Paso",
        "Carandaita",
        "Carreta Paso",
        "Colonia Caiman",
        "Colonia Gaiman",
        "Colonia La Union",
        "Colonia Madariaga",
        "Curupayti",
        "Curuzu Laurel",
        "Ipacarapa",
        "Los Sauces",
        "Mboi Cua",
        "Palma Sola",
        "S Ant Del Caiman",
        "Silvero Cue",
        "Tacuarembo",
        "Tape Rati",
        "Veron Cue",
        "Yataiti Sata",
        "Yataity Poi",
        "Villa Olivari",
        "Colonia Palmira",
        "Estancia La Aurora",
        "Tropezon",
        "Villa Alta",
        "Villa Libertad",
        "Garcete Cue",
    ],
    Misiones: [
        "Arroyo",
        "Damus",
        "Domingo Barthe",
        "El Reposo",
        "El Tropezon",
        "Fachinal",
        "Garupa Norte",
        "Itaembre Mini",
        "La Rotonda",
        "Las Vertientes",
        "Manantiales",
        "Nueva Valencia",
        "Parada Leis",
        "Posadas",
        "Puente Nacional",
        "Puerto Lujan",
        "Rincon De Bugres",
        "Rincon Del Rosario",
        "Rincon Itaembe",
        "San Andres",
        "San Borjita",
        "San Isidro",
        "San Juan",
        "Santa Ines",
        "Sierra De San Jose",
        "Tororo",
        "Villa Emilia",
        "Villa Lanus",
        "Villalonga",
        "El Centinela",
        "Florida",
        "Ibiritangay",
        "Las Tres Hermanas",
        "Libertad",
        "Garupa",
        "San Jose",
        "Arroyo Magdalena",
        "Arroyo Pastora",
        "B Del Tacuaruzu",
        "Bonpland Norte",
        "C A Del Valle",
        "C Profundidad",
        "Caapora",
        "Candelaria",
        "Capueron",
        "Colonia Alemana",
        "Colonia Guarani",
        "La Invernada",
        "Las Quemadas",
        "Loreto",
        "Lote 12",
        "Picada Finlandesa",
        "Picada San Javier",
        "Picada San Martin",
        "Profundidad",
        "Puerto La Mina",
        "Rincon De Bonpland",
        "Ruinas De Loreto",
        "Sol De Mayo",
        "Tacuaruzu",
        "Teyuguare",
        "Timbauba",
        "Tratado De Paz",
        "Villa Venecia",
        "Yabebiri",
        "Yacutinga",
        "Yerbal Mamboreta",
        "Bella Vista",
        "Cerro Cora",
        "Miguel Lanus",
        "O Victor Andrade",
        "Olegario V.Andrade",
        "Barrio Don Santiago",
        "Barrio Don Stiago",
        "Cerro Azul",
        "Arroyo Del Medio",
        "Arroyo Isabel",
        "Campo Tornquinst",
        "Colonia Alberdi",
        "Colonia Caa Guazu",
        "Colonia Finlandesa",
        "Colonia Polaca",
        "Colonia Taranco",
        "Dos Arroyos",
        "El Chaton",
        "Gral Guemes",
        "Kilometro 26",
        "Leandro N Alem",
        "Mecking",
        "Once Vueltas",
        "Picada Belgrano",
        "Picada Bonpland",
        "Picada G Lopez",
        "Picada Gobernador Lopez",
        "Picada Iglesia",
        "Picada Libertad",
        "Picada Polaca",
        "Picada Pozo Feo",
        "Picada Rusa",
        "Picada Sur Mecking",
        "Villa Libertad",
        "Santa Ana",
        "Bonpland",
        "Colonia Martires",
        "Puerto España",
        "Colonia Polana",
        "Aparicio Cue",
        "Arroyo Yabebiri",
        "Barrancon",
        "C San Ignacio",
        "Colonia Japonesa",
        "Colonia Leiva",
        "Colonia Roca",
        "Colonia Roca Chica",
        "Colonia Yacutinga",
        "E Exp De Loret",
        "El Destierro",
        "El Triunfo",
        "Hekenan",
        "Isla Argentina",
        "La Horqueta",
        "La Otilia",
        "Lote 25",
        "Lote 5",
        "Manis",
        "Maria Antonia",
        "Obligado",
        "Otilia",
        "Pastoreo",
        "Puerto Cazador",
        "Puerto Doce",
        "Puerto Gisela",
        "Puerto Hardelaste",
        "Puerto Menochio",
        "Puerto Naranjito",
        "Puerto Nuevo",
        "Puerto San Ignacio",
        "Puerto Tabay",
        "Puerto Viejo",
        "Puerto Yabebiri",
        "Roca Chica",
        "San Ignacio",
        "Santo Pipo",
        "Tacuara",
        "C Domingo Savio",
        "Colonia Domingo Savio",
        "Gobernador Roca",
        "Mojon Grande",
        "Colonia Oasis",
        "General Urquiza",
        "Corpus",
        "Jardin America",
        "Oasis",
        "Mbopicua",
        "Capiovi",
        "Capiovy",
        "Puerto Leoni",
        "3 De Mayo",
        "Capivu",
        "Colonia La Otilia",
        "Colonia Oro Verde",
        "Linea Cuchilla",
        "Los Teales",
        "Puerto Ing Morandi",
        "Puerto Mbopicua",
        "Puerto Mineral",
        "Puerto Oro Verde",
        "Puerto Paranay",
        "Puerto Rico",
        "Puerto San Alberto",
        "Puerto Tigre",
        "Ruiz De Montoya",
        "San Alberto",
        "San Gotardo",
        "San Miguel",
        "San Sebastian",
        "Hipolito Yrigoyen",
        "Colonia Almafuerte",
        "2 De Mayo",
        "Dos De Mayo",
        "Frigo",
        "El Soberbio",
        "Mariano Moreno",
        "Salto Encantado",
        "Villa Salto Encantado",
        "Casualidad",
        "E San Miguel",
        "Gobernador Ruiz",
        "Los Bretes",
        "Puerto Piedra",
        "Apostoles",
        "Arroyo Tunitas",
        "Carrillo Viejo",
        "Centinela",
        "Cheroguita",
        "Chirimay",
        "Colonia Apostoles",
        "El Rancho",
        "Ensanche Este",
        "Ensanche Norte",
        "La Capilla",
        "Las Tunas",
        "Lote 117",
        "Puerto Azara",
        "Rincon De Chimtray",
        "S J De La Sierra",
        "Sierras San Juan",
        "Tigre",
        "Azara",
        "Rincon De Mercedes",
        "San Pedro",
        "Itacaruare",
        "Tres Capones",
        "Arrechea",
        "Arroyo Persiguero",
        "Arroyo Santa Maria",
        "Barra Concepcion",
        "Bretes Martires",
        "C Capon Bonito",
        "C Martir S Maria",
        "C Santa Maria",
        "Colonia San Javier",
        "Concepcion De La Sierra",
        "El Persiguero",
        "Isla San Lucas",
        "Ojo De Agua",
        "P Del A Persiguero",
        "Persiguero",
        "Puerto  Concepcion",
        "Puerto San Lucas",
        "S Maria La Mayor",
        "San Lucas",
        "Santa Maria Martir",
        "San Vicente",
        "Buena Vista",
        "Colonia Cumanday",
        "Costa Portera",
        "Frances",
        "Guerrero",
        "In D Itacaruare",
        "Invernada Chica",
        "Invernada Grande",
        "Kilometro 78",
        "Los Galpones",
        "Puerto Rosario",
        "Puerto Ruben",
        "R. Del Guerrero",
        "Rincon De Lopez",
        "San Javier",
        "Tres Esquinas",
        "E La Merced",
        "El Socorro",
        "La Pupi",
        "La Pupii",
        "Estacion Apostoles",
        "Arroyo Fedor",
        "Barra Bonita",
        "Bayo Troncho",
        "Colonia Chapa",
        "Colonia Yabebiri",
        "Florentino Ameghino",
        "General Alvear",
        "Guayabera",
        "Obera",
        "P Dos Hermanas",
        "Picada Sueca",
        "Picada Yapeyu",
        "Samambaya",
        "Sierra De Oro",
        "Villa Armonia",
        "Villa Sarubbi",
        "Villa Svea",
        "Yapeyu Centro",
        "Acaragua",
        "Alberdi",
        "Campo Ramon",
        "Los Helechos",
        "Panambi",
        "Campo Grande",
        "Campo Viera",
        "9 De Julio",
        "Alba Posse",
        "Bartolito",
        "C El Doradillo",
        "C El Progreso",
        "Campos Salles",
        "Colonia Alicia",
        "Colonia Aurora",
        "Desplayada",
        "El Macaco",
        "El Saltito",
        "Filemon Pose",
        "Mai Bao",
        "Nueve De Julio",
        "Puerto Alicia",
        "Puerto Aurora",
        "Puerto Insua",
        "Puerto Londero",
        "Puerto San Martin",
        "S Fcisco D Asis",
        "San Carlos",
        "San Francisco De Asis",
        "Santa Rita",
        "Torta Quemada",
        "Tres Bocas",
        "Villa Vilma",
        "1 De Mayo",
        "A Del Valle",
        "A Echeverria",
        "Alta Union",
        "Aristobulo Del Valle",
        "Barracon",
        "Bernar Rivad",
        "C Ant Morales",
        "C La Chillita",
        "C Puerto Rosales",
        "C Siete Estrellas",
        "Cainguas",
        "Campana",
        "Colonia 25 De Mayo",
        "Colonia Chafariz",
        "Colonia Fortaleza",
        "Colonia Gramado",
        "Colonia Juanita",
        "Colonia La Gruta",
        "Colonia La Polaca",
        "Colonia Mondori",
        "Colonia Paduan",
        "Colonia Palmera",
        "Colonia Primavera",
        "Colonia Segui",
        "Com Andresito",
        "Comandante Andresito",
        "Cruce Caballero",
        "Cruce Londero",
        "D Bosques",
        "El Paraiso",
        "El Tigre",
        "Fracran",
        "Fronteras",
        "Guaibichu",
        "Kilometro 17 R 8",
        "Kilometro 286",
        "Las Mercedes",
        "Lujan",
        "Mesa Redonda",
        "Miguel Guemes",
        "Mocona",
        "Monteagudo",
        "Paraiso",
        "Paraje Lucero",
        "Pindaiti",
        "Pindayti",
        "Piray Mini",
        "Puerto Paraiso",
        "Rio Yabotay",
        "Santa Rosa",
        "Tobunas",
        "Villa Don Bosco",
        "Berna De Irigoyen",
        "Bernardo De Irigoyen",
        "C De America",
        "C Manuel Belgrano",
        "C Tres Marias",
        "Cabure",
        "Campo Alegre",
        "Colonia El Pesado",
        "Deseado",
        "Dos Hermanas",
        "El Porvenir",
        "Integracion",
        "P Intercontinental",
        "Paraje Azopardo",
        "Paraje Estelina",
        "Paraje Granado",
        "Paraje Villa Union",
        "Piñalito Norte",
        "San Antonio",
        "Villa Bonita",
        "Guarani",
        "22 De Diciembre",
        "C Del Iguazu",
        "Gobernador Lanusse",
        "Iguazu",
        "La Plantadora",
        "P Banderita",
        "Puerto Aguirre",
        "Puerto Bemberg",
        "Puerto Bossetti",
        "Puerto Canoas",
        "Puerto Carolina",
        "Puerto Errecaborde",
        "Puerto Iguazu",
        "Puerto Libertad",
        "Puerto Paulito",
        "Puerto Peninsula",
        "Puerto Uruguay",
        "Puerto Wanda",
        "Puerto Yacuy",
        "Segunda Zona",
        "Tirica",
        "Villa Flor",
        "Caburei",
        "Cpcion D La Sierra",
        "Caraguatay",
        "Cataratas Del Iguazu",
        "Wanda",
        "Esperanza",
        "Puerto Esperanza",
        "25 De Mayo",
        "Eldorado",
        "Puerto Piray",
        "Santiago De Liniers",
        "9 De Julio Km 20",
        "Colonia Cunci",
        "Colonia Delicia",
        "Colonia Duran",
        "Kilometro 10",
        "Maria Magdalena",
        "Pati Cua",
        "Puerto Delicia",
        "Puerto El Dorado",
        "Puerto Linares",
        "Puerto Mado",
        "Puerto Paticaa",
        "Puerto Victoria",
        "Villa Roulet",
        "C Santa Teresa",
        "Citrus",
        "Colonia Florida",
        "E Santa Rita",
        "El Alcazar",
        "Guaraypo",
        "Ita Curuzu",
        "Kilometro 34",
        "Kilometro 60",
        "La Misionera",
        "La Posta",
        "Larraque",
        "Linea De Peray",
        "Macaca",
        "Macaco",
        "Montecarlo",
        "Paranay",
        "Puerto Alcazar",
        "Puerto Avellaneda",
        "Puerto Caraguatay",
        "Puerto Laharrague",
        "Taruma",
        "Villa Ojo De Agua",
        "Villa Union",
        "Almirante Brown",
        "Colonia Caraguatay",
        "Piray",
        "Stiago De Liniers",
        "Colonia Victoria",
        "Puerto Pinares",
        "Colonia El Alcazar",
        "Garruchos",
        "Garuhape",
        "Dr Felix M Gomez",
        "Parque San Martin",
        "Villa El Dorado",
        "Villa Juan De Vera",
    ],
    Chaco: [
        "Colonia Florencia",
        "La Colonia",
        "Resistencia",
        "Tigre",
        "V Juan De Garay",
        "Villa Barberan",
        "Villa El Dorado",
        "Villa Paranacito",
        "Campo De Galnasi",
        "El Palmar",
        "La Liguria",
        "Barranqueras",
        "La Isla",
        "Puerto Vilelas",
        "Villa Forestacion",
        "Kilometro 596",
        "Arroyo Quintana",
        "Colonia Baranda",
        "Colonia Benitez",
        "Colonia Codutti",
        "Colonia El Pilar",
        "Colonia Lucinda",
        "Colonia Popular",
        "Coronel Avalos",
        "Costa Ine",
        "El Tragadero",
        "Isla Antequera",
        "Kilometro 523",
        "La Evangelica",
        "La Ganadera",
        "La Loma",
        "La Lucinda",
        "La Negra",
        "La Palometa",
        "La Pilar",
        "La Raquel",
        "Laguna Beligay",
        "Los Algarrobos",
        "Margarita Belen",
        "Maria Sara",
        "Pilar",
        "Puente Ine",
        "Puente Palometa",
        "Puerto Antequera",
        "Puerto Bastiani",
        "Puerto Tirol",
        "Punta De Rieles",
        "Punta Nueva",
        "Punta Rieles",
        "San Miguel",
        "Villa Jalon",
        "La Eduviges",
        "Pampa Almiron",
        "S Del Rio De Oro",
        "Selvas Del Rio De Oro",
        "C La Filomena",
        "C San Antonio",
        "C Siete Arboles",
        "C Tres Lagunas",
        "Campo El Bermejo",
        "Campo Winter",
        "Colonia Bermejo",
        "Colonia El Ciervo",
        "Colonia El Fiscal",
        "Colonia Esperanza",
        "Colonia Sabina",
        "El 15",
        "El Perdido",
        "El Zapallar",
        "G Jose De S Martin",
        "General Jose De San Martin",
        "Kilometro 39",
        "Kilometro 42",
        "Kilometro 48",
        "Kilometro 59",
        "Kilometro 62",
        "Loma Alta",
        "Loma Florida",
        "Pampa Chica",
        "Pampa Larga",
        "Paraje Las Tablas",
        "Puerto Zapallar",
        "Venezuela",
        "Villa Dos",
        "C Coronel Dorrego",
        "Colonia Rodriguez Peña",
        "Kilometro 254",
        "Los Pozos",
        "Presidencia Roca",
        "Arbol Solo",
        "Charadai",
        "Cote Lai",
        "El Tupi Km 474",
        "Estancia El Sabalo",
        "Estero Redondo",
        "Fortin Cardoso",
        "General Obligado",
        "Invernada",
        "La Choza",
        "La Sabana",
        "La Vicuña",
        "Las Toscas",
        "Lote 15 La Sabana",
        "Lote 9",
        "Macomitas",
        "Puesto Cocheri",
        "Puesto Mendizabal",
        "Rio Tapenaga",
        "Tres Horquetas",
        "C Puente Philipon",
        "Cacui",
        "Campo De La Choza",
        "Campo Echegaray",
        "Colonia Echegaray",
        "Colonia Juan Penco",
        "Colonia Mixta",
        "El Obraje",
        "Fontana",
        "General Donovan",
        "Hivonnait",
        "Kilometro 2 Fcgb",
        "Kilometro 22",
        "Kilometro 29",
        "Kilometro 34",
        "Kilometro 38",
        "Kilometro 5",
        "La Elaboradora",
        "La Escondida",
        "La Leonesa",
        "Laguna Blanca",
        "Laguna Escondida",
        "Lapachito",
        "Liva",
        "Lote 48 C Mixta",
        "Lote 53 C Mixta",
        "Puente Philippon",
        "Puente Svritz",
        "Puerto Vicentini",
        "Rio Araza",
        "Vicentini",
        "Capitan Solari",
        "Ciervo Petiso",
        "Colonia Elisa",
        "Colonias Unidas",
        "Ingeniero Barbet",
        "Kilometro 575",
        "Kilometro 602",
        "La Dificultad",
        "Laguna Limpia",
        "Salto De La Vieja",
        "Basail",
        "Campo Gola",
        "Campo Urdaniz",
        "Campo Verge",
        "Colonia Tacuari",
        "Florencia",
        "Las Mercedes",
        "Los Palmares",
        "Paralelo 28",
        "Puerto Piracua",
        "La Pastoril",
        "Cabral Cue",
        "Cancha Larga",
        "Colonia Cabral",
        "Colonia Rio De Oro",
        "El 14",
        "El Lapacho",
        "Guaycuru",
        "Isla Del Cerrito",
        "La Esperanza",
        "Laguna Patos",
        "Lapacho",
        "Las Rosas",
        "Pindo",
        "Puerto Las Palmas",
        "Ranchos Viejos",
        "Rincon Del Zorro",
        "Rio De Oro",
        "San Fernando",
        "Tacuari",
        "Termas Del Cerrito",
        "Yatay",
        "Cabo Adriano Ayala",
        "General Vedia",
        "El Retiro",
        "Floradora",
        "La Magdalena",
        "Lote 15 E 268",
        "Lote 16 E 204",
        "Quia",
        "San Carlos",
        "San Eduardo",
        "Las Palmas",
        "El Campamento",
        "El Mirasol",
        "L 92 La Rinconada",
        "La Posta",
        "La Rinconada",
        "Mieres",
        "Puerto Bermejo",
        "Rio Bermejo",
        "Solalinde",
        "Timbo",
        "Colonia San Isidro",
        "G I H Fotheringham",
        "G Lucio Vi Mansill",
        "Gandolfi",
        "General Lucio Victorio Mansi",
        "Gral Lucio V Mansilla",
        "Kilometro 100",
        "Kilometro 139",
        "Km 76 Rio Bermejo",
        "Las Bolivianas",
        "Nuevo Pilcomayo",
        "O Victor Andrade",
        "P De Los Caballos",
        "Puerto Velaz",
        "Soldado E Sosa",
        "Velaz",
        "La Verde",
        "Makalle",
        "Aldea Forestal",
        "C El Paraisal",
        "C Puente Uriburu",
        "Campo Feldman",
        "Cuatro Bocas",
        "El Tacuruzal",
        "El Zanjon",
        "Lote 4 Quitilipi",
        "Lote 43 E 250",
        "Pampa La Peligrosa",
        "Pampa Legua Cuatro",
        "Picaditas",
        "Quitilipi",
        "Reduccion Napalpi",
        "Villa El Palmar",
        "C Aborigen Chaco",
        "C Blas Parera",
        "Colonia Aborigen",
        "La Matanza",
        "Pampa Del Indio",
        "Pampa Verde",
        "Santos Lugares",
        "Las Garcitas",
        "Colonia El Aguara",
        "Colonia Gualtieri",
        "Colonia La Lola",
        "El Totoral",
        "La Soledad",
        "La Tambora",
        "Las Lomitas",
        "Lote 11",
        "Lote 14",
        "Lote 23",
        "Lote 3",
        "Lote 42",
        "Machagay",
        "Napalpi",
        "Pampa Bandera",
        "Santa Marta",
        "Tres Palmas",
        "Machagai",
        "Bocas",
        "C Coronel Brandsen",
        "C Hipolito Vieytes",
        "C Santa Elena",
        "Colonia Herrera",
        "Coronel Brandsen",
        "Cuatro Arboles",
        "Curandu",
        "El Curundu",
        "El Raigonal",
        "Fortin Aguilar",
        "Fortin Chaja",
        "Guayaibi",
        "Las Banderas",
        "Lote 4 C Pastoril",
        "Martinez De Hoz",
        "Paso Del Oso",
        "Pres De La Plaza",
        "Presidencia De La Plaza",
        "Avanzada",
        "C Juan Jose Paso",
        "C La Avanzada",
        "C Las Avispas",
        "Cabeza De Tigre",
        "Colonia Los Ganzos",
        "Colonia Lote 10",
        "Colonia Matheu",
        "Fortin Potrero",
        "La Manuela",
        "La Nueva",
        "La Ofelia",
        "La Suiza",
        "La Tapera",
        "La Viruela",
        "Las Golondrinas",
        "Las Moreras",
        "Los Fortines",
        "Los Gansos",
        "Lote 10",
        "Ls Golondrinas Sur",
        "P Clodomiro Diaz",
        "Tres Boliches",
        "Tucuru",
        "Villa Angela",
        "Campo Las Puertas",
        "Campo Nuevo",
        "Colonia El Curupi",
        "Colonia El Tigre",
        "Coronel Du Graty",
        "Santa Maria",
        "Santa Sylvina",
        "Tres Monjes",
        "Colonia Lote 12",
        "Colonia Lote 3",
        "El Esquinero",
        "El Porvenir",
        "Haumonia",
        "Horquilla",
        "Kilometro 498",
        "Kilometro 520",
        "Kilometro 53",
        "Lote 1",
        "Lote 12",
        "Lote 17",
        "Lote 23 Samuhu",
        "Lote 24",
        "Lote 25",
        "Lote 7",
        "Lote 8",
        "Samuhu",
        "Enrique Urien",
        "Kilometro 525",
        "Kilometro 530",
        "Villa Berthet",
        "Allende",
        "Cerrito",
        "El 44",
        "El Cincuenta",
        "Velazquez",
        "Colonia Alto Tigre",
        "Comandante Fontana",
        "El Cogoik",
        "El Porteño",
        "Fortin Media Luna",
        "Gabriela Mistral",
        "Jose Cancio",
        "Kilometro 184",
        "Mayor M T Rojas",
        "Posta San Martin 1",
        "Rincon Florido",
        "Sold R A Arrieta",
        "Tres Lagunas",
        "Dgo F Sarmiento",
        "El Porteñito",
        "F Cabo 1 Lugones",
        "F Sgto 1Ro Leyes",
        "Fortin Lugones",
        "Kilometro 224",
        "Las Lolas",
        "Maestro F Baez",
        "Posta San Martin 2",
        "Pozo Navagan",
        "Urbana Vieja",
        "V General Guemes",
        "Villa General Guemes",
        "B De Las Casas",
        "Agente F S Ibañez",
        "C El Silencio",
        "C Guillermina",
        "C Reconquista",
        "Campo El Suri",
        "Capitan Juan Sola",
        "Colonia Santa Rosa",
        "El Acheral",
        "El Aibalito",
        "El Bordo Santo",
        "El Bragado",
        "El Cavado",
        "El Cañon",
        "El Corralito",
        "El Marcado",
        "El Mojon",
        "El Palo Santo",
        "El Paraiso",
        "El Pilon",
        "El Pindo",
        "El Quebrachal",
        "El Remanso",
        "El Simbolar",
        "El Sombrero Negro",
        "El Surr",
        "El Yulo",
        "Estero Patiño",
        "Ex Fortin Sola",
        "Ex Posta G Lavalle",
        "F Cabo 1Ro Chaves",
        "Fortin Fontana",
        "G F B Bosch",
        "J Manuel Estrada",
        "Joaquin V Gonzalez",
        "La Libertad",
        "La Manija",
        "La Media Luna",
        "La Nobleza",
        "La Palmita",
        "La Primavera",
        "La Represa",
        "Laguna Yema",
        "Lamadrid",
        "Las Avispas",
        "Los Nidos",
        "Los Pocitos",
        "M Evang Lag De Ya",
        "Mision El Carmen",
        "Nicora",
        "Nueva Italia",
        "Palma Sola",
        "Poncho Quemado",
        "Posta Lencina",
        "Pozo De Mara",
        "Pozo De Piedra",
        "Pozo Del Cuchillo",
        "Pozo Del Leon",
        "Pres Yrigoyen",
        "Puerto Irigoyen",
        "R Natural Formosa",
        "Riacho Lindo",
        "Rio Muerto",
        "San Antonio",
        "San Isidro",
        "Sumayen",
        "Tres Pozos",
        "Agua Verde",
        "Buen Lugar",
        "Caballo Muerto",
        "Carlos Pelegrini",
        "Cañada San Pedro",
        "Centro Fronterizo Clorinda",
        "9 De Julio",
        "Villa Sarmiento",
        "Barrio Jose De San Martin",
        "Barrio Sarmiento",
        "C Jose Marmol",
        "Colonia Jose Marmol",
        "Pampa Aguado",
        "Pampa Alegria",
        "Pampa De Los Locos",
        "Pampa Galpon",
        "Pampa Gamba",
        "Pampa Loca",
        "Presidencia Roque Saenz Peña",
        "Almirante Brown",
        "C Bernar Rivad",
        "F Totoralita L H",
        "La Chaco",
        "La Clotilde",
        "La Tigra",
        "Las Cnia J Marmol",
        "Las Cuatro Bocas",
        "Malbalaes",
        "Pampa D Ls Flores",
        "Pampa Grande",
        "San Bernardo",
        "Wichi",
        "Aleloy",
        "C Velez Sarsfield",
        "Colonia Alelay",
        "El Boqueron",
        "El Cuarenta Y Seis",
        "El Destierro",
        "El Espinillo",
        "El Palmar 3 Isltas",
        "El Treinta Y Seis",
        "Fortin Lavalle",
        "Girasol",
        "Kilometro 841",
        "Kilometro 884",
        "Km 855 Estacion",
        "La Pobladora",
        "Ntra Sra D Cpcion",
        "Pampa Aguara",
        "Pampa Alelai",
        "Pampa El 11",
        "Pampa El 12",
        "Pampa Florida",
        "Tres Isletas",
        "Tres Naciones",
        "V Rio Bermejito",
        "Villa Rio Bermejito",
        "El Sauzalito",
        "Bajo Hondo",
        "Bajo Verde",
        "Berlin",
        "Bolsa Grande",
        "C Cabeza De Buey",
        "C J J Castelli",
        "C La Florida Chica",
        "C La Florida Gde.",
        "C Monte Quemado",
        "California",
        "Campo El Aibal",
        "Campo El Onza",
        "Campo Grande",
        "Campo Overos",
        "Castelli",
        "Colonia El Alazan",
        "Colonia Fortuni",
        "Colonia Indigena",
        "Colonia Juan Jose Castelli",
        "Comandancia Frias",
        "Corralito",
        "Diez De Mayo",
        "El Aibal",
        "El Asustado",
        "El Desierto",
        "El Pintado",
        "El Recreo",
        "El Sauzal",
        "El Viscacheral",
        "Estancia Loma Alta",
        "Ex Fortin Arenales",
        "Ex Fortin Lavalle",
        "Ex Fortin P Millan",
        "Ex Fortin Wilde",
        "Ex Fortin Zelava",
        "Ex Fortin Zelaya",
        "Ex Ftin Com Frias",
        "Fuerte Esperanza",
        "Juan Jose Castelli",
        "Kilometro 40",
        "La Armonia",
        "La Costosa",
        "La Entrada",
        "La Estacion",
        "La Fidelidad",
        "La Flojera",
        "La Gringa",
        "La Invernada",
        "La Mora",
        "La Saltarina",
        "La Zanja",
        "Las Blancas",
        "Las Flores",
        "Las Hacheras",
        "Las Maravillas",
        "Las Vertientes",
        "Los Barriles",
        "Los Porongos",
        "Los Quirquinchos",
        "Los Tunales",
        "Lote Ocho",
        "Manantiales",
        "Miraflores",
        "Miramar",
        "Mision Angelicana",
        "Mision Nueva Pompeya",
        "Mision Nva Pompeya",
        "Molle Marcado",
        "Monte Caseros",
        "Nueva Poblacion",
        "Nueva Union",
        "Palo Marcado",
        "Pampa Castro",
        "Pampa El Silencio",
        "Pampa Los Bedogni",
        "Pampa Machete",
        "Pampa Tolosa Chica",
        "Pampa Tolosa Gde",
        "Pampa Vargas",
        "Paraje El Colchon",
        "Paraje El Colorado",
        "Paso De Los Libres",
        "Pozo De La Linea",
        "Pozo De La Mula",
        "Pozo De La Pava",
        "Pozo De La Tuna",
        "Pozo De Las Garzas",
        "Pozo De Los Suris",
        "Pozo Del Cincuenta",
        "Pozo Del Gato",
        "Pozo Del Gris",
        "Pozo Del Molle",
        "Pozo Del Negro",
        "Pozo Del Tala",
        "Pozo Del Tigre",
        "Pozo Del Toro",
        "Pozo La Brea",
        "Pozo La Osca",
        "Puerto Lavalle",
        "Puerto Urquiza",
        "Red D L Cye Lugar",
        "Red De San B El V",
        "Rosales",
        "San Agustin",
        "San Juancito",
        "San Lorenzo",
        "Santa Rita",
        "Santo Domingo",
        "Sol De Mayo",
        "Tartagal",
        "Tolderias",
        "Zaparinqui",
        "Avia Terai",
        "C A Pampa Napenay",
        "C Mariano Sarratea",
        "El Catorce",
        "El Triangulo",
        "La Mascota",
        "Lote 34",
        "Napenay",
        "Pmpa Dl Regimiento",
        "Concep. Dl Bermejo",
        "Concepcion Del Bermejo",
        "Pampa Borracho",
        "Pampa Del Infierno",
        "Pampa Hermosa",
        "Pampa Juanita",
        "El Perseguido",
        "Estados Unidos",
        "Kilometro 1338",
        "La Granja",
        "Los Frentones",
        "Pinedo",
        "Taco Pozo",
        "Campo Largo",
        "La Cuchilla",
        "Amambay",
        "Corzuela",
        "Loro Blanco",
        "Pampa Alsina",
        "Pampa Cuvalo",
        "Puesto Carrizo",
        "2 De Mayo",
        "C Cuero Quemado",
        "C General Necochea",
        "C Juan Lavalle",
        "Campo Zapa",
        "Curva De Novoa",
        "Dos Boliches",
        "El Cajon",
        "El Estero",
        "El Oro Blanco",
        "El Recoveco",
        "El Recovo",
        "Las Breñas",
        "Las Cuchillas",
        "Las Piedritas",
        "Los Cerritos",
        "Los Chinacos",
        "Pampa Brugnoli",
        "Pampa Del Cielo",
        "Pampa Del Huevo",
        "Pampa Del Tordillo",
        "Pampa Del Zorro",
        "Pampa Ipora Guazu",
        "Pampa Mitre",
        "Pampa San Martin",
        "Pampa Villordo",
        "Pampa Zanata",
        "Pampini",
        "Tres Estacas",
        "Pampa Sommer",
        "Meson De Fierro",
        "C Juan Larrea",
        "Cabral",
        "Campo Ferrando",
        "Charata",
        "Colonia Schmidt",
        "El Picaso",
        "El Puca",
        "General Necochea",
        "India Muerta",
        "Ipora Guazu",
        "Los Gualcos",
        "Lote 77",
        "Pampa Avila",
        "Pampa Barrera",
        "Pampa Cabrera",
        "Pampa Cejas",
        "Pampa Flores",
        "Pueblo Puca",
        "Arbol Blanco",
        "Pampa Landriel",
        "Santa Elvira",
        "C El Triangulo",
        "C Hamburguesa",
        "C Necochea Sud",
        "Colonia Abate",
        "Colonia Barrera",
        "Colonia Bravo",
        "Colonia Economia",
        "Colonia Welhers",
        "General Capdevila",
        "General Pinedo",
        "La Economia",
        "Las Leonas",
        "Mi Ramon Gomez",
        "Palmar Central",
        "Palmar Norte",
        "Pampa Dorotier",
        "Pinedo Central",
        "Puerta De Leon",
        "Welhers",
        "Campo El Jacaranda",
        "Hermoso Campo",
        "Itin",
        "Tres Mojones",
        "Venados Grandes",
        "C La Maria Luisa",
        "Colonia Drydale",
        "El Cuadrado",
        "Pzo D Indio E Fcgb",
        "Chorotis",
    ],
    Formosa: [
        "Villa Escolar",
        "B Del R De Pilaga",
        "Bahia Negra",
        "C Isla Alvarez",
        "C Isla De Oro",
        "C Puente Pucu",
        "C Puente Uriburu",
        "Cañada Doce",
        "Colonia Dalmacia",
        "Formosa",
        "Guaycolec",
        "Isla 9 De Julio",
        "Isla Oca",
        "Km 642 N R Bermejo",
        "La Florida",
        "Lote 4",
        "Monte Lindo",
        "Monteagudo",
        "P B Forestal Igr L",
        "Puerto Dalmacia",
        "Santa Catalina",
        "Timbo Pora",
        "Tres Marias",
        "Villa Emilia",
        "Los Galpones",
        "Banco Payagua",
        "Campo Goreta",
        "Churqui Cue",
        "Colonia Aquino",
        "Colonia Cano",
        "Colonia Pastoril",
        "Costa Del Lindo",
        "Cria Pte Yrigoyen",
        "Curupay",
        "El Angelito",
        "El Arbol Solo",
        "El Arbolito",
        "El Gato",
        "El Olvido",
        "El Ombu",
        "El Pindo",
        "El Silencio",
        "Esterito",
        "Fortin Galpon",
        "Fray M Esquiu",
        "Herradura",
        "Isla Payagua",
        "Ituzaingo",
        "La China",
        "La Esperanza",
        "La Lucrecia",
        "La Pasion",
        "La Picadita",
        "Los Claveles",
        "M L Cnia Pastoril",
        "Mayor V  E Vfañe",
        "Mercedes Cue",
        "Pres Yrigoyen",
        "Riacho Lindo",
        "Riacho Ramirez",
        "San Antonio",
        "San Cayetano",
        "Santa Maria",
        "Sargento Cabral",
        "Soldado T Schez",
        "Tatane",
        "Tres Lagunas",
        "Tres Mojones",
        "Tres Pocitos",
        "Villa Dos Trece",
        "El Colorado",
        "Espinillo",
        "G Pablo Ricchieri",
        "Hipolito Vieytes",
        "Kilometro 142",
        "Kilometro 193",
        "Kilometro 213",
        "Kilometro 232",
        "Las Cañitas",
        "Media Luna",
        "Miguel Cane",
        "Pzo D Las Botijas",
        "Racedo Escobar",
        "Gran Guardia",
        "Los Pilagas",
        "Mariano Boedo",
        "S F De Laishi",
        "San Francisco De Laishi",
        "B S Jose Obrero",
        "Bañaderos",
        "C 5 De Octubre",
        "C El Zapallito",
        "C La Disciplina",
        "C Palmar Grande",
        "Cabo Noroña",
        "Campo Hardy",
        "Campo Rigonato",
        "Casco Cue",
        "Colonia El Alba",
        "Colonia El Olvido",
        "Colonia Sabina",
        "Coronel J I Warnes",
        "Costa Rio Negro",
        "Costa Salado",
        "El Algarrobo",
        "El Corralito",
        "El Guajho",
        "El Palmar",
        "El Poi",
        "El Quebranto",
        "El Resguardo",
        "El Salado",
        "Estancia El Ciervo",
        "Estero Grande",
        "Gendarme V Garcete",
        "Isla Toldo",
        "Jose Hernandez",
        "Kilometro 109",
        "Kilometro 1695",
        "La Blanca",
        "La Loma",
        "La Sirena",
        "Loma Sene",
        "Monseñor De Andrea",
        "Palmar Chico",
        "Para Todo",
        "Pilanga Iii",
        "Pirane",
        "Rincon Ñaro",
        "Saladillo",
        "Salado",
        "San Camilo",
        "San Jacinto",
        "San Simon",
        "Zorrilla Cue",
        "Campo Oswald",
        "Desvio Los Matacos",
        "El Ñandu",
        "Kilometro 128",
        "Kilometro 1895",
        "Laguna Murua",
        "Palo Santo",
        "Potrero Norte",
        "Rincon Ñandu",
        "C Campo Vfañe",
        "Colonia Campo Villafañe",
        "B G Pueyrredon",
        "Barrio San Martin",
        "Barrio Sud America",
        "Ceibo Trece",
        "E Las Horquetas",
        "El Paraiso",
        "Isla De Puen",
        "Loma Hermosa",
        "Parque Nacional",
        "Primavera",
        "Punta Guia",
        "Riacho Negro",
        "Sol De Mayo",
        "Virasol",
        "Clorinda",
        "3 Lags  Pilcomayo",
        "Angostura",
        "Ayudante Paredes",
        "Bocarin",
        "Colonia Bouvier",
        "Curtiembre Cue",
        "El Pombero",
        "F Ameghino",
        "G Luna Olmos",
        "Isla Apango",
        "Isla Caraya",
        "Laguna Gallo",
        "Lucero Cue",
        "Monte Claro",
        "Palma Sola",
        "Pigo",
        "Pres Avellaneda",
        "Puerto Pilcomayo",
        "Punta Pora",
        "Riacho He He",
        "Rodeo Tapiti",
        "Rozadito",
        "Salvacion",
        "San Juan",
        "San Pedro",
        "Santa Isabel",
        "Sto Mayor B Aguila",
        "Toro Paso",
        "Tte Gral J C Sche",
        "Villa Lucero",
        "El Espinillo",
        "Siete Palmas",
        "Chirochilas",
        "Colonia Alfonso",
        "Frontera",
        "La Frontera",
        "Laguna Blanca",
        "Laguna Ines",
        "Marca M",
        "Nuevo Pilcomayo",
        "Primera Junta",
        "Segunda Punta",
        "Mision Tacaagle",
        "Aguas Negras",
        "Apayerey",
        "Bella Vista",
        "Cataneo Cue",
        "Chagaday",
        "Colonia 25 De Mayo",
        "Colonia Santa Rosa",
        "G Julio De Vedia",
        "G Manuel Belgrano",
        "General Manuel Belgrano",
        "Julio Cue",
        "La Urbana",
        "Loma Zapatu",
        "Loro Cue",
        "Porton Negro",
        "Puerto San Carlos",
        "S Heriberto Avalos",
        "Subtte R Masaferro",
        "Tte Cnel Gaspar Cs",
        "Villa Hermosa",
        "Villa Real",
        "V Gral M Belgrano",
        "Buena Vista",
        "San Hilario",
        "Alto Alegre",
        "Andres Flores",
        "C A Larrabure",
        "C Buena Vista",
        "C Siete Quebrados",
        "Bartolome De Las Casas",
        "Bruchard",
        "Teniente Brown",
        "Campo Azcurra",
        "Campo Del Cielo",
        "Colonia El Catorce",
        "Colonia Isla Sola",
        "Colonia Perin",
        "Colonia San Jose",
        "Coronel E Rostagno",
        "Dr Carlos Montag",
        "El Oculto",
        "Ibarreta",
        "La Inmaculada",
        "Lazo Quemado",
        "Legua A",
        "Maestra B Gomez",
        "Olegario V Andrade",
        "S D Salvatierra",
        "Soldado I Schez",
        "Subteniente Perin",
        "Villa Adelaida",
        "Villa Mercedes",
        " E R M Chiriguanos",
        "Dr E R.Mejia Chiriguanos",
        "Alolague",
        "C Juan B Alberdi",
        "C La Sociedad",
        "C Union Escuela",
        "Cabo 1Ro C Benitez",
        "Colonia Juanita",
        "Colonia La Brava",
        "Colonia Tatane",
        "Coronel F Bogado",
        "El Recreo",
        "El Sauce",
        "Estanio Del Campo",
        "Estanislao Del Campo",
        "Hermindo Bonas",
        "Juan Jose Paso",
        "Kilometro 503",
        "Las Choyas",
        "Las Mochas",
        "Loma Clavel",
        "Los Esteros",
        "Los Inmigrantes",
        "Pato Marcado",
        "Porteño Viejo",
        "Ranero Cue",
        "San Lorenzo",
        "Saturnino Segurola",
        "Sto A V Sabria",
        "Transito Cue",
        "Tres Pozos",
        "San Martin Ii",
        "Arbol Solo",
        "C Pcipal Ismael St",
        "Kilometro 1769",
        "La Paloma",
        "Paso De Naite",
        "Pozo Del Tigre",
        "Pozo Verde",
        "V General Urquiza",
        "19 De Marzo",
        "3 Lags  Herradura",
        "A Olegario Victor",
        "Bajo Hondo",
        "C  F J Muñiz",
        "C 8 De Setiembre",
        "C Aborigen B B D",
        "C Los Tres Reyes",
        "C San Bernardo",
        "C Santa Catalina",
        "Cabo Pro Chavez",
        "Campo Alegre",
        "Campo Redondo",
        "Colonia San Isidro",
        "Colonia San Pablo",
        "Colonia Santoro",
        "Colonia Villa Rica",
        "Costa Dl Pilcomayo",
        "El Ceibal",
        "El Coati",
        "El Corredero",
        "El Descanso",
        "El Mirador",
        "El Perdido",
        "El Quebracho",
        "El Tacuruzal",
        "El Totoral",
        "El Yacare",
        "El Yuchan",
        "F Cabo 1Ro Chavez",
        "Fortin Guemes",
        "Fortin La Soledad",
        "Fortin Pilcomayo",
        "Isleta",
        "Juan G Bazan",
        "Kilometro 15",
        "Kilometro 525",
        "Kilometro 642",
        "La Soledad",
        "Las Delicias",
        "Las Lomitas",
        "Las Saladas",
        "Los Baldes",
        "Los Suspiros",
        "Los Tres Reyes",
        "Paso De Los Tobas",
        "Paso La Cruz",
        "Paso Nalte",
        "Pavao",
        "Posta S Cabral",
        "Pozo De Las Garzas",
        "Pozo De Navagan",
        "Pozo El Lecheron",
        "Pozo Hondo",
        "Pozo La China",
        "Pozo La Negra",
        "Puerto Ramona",
        "Puesto Aguara",
        "Pzo D Ls Chanchos",
        "Quebracho Marcado",
        "Red C Coquena",
        "Rio Cue",
        "San Isidro",
        "San Martin 1",
        "San Miguel",
        "San Ramon",
        "Santa Rosa",
        "Sargento Agramonte",
        "Soldado E Luna",
        "Soldado M Torales",
        "Suipacha",
        "Tatu Pire",
        "Tomas Godoy Cruz",
        "El Pimpin",
        "El Tastas",
        "General Francisco Basiliano Bosch",
        "Los Chiriguanos",
        "Matias Gulacsi",
        "P Cambio A Zalazar",
        "Posta Cambio A Zalazar",
        "Pozo Del Mortero",
        "Agente A Alegre",
        "Alfonsina Storni",
        "Bajo Verde",
        "Ballon",
        "General Enrique Mosconi",
        "General Mosconi",
        "Laguna Naick Neck",
    ],
    "La Rioja": [
        "San Vicente",
        "Campo Redondo",
        "Guasuncho",
        "Isleta",
        "Kilometro 67",
        "La Reserva",
        "Mocovi",
        "Quebrachal",
        "Santa Barbara",
        "Isla Tigre",
        "Kilometro 41",
        "Kilometro 403",
        "Kilometro 421",
        "Paul Groussac",
        "Agua Blanca",
        "Villa Sanagasta",
        "Chila",
        "Adolfo E Carranza",
        "San Martin",
        "9 De Julio",
        "El Chacho",
        "El Moyano",
        "Kilometro 619",
        "Las Latas",
        "Pozo Del Barrial",
        "Ambil",
        "Comandante Leal",
        "Milagro",
        "Agua Colorada",
        "C Ortiz De Ocampo",
        "Catuna",
        "Dique De Anzulon",
        "El Verde",
        "Fcisco Ortiz De Oc",
        "La Aguadita",
        "Las Palomas",
        "Los Aguirres",
        "Los Alanices",
        "Los Mistoles",
        "Olpas",
        "Torrecitas",
        "Villa Santa Rita",
        "Baldes De Pacheco",
        "Castro Barros",
        "El Bordo",
        "El Chusco",
        "Kilometro 645",
        "La Florida",
        "La Zanja",
        "Las Vertientes",
        "Nepes",
        "P Monte Grande",
        "Simbolar",
        "Verde Olivo",
        "Chaqar",
        "Chañar",
        "Caqada Honda",
        "Huascha",
        "Kilometro 505",
        "La Carbonera",
        "La Toma",
        "Negro Huasi",
        "Nueva Esperanza",
        "Olivares S Nicolas",
        "Palo Cortado",
        "R De La Poblacion",
        "Tabaquillo",
        "Altos De Los Queb",
        "La Abra",
        "Las Piedritas",
        "Los Hormigueros",
        "Palo Labrado",
        "San Antonio",
        "San Isidro",
        "San Nicolas",
        "Villa Los Leones",
        "Amana",
        "Carrizal E Fcgb",
        "Cebollar",
        "El Cantadero",
        "Flamenco",
        "Kilometro 861",
        "Kilometro 875",
        "La Buena Suerte",
        "La Esperanza",
        "La Flor",
        "La Lancha",
        "La Ramadita",
        "La Rioja",
        "Las Caqas",
        "Las Cañas",
        "Las Padercitas",
        "Puerta De La Q",
        "Puerto Alegre",
        "San Javier",
        "San Juan",
        "Santo Domingo",
        "Chumbicha",
        "Aguada",
        "Aminga",
        "Anchico",
        "Anillaco",
        "Campo Tres Pozos",
        "El Barreal",
        "El Bayito",
        "El Escondido",
        "El Huaco",
        "El Quebracho",
        "El Valle",
        "Huaco",
        "Ismiango",
        "La Antigua",
        "La Buena Estrella",
        "La Rosilla",
        "Las Bombas",
        "Las Catas",
        "Las Peqas",
        "Las Peñas",
        "Las Sierras Bravas",
        "Mesillas Blancas",
        "Pozo Blanco",
        "Puerto Del Valle",
        "San Bernardo",
        "San Ignacio",
        "San Jose",
        "San Lorenzo",
        "San Rafael",
        "Santa Ana",
        "Santa Cruz",
        "Santa Teresa",
        "Sierra Brava",
        "Villa Bustos",
        "Anjullon",
        "El Tala",
        "Talamuyuna",
        "Las Chacritas",
        "Bazan",
        "Carrizal",
        "Chuquis",
        "Pinchas",
        "San Miguel",
        "San Pedro",
        "Aimogasta",
        "B Del Pantano",
        "Las Tuscas",
        "Los Baldes",
        "Pozo De La Yegua",
        "Seqor De La Peqa",
        "Arauco",
        "Machigasta",
        "Udpinango",
        "Estacion Mazan",
        "Kilometro 891",
        "Kilometro 921",
        "Mazan",
        "Tinocan",
        "Tmas De S Teresita",
        "Villa Mazan",
        "Apuyaco",
        "Calera La Norma",
        "E Minero C Blanco",
        "El Potrero",
        "Estacion Poman",
        "Joyanco",
        "Joyanguito",
        "Kilometro 975",
        "La Aguada Grande",
        "La Yegua Muerta",
        "Las Breas",
        "Las Casitas",
        "Las Cienagas",
        "Los Cajones",
        "Malcasco",
        "Michango",
        "Retiro De Colana",
        "Tucumanao",
        "Alpasinche",
        "La Pirgua",
        "Santa Vera Cruz",
        "Cerro Negro",
        "Chaupihuasi",
        "Cordobita",
        "El Pueblito",
        "Salicas",
        "Salicas - San Blas",
        "Los Molinos",
        "Cuipan",
        "Las Talas",
        "Los Robles",
        "Schaqui",
        "San Jose De Tinogasta",
        "Balungasta",
        "Los Robledos",
        "Mesada Grande",
        "Pampa Blanca",
        "Paso San Francisco",
        "Pillahuasi",
        "Rio Grande",
        "Saujil D Tinogasta",
        "Vega",
        "Viqa Del Cerro",
        "Yacochuyo",
        "Villa Union",
        "Banda Florida",
        "Santa Clara",
        "Los Palacios",
        "Guandacol",
        "Rivadavia",
        "Villa Castelli",
        "Vinchina",
        "Alto Jague",
        "Alto Jaguel",
        "Bajo Jague",
        "Bajo Jaguel",
        "Boca De La Q",
        "Buena Vista",
        "Casa Pintada",
        "Distrito Pueblo",
        "Jague",
        "La Armonia",
        "La Banda",
        "La Pampa",
        "Peqas Blancas",
        "Potrero Grande",
        "V S J De Vinchina",
        "Valle Hermoso",
        "Chilecito",
        "La Puntilla",
        "Samay Huasi",
        "Aicuqa",
        "Alto Carrizal",
        "Anchumbil",
        "Angulos",
        "Antinaco",
        "Carrizalillo",
        "Chaqarmuyo",
        "El Chocoy",
        "El Chuschin",
        "El Pedregal",
        "El Potrerillo",
        "Estancia De Maiz",
        "La Cuadra",
        "La Higuera",
        "Las Tucumanesas",
        "Los Corrales",
        "Los Tambillos",
        "Malligasta",
        "P De Talampaya",
        "Piedra Pintada",
        "Plaza Vieja",
        "S Dgo Depto Fam",
        "Santa Elena",
        "Snas Del Leoncito",
        "Tilimuqui",
        "Tres Cerros",
        "Anguinan",
        "El Jumeal",
        "Famatina",
        "Las Gredas",
        "Plaza Nueva",
        "Campanas",
        "Cachiyuyal",
        "Guachin",
        "Miranda",
        "Santa Florentina",
        "Saqogasta",
        "Sañogasta",
        "Pagancillo",
        "Pituil",
        "Nonogasta",
        "Los Sarmientos",
        "C Embarcadero Fcgb",
        "Catinzaco",
        "Vichigasta",
        "Barrio De Galli",
        "Carrizal Tama",
        "Chamical",
        "Chulo",
        "Colonia Alfredo",
        "El Garabato",
        "El Quemado",
        "El Retamo",
        "Esquina Del Norte",
        "G Gordillo",
        "La Invernada",
        "La Serena",
        "Pozo De La Orilla",
        "Quebracho Herrado",
        "Bella Vista",
        "Cortaderas",
        "El Mollar",
        "La Cienaga",
        "Talva",
        "Loma Blanca",
        "Bajo Grande",
        "Balde Salado",
        "Cisco",
        "El Alto",
        "El Quebrachal",
        "Esquina Del Sud",
        "Iliar",
        "La Chimenea",
        "La Huerta",
        "La Trampa",
        "Loma Larga",
        "Monte Grande",
        "Olta",
        "San Ramon",
        "Tala Verde",
        "Tres Cruces",
        "Pta De Los Llanos",
        "Punta De Los Llanos",
        "Aguadita",
        "Alcazar",
        "Casagate",
        "Casangate",
        "Chimenea",
        "El Barranco",
        "El Carrizal",
        "El Portezuelo",
        "El Puesto",
        "Falda De Citan",
        "Huaja",
        "La Lomita",
        "La Merced",
        "La Represa",
        "Las Higueras",
        "Los Algarrobos",
        "Mollaco",
        "Nacate",
        "Pacatala",
        "Puluchan",
        "Retamal",
        "Rio De Las Caqas",
        "Salana",
        "San Isidro-Rioja",
        "San Roque",
        "Solca",
        "Tama",
        "Tasquin",
        "Tuani",
        "Tuizon",
        "Bajo De Gallo",
        "Balde San Isidro",
        "Cueva Del Chacho",
        "El Chiflon",
        "Guayapa",
        "La Torre",
        "Los Baldecitos",
        "Los Colorados",
        "Los Mog Colorados",
        "Paganzo",
        "Patquia",
        "Puesto  Talita",
        "Rep De La Punta",
        "Represa De La Punta",
        "Salinas De Bustos",
        "Termas",
        "Atiles",
        "Malanzan",
        "Colon",
        "Hilario",
        "Puchuzun",
        "R 20 Kilometro 114",
        "Sorocayense",
        "Villa Corral",
        "Mina San Jorge",
        "Cerro Mercedario",
        "Cerro Panteon",
        "Cerro Pichereguas",
        "E La Puntilla",
        "Gendarmeria Nac",
        "La Alumbrera",
        "La Capilla",
        "Manantiales",
        "Pachaco",
        "Peqasquito",
        "Po D Ls Piuquenes",
        "Po De Barahona",
        "Po De Las Ojotas",
        "Po De Los Teatinos",
        "Pto S Ros De Abaj",
        "Chepes",
        "Caqada Verde",
        "Casas Viejas",
        "Corral De Isaac",
        "El Balde",
        "El Barrial",
        "El Rodeo",
        "Ilisco",
        "La Calera",
        "La Callana",
        "La Escondida",
        "La Esquina",
        "La Jarilla",
        "La Laguna",
        "La Reforma",
        "La Tordilla",
        "La Yesera",
        "Las Barrancas",
        "Las Salinas",
        "Las Toscas",
        "Los Corias",
        "Mascasin",
        "P De Los Schez",
        "Puesto De Carrizo",
        "Puesto Dichoso",
        "Pzuelo De Los Arce",
        "Q. Del Vallecito",
        "Qoqueves",
        "Real Del Cadillo",
        "Totoral",
        "Villa Casana",
        "Villa Chepes",
        "El Chorrillo",
        "Bajo Corral De Isaac",
        "Cilla Nidia",
        "Aguayo",
        "Algarrobo Grande",
        "B Corral De Isaac",
        "B Del Quebracho",
        "Bajo Hondo",
        "Cuatro Esquinas",
        "El Abra",
        "El Valdecito",
        "El Vallecito",
        "Kilometro 682",
        "Kilometro 732",
        "La America",
        "La Chilca",
        "La Envidia",
        "La Libertad",
        "Pozo De La Piedra",
        "Pozo De Piedra",
        "Siempre Verde",
        "Ulapes",
        "Villa Nidia",
        "Barranquitas",
        "Cort Embarc Fcgb",
        "Desiderio Tello",
        "El Catorce",
        "La Igualdad",
        "Agua De La Piedra",
        "Chelcos",
        "El Cerco",
        "El Potreril G Roca",
        "La Dora",
        "Piedra Larga",
        "San Solano",
        "El Calden",
        "Punta De La Sierra",
    ],
    Salta: [
        "Bolsa De Palomo",
        "C Saavedra Lamas",
        "Campo Grande",
        "Cnel M Mez De Hoz",
        "Doctor Luis Agote",
        "Dr G Sayago",
        "El Alambrado",
        "El Azotado",
        "El Desmonte",
        "El Mistolar",
        "El Potrerito",
        "El Quemado",
        "El Rosado",
        "El Totoral",
        "El Zorro",
        "Esquinitas",
        "F N De Laprida",
        "Florencio Sanchez",
        "Gobernador Yalur",
        "Guadalcazar",
        "Ing E H Faure",
        "Ing G. N Juarez",
        "Ing Guillermo N Juarez",
        "Ingeniero Juarez",
        "La Florencia",
        "La Junta",
        "La Palma Sola",
        "Las Cañitas",
        "Las Tres Marias",
        "Los Chaguancos",
        "Lote Nro 8",
        "Maria Cristina",
        "Media Luna",
        "Mistol Marcado",
        "Palmar Largo",
        "Palmarcito",
        "Pozo Cercado",
        "Pozo De La Yegua",
        "Pozo Del Maza",
        "Puerto Yrigoyen",
        "Pzo D Ls Chanchos",
        "Pzo Vrde  I G N Ju",
        "Ricardo Guiraldes",
        "S Alberto Vlba",
        "San Isidro",
        "Santa Teresa",
        "Selva Maria",
        "Sombrero Negro",
        "Tte Gral R N Fra",
        "Vaca Perdida",
        "Yunca",
        "Ctro F Clorinda",
        "B Gral J De S M",
        "Colonia Bajo Hondo",
        "La Chiquita",
        "Alem",
        "Ceibal",
        "El Brete",
        "El Jardin",
        "El Naranjo",
        "El Sunchal",
        "La Asuncion",
        "La Candelaria",
        "La Cuesta",
        "La Cueva",
        "La Maravilla",
        "La Poblacion",
        "Miraflores",
        "Potrerillos",
        "Riarte",
        "Ruiz De Los Llanos",
        "S Pedro De Aranda",
        "Tala",
        "Tala E R L Llanos",
        "Villa Trancas",
        "El Carmen",
        "El Ceibal",
        "Escuela 243",
        "San Jose De Lules",
        "Anjuana",
        "Calimonte",
        "Colalao Del Valle",
        "El Bañado",
        "El Carrizal",
        "El Paso",
        "Julipao",
        "La Cieneguita",
        "Loma Colorada",
        "Macho Rastrojo",
        "Managua",
        "Puerta De Julipao",
        "Puesto De Julipao",
        "Quilmes",
        "Quisca Grande",
        "Rincon De Quilmes",
        "Tala Paso",
        "Tio Franco",
        "Totorilla",
        "Totoritas",
        "Yasyamayo",
        "C Santa Catalina",
        "El Cercado",
        "El Indio",
        "Tolombon",
        "Aguas Calientes",
        "Bajada",
        "Barbayasco",
        "Cerro Negro",
        "Condor",
        "Cosme",
        "El Portezuelo",
        "Las Piedritas",
        "Ojo De Agua",
        "Ovando",
        "Palomar",
        "Potrerillo",
        "Rosario De La Frontera",
        "Rosario Funca",
        "San Esteban",
        "San Vicente",
        "Villa Aurelia",
        "S P. De L Corrales",
        "Vaqueria",
        "Almirante Brown",
        "Antilla",
        "Bajada Blanca",
        "Balboa",
        "Bella Vista",
        "C. De Las Juntas",
        "Cabeza De Anta",
        "Cerro Colorado",
        "Colgadas",
        "Copo Quile",
        "El Bordo",
        "El Condor",
        "El Ojo",
        "El Potrero",
        "La Cienaga Capital",
        "La Cruz",
        "La Firmeza",
        "La Pajita",
        "Las Catitas",
        "Las Saladas",
        "Las Tunillas",
        "Los Baños",
        "Los Rosales",
        "Madariaga",
        "Morenillo",
        "Pozo Blanco",
        "Puente De Plata",
        "Recreo",
        "San Agustin",
        "San Juan",
        "San Luis",
        "San Roque",
        "Santa Catalina",
        "Santa Maria",
        "Suri Micuna",
        "Tala Yaco",
        "Villa Corta",
        "Vizcacheral",
        "San Lorenzo",
        "Cortaderas",
        "El Remate",
        "La Florida",
        "Las Colas",
        "Las Mojarritas",
        "Los Canteros",
        "Monte Quemado",
        "Potrero",
        "Pozo Grande",
        "Pozo Largo",
        "Quebracho Coto",
        "Quemadito",
        "Siete De Abril",
        "Suriyacu",
        "Tala Bajada",
        "Ahi Veremos",
        "Bagual Muerto",
        "Chañar Pozo",
        "Lomas Blancas",
        "Masumpa",
        "Arenal",
        "Bajada De Cavi",
        "Bajada Grande",
        "Camara",
        "Diamante",
        "El Corral Viejo",
        "El Ojito",
        "El Puestito",
        "El Tandil",
        "Federacion",
        "La Hoyada",
        "La Palata",
        "Los Churquis",
        "Los Zanjones",
        "Ovejeria",
        "Paso Verde",
        "Pozos Largos",
        "San Felipe",
        "Santa Rosa",
        "Tamas Cortadas",
        "Mercedes",
        "Puesto Del Medio",
        "Tala Pozo",
        "Villa Grimanesa",
        "Belgrano",
        "Buena Vista",
        "Campo Caseros",
        "Chachapoyas",
        "Chamical",
        "El Prado",
        "Huguerillas",
        "La Isla",
        "La Lagunilla",
        "La Quesera",
        "La Troja",
        "Limache",
        "Los Noques",
        "Rio Ancho",
        "Salta",
        "Tres Cerritos",
        "Antonio Alice",
        "Atocha",
        "Caldera",
        "Calderilla",
        "Castellanos",
        "Curuzu",
        "El Gallinato",
        "General Alvarado",
        "Humaita",
        "Kilometro 1125",
        "La Union",
        "Las Costas",
        "Lesser",
        "Los Alamos",
        "Los Mercados",
        "Los Sauces",
        "Mayo Torito",
        "Monte",
        "Paredes",
        "Pot De Castilla",
        "San Alejo",
        "Santa Rufina",
        "Vaqueros",
        "Velardes",
        "Villa San Lorenzo",
        "Yacones",
        "Cebados",
        "Cerrillos",
        "Colon",
        "El Colegio",
        "El Huaico",
        "F Cno A Colon",
        "F Cno Vallisios",
        "Finca Colon",
        "Finca El Colegio",
        "I De La Candelaria",
        "Kilometro 1156",
        "La Falda",
        "La Fama",
        "Las Blancas",
        "Las Palmas",
        "Olmos",
        "Paraje Zanjon",
        "Piedemonte",
        "Rodeos",
        "San Clemente",
        "San Miguel",
        "Santa Elena",
        "Villa Los Tarcos",
        "Zanjon",
        "Ballenal",
        "Carabajal",
        "Chorrillos",
        "El Corralito",
        "El Dorado",
        "El Manzano",
        "El Mollar",
        "El Porvenir",
        "El Pucara",
        "El Puyil",
        "El Rosal",
        "El Timbo",
        "La Estela",
        "Las Arenas",
        "Las Mesadas",
        "Las Rosas",
        "Merced De Arriba",
        "Pascha",
        "Pie De La Cuesta",
        "Rosario De Lerma",
        "Alejo De Alberro",
        "Campo Quijano",
        "El Encon",
        "Eltunal",
        "Kilometro 1172",
        "Las Arcas",
        "Potrero De Linares",
        "Potrero De Uriburu",
        "Virrey Toledo",
        "Alfarcito",
        "Cerro Bayo",
        "Chorrillitos",
        "Damian N Torino",
        "Diego De Almagro",
        "El Alfredito",
        "El Alisal",
        "El Golgota",
        "El Toro",
        "Enc. De Tastil",
        "G Manuel Sola",
        "Gobernador Saravia",
        "Huaicondo",
        "Incahuasi",
        "Incamayo",
        "Ingeniero Maury",
        "Las Capillas",
        "Las Cebadas",
        "Las Cuevas",
        "Meseta",
        "Mina Carolina",
        "Puerta De Tastil",
        "Quebrada Del Toro",
        "Quebrada Muqano",
        "S Rosa De Tastil",
        "Santa Rosa De Tastil",
        "Tacuara",
        "Toro",
        "Tres Cruces",
        "Villa Sola",
        "Abra Del Gallo",
        "Acazoque",
        "Grandes Pastos",
        "Juncal",
        "Los Patos",
        "Mina Julio",
        "P De Poyogasta",
        "P Esq De Guardia",
        "P Mina Concordia",
        "P Morro Colorado",
        "P Pastos Grandes",
        "Paraje Cerro Negro",
        "Paraje Cobres",
        "Paraje Cortaderas",
        "Paraje Las Cuevas",
        "Paraje Nacimientos",
        "Paraje Pircas",
        "Paraje Pizcuno",
        "Paraje Uncuru",
        "S Ant D Los Cobres",
        "S R De L P Grande",
        "San Antonio De Los Cobres",
        "Caipe",
        "Catua",
        "Cauchari",
        "Chachas",
        "Chuculaqui",
        "Incachuli",
        "Juncalito",
        "Kilometro 1369",
        "Kilometro 1373",
        "Kilometro 1424",
        "La Casualidad Mina",
        "Laguna Seca",
        "Los Colorados",
        "Mina Tincalaya",
        "Olacapanto Chico",
        "Olacapanto Grande",
        "Paraje Olacapato",
        "Quebrada Del Agua",
        "Salar De Pocitos",
        "Socompa",
        "Taca Taca E Fcgb",
        "Tolar Chico",
        "Tolar Grande",
        "U Embarcadero Fcgb",
        "Vega De Arizaro",
        "Agua Negra",
        "Ampata",
        "Angostura",
        "Atudillo",
        "Barro Negro",
        "Cangrejillos",
        "Cerro Mal Canto",
        "Chorro Blanco",
        "Cuesta Chica",
        "El Candado",
        "El Maray",
        "El Molino",
        "El Nogalar",
        "El Rodeo",
        "El Trigal",
        "Finca Belgrano",
        "Huayra Huasy",
        "La Esperanza",
        "La Herradura",
        "La Yesera",
        "Las Animas",
        "Las Cortderas",
        "Las Zanjas",
        "Loma De Burro",
        "Los Laureles",
        "Mal Cante",
        "Mal Paso",
        "Mina San Esteban",
        "Mina San Guillermo",
        "Mina San Walterio",
        "Minas Victoria",
        "Molino De Gongora",
        "Palermo Oeste",
        "Payogasta",
        "Piul",
        "Pompeya",
        "Pueblo Nuevo",
        "Pueblo Viejo",
        "Punta De Agua",
        "Rio Blanco",
        "Rio Toro",
        "Rumiarco",
        "S Martin La Cuesta",
        "Saladillo",
        "Tonco",
        "Toro Yaco",
        "Trigal",
        "Valle Encantado",
        "Villitas",
        "Yacera",
        "La Poma",
        "Barrio Parabolica",
        "Cachi",
        "Cachi Adentro",
        "Cuesta Del Obispo",
        "Escalchi",
        "Fuerte Alto",
        "Las Pailas",
        "Las Trancas",
        "Puerta De La Paya",
        "Puil",
        "Rumihuasi",
        "San Jose De Colte",
        "Amaicha",
        "Banda Grande",
        "Brealito",
        "Burro Yaco",
        "C D A De La Falda",
        "C De La Z Vieja",
        "Cerro Bravo",
        "Chicoana",
        "Colmenar",
        "Colome",
        "Colte",
        "Corrida De Cori",
        "Cuchiyaco",
        "El Brealito",
        "El Churcal",
        "Esquina",
        "Gualfin",
        "H Muerto Salar",
        "Humanas",
        "La Paya",
        "La Puerta",
        "Luracatao",
        "Monte Grande",
        "S De Hombre Muerto",
        "S Jose De Escalchi",
        "San Martin",
        "Seclanta Adentro",
        "Seclantas",
        "Tacuil",
        "Tomuco",
        "Volcan Azufre",
        "Molinos",
        "Ablome",
        "Almona",
        "Ampascachi",
        "Cabra Corral Dique",
        "Cacho Molino",
        "Calvimonte",
        "Colonia El Fuerte",
        "Coronel Moldes",
        "Dr Facundo Zuviria",
        "El Carancho",
        "El Carril",
        "El Leoncito",
        "El Potrero De Diaz",
        "El Simbolar",
        "Escoipe",
        "Estacion Zuviria",
        "Gualiana",
        "Kilometro 1176 300",
        "La Argentina",
        "La Armonia",
        "La Bodega",
        "La Bodeguita",
        "La Costa",
        "La Merced",
        "La Toma",
        "Las Garzas",
        "Las Mercedes",
        "Las Pircas",
        "Las Tienditas",
        "Osma",
        "Paso Del Rio",
        "Piedra Del Molino",
        "Piedras Moradas",
        "Puente De Diaz",
        "Quisca Loro",
        "Retiro",
        "S Antonio Chicoana",
        "Saladillo De Osma",
        "San Geronimo",
        "San Jose",
        "San Nicolas",
        "Sauce Alegre",
        "Sevillar",
        "Sumalao",
        "Tres Acequias",
        "Campo Alegre",
        "Chivilme",
        "El Moyar",
        "El Tipal",
        "La Calavera",
        "La Guardia",
        "La Margarita",
        "La Maroma",
        "Las Moras",
        "Los Los",
        "Molino",
        "Palmira",
        "Pedregal",
        "Potrero De Diaz",
        "Pulares",
        "S F. De Escoipe",
        "San Joaquin",
        "Santa Ana",
        "Santa Gertrudis",
        "Tilian",
        "Villa Fanny",
        "La Viña",
        "20 De Febrero",
        "Acosta",
        "Alemania",
        "Amblayo",
        "Bodeguita",
        "Carahuasi",
        "Cerro Alemania",
        "Cevilar",
        "Coropampa",
        "Durazno",
        "El Acheral",
        "El Creston",
        "El Fraile",
        "El Obelisco",
        "El Sapo",
        "Finca El Carmen",
        "Guachipas",
        "La Pampa",
        "La Represa",
        "Las Curtiembres",
        "Las J De Alemania",
        "Las Lechuzas",
        "Los Castillos",
        "Mina Don Otto",
        "Morales",
        "Pampa Grande",
        "Redondo",
        "Rio Alemania",
        "Sauce",
        "Sauce Redondo",
        "Talapampa",
        "Tipa Sola",
        "Angastaco",
        "Lorohuasi",
        "Animana",
        "Barrial",
        "Cafayate",
        "Corralito",
        "El Recreo",
        "Finca La Rosa",
        "Isonza",
        "Jacimana",
        "La Arcadia",
        "La Banda",
        "La Punilla",
        "La Viqa",
        "Las Conchas",
        "Las Viqas",
        "Monte Viejo",
        "Palo Pintado",
        "Payogastilla",
        "Pucara",
        "Rumiuarco",
        "San Antonio",
        "San Isidro-Salta",
        "San Lucas",
        "San Rafael",
        "Santa Barbara",
        "Simbolar",
        "Yacochuya",
        "Yanchuya",
        "San Carlos",
        "Algarrobal",
        "Puerto De Diaz",
        "Arjuntas",
        "Chacra Exp.",
        "El Colgado",
        "El Espinillo",
        "El Moranillo",
        "El Zapallar",
        "Entre Rios",
        "Fuerte Quemado",
        "General Guemes",
        "Kilometro 1094",
        "La Defensa",
        "Madre Vieja",
        "Paramamaya",
        "Pozo El Algarrobo",
        "Puesto Viejo",
        "Quisto",
        "Santa Cruz",
        "Santa Rita",
        "Sargento Cristobal",
        "Sausalito",
        "Simbol Yaco",
        "Torzalito",
        "Tres Yuchanes",
        "V Mayor Zabaleta",
        "Vertientes S Rita",
        "Yasquiasme",
        "Zapallito",
        "Agua Caliente",
        "Altos H. Guemes",
        "Betania",
        "Borde De S Miguel",
        "C S Rosa De Lima",
        "Campo Santo",
        "Cantera Del Sauce",
        "Cobos",
        "D Embalse C Alegre",
        "El Sauce",
        "Gallinato",
        "Ingenio San Isidro",
        "Kilometro 1102",
        "La Ofelia",
        "La Oliva",
        "Mojotoro",
        "Rio Lavallen",
        "Rodeo Grande",
        "Santa Lucia",
        "La Silleta",
        "Acharas",
        "Anta",
        "Cabeza De Buey",
        "Carreras",
        "Chilcas",
        "Coba",
        "Cruz Quemada",
        "Ebro",
        "El Algarrobo",
        "El Estanque",
        "El Libano",
        "El Oso",
        "El Rey",
        "El Salto",
        "El Zanjon",
        "Estancia Vieja",
        "Finca La China",
        "Gonzalez",
        "Juramento",
        "La Cuestita Anta",
        "La Cuestita Metan",
        "La Posta",
        "La Puntilla",
        "La Trampa",
        "Las Acheras",
        "Las Caqas",
        "Las Cuestitas",
        "Las Flacas",
        "Las Hecheras",
        "Las Mesitas",
        "Las Viboras",
        "Lechiguana",
        "Los Corrales",
        "Los Nogales",
        "Lumbreras",
        "Palomitas",
        "Paso De La Cruz",
        "Piquete De Anta",
        "Quesera",
        "Rio De Las Piedras",
        "San Sebastian",
        "Sauce Bajada",
        "Virgilio Tedin",
        "Balderrama",
        "Campo Azul",
        "Conchas",
        "E Embarc. Fcgb",
        "El Guanaco",
        "El Sauzal",
        "El Vallecito",
        "Hosteria Juramento",
        "La Aguadita",
        "La Costosa",
        "Las Juntas",
        "Los Baqados",
        "Metan",
        "Nogalito",
        "Paso De Baderrana",
        "Paso Del Durazno",
        "Pasteadero",
        "Peru",
        "Punta Del Agua",
        "San Javier",
        "Schneidewind",
        "Sucha Pera",
        "Tala Muyo",
        "Vera Cruz",
        "Yatasto",
        "Metan Viejo",
        "Arrocero Italiano",
        "B G Desvio Fcgb",
        "El Galpon",
        "El Parque",
        "Est Foguista J F",
        "F. J F Juarez",
        "Finca Armonia",
        "Finca Rocca",
        "Las Delicias",
        "Miraflores M",
        "Pob. De Ortega",
        "Algarrobal Viejo",
        "Alto Del Mistol",
        "Bajo Grande",
        "Ceibalito",
        "Chaqar Muyo",
        "Chorroarin",
        "Coronel Olleros",
        "Corral Quemado",
        "Divisadero",
        "El Bordo Campo S",
        "El Jaravi",
        "El Pacara",
        "El Tunal",
        "La Carretera",
        "La Manga",
        "Lag. N Poblacion",
        "Lagunita",
        "Molle Pozo",
        "P De Las Carretas",
        "Paso La Cruz",
        "Poblacion",
        "Pringles",
        "Puli",
        "Rosales",
        "S Jose De Orqueras",
        "Sauzal",
        "Talas",
        "Tunalito",
        "Coronel Vidt",
        "El Algarrobal",
        "El Arenal",
        "Finca Mision Zenta",
        "Joaquin V Gonzalez",
        "Kilometro 1088",
        "La Lomita",
        "Laguna Blanca",
        "Las Flechas",
        "Limoncito",
        "Los Mollinedos",
        "Minas Ypf",
        "Poso De Algarrobo",
        "Pozo Cantado",
        "Pozo Del Greal",
        "Puerta Blanca",
        "Quebrachal",
        "Saladillo  Juarez",
        "San Fernando",
        "San Ignacio",
        "San Jorge",
        "Santo Domingo",
        "Sapo Quemado",
        "Sauce Solo",
        "Simbolito",
        "Vieja Pozo",
        "Viqal Pozo",
        "Weisburg",
        "Agua Sucia",
        "Alto Alegre",
        "Apolinario Saravia",
        "Arita",
        "Barrealito",
        "Colonia Hurlingham",
        "El Manantial",
        "El Pericote",
        "El Yeso",
        "Espinillo",
        "General Pizarro",
        "Kilometro 1104",
        "Laguna Verde",
        "Las Bateas",
        "Las Flores",
        "Las Lajitas",
        "Las Tortugas",
        "Las Vateas",
        "Los Chifles",
        "Luis Burela",
        "Monasterios",
        "Palermo",
        "Palma",
        "Palo A Pique",
        "Piquete Cabado",
        "Pozo Verde",
        "Rio Del Valle",
        "Rosario Del Dorado",
        "S Dgo S Victoria",
        "S. Forestal Km 50",
        "San Ramon",
        "Santo Domingo Anta",
        "Totoral",
        "Coronel Mollinedo",
        "Cruz Bajada",
        "Dos Arboles",
        "El Vencido",
        "Guayacan",
        "Kilometro 1152",
        "Las Puertas",
        "Llucha",
        "Macapillo",
        "Manga Vieja",
        "Mistolito",
        "N Sra De Talavera",
        "Picos De Amor",
        "Picos De Arroz",
        "Platero",
        "Roca",
        "Roma",
        "San Gabriel",
        "Santa Rosa De Anta",
        "Sunchalito",
        "Tacioj",
        "Taco Esquina",
        "Taco Pampa",
        "Toro Pampa",
        "Vencida",
        "Vinal Macho",
        "Vinal Pozo",
        "Vinalito",
        "Botija",
        "Chañar Aguada",
        "Floresta",
        "Fuerte El Pito",
        "Gaona",
        "Nuestra Señora De Talavera",
        "Talavera",
        "Tolloche",
        "Villa Matoque",
        "El Quebrachal",
        "Arroyo Colorado",
        "B La Providencia",
        "Esquina De Quisto",
        "Moralito",
        "Abra Grande",
        "Aguas Blancas",
        "C Agri S Agustin",
        "C Santa Maria",
        "Colonia A",
        "Colonia D",
        "El Molle",
        "Fortin Belgrano",
        "Juntas De S Ant",
        "Lomas De Olmedo",
        "Lote Josefina",
        "Lote Lucrecia",
        "Lote Marcela",
        "Lote Sarita",
        "Maria Jose",
        "Mision Franciscana",
        "Oran",
        "P. Del Potrillo",
        "Pozo Azul",
        "Pozo De La Piedra",
        "Pozo Pringles",
        "Puesto De Motijo",
        "Quequa",
        "Rio Colorado",
        "Rio Pescado",
        "S Ign De Loyola",
        "S R De La Nva Oran",
        "San Andres",
        "San Bernardo",
        "San Ramon De La Nueva Oran",
        "Solazuti",
        "Soledad",
        "Tres Pozos",
        "Vado Hondo",
        "Colonia Santa Rosa",
        "Isla De Caqas",
        "Isla De Cañas",
        "Los Toldos",
        "Hipolito Yrigoyen",
        "Angel Peredo",
        "Angelica",
        "El Tabacal",
        "Ingenio San Martin",
        "Kilometro 1298",
        "Lote Estela",
        "Maria Luisa",
        "Tabacal Ingenio",
        "Arbol Solo",
        "Coronel Cornejo",
        "El Mistol",
        "El Q. Carboncito",
        "El Zapallo",
        "Kilometro 1280",
        "Kilometro 1281",
        "Kilometro 1291",
        "Las Varas",
        "Pichanal",
        "Pizarro",
        "Yacara",
        "Aguas Muertas",
        "Alto Verde",
        "Arenales",
        "Belgrano Fortin 2",
        "Campo Argentino",
        "Ciervo Cansado",
        "El Breal",
        "El Destierro",
        "El Mirador",
        "El Soldadito",
        "El Tunalito",
        "El Yacon",
        "Fortin Frias",
        "La Cancha",
        "La Esquinita",
        "La Montaqa",
        "La Tablada",
        "Las Bolsas",
        "Las Llaves",
        "Martin Garcia",
        "Mollinedo",
        "P El Milagro Sani",
        "Paraiso",
        "Porongal",
        "Pozo Del Pato",
        "Pozo Del Sauce",
        "Pozo Del Zorro",
        "Puesto De La Viuda",
        "Rivadavia",
        "Santos Lugares",
        "Victorica",
        "Villa Petrona",
        "Chaguaral",
        "Esteban De Urizar",
        "Jeronimo Matorras",
        "La Estrella",
        "Martinez Del Tinco",
        "Martinez Del Tineo",
        "Yuchan",
        "Coronel Juan Sola",
        "Finca La Toma",
        "Las Maravillas",
        "Saucelito",
        "El Cuinco Pozo Del",
        "Ingenio La Esperanza",
        "Ingenio La Espza",
        "Santa Marina",
        "Urundel",
        "La Higuera",
        "Buen Lugar",
        "Colonia Otomana",
        "Corzuela",
        "El Cuchillo",
        "El Retiro",
        "Embarcacion",
        "Km 1306 Fcgb",
        "La Fortuna",
        "La Quena",
        "Los Baldes",
        "Manuel Elordi",
        "Nuevo Porvenir",
        "Otomana",
        "Puesto Grande",
        "Santa Victoria",
        "Antonio Quijarro",
        "Campichuelo",
        "General Ballivian",
        "Pastor Sevillosa",
        "Pocoy",
        "Puerto Baules",
        "Rio Seco",
        "S Hachada E Fcgb",
        "Tranquitas",
        "Campo Largo",
        "Campo Libre",
        "Capitan Juan Page",
        "Capitan Pages",
        "Chirete",
        "Cnel Juan Sola Est Morillo",
        "Dos Yuchanes",
        "Dragones",
        "El Cienego",
        "El Pertigo",
        "El Talar",
        "El Tartagal",
        "Emboscada",
        "Hickman",
        "La Curva",
        "La Entrada",
        "La Mora",
        "Las Horquetas",
        "Los Ranchillos",
        "Luna Muerta",
        "Madrejon",
        "Mision Chaqueqa",
        "Misiones",
        "Mistol Mareado",
        "Mistolar",
        "Monte Carmelo",
        "Morillo",
        "Palo Santo",
        "Pedro Lozano",
        "Pluma De Pato",
        "Pluma Del Pato",
        "Pozo Bravo",
        "Pozo Del Chaqar",
        "Pozo Del Cuico",
        "Pozo Hondo",
        "Puesto Del Paquelo",
        "Resistencia",
        "San Patricio",
        "Santa Clara",
        "Suri Pintado",
        "Tabaco Cimarron",
        "Vlta De Las Tobas",
        "Los Blancos",
        "Barrio La Loma",
        "Barrio S Cayetano",
        "Barrio San Antonio",
        "C Zanja Del Tigre",
        "Capiazutti",
        "Carapari",
        "El Chorro",
        "Frontera 4",
        "Frontera 5",
        "Frontera Tres",
        "Guamachi",
        "La Porcelana",
        "La Soledad",
        "Lote 27",
        "Manuela Pedraza",
        "Paraje Campo Largo",
        "Pozo Bermejo",
        "S Jollin",
        "San Laurencio",
        "San Pedro",
        "Tartagal",
        "Tonono",
        "Tuyunti",
        "V General Guemes",
        "Villa Saavedra",
        "Yacay",
        "Yacimiento Tonono",
        "Yariguarenda",
        "Zanja Honda",
        "Agua Verde",
        "Alto De La Sierra",
        "Amberes",
        "Balbuena",
        "Buena Fe",
        "C Buenaventura",
        "El Qato",
        "Hito 1",
        "La China",
        "Las Caqitas",
        "Mision La Paz",
        "Mision Santa Lucia",
        "Puerto La Paz",
        "S Victoria Este",
        "Santa Victoria Este",
        "G Enrique Mosconi",
        "General Enrique Mosconi",
        "Kilometro 1398",
        "Las Lomitas",
        "Vespucio",
        "Caminera S Pedrito",
        "Caminera San Pedrito",
        "Camp Tablilla",
        "Camp Vespucio",
        "Campamento Vespucio",
        "El Aguay",
        "Recaredo",
        "Kilometro 1448",
        "Piquirenda",
        "Tobantirenda",
        "Yacuy",
        "Aguaray",
        "Campo Duran",
        "Ituyuro",
        "Macueta",
        "Rio Carapan",
        "Acambuco",
        "Dique Itiruyo",
        "P S Mazza E Pocito",
        "Pocitos",
        "Salvador Mazza",
        "General Mosconi",
        "Atalaya",
        "B Alto La Viqa",
        "El Cucho",
        "La Almona",
        "San Pablo De Reyes",
        "Tilquiza",
        "Astillero",
        "Cancillar",
        "Casa Grande",
        "Chaqar",
        "Chiyayoc",
        "Colorado",
        "Coranzulli",
        "El Tapial",
        "Higueras",
        "Iruya",
        "La Huerta",
        "Matansillas",
        "Pinal",
        "Rodeo Colorado",
        "S Antonio De Iruya",
        "Tipayoc",
        "Titicoite",
        "Tres Morros",
        "Uchogol",
        "Uchuyoc",
        "Valle Delgado",
        "Villa Alem",
        "Volcan Higuera",
        "Volcan Higueras",
        "Kilometro 1321",
        "La Veta",
        "Quera",
        "Rio Grande",
        "Veta Mina Aguilar",
        "Yacoraite",
        "Bacoya",
        "Cangrejos",
        "Caracara",
        "Chocoite",
        "Kilometro 1397",
        "Llameria",
        "Llulluchayoc",
        "Mayilte",
        "Mina Belgica",
        "Mina Sol De Mayo",
        "Mollar",
        "Pairique Chico",
        "Puesto Del Marquez",
        "Pumahuasi",
        "Quenti Taco",
        "Redonda",
        "Rontuyoc",
        "Toroyoc",
        "Tuite",
        "Vizcarra",
        "Barrio La Union",
        "Abra De Peqas",
        "Casti",
        "El Monumento",
        "Sansana",
        "Acoyte",
        "Azul Cuesta",
        "Baritu",
        "Cajas",
        "Carayoc",
        "Condado",
        "Cuesta Azul",
        "Hornillos",
        "Inticancho",
        "La Chinaca",
        "Lipeo",
        "Mecoyita",
        "Meson",
        "Nazareno",
        "Papa Chacra",
        "Pascalla",
        "Piscuno",
        "Poscaya",
        "Puesto",
        "Punca Viscana",
        "Punco Viscana",
        "Rodeo",
        "Rodeo Pampa",
        "San Francisco",
        "San Juan De Oro",
        "San Leon",
        "San Marcos",
        "Soledani",
        "Suripujio",
        "Trigo Huayco",
        "Trusuca",
        "Tuctuca",
        "Viscachani",
        "Yavi Chico",
        "Cerrito",
        "Huallatayoc",
        "Ornillo",
        "Pasajes",
        "Pasto Pampa",
        "Toquero",
        "Capilla De Siton",
        "Encrucijada",
        "S Roque Las Arrias",
        "Sebastian El Cano",
        "El Pozo",
        "El Vismal",
        "Los Tajamares",
        "Puesto De Castro",
        "V R Del Saladillo",
        "El Progreso",
        "Taco Pozo",
        "C De Las Piedras",
        "Casas Viejas",
        "El Cres De Piedra",
        "El Pedacito",
        "El T V Gral Mitre",
        "Haras San Antonio",
        "Las Bandurrias",
        "Puesto Del Rosario",
        "S Ant De B Vista",
        "V General Mitre",
    ],
    Tucumán: [
        "San Miguel De Tucumán",
        "V Zenon Santillan",
        "Pacara",
        "Villa Carmela",
        "Los Sarmientos",
        "A G D V Burruyacu",
        "Agua Negra",
        "Aguadita",
        "Aserradero",
        "Barrio Diagonal",
        "Barrio Rivadavia",
        "Chorrillos",
        "Colonia Los Hills",
        "Colonia San Ramon",
        "Colonia Sarmiento",
        "Cuchillas",
        "E 300 La Picada",
        "E Capitan G De Med",
        "E Exper Agricola",
        "E Fortunata Garcia",
        "E Miguel Azcuenaga",
        "E Miguel Cervantes",
        "E Ramon Carrillo",
        "E S Agricola",
        "El Colmenar",
        "El Mutul",
        "El Timbo",
        "El Zanjon",
        "Escuela 107",
        "Escuela 163",
        "Escuela 164",
        "Escuela 210",
        "Escuela 215",
        "Escuela 218",
        "Escuela 219",
        "Escuela 255",
        "Escuela 256",
        "Escuela 299",
        "Escuela 350",
        "Escuela 393",
        "Escuela 48",
        "Escuela 59",
        "Escuela 70",
        "Granja Modelo",
        "La Aguadita",
        "La Cienaga",
        "La Puerta",
        "Las Moritas",
        "Las Salinas",
        "Las T Tranquitas",
        "Las Talitas",
        "Leo Huasi",
        "Los Hilos",
        "Los Nogales",
        "Los Pocitos",
        "Los Timbos",
        "Matul",
        "Medina",
        "Nio V Padre Monti",
        "Nogalito",
        "Nueva Rosa",
        "Ojo",
        "Ovejeria",
        "P De Palavecino",
        "Potrerillo",
        "Puerta Vieja",
        "Rio Loro",
        "Sunchal",
        "Timbo Nuevo",
        "Timbo Viejo",
        "Tranquitas",
        "Tres Sargentos",
        "V De Los Britos",
        "Vacahuasi",
        "Villa Padre Monti",
        "Villa Rosa",
        "Bajo De Raco",
        "C La Esperanza",
        "El Cadillal",
        "El Cuarteadero",
        "El Duraznito",
        "El Pelado",
        "El Tiro Argentino",
        "G Anselmo Rojo",
        "Kilometro 925",
        "La Falda",
        "La Manga",
        "La Picada",
        "La Ramada",
        "La Toma",
        "Laguna Grande",
        "Los Estanques",
        "Los Zaragoza",
        "Luz Y Fuerza",
        "Monasterio",
        "Nueva Esperanza",
        "P Cienaga Amarilla",
        "Pueblo Obrero",
        "Rincon",
        "Tafi Viejo",
        "Taficillo",
        "Talleres Naces",
        "Villa La Colmena",
        "Villa Mitre",
        "El Manantial",
        "Abra Del Tafi",
        "Ancajulli",
        "Anfana",
        "C Los Chasales",
        "Calimayo",
        "Camino Del Peru",
        "Campo Redondo",
        "Cevil Redondo",
        "Chasquivil",
        "Colonia Felipe",
        "Colonia Tacapunco",
        "Cuatro Sauces",
        "Curva De Los Vegas",
        "E Antonio Medina",
        "E G De S Mart",
        "E Otilde De Toro",
        "El Catorce",
        "El Ceibal",
        "El Duraznillo",
        "El Nogalito",
        "El Siambon",
        "Escuela",
        "Escuela 113",
        "Escuela 311",
        "Fagsa",
        "Finca Tina",
        "Hitachi",
        "Horco Molle",
        "Hoyada",
        "Kilometro 792",
        "Kilometro 808",
        "La Banda",
        "La Bomba",
        "La Cavera",
        "La Hoyada",
        "La Sala",
        "Lacavera",
        "Las Juntas",
        "Las Talas",
        "Las Tipas",
        "Lomas De Imbaud",
        "Los Aguirre",
        "Los Alamos",
        "Los Alcaraces",
        "Los Bulacios",
        "Los Chamicos",
        "Los Planchones",
        "Los Vazquez",
        "Manantial",
        "Manantial De Ovanta",
        "Misky",
        "Mtial De Ovanta",
        "Mundo Nuevo",
        "Parada De Ohuanta",
        "Puesto Cochucho",
        "R Provincial 338",
        "Raco",
        "San Alberto",
        "San Felipe",
        "San Javier",
        "San Miguel",
        "Santa Barbara",
        "Tecotex",
        "Tipas",
        "Villa Angelina",
        "Villa Nougues",
        "Villa San Javier",
        "Yerba Huasi",
        "Aconquija",
        "Barrio Casino",
        "Cuatro Gatos",
        "Higueritas",
        "Iglesias",
        "Las Mellizas",
        "Ojo De Agua",
        "Pie Del Aconquija",
        "Puerta San Javier",
        "Villa Marcos Paz",
        "Yerba Buena",
        "Banda Del Rio Sali",
        "Barrio Belgrano",
        "Ingenio Concepcion",
        "Ingenio San Juan",
        "Los Vallistos",
        "Nuevos Mataderos",
        "Puente Rio Sali",
        "Campo Herrera",
        "Aguada",
        "Bajo Grande",
        "Bilca Pozo",
        "Campana",
        "Candelillal",
        "Carbon Pozo",
        "Cevilarcito",
        "Chilcal",
        "Colombres",
        "Colonia Agricola",
        "Colonia Argentina",
        "Cortaderal",
        "Cortaderas",
        "Costa A Esquina",
        "E Esquina",
        "El C Colombres",
        "Esquina",
        "Esquina Del Llano",
        "Finca Elisa",
        "Finca Pacara",
        "Fronteritas",
        "Gobernador Nouges",
        "Ingenio Cruz Alta",
        "Ingenio Leales",
        "Juan Posse",
        "La Aguada",
        "La Empatada",
        "La Encantada",
        "La Fronterita",
        "Laguna Blanca",
        "Las Mercedes",
        "Lastenia",
        "Loma Verde",
        "Los Camperos",
        "Los Porceles",
        "Los Sueldos",
        "Los Villagra",
        "Pacara Pintado",
        "Pala Pala",
        "Polito",
        "Pozo Alto",
        "Pozo Del Alto",
        "Puesto Chico",
        "Puma Pozo",
        "Quilmes",
        "Retiro",
        "Roma",
        "Rosario Oeste",
        "S Rosa De Leales",
        "San Andres",
        "San Nicolas",
        "Santa Felisa",
        "Santa Rosa De Leales",
        "Sueldo",
        "Sueldos",
        "Vilca Pozo",
        "El Bracho",
        "Acostilla",
        "Avestilla",
        "C Y Apeadero Fcgb",
        "Cachi Huasi",
        "Cachi Yaco",
        "El Carmen",
        "El Durazno",
        "El Guardamonte",
        "El Naranjo",
        "El Rosario",
        "Entre Rios",
        "Gomez Chico",
        "Las Acostillas",
        "Leales",
        "Los Britos",
        "Los Crespo",
        "Los Gomez",
        "Los Herreras",
        "Los Juarez",
        "Los Quemados",
        "Los Romanos",
        "Lunarejos",
        "Miguel Lillo",
        "Noario",
        "San Jose De Leales",
        "Tusca Pozo",
        "Tusquitas",
        "Uturungu",
        "Villa Leales",
        "Yatapayana",
        "Agua Azul",
        "Agua Dulce",
        "Ahi Veremos",
        "Barrealito",
        "Buena Vista",
        "Camas Amontonadas",
        "Campo Azul",
        "Condor Huasi",
        "E Las Cs",
        "El Mollar",
        "El Naranjito",
        "El Pavon",
        "El Suncho",
        "Favorina",
        "Jusco Pozo",
        "La Colonia",
        "La Florida",
        "Las Celayas",
        "Las Colonias",
        "Las Encrucijadas",
        "Las Palmitas",
        "Las Zorras",
        "Los Puestos",
        "Los Villegas",
        "Los Zelayas",
        "Mancopa",
        "Mancopa Chico",
        "Mixta",
        "Mojon",
        "Monte Bello",
        "Moyar",
        "Naranjito",
        "Oran",
        "Palmitas",
        "Pirhuas",
        "Posse Desv P Fcgm",
        "Punta De Rieles",
        "Punta Rieles",
        "Rafaela Pozo",
        "Romera Pozo",
        "San Antonio",
        "Sandis",
        "Soledad",
        "Vielos",
        "Alabama",
        "Alto De Medina",
        "Angostura",
        "Carolinas Bajas",
        "Cañada De Alzogaray",
        "Chabela",
        "Delfin Gallo",
        "El Aserradero",
        "El Cochuchal",
        "El Espinillo",
        "El Mojon",
        "El Paraiso",
        "El Portezuelo",
        "Finca Lopez",
        "Guzman E Fcgb",
        "Ingenio La Florida",
        "Los Godos",
        "Los Perez",
        "Luisiana E Fcgm",
        "Lujan",
        "Macomita",
        "Mariño",
        "Monte Largo",
        "Moya",
        "Nuevo P La Florida",
        "P G Mendez",
        "Paraiso",
        "Portezuelo",
        "Pozo Hondo",
        "Puesto De Avila",
        "S Jose De Macomita",
        "San Jose De Macomita",
        "San Pedro",
        "Santa Teresa",
        "Taco",
        "Taco Palta",
        "Tambor De Tacuari",
        "Taquello",
        "Villa Fiad",
        "Agua Colorada",
        "Aguas Blancas",
        "Alto Verde",
        "Antillas",
        "Antu Mapu",
        "Asna Yaco",
        "Benjamin Araoz",
        "Benjamin Paz",
        "Burruyacu",
        "C De Los Negros",
        "Calera Aconquija",
        "California",
        "Casa Del Alto",
        "Casales",
        "Chamico",
        "Chilcas",
        "Chorrilos",
        "Churqui",
        "Concepcion",
        "Coop Agronomica",
        "Coromama",
        "Cossio",
        "Cruz De Abajo",
        "Descanso",
        "Desmonte",
        "E El Diamante",
        "El Atacal",
        "El Azul",
        "El Barco",
        "El Barrialito",
        "El Cajon",
        "El Castoral",
        "El Chorro",
        "El Churqui",
        "El Cruce",
        "El Establo",
        "El Frasquillo",
        "El Interes",
        "El Jardin",
        "El Matal",
        "El Morado",
        "El Obraje",
        "El Once",
        "El Porvenir",
        "El Puestito",
        "El Rodeo",
        "El Sinquial",
        "El Sunchal",
        "El Tajamar",
        "El Tipal",
        "El Triunfo",
        "El Zapallar",
        "F Piedra Blanca",
        "Finca Anchorena",
        "Finca Cristina",
        "Gramilla",
        "Jaguel",
        "Juliana",
        "Kilometro 80",
        "Kilometro 94",
        "La Aguita",
        "La Argentina",
        "La Calera - Tucuman",
        "La Caqada",
        "La Cautiva",
        "La Cañada",
        "La Corzuela",
        "La Cruz",
        "La Cruz De Arriba",
        "La Fortuna",
        "La Junta",
        "La Loma",
        "La Marta",
        "La Pola",
        "La Puerta De Luca",
        "La Ramada De Abajo",
        "La Ruda",
        "La Soledad",
        "La Union",
        "La Verde",
        "Laguna De Robles",
        "Las Chacras",
        "Las Pechosas",
        "Las Zanjas",
        "Loma Grande",
        "Loma Negra",
        "Los Eucaliptos",
        "Los Gonzales",
        "Los Gonzalez",
        "Los Pedraza",
        "Molle Chato",
        "Nio El Puestito",
        "P Cevil Con Agua",
        "Pacara Marcado",
        "Palomitas",
        "Paso De Las Lanzas",
        "Piedra Blanca",
        "Piedra Tendida",
        "Pozo Del Algarrobo",
        "Puerta Quemada",
        "Puestito De Arriba",
        "Puesto De Uncos",
        "Puesto Villagra",
        "Punta Del Agua",
        "Requelme",
        "Rio Del Nio",
        "Rodeo Toro",
        "S Jose De S Martin",
        "San Eusebio",
        "San Geronimo",
        "San Lorenzo",
        "San Patricio",
        "Santa Lucia",
        "Santa Rosa",
        "Santos Lugares",
        "Sinqueal",
        "Soraire",
        "Tala Pampa",
        "Tala Pozo",
        "Talita Pozo",
        "Taruca Pampa",
        "Tierras Blancas",
        "Toquello",
        "Totoral",
        "Tunalito",
        "Tusca Pampa",
        "V Benjamin Araoz",
        "El Chañar",
        "Villa Burruyacu",
        "Alizal",
        "Alurralde",
        "Aragon",
        "Choromoro",
        "Chusca",
        "Criollas",
        "E El Cadillal",
        "El Cedro",
        "El Ojo",
        "Gonzalo",
        "Huasamayo",
        "Huasamayo Sud",
        "Junta",
        "La Higuera",
        "Loma Del Medio",
        "Mato Yaco",
        "Posta Vieja",
        "Potro Yaco",
        "Puertas",
        "Puesto Grande",
        "R Del Algarrobo",
        "Rio Vipos",
        "Rodeo",
        "Rodeo Grande",
        "Salamanca",
        "Salinas",
        "San Julian Yaco",
        "San Vicente",
        "Sauce Yacu",
        "Sepultura",
        "Simbolar",
        "Tala Yaco",
        "Tapia",
        "Ticucho",
        "Tuna Sola",
        "Vesubio",
        "Viaductos Del Toro",
        "Vipos",
        "Yaco",
        "Ñorco",
        "A De La Angostura",
        "A De Los Gimenez",
        "Abra El Candado",
        "Acequiones",
        "Agua El Simbo",
        "Agua Rosada",
        "Agua Salada",
        "Aguada De Jorge",
        "Alto La Totora",
        "Anta",
        "Barborin",
        "Boba Yacu",
        "Capilla",
        "Casas Viejas",
        "Cervalito",
        "Chulca",
        "Corral Viejo",
        "E Hernan Miraval",
        "E Juan Jose Paso",
        "El Alpizar",
        "El Arenal",
        "El Boyero",
        "El Brete",
        "El Junquillar",
        "El Milagro",
        "El Mistol",
        "El Molino",
        "El P De Paranillo",
        "El Potrero",
        "El Pozo",
        "El Quebrachal",
        "El Suncal",
        "El Talar",
        "El Zauzal",
        "Estanque",
        "Hualinchay",
        "India Muerta",
        "Kilometro 1340",
        "Kilometro 847",
        "La Dorita",
        "La Esquina",
        "La Laguna",
        "La Maravilla",
        "Las Arcas",
        "Las Botijas",
        "Las Burras",
        "Las Criollas",
        "Las Pircas",
        "Las T De Colalao",
        "Las Tacanas",
        "Laurel Yaco",
        "Leocadio Paz",
        "Los Bordos",
        "Manantiales",
        "Mimillo",
        "Miranda",
        "Molle Yaco",
        "Pantanillo",
        "Perucho",
        "Pie De La Cuesta",
        "Pingollar",
        "Pozo Suncho",
        "Pradera Alegre",
        "Puesto Varela",
        "Rearte",
        "S Pedro De Colalao",
        "San Carlos",
        "San Fernando",
        "San Isidro",
        "San Jose",
        "San Pedro De Colalao",
        "Santa Rita",
        "Sauzal",
        "Tacanas",
        "Taco Llano",
        "Taco Punco",
        "Taco Yaco",
        "Taco Yana",
        "Tipa Mayo",
        "Toco Llana",
        "Toro Loco",
        "Trancas",
        "Villa Gloria",
        "Villa Rita",
        "Villa Vieja",
        "Yarami",
        "Yuchaco",
        "Zarate",
        "Los Zazos",
        "Baradero",
        "El Cuibal",
        "Los Mogotes",
        "Ovejero",
        "Salazar",
        "Tafi Del Valle",
        "E De M Ouanta",
        "E De Manualidades",
        "E Dean Salcedo",
        "E F N Laprida",
        "E I Colombres",
        "E Manuel Savio",
        "Escuela 12",
        "Escuela 130",
        "Escuela 212",
        "Escuela 222",
        "Escuela 247",
        "Escuela 251",
        "Escuela 253",
        "Escuela 254",
        "Escuela 260",
        "Escuela 324",
        "Escuela 333",
        "Escuela 348",
        "Escuela 39",
        "Escuela 51",
        "Escuela E Canton",
        "Escuela F Nogues",
        "Escuela Ing Bertre",
        "Escuela L Blanco",
        "Escuela Malvinas",
        "Ingenio Lules",
        "La Bolsa",
        "La Capilla",
        "Las Moreras",
        "Lules",
        "P De Las Tablas",
        "Potrero De Las Tablas",
        "R Provincial 301",
        "R Provincial 380",
        "Ruta Nacional 157",
        "Ruta Nacional38",
        "Santa Victoria",
        "C Maria Elena",
        "La Quebrada",
        "La Reduccion",
        "Las Tablas",
        "Malvinas",
        "Obraje",
        "Potrero",
        "Punta Del Monte",
        "Quebrada De Lules",
        "San Jenaro",
        "San Pablo",
        "San Rafael",
        "Mercedes",
        "Agua Blanca",
        "Arroyo De La Cruz",
        "B Coloradas",
        "Buen Retiro",
        "C Santa Clara",
        "C Santa Lucia",
        "Carrichango",
        "Colonia Acevedo",
        "Colonia Bascary",
        "Colonia Pacara",
        "Colonia Santa Rita",
        "E C Tucumanos",
        "E G Moreira",
        "E Monte Grande",
        "E Velez Sarsfield",
        "El Matadero",
        "El Tropezon",
        "Escuela 100",
        "Escuela 124",
        "Escuela 154",
        "Escuela 160",
        "Escuela 197",
        "Escuela 200",
        "Escuela 206",
        "Escuela 257",
        "Escuela 261",
        "Escuela 298",
        "Escuela 321",
        "Escuela 356",
        "Escuela 373",
        "Escuela 63",
        "Escuela 88",
        "Esquina Norte",
        "Famailla",
        "Finca San Luis",
        "Ing La Fronterita",
        "Invernada",
        "Kilometro 102",
        "Kilometro 108",
        "La Banderita",
        "La Pinta Y La 40",
        "Las Banderitas",
        "Las Mesadas",
        "Las Ratas",
        "Las Tres Flores",
        "Laureles",
        "Laureles Norte",
        "Laureles Sur",
        "Los Sifones",
        "R Provincial 322",
        "R Provincial 334",
        "Ruta Nacional 38",
        "S G Del Monte",
        "S J De Buena Vista",
        "San Luis",
        "Sauce Huacho",
        "Sauce Partido",
        "Teniente Berdina",
        "Tres Almacenes",
        "Monte Grande",
        "Padilla",
        "Acheral",
        "Aranilla",
        "Arenilla",
        "Kilometro 99",
        "San Gabriel",
        "San Jose De Flores",
        "Caspinchango",
        "Duraznos Blancos",
        "El Nogalar",
        "Finca Pereyra",
        "Ingeniero S Lucia",
        "Ingenio Santa Lucia",
        "La Ramadita",
        "Las Cienagas",
        "Los Rodriguez",
        "Negro Potrero",
        "Puesto La Ramadita",
        "Santa Elena",
        "Santa Monica",
        "Nueva Baviera",
        "A Del Infiernillo",
        "Abra Baya",
        "Abra De La Picaza",
        "Abra De Yareta",
        "Alisos",
        "Alto Cazadera",
        "Alto De Anfama",
        "Alto De Los Reales",
        "Alto Del Huascho",
        "Alto Del Lampazo",
        "Alto Los Cardones",
        "Amaicha Del Valle",
        "Ampimpa",
        "Antama",
        "Antiguo Quilmes",
        "Banda",
        "C De Las Gallinas",
        "C De Los Cardones",
        "Campo Blanco",
        "Campo Zauzal",
        "Carapunco",
        "Casa De Campo",
        "Casa De Zinc",
        "Cienaguita",
        "Corral Blanco",
        "Corral Grande",
        "E Cnel I Murga",
        "E Gob J M Silva",
        "E Manuela Pedraza",
        "El Antigal",
        "El Arqueal",
        "El Casial",
        "El Casialito",
        "El Espinal",
        "El Infiernillo",
        "El Lamedero",
        "El Lamparazo",
        "El Molle",
        "El Molle Viejo",
        "El Pabellon",
        "El Payanal",
        "El Potrerillo",
        "El Toro",
        "Espiadero",
        "Esquina Del Valle",
        "Fuerte Quemado",
        "La Angostura",
        "La Combada",
        "La Lagunita",
        "La Mesada",
        "La Puntilla",
        "La Queseria",
        "La Salamanca",
        "La Silla",
        "La Tranca",
        "Lamparcito",
        "Lara",
        "Las Bolsas",
        "Las Carreras",
        "Loma Redonda",
        "Los Bateones",
        "Los Colorados",
        "Los Cordones",
        "Los Corpitos",
        "Los Cuartos",
        "Mesada De Encima",
        "Molle De Abajo",
        "Nogalita",
        "P De Encalillo",
        "Palo Gacho",
        "Piedras Blancas",
        "Port De Las Animas",
        "Port De Tomas",
        "Puesto De Alumbre",
        "Puesto De Zarzo",
        "Puesto Viejo",
        "Rio Blanco",
        "S J De Chasquivil",
        "Salas",
        "San Carlitos",
        "San Francisco",
        "Tio Punco",
        "Toro Yaco",
        "Zurita",
        "Andahuala",
        "Cerro Colorado",
        "El Derrumbe",
        "Iapes",
        "Alto De Leiva",
        "Aragones",
        "Aran",
        "B Zorrilla",
        "C Santa Marina",
        "Capitan Caceres",
        "Casa De Piedra",
        "Caspichango Viejo",
        "Chilcar",
        "Colonia 6",
        "Costilla",
        "E Fray M Esquiu",
        "E J Castellano",
        "E Manuel Borda",
        "E T De Tacuari",
        "El Churquis",
        "El Huaico",
        "El Naranjal",
        "Fin Del Mundo",
        "Huasa Pampa",
        "Ibatin",
        "Isla San Jose",
        "Isla San Jose Sud",
        "Kilometro 93",
        "La Heladera",
        "La Quinta",
        "Las Pampitas",
        "Los Robles",
        "Los Sosa",
        "Monteros",
        "Monteros Viejo",
        "Piedras Coloradas",
        "Pilco",
        "Playa Larga",
        "Puesto Los Robles",
        "Rancho De Cascada",
        "Rincon Grande",
        "Santa Catalina",
        "Santo Domingo",
        "V Nueva Aguilares",
        "Villa Brava",
        "Yacuchina",
        "Yonopongo",
        "Yunopongo Sud",
        "Zavalia",
        "Independencia",
        "Ingenio Santa Rosa",
        "Leon Rouges",
        "Los Moyes",
        "Los Reyes",
        "Los Rojos",
        "Amberes",
        "Las Higuerillas",
        "Las Higueritas",
        "Quinteros 1",
        "Quinteros 2",
        "Villa Quinteros",
        "Ing La Providencia",
        "Rio Seco",
        "Barrio Textil",
        "C De Chirimayo",
        "C S Ls Cochamolles",
        "Carreta Quemada",
        "Cochamolle",
        "Cuesta De Chilca",
        "E Almirante Brown",
        "E Florencio Varela",
        "E G Lamadrid",
        "E M Dgo Bassail",
        "E Mercedes Pacheco",
        "Escuela 115",
        "Escuela 118",
        "Escuela 168",
        "Escuela 17",
        "Escuela 18",
        "Escuela 186",
        "Escuela 19",
        "Escuela 234",
        "Escuela 239",
        "Escuela 268",
        "Escuela 365",
        "Escuela 387",
        "Escuela 6",
        "Escuela 65",
        "Escuela 85",
        "Escuela Gregoria Lamadrid",
        "Iltico",
        "Ingenio La Corona",
        "Ischillon",
        "Kilometro 66",
        "Las Cuevas",
        "Las Pavas",
        "Lescano",
        "Los Vega",
        "Membrillo",
        "Muyo",
        "Piedra Grande",
        "Saladillo",
        "Villa Alvear",
        "Villa Devoto",
        "Yunca Suma",
        "Alto Las Lechuzas",
        "Arcadia",
        "C J J Iramain",
        "C P Leon Cornet",
        "Colonia Fara",
        "Gastonilla",
        "Las Faldas",
        "Los Timbres",
        "Villa Carolina",
        "Yalapa",
        "Alpachiri",
        "Belicha Huaico",
        "Cochuna",
        "Costa Del Rio Seco",
        "El Puesto",
        "Gastona",
        "Java",
        "Jaya",
        "La Tuna",
        "Las Animas",
        "Las L Las Leguas",
        "Las Leguas",
        "P De Los Valdes",
        "S R Chicligasta",
        "San Ramon",
        "Santa Cruz",
        "Valenzuela",
        "Bjo De Los Sueldos",
        "C Humaita Primera",
        "El Palcara",
        "El Sauzal",
        "Finca Entre Rios",
        "Humaita 1",
        "Humaita 2",
        "Ing La Trinidad",
        "La Esperanza",
        "La Trinidad",
        "Las G Los Guchea",
        "Los Arrietas",
        "Los Guchea",
        "Molinos",
        "Trinidad",
        "Villa La Trinidad",
        "Yucumanita",
        "Aguilares",
        "Alto Las Flores",
        "Arroyo Barriento",
        "C Nueva Trinidad",
        "Colonia Marull",
        "Colonia Naschi",
        "E Alfonsina Storni",
        "E C Pellegrini",
        "E Domingo Garcia",
        "E Luis Gianneo",
        "El Cebil",
        "Escuela 137",
        "Escuela 140",
        "Escuela 176",
        "Escuela 21",
        "Escuela 264",
        "Escuela 274",
        "Escuela 279",
        "Escuela 280",
        "Escuela 287",
        "Escuela 288",
        "Escuela 289",
        "Escuela 320",
        "Escuela 64",
        "Escuela 66",
        "Escuela 67",
        "Huasa Rincon",
        "Ing Mercedes",
        "Kilometro 1455",
        "Los Callejones",
        "Los Ocho Cuartos",
        "Monte Redondo",
        "Monte Rico",
        "Moras Minucas",
        "Multiflores",
        "Nasche",
        "P De Las Cabras",
        "Rincon Huasa",
        "Santa Isabel",
        "C De La Chilca",
        "Rio Chico",
        "Cevil Grande",
        "Chavarria",
        "Ingenio Santa Ana",
        "Kilometro 55",
        "Las Tuscas Tuscal",
        "Los Lunas",
        "Santa Ana",
        "Tuscal",
        "V Clodomiro H",
        "V Vieja Santa Ana",
        "Soldado Maldonado",
        "Arroyo Mal Paso",
        "Cevil Solo",
        "El Rincon",
        "El Tuscal",
        "Falda De Arcadia",
        "Ingenio S Barbara",
        "La Tapia",
        "La Tipa",
        "Los Agudos",
        "Los Cordoba",
        "Los Galpones",
        "Los Rios",
        "Los Rizos",
        "Mal Paso",
        "Maria Blanca",
        "Nueva Trinidad",
        "Posta",
        "Alberdi",
        "Campo De Talamayo",
        "Casa De Piedras",
        "Dique Escaba",
        "El Batiruano",
        "El Corralito",
        "El Divisadero",
        "Escaba",
        "Escaba De Abajo",
        "Escaba De Arriba",
        "Escuela 138",
        "Escuela 190",
        "Escuela 26",
        "Escuela 263",
        "Escuela 267",
        "Escuela 307",
        "Escuela 318",
        "Escuela 328",
        "Escuela 352",
        "Escuela 376",
        "Escuela 69",
        "Escuela V Generala",
        "Fuerte Alto",
        "Ingenio Marapa",
        "Kilometro 36",
        "Kilometro 46",
        "La Invernada",
        "La P De Marapa",
        "Las Tablitas",
        "Los Alamitos",
        "Los Alisos",
        "Los Arroyo",
        "Los Guayacanes",
        "Marapa",
        "Naranjo Esquina",
        "R Provincial 308",
        "Talamuyo",
        "Ucuchacra",
        "V Alberdi Estacion",
        "Villa Alberdi",
        "Villa Belgrano",
        "Yaminas",
        "Yanimas",
        "Yaquilo",
        "Alto El Puesto",
        "Campo Bello",
        "Campo Grande",
        "Escobas",
        "Gramajos",
        "Graneros",
        "Los Diaz",
        "Los Gramajo",
        "Los Gramajos",
        "Pampa Larga",
        "Taco Rodeo",
        "Ympas",
        "Zapallar",
        "Alongo",
        "Campo La Cruz",
        "Dolavon",
        "Domingo Millan",
        "Donato Alvarez",
        "El Nogal",
        "El Polear",
        "J. B. Alberdi",
        "Juan Bautista Alberdi",
        "Kilometro 10 Fcgb",
        "Los Bajos",
        "Los Tres Bajos",
        "Nueva Esquina",
        "Palo Blanco",
        "Sacrificio",
        "Batiruana",
        "Cajas Viejas",
        "Casa Vieja",
        "E J V Gonzalez",
        "E Mario Bravo",
        "E Olegario Andrade",
        "E Pedro Medrano",
        "El Colcolar",
        "El Pila",
        "Huacra",
        "Huasa Pampa Sur",
        "La Cocha",
        "La Huerta",
        "La Posta",
        "Las Cejas",
        "Loa Diaz",
        "Los Mistoles",
        "Los Pizarro",
        "Mistol",
        "Monte Redomon",
        "Ponzacon",
        "Potrerillos",
        "Pozo Cavado",
        "Ruta Nacional 64",
        "Sauce Seco",
        "Sauce Yaco",
        "Tata Yacu",
        "Villa Nueva",
        "Huasa Pampa Norte",
        "La Lagunilla",
        "Puesto Nuevo",
        "Romerello",
        "S Jose De La Cocha",
        "Bajastine",
        "El Bajo",
        "Pueblo Nuevo",
        "Pueblo Viejo",
        "Puerta Grande",
        "Rumi Punco",
        "Suncho Punta",
        "Sargento Moya",
        "Kilometro 1220",
        "Kilometro 1235",
        "Kilometro 1248",
        "Kilometro 1256",
        "Kilometro 5",
        "M Garcia Fernandez",
        "Manchala",
        "Manuel Garcia",
        "Manuela Pedraza",
        "P El Manantial",
        "R. De Balderrama",
        "Rio Lules",
        "Amaicha Del Llano",
        "Anegados",
        "Bella Vista",
        "Buena Vista Oeste",
        "Cachiyaco",
        "Carancho Pozo",
        "Colonia El Sunchal",
        "Colonia Sobrecasa",
        "E A Heredia",
        "E Angel Padilla",
        "E B De Tucuman",
        "E Cacique Manamico",
        "E Estanio Zeballos",
        "E G Miguel Nogu",
        "E Ignacio Bas",
        "E La Asuncion",
        "E La Princesa",
        "E M De Pueyrredon",
        "E P De Mendoza",
        "E Pedro Echeverry",
        "El Aguilar",
        "El Atacat",
        "El Cortaderal",
        "El Moyar",
        "Escuela 1",
        "Escuela 1 Junta",
        "Escuela 102",
        "Escuela 103",
        "Escuela 104",
        "Escuela 105",
        "Escuela 11",
        "Escuela 114",
        "Escuela 132",
        "Escuela 145",
        "Escuela 146",
        "Escuela 147",
        "Escuela 153",
        "Escuela 156",
        "Escuela 157",
        "Escuela 174",
        "Escuela 175",
        "Escuela 185",
        "Escuela 192",
        "Escuela 194",
        "Escuela 195",
        "Escuela 207",
        "Escuela 209",
        "Escuela 224",
        "Escuela 226",
        "Escuela 227",
        "Escuela 229",
        "Escuela 231",
        "Escuela 232",
        "Escuela 235",
        "Escuela 238",
        "Escuela 25 De Mayo",
        "Escuela 27",
        "Escuela 272",
        "Escuela 273",
        "Escuela 293",
        "Escuela 323",
        "Escuela 354",
        "Escuela 364",
        "Escuela 370",
        "Escuela 52",
        "Escuela 77",
        "Escuela 78",
        "Escuela 79",
        "Escuela 80",
        "Escuela 81",
        "Escuela 82",
        "Escuela Granillo",
        "Escuela R Rojas",
        "Finca Araoz",
        "Finca Los Llanos",
        "Finca Tinta",
        "Finca Tulio",
        "Ingenio B Vista",
        "Kilometro 1231",
        "Kilometro 1240",
        "Kilometro 1244",
        "Kilometro 1260",
        "Kilometro 35",
        "La Donosa",
        "La Isla",
        "La Princesa",
        "Las Barrancas",
        "Las Pirvas",
        "Los Decima",
        "Los Valdes",
        "Maria Elena",
        "Molle Pozo",
        "P De Bombeo De Ypf",
        "Paja Blanca",
        "Potrero Grande",
        "Puertas Grandes",
        "R Provincial 302",
        "R Provincial 306",
        "R Provincial 320",
        "R Provincial 323",
        "R Provincial 366",
        "R Provincial 374",
        "R Provincial 375",
        "Ruta Nacional 9",
        "Talilar",
        "Vizcachera",
        "Yangallo",
        "San Ignacio",
        "Agua De Castilla",
        "Atahona",
        "Balderrama",
        "Campo Volante",
        "Castillas",
        "Cejas De Aroca",
        "Chicligasta",
        "Ciudacita",
        "E Agueda De Posse",
        "E Araoz Alfaro",
        "E Cnel Geronimo H",
        "E Corn Saavedra",
        "E Lopez Y Planes",
        "El Chilcar",
        "El Tobar",
        "Escuela 116",
        "Escuela 126",
        "Escuela 129",
        "Escuela 131",
        "Escuela 133",
        "Escuela 134",
        "Escuela 15",
        "Escuela 16",
        "Escuela 162",
        "Escuela 183",
        "Escuela 193",
        "Escuela 198",
        "Escuela 199",
        "Escuela 20",
        "Escuela 201",
        "Escuela 203",
        "Escuela 237",
        "Escuela 241",
        "Escuela 266",
        "Escuela 269",
        "Escuela 286",
        "Escuela 297",
        "Escuela 317",
        "Escuela 322",
        "Escuela 332",
        "Escuela 345",
        "Escuela 36",
        "Escuela 391",
        "Escuela 41",
        "Escuela 43",
        "Escuela 55",
        "Escuela 68",
        "Escuela 84",
        "Escuela 93",
        "Escuela 94",
        "Escuela 95",
        "Escuela 97",
        "Escuela 99",
        "Escuela Gomez",
        "Escuela Lugones",
        "Escuela Matienzo",
        "Guemes",
        "Ichipuca",
        "Kilometro 1213",
        "La Rinconada",
        "Macio",
        "Macio Sur",
        "Mascio Pilco",
        "Mothe",
        "P Mayo Noroeste",
        "Pampa Mayo",
        "San Pedro Martir",
        "Simoca",
        "Zanjon Mascio",
        "Rio Colorado",
        "Ampata",
        "Ampatilla",
        "Arocas",
        "Arroyo Atahona",
        "Balderrama Sur",
        "Buena Yerba",
        "Consimo",
        "Corona",
        "El Mistolar",
        "Ensenada",
        "Estancia Suri Yaco",
        "Ingas",
        "Kilometro 1185",
        "Kilometro 1194",
        "Kilometro 1207",
        "La Grama",
        "La Planta",
        "La Reina",
        "Lagarte",
        "Lazarte",
        "Los Agueros",
        "Los Lescanos",
        "Los Mendozas",
        "Los Trejos",
        "Lovar",
        "Maria Luisa",
        "Monteagudo",
        "Niogasta",
        "Palomas",
        "Palominos Banda De",
        "Planta Comp Ypf",
        "Poliar",
        "Rescate",
        "Riegasta",
        "Rio Seco Km 1207",
        "S Antonio De Padua",
        "Sandovales",
        "Sud De Lazarte",
        "Sud De Sandovales",
        "Sud De Trejos",
        "Suriyaco",
        "Yacuchiri",
        "Amunpa",
        "El Barranquero",
        "El Vallecito",
        "Escuela 123",
        "Estacion Araoz",
        "3 Pozos",
        "Abra Rica",
        "Alabama Nueva",
        "Alderetes",
        "Andres Ferreyra",
        "Araoz",
        "Arbol Solo",
        "Bello Horizonte",
        "Blanco Pozo",
        "Boca Del Tigre",
        "Bracho Viejo",
        "Campo El Luisito",
        "Campo El Mollar",
        "Campo La Flor",
        "Casa Rosada",
        "Cerco Cuatro",
        "Cevil Pozo",
        "Cohigac",
        "Colonia El Puesto",
        "Colonia El Tarco",
        "Colonia La Bonaria",
        "Colonia La Ortiz",
        "Colonia La Roca",
        "Colonia Mercedes",
        "Colonia Mistol",
        "Colonia Monteros",
        "Colonia San Luis",
        "Colonia San Miguel",
        "Cruz Alta",
        "Cruz Del N E Fcgm",
        "E 12 De Octubre",
        "E Blas Parera",
        "E Coronel Roca",
        "E Guido Spano",
        "E Ing Bascary",
        "E Jose Colombres",
        "E Juana Manso",
        "E Sargento Cabral",
        "E Stiago Gallo",
        "El Bagual",
        "El Cardenal",
        "El Carmen P Alto",
        "El Cevilar",
        "El Corte",
        "El Cuadro",
        "El Guayacan",
        "El Melon",
        "El Pacara",
        "El Pajal",
        "El Puerto",
        "El Quimil",
        "Empalme Agua Dulce",
        "Escuela 106",
        "Escuela 108",
        "Escuela 109",
        "Escuela 110",
        "Escuela 111",
        "Escuela 141",
        "Escuela 142",
        "Escuela 220",
        "Escuela 225",
        "Escuela 228",
        "Escuela 270",
        "Escuela 277",
        "Escuela 283",
        "Escuela 284",
        "Escuela 291",
        "Escuela 3",
        "Escuela 308",
        "Escuela 312",
        "Escuela 327",
        "Escuela 329",
        "Escuela 330",
        "Escuela 334",
        "Escuela 335",
        "Escuela 56",
        "Escuela 76",
        "Escuela 86",
        "Escuela 9",
        "Escuela Almafuerte",
        "Escuela Arenales",
        "Escuela E De Lucas",
        "Escuela G De Vega",
        "Escuela Jose Posse",
        "Escuela N Vergara",
        "Escuela R J Freyre",
        "Escuela W Posse",
        "Finca El Ceibo",
        "Finca Leila",
        "Guanaco Muerto",
        "Kilometro 1270",
        "Kilometro 34",
        "Kilometro 771",
        "La Cantina",
        "La Cornelia",
        "La Ercilia",
        "La Favorita",
        "La Flor",
        "La Guillermina",
        "La Media Agua",
        "La Tala",
        "Las Cantinas",
        "Las Palomitas",
        "Las Piedritas",
        "Los Angeles",
        "Los Gutierrez",
        "Los Pereyra",
        "Marta",
        "Mujer Muerta",
        "Overo Pozo",
        "P De Los Alamos",
        "Palmas Redondas",
        "Pasteur Luis",
        "Pereyra Norte",
        "Pereyra Sur",
        "Porvenir",
        "R Provincial 303",
        "R Provincial 304",
        "R Provincial 319",
        "R Provincial 335",
        "R. De Las Tacanas",
        "Ranchillos Viejos",
        "S Ledesma",
        "San Miguelito",
        "Sinquial",
        "Tres Pozos",
        "Va Recaste",
        "Viclos",
        "Ranchillos",
        "Garmendia",
        "C La F Los Ralos",
        "C Lolita Norte",
        "Colmena Lolita",
        "Finca Mayo",
        "Ingenio Los Ralos",
        "Lolita",
        "Lolita Nueva",
        "Los Ralos",
        "Mayo",
        "San Pereyra",
        "Villa Tercera",
        "3 Flores",
        "Animas",
        "Charco Viejo",
        "Lopez Dominguez",
        "7 De Abril",
        "25 De Mayo",
        "9 De Julio",
        "Beltran",
        "Chalchacito",
        "Chilca",
        "Coco",
        "Durazno",
        "El Quebrachito",
        "El Tostado",
        "Encrucijada",
        "Iguana",
        "La Chilca",
        "La Iguana",
        "La Zanja",
        "Lachico",
        "Las Brisas",
        "Los Molles",
        "Moron",
        "Paez",
        "Pampa Pozo",
        "Puesto 9 De Julio",
        "Puesto Los Avilas",
        "Puesto Los Perez",
        "Quebrachito",
        "Ramaditas",
        "Ramos",
        "Rumi Yura",
        "Sala Vieja",
        "San German",
        "San Juancito",
        "Sauce Gaucho",
        "Sesteadero",
        "Simbol",
        "Taco Ralo",
        "Talitas",
        "Toro Muerto",
        "Tostado",
        "Viltran",
        "Yapachin",
        "Yumillura",
        "El 25 De Mayo",
        "Barrio Este",
        "C Maria Luisa",
        "Jumialito",
    ],
    Jujuy: [
        "Sey",
        "Cauta",
        "Aguas Calientes",
        "La Ramada",
        "Alto Del Saladillo",
        "El Saladillo",
        "Ensenada",
        "La Sanga",
        "Rodeitos",
        "Saladillo Ledesma",
        "San Jose Del Bordo",
        "San Lucas",
        "San Pedro",
        "Abra Del Trigo",
        "Aguas Blancas",
        "Arroyo Del Medio",
        "Bella Vista",
        "Cachi Punco",
        "El Fuerte",
        "El Mistol",
        "El Olvido",
        "El Palmar",
        "El Piquete",
        "El Sunchal",
        "El Tipal",
        "Gobernador Ovejero",
        "Ignio La Esperanza",
        "Ingenio La Esperanza",
        "Isla Chica",
        "Isla Grande",
        "La Ollada",
        "La Quinta",
        "La Ronda",
        "La Vertiente",
        "Laguna San Miguel",
        "Laguna Totorillas",
        "Lapachal Ledesma",
        "Lapachal S Barbara",
        "Loma Pelada",
        "Los Matos",
        "Milan",
        "Pie De La Cuesta",
        "Puesto Nuevo",
        "Real De Los Toros",
        "San Juan De Dios",
        "San Rafael",
        "Santa Clara",
        "Santa Rita",
        "Sauzal",
        "Siete Aguas",
        "23 De Agosto",
        "Arrayanal",
        "La Esperanza",
        "Lavayen",
        "Los Bayos",
        "Lote El Puesto",
        "Lote La Cienaga",
        "Lote La Posta",
        "Lote Miraflores",
        "Lote Palmera",
        "Lote Parapeti",
        "Lote San Antonio",
        "San Antonio",
        "Chalican",
        "El Quemado",
        "El Rio Negro",
        "Finca Leach",
        "Jaramillo",
        "Leachs",
        "Lote Zora",
        "Rastrojos",
        "Rio Negro",
        "Alegria",
        "Arenal Barroso",
        "Bajada Alta",
        "El Cardonal",
        "Fraile Pintado",
        "Guayacan",
        "La Bajada",
        "La Reduccion",
        "Lote Maiz Negro",
        "Oculto",
        "Ojo De Agua",
        "Siberia",
        "Palma Sola",
        "Agua Negra",
        "Alto Calilegua",
        "Animas",
        "Aparejo",
        "Bateas",
        "C 8 De Septiembre",
        "Campo Bajo",
        "Campo Colorado",
        "Candelaria",
        "Cevilar",
        "Cienaga",
        "Cortaderas",
        "Don Jorge",
        "Duraznal",
        "El Aibal",
        "El Bananal",
        "El Caulario",
        "El Manantial",
        "El Naranjo",
        "El Sauce",
        "Esquina",
        "F Del Quebrachal",
        "Falda Mojon",
        "Falda Montosa",
        "Florencia",
        "Guachan",
        "Higueritas",
        "Ingenio Ledesma",
        "La Calera",
        "La Puerta",
        "Las Higueritas",
        "Las Quintas",
        "Ledesma",
        "Lib Gral S Martin",
        "Lib Gral San Martin",
        "Loma Del Medio",
        "Los Catres",
        "Lote Prediliana",
        "Mal Paso",
        "Marta",
        "Mojon Azucena",
        "Molular",
        "Naranjito",
        "Normenta",
        "Palo A Pique",
        "Pampa Larga",
        "Paulete",
        "Paulina",
        "Piedra Blanca",
        "Potrero Alegre",
        "Pozo Cabado",
        "Pozo Verde",
        "Prediliana",
        "Pueblo Ledesma",
        "Pueblo Nuevo",
        "Puerta Vieja",
        "Ramada",
        "Rio Seco",
        "San Francisco",
        "Santillo",
        "Sauzalito",
        "Sepultura",
        "Socabon",
        "Soledad",
        "Tarijita",
        "Toquillera",
        "Trementinal",
        "Tucumancito",
        "Amancayoc",
        "Amarguras",
        "Cacho",
        "Chorro",
        "Fundiciones",
        "Gobernador Tello",
        "Loma Larga",
        "Nogal",
        "Nogales",
        "Nogalito",
        "Pampichuela",
        "Picacho",
        "Pilcomayo",
        "Pueblo",
        "Puerto Tolava",
        "Queqoal",
        "Santa Barbara",
        "Talar",
        "Valle Grande",
        "Calilegua",
        "Lecheria",
        "P Guardap Nac Ca",
        "San Lorenzo",
        "Caimancito",
        "Chaqar Solo",
        "Chaqaral",
        "Chañar Solo",
        "Chañaral",
        "Finca Agua Salada",
        "Finca Agua Tapada",
        "Finca La Lucrecia",
        "Finca La Realidad",
        "Finca S Cornelia",
        "Finca Santa Cornelia",
        "Los Baqos Termales",
        "Los Baños Termales",
        "Obraje San Jose",
        "Vinalito",
        "Yuto",
        "Barro Negro",
        "El Palmar De S F",
        "La Mendieta",
        "Lote Don David",
        "Lote Don Emilio",
        "Lote Piedritas",
        "Lote San Juancito",
        "Lote Sauzal",
        "Palo Blanco",
        "Palo Santo",
        "Rio Grande",
        "Saladillo S Pedro",
        "Rawson",
        "La Toma",
        "Las Cortaderas",
        "Limoncito",
        "Parani",
        "Pozo De La Esquina",
        "El Talar",
        "Santa Maria",
        "Algarrobal",
        "Alto Comedero",
        "B Alto La Loma",
        "B Bajo La Viqa",
        "B P 19 De Abril",
        "Barrio 9 De Julio",
        "Barrio Alberdi",
        "Barrio Chijra",
        "Barrio Cuyaya",
        "Barrio Lujan",
        "Barrio Santa Rita",
        "Chuquina",
        "El Algarrobal",
        "El Amancay",
        "Guerrero",
        "Huaico Chico",
        "Juan Galan",
        "La Cuesta",
        "Las Capillas",
        "Las Escaleras",
        "Las Higuerillas",
        "Los Alisos",
        "Nazareno",
        "Payo",
        "Reyes",
        "S. S. De Jujuy",
        "San Salvador De Jujuy",
        "Tesorero",
        "V Ciudad De Nieva",
        "Villa Gorriti",
        "Corral De Piedras",
        "El Arenal",
        "Guerreros",
        "Ituaicochico",
        "Ocloyas",
        "Reyes Villa Jardin",
        "Rio Blanco",
        "Termas De Reyes",
        "Alisos  De Abajo",
        "Alisos De Arriba",
        "Catamontaqa",
        "Ceibal",
        "El C Perico De",
        "El Carmen",
        "El Chucupal",
        "El Ollero",
        "La Cienaga",
        "Las Pircas",
        "Los Cedros",
        "Perico Del Carmen",
        "Cerro Negro",
        "La Cabaqa",
        "Paqo",
        "S Ant Perico De",
        "Alto Verde",
        "C Los Lapachos",
        "El Toba",
        "Las Caqadas",
        "Las Pichanas",
        "Los Lapachos",
        "Los Manantiales",
        "Maquinista Veron",
        "Pampa Blanca",
        "Pampa Vieja",
        "Puesto Viejo",
        "Toba",
        "Bordo La Isla",
        "Cadilla",
        "Campo La Tuna",
        "Chamical",
        "Coronel Arias",
        "El Cabral",
        "El Cadillal",
        "El Mollar",
        "El Pongo",
        "Entre Rios",
        "Estacion Perico",
        "Hornillos",
        "Iriarte",
        "Kilometro 1129",
        "La Ovejeria",
        "La Union",
        "Lagunilla El Carm",
        "Lagunilla Ledesma",
        "Las Pampitas",
        "Monte Rico",
        "Perico",
        "Perico San Juan",
        "Pozo D L Avispas",
        "San Gabriel",
        "San Juancito",
        "San Vicente",
        "Santo Domingo",
        "Venecias Args",
        "Villa Argentina",
        "San  Pedro De Jujuy",
        "Alto Hornos Zapla",
        "Carahunco",
        "Centro Forestal",
        "Cerros Zapla",
        "El Brete",
        "El Cucho",
        "El Remate",
        "G Manuel Savio",
        "Gral Manuel Savio",
        "J Manuel De Rosas",
        "Los Blancos",
        "Mina 9 De Octubre",
        "Palpala",
        "Puerta De Salas",
        "Zapla",
        "Barcena",
        "Bomba",
        "Chaqi",
        "Chilcayoc",
        "Kilometro 1183",
        "Lagunas De Yala",
        "Lagunas Deyala",
        "Leon",
        "Lozano",
        "Molinos",
        "Potrero",
        "Santuyoc",
        "Tiraxi",
        "Tiraxi Chico",
        "Volcan",
        "Yala",
        "Abra De Pives",
        "Achacamayoc",
        "Agua Bendita",
        "Agua Palomar",
        "Alto De Casa",
        "Alto De Lozano",
        "Alto De Mojon",
        "Alto Del Angosto",
        "Alto Huancar",
        "Alto Minero",
        "Alto Potrerillo",
        "Alto Quemado",
        "Alto Quirquincho",
        "Boleteria",
        "Campo Oculto",
        "Canchahuasi",
        "Capachacra",
        "Carcel",
        "Casa Negra",
        "Casa Vieja",
        "Chaqarcito",
        "Chaqi Chico",
        "Chilcar",
        "Chorrillos",
        "Coiruro",
        "Colorados",
        "Condor",
        "Costillar",
        "Cruz Nido",
        "El Colorado",
        "El Molino",
        "El Moreno",
        "El Morro",
        "Encrucijada",
        "Esquina De Huancar",
        "Esquina Grande",
        "Estancia Grande",
        "Huachichocana",
        "Huancar",
        "Inca Huasi",
        "La Aguadita",
        "Lagunas",
        "Lindero",
        "Lipan",
        "Minas De Borato",
        "Molli Punco",
        "Moreno Chico",
        "Morrito",
        "P Amontonadas",
        "Piedra Chota",
        "Piedras Blancas",
        "Porvenir",
        "Posta De Hornillos",
        "Pozo Bravo",
        "Pozo Colorado",
        "Pta De Colorados",
        "Ptas De Colorados",
        "Pueblo Pla",
        "Puerta De Lipan",
        "Puerta Patacal",
        "Puna De Jujuy",
        "Punta Canal",
        "Punta Corral",
        "Purmamarca",
        "Quisquine",
        "Receptoria",
        "Riverito",
        "S Rosa Vlle Grande",
        "Sala",
        "San Bernardo",
        "San Javier",
        "San Jose Del Chaqi",
        "Santa Rosa Tumbaya",
        "Susuyaco",
        "Tala Grusa",
        "Totorito",
        "Tres Morros",
        "Triunvirato",
        "Tumbaya",
        "Tumbaya Grande",
        "Tunalito",
        "Abra Mayo",
        "Cachihuaico",
        "El Callejon",
        "Las Chicapenas",
        "Maimara",
        "Punta Del Campo",
        "San Pedrito",
        "Tacta",
        "Yerba B Ledesma",
        "Yerba B Tilcara",
        "Alfarcito",
        "Caqas",
        "El Durazno",
        "El Perchel",
        "Huichaira",
        "Juella",
        "La Banda",
        "Molulo",
        "Pucara",
        "Puesto",
        "Quebrada Huasamayo",
        "Tilcara",
        "Yala D Mte Carmelo",
        "Angos Del Perchel",
        "Capla",
        "Chucalezna",
        "Huacalera",
        "San Jose",
        "Senador Perez",
        "Uquia",
        "Villa Del Perchel",
        "Iturbe",
        "Aparzo",
        "Balliazo",
        "Calete",
        "Coraya",
        "Galeta",
        "Hornaditas",
        "Humahuaca",
        "Kilometro 1289",
        "Ocumazo",
        "Ovara",
        "Quimazo",
        "San Andres",
        "San Roque",
        "Antiguo",
        "Azul Pampa",
        "Baliazo",
        "Caspala",
        "Chorcan",
        "Cianzo",
        "Coctaca",
        "Doglonzo",
        "Palca De Aparzo",
        "Potrerillo",
        "Rodero",
        "Ronque",
        "Santa Ana",
        "Tablon",
        "Toro Muerto",
        "Trancas",
        "Valle Colorado",
        "Varas",
        "Vizacachal",
        "Abralaite",
        "Antumpa",
        "Casayock",
        "Casilla",
        "Casillas",
        "Chaupi Rodero",
        "La Cueva",
        "Miyuyoc",
        "Peqas Blancas",
        "Pisungo",
        "Pueblo Viejo",
        "Chacar",
        "Colanzuli",
        "Finca Santiago",
        "S Isidro De Iruya",
        "San Juan",
        "San Pedro De Iruya",
        "El Aguilar",
        "Esquinas Blancas",
        "Tejadas",
        "Vicuqayoc",
        "Hipol Y Es Iturbe",
        "Hipolito Yrigoyen Estacion Iturbe",
        "Tres Cruces",
        "S F De Alfarcito",
        "San Francisco De Alfarcito",
        "Abra Pampa",
        "Agua Chica",
        "Agua De Castilla",
        "Catari",
        "Cerro Chico",
        "Chorojra",
        "Chulin O Inca Nva",
        "Cochagate",
        "E Zootecnica",
        "Guemes",
        "La Falda",
        "Lagunillas",
        "Lumara",
        "Mira Flores",
        "Miraflores De La C",
        "Potrero De La Puna",
        "Puerta Potrero",
        "Quebraleqa",
        "Ramallo",
        "Rumi Cruz",
        "S J De Miraflores",
        "Santuario",
        "Sayate",
        "Sorcuyo",
        "Tabladitas",
        "Turu Tari",
        "Abdon Castro Tolay",
        "Agua C De La Puna",
        "Agua Caliente",
        "Arbolito Nuevo",
        "Barrancas",
        "Casabindo",
        "Cauchari",
        "Cochinoca",
        "Doncellas",
        "El P De La Puna",
        "El Porvenir",
        "Kilometro 1369",
        "Muqayoc",
        "Olaroz Chico",
        "Olaroz Grande",
        "Pastos Chicos",
        "Queta",
        "Quichagua",
        "Rachaite",
        "Rinconadillas",
        "S Ana De La Puna",
        "S J De Quillagues",
        "Sijes",
        "Tambillos",
        "Tanques",
        "Teuco",
        "Turilari",
        "Tusaquillas",
        "Susques",
        "Antiguyos",
        "Carahuasi",
        "Casa Colorada",
        "Cerro Redondo",
        "Cienaga Grande",
        "Cienego Grande",
        "Cincel",
        "Coyaguaima",
        "Cusi Cusi",
        "El Toro",
        "Farillon",
        "Granadas",
        "Guayatayoc",
        "La Veta",
        "Loma Blanca",
        "Mina Ajedrez",
        "Mina Pirquitas",
        "Miniaio",
        "Olacapato",
        "Orosmayo",
        "Paicone",
        "Pairique Grande",
        "Pan De Azucar Mina",
        "Pozuelo",
        "Quera",
        "S J D La Rinconada",
        "Salitre",
        "San Juan (Jujuy)",
        "Soysolayte",
        "Tio Mayo",
        "Tocol",
        "Escaya",
        "Mayinte",
        "Mina Pulpera",
        "Mina San Francisco",
        "Mocoraite",
        "Punta Del Agua",
        "Vallecito",
        "Rinconada",
        "Barrios",
        "La Intermedia",
        "La Quiaca",
        "Mulli Punco",
        "Tafna",
        "Angosto Pescado",
        "Capilla Fuerte",
        "Huerta",
        "Lizoite",
        "Pal Tolco",
        "Yavi",
        "Cabreria",
        "Casira",
        "Cerrillos",
        "Cieneguillas",
        "Puesto Chico",
        "Rodeo Chico",
        "Yoscaba",
        "Calahoyo",
        "Canchuela",
        "Corral Blanco",
        "El Angosto",
        "La Cruz",
        "Merco",
        "Minas Azules",
        "Mires",
        "Morro",
        "Oros San Juan",
        "Peqa Colorada",
        "Piscuno",
        "San Juan De Oros",
        "San Leon",
        "Santa Catalina",
        "Timon Cruz",
        "Oratorio",
        "Cap De Los Reme",
        "Banda Varela",
        "La Aguada",
        "Lazareto",
        "Payabuayca",
        "Rio Del Tala",
        "S Fdo De Catamarca",
        "V Parque Chacabuco",
    ],
    "San Luis": [
        "Santa Clara",
        "El Leoncito",
        "La Florida",
        "Villa General Roca",
        "Villa De Praga",
        "Alto Grande",
        "Cortaderas",
        "El Quebracho",
        "Estancia Rivadavia",
        "La Cortadera",
        "La Espesura",
        "La Nelida",
        "Pescadores",
        "Represa Del Monte",
        "San Luis",
        "Tres Marias",
        "Algarrobitos",
        "Antihuasi",
        "Arenilla",
        "Balde De La Isla",
        "Buena Ventura",
        "Buena Vista",
        "C Honda De Guzman",
        "Cachi Corral",
        "Caqada Honda",
        "Casa De Piedra",
        "Cañada Honda",
        "Cerro Blanco",
        "Cerro De Piedra",
        "Cerros Largos",
        "Chipical",
        "Cruz De Caqa",
        "Cruz De Caña",
        "Cruz De Piedra",
        "Daniel Donovan",
        "El  Valle",
        "El Amparo",
        "El Arenal",
        "El Chorrillo",
        "El Durazno",
        "El Riecito",
        "El Tala",
        "El Talita",
        "El Volcan",
        "Embalse La Florida",
        "Estancia Grande",
        "Gruta De Intihuasi",
        "Hinojito",
        "Intiguasi",
        "Isla",
        "Juana Koslay",
        "La Alianza",
        "La Bajada",
        "La Calaguala",
        "La Cienaga",
        "La Higuerita",
        "La Pampa",
        "Laguna Brava",
        "Las Barranquitas",
        "Las C De S Martin",
        "Los Arroyos",
        "Los Carricitos",
        "Los Montes",
        "Los Pasitos",
        "Los Puquios",
        "Los Tapiales",
        "Maray",
        "Marmol Verde",
        "Mina Carolina",
        "Mina Santo Domingo",
        "Monte Chiquito",
        "Ojo De Agua",
        "Once De Mayo",
        "Pampa D Tamborero",
        "Pampita",
        "Pantanillos",
        "Paso De Cuero",
        "Paso Del Rey",
        "Paso Juan Gomez",
        "Q De La Burra",
        "Rio Grande",
        "San Miguel",
        "Saucesito",
        "Sol De Abril",
        "Solobasta",
        "Talarcito",
        "Totoral",
        "Trapiche",
        "Tukiros",
        "V De La Pancanta",
        "Vallecito",
        "Virarco",
        "Ahi Veremos",
        "Alta Gracia",
        "Alto",
        "Arbol Solo",
        "Balde De Amira",
        "Balde Del Rosario",
        "Barranca Colorada",
        "Barrial",
        "Caqada Angosta",
        "Divisadero",
        "El Bagual",
        "El Balde",
        "El Chaqar",
        "El Espinillo",
        "El Milagro",
        "El Paraiso",
        "El Payero",
        "El Pot De Leyes",
        "El Salto",
        "El Zampal",
        "Espinillo",
        "Hipolito Yrigoyen",
        "La Chilca",
        "La Corina",
        "La Duda",
        "La Escondida",
        "La Esperanza",
        "La Eulogia",
        "La Garza",
        "La Julia",
        "La Majada",
        "La Quebrada",
        "La Salud",
        "La Sandia",
        "La Serrana",
        "La Union",
        "Las Caritas",
        "Las Lomas",
        "Longari",
        "Los Algarrobos",
        "Los Chaqares",
        "Los Molles",
        "Los Ramblones",
        "Manantiales",
        "Paliguanta",
        "Paraiso",
        "Pozo Del Carril",
        "Pozo Del Espinillo",
        "Pozo Santiago",
        "Pozo Simon",
        "Represa Del Chaqar",
        "Retamo",
        "Retiro",
        "Rumiguasi",
        "San Jose",
        "San Raimundo",
        "San Roque",
        "Seis De Septiembre",
        "Taza Blanca",
        "Toro Negro",
        "Vizcacheras",
        "Potrero De Los Funes",
        "Agua Hedionda",
        "Algarrobal",
        "Balde Hondo",
        "Banda Sud",
        "Baqado Lindo",
        "Bella Vista",
        "Campanario",
        "La Bava",
        "La Porfia",
        "La Represita",
        "Las Salinas",
        "Maravilla",
        "P Pampa Invernada",
        "P Q Cal",
        "Pampa Invernada",
        "Pozo Cavado",
        "Pozo De Los Rayos",
        "Pozo Del Tala",
        "Pta Del Portezuelo",
        "Puerto Rico",
        "Ramadita",
        "Ramblones",
        "Rio Juan Gomez",
        "Rodeo Cadenas",
        "S F Del M. De Oro",
        "San Fco Del Monte De Oro",
        "Socoscora",
        "Tintitaco",
        "Aguaditas",
        "Balde De Arriba",
        "Balde De Azcurra",
        "Balde De Puertas",
        "Baldecito La Pampa",
        "Baqado",
        "Barriales",
        "Botijas",
        "Cantantal",
        "Carmelo",
        "Cerro Bayo",
        "Cerro Negro",
        "Chimbas",
        "Chimborazo",
        "El Mollarcito",
        "El Rincon",
        "El Verano",
        "Gualtaran",
        "La Patria",
        "La Salvadora",
        "Las Lagunas",
        "Las Lagunitas",
        "Las Mesias",
        "Las Pampitas",
        "Lindo",
        "Lomas Blancas",
        "Los Almacigos",
        "Los Mendocinos",
        "Manantial Blanco",
        "Monte Carmelo",
        "Pampa Grande",
        "Paso De Piedra",
        "Pastal",
        "Peqon Colorado",
        "Pozo Del Molle",
        "Reconquista",
        "San Rufino",
        "San Salvador",
        "Santa Rosa",
        "Santa Victoria",
        "Santo Domingo",
        "Serafina",
        "Temeraria",
        "Vista Alegre",
        "Zampal",
        "Baqadito Viejo",
        "Caqada Quemada",
        "Cebollar",
        "Consulta",
        "Corral De Piedra",
        "El Manantial",
        "El Molino",
        "La Esquina",
        "La Legua",
        "Los Pejes",
        "Lujan",
        "Manantial",
        "Santa Rufina",
        "Santa Teresita",
        "La Punta",
        "Angelita",
        "Bajada",
        "Balde De Escudero",
        "Balde Retamo",
        "Balde Viejo",
        "Baqadito",
        "Baqado De Cautana",
        "Baqos Zapallar",
        "Becerra",
        "El Baqado",
        "El Calden",
        "El Injerto",
        "El Potrerillo",
        "El Puesto",
        "El Retamo",
        "El Rio",
        "El Zapallar",
        "Entre Rios",
        "Estancia La Blanca",
        "Estancia La Union",
        "La Aguada",
        "La Brea",
        "La Linea",
        "La Vertiente",
        "Las Claritas",
        "Las Playas",
        "Las Puertas",
        "Los Chenas",
        "Naranjo",
        "Paso Del Medio",
        "Pie De La Cuesta",
        "Puesto De Tabares",
        "Puesto Talar",
        "Puntos De La Linea",
        "Quines",
        "Salinas",
        "Sant Ana",
        "Balde Ahumada",
        "Balde De Guardia",
        "Balde De La Linea",
        "Balde De Quines",
        "Balde De Torres",
        "Balde El Carril",
        "Baldecito",
        "Candelaria",
        "El Cadillo",
        "El Hormiguero",
        "El Mollar",
        "El Puestito",
        "El Sembrado",
        "La Colonia",
        "La Medula",
        "La Moderna",
        "La Plata",
        "La Ramada",
        "La Resistencia",
        "La Sirena",
        "La Tusca",
        "Las Bajadas",
        "Las Chimbas",
        "Las Playas Args",
        "Los Arces",
        "Medano Ballo",
        "Patio Limpio",
        "Puesto Roberto",
        "Quebrachito",
        "San Celestino",
        "San Martin",
        "San Pedro",
        "Tres Caqadas",
        "Pot De Los Funes",
        "Arbol Verde",
        "Balde De Garcia",
        "Balde De Guiqazu",
        "Balde De Ledesma",
        "Balde De Monte",
        "Balde De Nuevo",
        "Balde Del Escudero",
        "Caqada",
        "El Alto",
        "El Barrial",
        "El Pocito",
        "El Tembleque",
        "Florida",
        "Islitas",
        "Las Cabras",
        "Las Islitas",
        "Las Palomas",
        "Las Rosadas",
        "Los Algarrobitos",
        "Los Cerrillos",
        "Pocitos",
        "Pozo Del Medio",
        "San Jorge",
        "V De La Quebrada",
        "Villa De La Quebrada",
        "El Pimpollo",
        "Agua Amarga",
        "Alazanas",
        "Altillo",
        "Bajo De La Cruz",
        "Barzola",
        "Bebida",
        "Charlones",
        "El Carmen",
        "El Charabon",
        "El Jarillal",
        "El Molle",
        "El Pedernal",
        "El Valle",
        "Estancia El Medano",
        "Gigante",
        "Hualtaran",
        "La Calera",
        "La Calera- San Luis",
        "La Chaqarienta",
        "La Empajada",
        "La Estrella",
        "La Josefa",
        "La Juanita",
        "La Primavera",
        "La Yesera",
        "Las Barrancas",
        "Los Aguados",
        "Los Araditos",
        "Los Cerritos",
        "Los Talas",
        "Los Telarios",
        "Monte Verde",
        "Puerto Alegre",
        "Represa Del Carmen",
        "San Antonio",
        "San Geronimo",
        "San Isidro",
        "Santa Ana",
        "Nogoli",
        "Agua Seballe",
        "Aguas De Piedras",
        "Alto Del Valle",
        "Alto Pelado",
        "Beazley",
        "C Santa Virginia",
        "Caqa Larga",
        "Cerro Varela",
        "Cerro Viejo",
        "Chalanta",
        "Chischaca",
        "Chischaquita",
        "El Cazador",
        "El Lechuzo",
        "El Socorro",
        "El Totoral",
        "Estacion Zanjitas",
        "Fortin Salto",
        "Gorgonta",
        "Huejeda",
        "La Agua Nueva",
        "La Amarga",
        "La Bonita",
        "La Caqada",
        "La Costa",
        "La Dulce",
        "La Emilia",
        "La Irene",
        "La Jerga",
        "La Peregrina",
        "La Represa",
        "La Seqa",
        "La Seña",
        "La Tosca",
        "La Totora",
        "La Verde",
        "Las Colonias",
        "Las Gamas",
        "Las Piedritas",
        "Las Tres Caqadas",
        "Las Viscacheras",
        "Los Chaqaritos",
        "Los Claveles",
        "Los Coros",
        "Los Jagueles",
        "Mosmota",
        "P De Las Salinas",
        "P De Los Jumes",
        "Paje",
        "Paso Ancho",
        "Paso De La Tierra",
        "Paso De Las Toscas",
        "Paso De Las Vacas",
        "Paso De Los Bayos",
        "Pozo Cercado",
        "Puente La Orqueta",
        "Puerta De La Isla",
        "Punta Del Cerro",
        "Puntos De Agua",
        "Salitral",
        "Salto Chico",
        "San Vicente",
        "Santa Isabel",
        "Tamascanes",
        "Transval",
        "Travesia",
        "Vacas Muertas",
        "Varela",
        "Acasape",
        "Alto Del Leon",
        "Auto Grande",
        "Balda",
        "Campo De San Pedro",
        "Eleodoro Lobos",
        "Juan W Gez",
        "La Delia",
        "La Maria",
        "La Yerba Buena",
        "Las Caqitas",
        "Las Higueras",
        "Lince",
        "Santa Dionisia",
        "Alto Pencoso",
        "Balde",
        "Bebedero",
        "Chosmes",
        "El Charco",
        "El Mataco",
        "El Negro",
        "Guasquita",
        "Jarilla",
        "La Cabra",
        "La Reforma",
        "La Selva",
        "Laguna Seca",
        "Los Tamariqos",
        "Mantilla",
        "Mataco",
        "Negro Muerto",
        "P Los Algarrobos",
        "Palomar",
        "Reforma Chica",
        "Salinas Del Bebedero",
        "Santa Rita",
        "Snas D Bebedero",
        "Carolina",
        "20 De Febrero",
        "3 Esquinas",
        "A Del Portezuelo",
        "C Jardin De S Luis",
        "Coronel Alzogaray",
        "Cra La Primavera",
        "E El Chamico",
        "E El Divisadero",
        "E El Quebrachal",
        "E El Saucecito",
        "E La Guardia",
        "E La Reserva",
        "E La Zulemita",
        "E Las Bebidas",
        "E Los Hermanos",
        "E Los Nogales",
        "E San Alberto",
        "El Dique",
        "El Fortin",
        "Est S Francisco",
        "Kilometro 656",
        "La Negrita",
        "Las Palmas",
        "Lavaisse",
        "Liborio Luna",
        "Marlito",
        "Medano Chico",
        "Medano Grande",
        "Mercedes",
        "Pedernera",
        "Puesto Bella Vista",
        "Puesto El Tala",
        "San Ramon",
        "Villa Mercedes",
        "Villa Santiago",
        "Vista Hermosa",
        "Alfaland",
        "E La Guillermina",
        "E San Antonio",
        "El Morro",
        "El Pasajero",
        "El Plateado",
        "El Sarco",
        "Estancia La Morena",
        "Juan Jorba",
        "Juante",
        "La Angelina",
        "La Bertita",
        "La Gama",
        "La Iberia",
        "La Javiera",
        "La Negra",
        "La Portada",
        "Las Carolinas",
        "Las Praderas",
        "Los Cisnes",
        "San Juan De Tastu",
        "Tasto",
        "Viscacheras",
        "Cramer",
        "Villa Reynolds",
        "La Ribera",
        "C Bella Vista",
        "Colonia Bella Vista",
        "Isondu",
        "Juan Llerena",
        "La Venecia",
        "Fraga",
        "La Adela",
        "La Cautiva",
        "Medanos",
        "P De Las Carretas",
        "Paines",
        "San Ignacio",
        "Santa Catalina",
        "Avanzada",
        "Caldenadas",
        "Colonia Luna",
        "El Mangrullo",
        "El Nasao",
        "General Pedernera",
        "Justo Daract",
        "La Carmen",
        "La Elida",
        "La Garrapata",
        "La Magdalena",
        "La Mascota",
        "La Tula",
        "Las Encadenadas",
        "Las Meladas",
        "Las Totoritas",
        "Los Cesares",
        "Los Esquineros",
        "Los Medanos",
        "Los Pozos",
        "Paunero",
        "Rio Quinto",
        "Alto Verde",
        "Aviador Origone",
        "Bajos Hondos",
        "Capelen",
        "La Elisa",
        "La Flecha",
        "La Josefina",
        "La Palmira",
        "La Realidad",
        "La Silesia",
        "Laguna Capelen",
        "Laguna Sayape",
        "Nossar",
        "Portada Del Sauce",
        "S Jose Del Durazno",
        "Trece De Enero",
        "El Pastal",
        "La Isabel",
        "Nueva Escocia",
        "San Camilo",
        "Alto De La Leqa",
        "Arboleda",
        "Canteras S Isabel",
        "Caqada Del Pasto",
        "Caqada San Antonio",
        "Casas Viejas",
        "Cerro De La Pila",
        "Cerro Verde",
        "Com Granville",
        "Comandante Granville",
        "Cuatro Esquinas",
        "Dique La Florida",
        "E Las Flores",
        "El Blanco",
        "El Portezuelo",
        "El Pozo",
        "El Rosario",
        "El Salado",
        "El Vallecito",
        "Estancia El Salado",
        "La Alameda",
        "La Atalaya",
        "La Fragua",
        "La Justa",
        "La Providencia",
        "La Rinconada",
        "La Toma",
        "Las Delicias",
        "Las Flores",
        "Las Peqas",
        "Las Rosas",
        "Loma Del Medio",
        "Los Menbrillos",
        "Piedras Blancas",
        "Quebrada Honda",
        "Riecito",
        "S J De L Chaqares",
        "Salado",
        "Sololosta",
        "Tamboreo",
        "Yacoro",
        "Agua Salada",
        "La Arboleda",
        "La Petra",
        "Los Medanitos",
        "Manantial Grande",
        "Saladillo",
        "San Gregorio",
        "Las Chacras",
        "Agua Linda",
        "Ancamilla",
        "Arroyo La Cal",
        "Bajo De Conlara",
        "Bajo La Laguna",
        "C Del Puestito",
        "Caqada Blanca",
        "Caqada La Tienda",
        "Casa De Condor",
        "Cerrito",
        "Cerro Colorado",
        "Chutusa",
        "Consuelo",
        "Corral Del Tala",
        "Corrales",
        "Cruz Brillante",
        "Dormida",
        "El Baldecito",
        "El Burrito",
        "El Cardal",
        "El Cerrito",
        "El Condor",
        "El Coro",
        "El Mtial Escondido",
        "El Pajarete",
        "El Pantanillo",
        "El Pantano",
        "El Paraguay",
        "El Progreso",
        "El Puerto",
        "Hinojos",
        "Hornito",
        "La Agueda",
        "La Carmencita",
        "La Dora",
        "La Esquina Del Rio",
        "La Estancia",
        "La Huerta",
        "La Ulbara",
        "Laguna Larga",
        "Las Chacritas",
        "Las Lajas",
        "Las Mangas",
        "Las Toscas",
        "Los Alamos",
        "Los Comederos",
        "Los Corrales",
        "Los Hinojos",
        "Los Lechuzones",
        "Los Sauces",
        "Manantial Lindo",
        "Pampa Del Bajo",
        "Pantanillo",
        "Paso Grande",
        "Piedra Bola",
        "Piedra Larga",
        "Piedra Rosada",
        "Piedra Sola",
        "Planta De Sandia",
        "Potrerillo",
        "Pozo Seco",
        "Puestito",
        "Q De Los Barrosos",
        "Salado De Amaya",
        "San Fernando",
        "San Lorenzo",
        "San Rafael",
        "Sauce",
        "Venta De Los Rios",
        "Alto Del Molle",
        "Caqaditas",
        "Cañaditas",
        "Cerrito Negro",
        "Chacaritas",
        "Cueva De Tigre",
        "El Bajo",
        "El Hornito",
        "El Peje",
        "Fortuna De S Juan",
        "Fortuna De San Juan",
        "La Huertita",
        "La Mina",
        "La Puerta",
        "Las C. De S Martin",
        "Las Chacras De San Martin",
        "Los Poleos",
        "Media Luna",
        "Pampa",
        "Piedras Anchas",
        "Puerta De Palo",
        "Q De La Mora",
        "Quebrada De La Mora",
        "Quebrada De San Vicente",
        "Sol D Abril -San M",
        "9 De Julio",
        "Pampa De Los Ges",
        "Calera Argentina",
        "Caleras C Grande",
        "Conlara",
        "Guanaco",
        "Huchisson",
        "La Guardia",
        "La Suiza",
        "Las Canteras",
        "Los Corralitos",
        "Los Mollecitos",
        "Manantial De Renca",
        "Mollecito",
        "Naschel",
        "Piedras Chatas",
        "Retazo Del Monte",
        "San Felipe",
        "General Urquiza",
        "Chacras Viejas",
        "Concaran",
        "El Arroyo",
        "El Cavado",
        "El Cerro",
        "El Sauce",
        "Fenoglio",
        "La Elvira",
        "La Gramilla",
        "Las Nieves",
        "Los Comedores",
        "Los Puestos",
        "Los Quebrachos",
        "Mina Los Condores",
        "Q De S Vicente",
        "Santa Martina",
        "Santa Simona",
        "Villa Dolores",
        "Arboles Blancos",
        "Arroyo De Vilches",
        "Barranquitas",
        "C De Los Tigres",
        "Cain De Los Tigres",
        "Caqada De Atras",
        "El Poleo",
        "El Rodeo",
        "El Salado De Amaya",
        "El Salvador",
        "Guanaco Pampa",
        "Huertas",
        "La Aurora",
        "La Chilla",
        "La Crucecita",
        "La Mesilla",
        "La Sala",
        "Lag De La C",
        "Laguna De Patos",
        "Las Aguadas",
        "Las Raices",
        "Los Lobos",
        "Pozo Frio",
        "Puerta Colorada",
        "Santa Maria",
        "Tala Verde",
        "Totorilla",
        "Unquillo",
        "Vieja Estancia",
        "C De Las Lags",
        "Caqitas",
        "Chaqar De La Legua",
        "Crucecitas",
        "Duraznito",
        "El Olmo",
        "El Porvenir",
        "El Torcido",
        "Ensenada",
        "Guzman",
        "La Armonia",
        "La Cocha",
        "La Cristina",
        "La Estanzuela",
        "La Riojita",
        "Lag De Los Patos",
        "Las Caqas",
        "Los Condores",
        "Los Duraznitos",
        "Los Manantiales",
        "Los Noques",
        "Otra Banda",
        "P De L Algarrobos",
        "Puente Hierro",
        "Punta De La Loma",
        "Punta Del Alto",
        "Riojita",
        "Selci",
        "Tilisarao",
        "San Pablo",
        "Bajo Grande",
        "Man De Flores",
        "Manantial De Flores",
        "Piscoyaco",
        "Renca",
        "San Ramon Sud",
        "Valle San Agustin",
        "Valle San Jose",
        "Adolfo Rodrig Saa",
        "Alanices",
        "Barrio Blanco",
        "Caqada De La Negra",
        "Caqada Grande",
        "Caqada Verde",
        "Cerrito Blanco",
        "Chilcas",
        "Corral De Torres",
        "El Pueblito",
        "Invernada",
        "Las Chilcas",
        "Las Tigras",
        "Los Arguellos",
        "Los Peros",
        "Los Roldanes",
        "Los Tigres",
        "Moyar",
        "Ojo Del Rio",
        "Paso De La Cruz",
        "Picos Yacu",
        "Pizarras B Velez",
        "Pozo De Las Raices",
        "S Rosa De Conlara",
        "Santa Rosa De Conlara",
        "Cabeza De Novillo",
        "Quebracho Ladeado",
        "Rincon Del Carmen",
        "Pueblo Alberdi",
        "C El Carmen Paraje",
        "C Paso Carril",
        "Campo De La Torre",
        "Colonia La Piedra",
        "Costa Del Tambo",
        "El Duraznito",
        "El Potosi",
        "El Tambo",
        "Kilometro 545",
        "La Cumbre",
        "La Invernada",
        "La Mesada",
        "La Veronica",
        "Las Abahacas",
        "Las Calecitas",
        "Las Guindas",
        "Las Moras",
        "Las Tapias",
        "Monte La Invernada",
        "Permanentes",
        "San Bartolome",
        "Villa Santa Rita",
        "Paso Del Durazno",
        "Puente Los Molles",
        "Achiras",
        "Agua Fria",
        "Aguada",
        "Boca Del Rio",
        "Chancarita",
        "Estanzuela",
        "La Cañada",
        "La Hermosura",
        "La Rosada",
        "Posta De Fierro",
        "Primer Agua",
        "Real",
        "San Alberto",
        "San Alejandro",
        "Santa Felisa",
        "Toigus",
        "Punilla",
        "San Nicolas Punilla",
        "Villa Del Carmen",
        "La Brianza",
        "El Salto Norte",
        "Cnia V De Empleado",
        "Segunda Usina",
        "Unidad T Embalse",
        "V A De Los Reyes",
        "V Sierras De Lago",
        "Arroyo San Antonio",
        "Tala Cruz",
        "Lafinur",
        "Banda De Varela",
        "C Montes Negros",
        "La Siena",
        "Lomitas",
        "Corralito S Javier",
        "La Fuente",
        "Las Tres Piedras",
        "Loma Bola",
        "Caqada La Negra",
        "Cerro De Oro",
        "E Tres Arboles",
        "Merlo",
        "Quebrada Del Tigre",
        "Santa Lucia",
        "Villa Larca",
        "Carpinteria",
        "Papagayos",
        "Algarrrobal",
        "Alto Resbaloso",
        "El Algadobal",
        "El Perchel",
        "El Tajamar",
        "Q De Los Pozos",
        "V Rafael Benegas",
        "Villa Angelica",
        "Bajo El Molino",
        "El Sauzal",
        "Hucle",
        "La Aguadita",
        "Paso Las Tropas",
        "Piedra Blanca",
        "Billiken",
        "Boca De La Q",
        "C El Campamento",
        "Colonia La Florida",
        "Colonia Urdaniz",
        "El Campamento",
        "El Martillo",
        "El Pigue",
        "El Toro Muerto",
        "Fortuna",
        "La Alcorteqa",
        "La Caldera",
        "La Elena",
        "La Emma",
        "La Ernestina",
        "La Gaviota",
        "La Gitana",
        "La Holanda",
        "La Marg Carlota",
        "La Maroma",
        "La Media Legua",
        "La Melina",
        "La Reserva",
        "La Uruguaya",
        "Las Martinetas",
        "Los Barriales",
        "Los Dueros",
        "Martin De Loyola",
        "Milagro",
        "P De Los Gauchos",
        "Polledo",
        "Ranquelco",
        "Rosales",
        "Santa Teresa",
        "Toingua",
        "Union",
        "Colonia Trenquenda",
        "Colonia Trequen",
        "El Antojo",
        "El Recreo",
        "Santa Felicitas",
        "C Las Mercedes",
        "Chacra La Casilda",
        "E La Pampeana",
        "E La Voluntad",
        "Estancia La Lucha",
        "La Paulina",
        "La Victoria",
        "Burmeister",
        "Aguas Buenas",
        "C Hilarios Lagos",
        "Colonia Denevi",
        "Gallinao",
        "La Casilda",
        "La Energia",
        "La Lucha",
        "La Pampeana",
        "La Pradera",
        "La Voluntad",
        "Malvinas Arg",
        "Ramon Segundo",
        "San Urbano",
        "Tres Lagunas",
        "La Colina",
        "Buena Esperanza",
        "Cochenelos",
        "El Oasis",
        "El Quingual",
        "Frisia",
        "La Esmeralda",
        "La Ethel",
        "La Maria Luisa",
        "La Rosina",
        "La Segunda",
        "Las Aromas",
        "Las Mestizas",
        "Los Oscuros",
        "Machao",
        "Nilinast",
        "Placilla",
        "San Juan",
        "Colonia Calzada",
        "Coronel Segovia",
        "E Don Arturo",
        "El Aguila",
        "El Yacatan",
        "La Amalia",
        "La Bavaria",
        "La Germania",
        "La Hortensia",
        "La Isla",
        "La Juana",
        "La Laura",
        "La Luisa",
        "La Nutria",
        "La Reina",
        "La Rosalia",
        "Las Carretas",
        "Laura Elisa",
        "Los Huaycos",
        "Los Valles",
        "Nahuel Mapa",
        "Penice",
        "Qurilay",
        "Santa Cecilia",
        "Toro Bayo",
        "Uchaima",
        "Valle Hermoso",
        "Viva La Patria",
        "Batavia",
        "Fortin El Patria",
        "B Coloradas",
        "Colonia Lagos",
        "E Mirador D Juarez",
        "La Fortuna",
        "La Malvina",
        "La Pvera  S Rosa",
        "Las Malvinas",
        "Los Nogales",
        "Medano Blanco",
        "Boliche La Araqa",
        "C Maria Luisa",
        "C San Victorio",
        "Colonia Aguirre",
        "Colonia Echeta",
        "Colonia Sobadell",
        "La Araqa",
        "La Dolores",
        "La Pvera M Riglos",
        "Los Quinientos",
        "C Ramon Quintas",
        "Chacu",
        "Colonia Ferraro",
        "Colonia Roca",
        "La Baya",
        "La Celina",
        "La Celmira",
        "Pichi  Huilco",
        "Ramon Quintas",
        "Ta Huilco",
        "Anchorena",
        "Arizona",
        "Nueva Galia",
        "E Lago Epecuen",
        "Lago Epecuen",
        "Arturo Vatteone",
        "El Trio",
        "Kilometro 393",
        "Navia",
    ],
    "San Juan": [
        "Zonda",
        "Medano De Oro",
        "Desamparados",
        "San Juan",
        "Talacasto",
        "Trinidad",
        "Villa Carolina",
        "Concepcion",
        "Tamberias",
        "Villa Media Agua",
        "Potrero De Los Funes",
        "Calingasta",
        "Barreal",
        "Cerro Las Mulas",
        "Cerro Los Patos",
        "Cerro Mudadero",
        "Cerro Puntudo",
        "E Casa Rio Blanco",
        "E El Totoral",
        "Estancia Leoncito",
        "Las Hornillas",
        "Po De La Guardia",
        "Po De Las Llaretas",
        "Po Del Portillo",
        "Port De Longomiche",
        "Potrerillos",
        "Tira Larga",
        "Tontal",
        "Yto De Cbre Pachon",
        "Rivadavia",
        "Marquesado",
        "Adan Quiroga",
        "Coyon",
        "Cumiyango",
        "El Balde",
        "El Fuerte",
        "El Volcan",
        "Ing Matias G Schez",
        "Las Aguaditas",
        "Los Diaguitas",
        "Niquivil",
        "Niquivil Viejo",
        "Santa Barbara",
        "Tucunuco",
        "Ullun",
        "Villa Palermo",
        "Santa Lucia",
        "Mogna",
        "Chimbas",
        "Ullum",
        "Angaco Norte",
        "Calle Aguileras",
        "Calle Nacional",
        "Domingo De Oro",
        "El Alamito",
        "El Bosque",
        "La Cañada",
        "Las Tapias",
        "Paquita",
        "Pichagual",
        "Plumerillo",
        "Punta Del Monte",
        "Ranchos De Famacoa",
        "Villa Del Salvador",
        "Villa General Acha",
        "Villa San Isidro",
        "San Isidro",
        "Angaco Sud",
        "Las Chacritas",
        "Los Quillay",
        "Nueve De Julio",
        "Veinticinco De Mayo",
        "San Martin",
        "Albardon",
        "Est Albardon",
        "Las Lomitas",
        "Matagusanos",
        "Villa General San Martin",
        "Rawson",
        "La Tranca",
        "Colonia Rodas",
        "Encon",
        "Capitan Lazo",
        "Los Compartos",
        "Villa Lerga",
        "Barrio Rawson",
        "Primer Cuartel",
        "Segundo Cuartel",
        "Villa Krause",
        "Campo Afuera",
        "Pocitos",
        "Villa Aberastain",
        "Aberastain",
        "El Ceibo",
        "Pocito",
        "El Abanico",
        "Rinconada",
        "A Guanacache",
        "Baqos Del Cerro",
        "Bodega San Antonio",
        "C De Los Burros",
        "C Riquiliponche",
        "Carbometal",
        "Cañada Honda",
        "Cerro Del Medio",
        "Cerro Hediondo",
        "Cerro Los Pozos",
        "Cienaguita",
        "D Las Crucecitas",
        "E El Durazno",
        "El Infierno",
        "Estancia Acequion",
        "Estancia La Posta",
        "Guanacache",
        "Kilometro 10 650",
        "La Chilca",
        "Lomas Dl Aguadits",
        "Los Nogales",
        "Los Sombreros",
        "P L Chilca De Abjo",
        "Pedernal",
        "Potranca",
        "Puesto Angostura",
        "Puesto De Arriba",
        "Puesto Olguin",
        "Puesto Santa Rosa",
        "Santa Clara",
        "V G Sarmiento",
        "La Vertiente",
        "Estacion La Rinconada",
        "Los Berros",
        "Campo De Batalla",
        "Algarrobo Grande",
        "Azucarera De Cuyo",
        "C San Antonio",
        "Carpinteria",
        "Cochagual",
        "Colonia Fiorito",
        "El Chaqar",
        "La Cieneguita",
        "Laguna Del Rosario",
        "Los Chaqares",
        "Lote Alvarado",
        "Media Agua",
        "P Los Chaqares",
        "Puesto Isla Chaqar",
        "Punta Del Medano",
        "Ramblon",
        "Retamito",
        "San Carlos",
        "Tres Esquinas",
        "Colonia Zapata",
        "Alto De Sierra",
        "Callecita",
        "Coll",
        "Colonia Gutierrez",
        "Colonia Richet",
        "Los Viñedos",
        "Puente Nacional",
        "Puente Rio San Juan",
        "Puente Rufino",
        "Usina",
        "Belgrano",
        "Dos Acequias",
        "La Puntilla",
        "Caucete",
        "Algarrobo Verde",
        "Difunta Correa",
        "Las Casuarinas",
        "Vallecito",
        "Bermejo",
        "Pie De Palo",
        "Tupeli",
        "Las Chacras",
        "Marayes",
        "Agua Cercada",
        "Agua Escondida",
        "Astica",
        "Balde Plumerito",
        "Balde San Carlos",
        "Baldes Del Tarabay",
        "Barranca Colorada",
        "Barrealito",
        "Caqada De Laguna",
        "Caqada Del Pozo",
        "Casa De Javier",
        "Cerro Asilan",
        "Cerro La Colorada",
        "Cerro Tres Puntas",
        "Chanchos",
        "Chica Negra",
        "Chucuma",
        "Cienaga",
        "Condor Muerto",
        "Corral De Pirca",
        "Cuchillazo",
        "Cuesta Viejo",
        "Culebra",
        "Dos Mojones",
        "E Bjo D Ls Tumanas",
        "E El Chaqar Nuevo",
        "E La Escalera",
        "E La Florida",
        "El Barrialito",
        "El Gigantillo",
        "El Lechuzo",
        "El Plumerito",
        "El Rincon",
        "El Salto",
        "Est D Herrera Vgas",
        "Est Marayes",
        "Estancia El Jumeal",
        "Estancia El Molino",
        "Estancia El Polear",
        "Estancia Elizondo",
        "Estancia La Lata",
        "Estancia Quiroga",
        "Estancia Rio Verde",
        "Estancia San Roque",
        "Filo Del Mocho",
        "Finca Del Japones",
        "Ichigualasto",
        "Ischigualasto",
        "Jarilla Chica",
        "Jtas Del Guandacol",
        "La Carpa",
        "La Cercada",
        "La Cienaguita",
        "La Cruz",
        "La Esquina",
        "La Huerta",
        "La Mesada",
        "La Orqueta",
        "La Penca",
        "La Ripiera",
        "La Rosita",
        "La Sal",
        "Las Delicias",
        "Las Hermanas",
        "Las Juntas",
        "Las Ramaditas",
        "Las Tumanas",
        "Las Yeguas",
        "Loma Ancha",
        "Loma De Cocho",
        "Loma Leones",
        "Los Baldes",
        "Los Lagares",
        "Los Porongos",
        "Los Sanchez",
        "Ls Baldes D Astica",
        "Mesada Aguada",
        "Mica",
        "Milagro",
        "Morterito",
        "Naquera",
        "Pamp D Ls Caballos",
        "Pampa Grande",
        "Paso De Ferreira",
        "Paso De Lamas",
        "Piedra Blanca",
        "Piedra Colorada",
        "Piedra Rajada",
        "Pila De Macho",
        "Port Las Chilcas",
        "Port Las Francas",
        "Pozo De Aguadita",
        "Pta De La Chilca",
        "Pto Chanquia",
        "Pto Chavez",
        "Pto Gordillo",
        "Pto Huasi",
        "Pto Lima",
        "Pto Romero",
        "Pto San Isidro",
        "Pto Vega",
        "Punta Blanca",
        "Punta Norte",
        "Refugio",
        "Richard",
        "Rincon Chico",
        "Rincon Grande",
        "Sanjuanino",
        "Sierra De Elizondo",
        "Tumanas",
        "Yerba Buena",
        "Aguango",
        "Baldcito Dl Morado",
        "Balde Del Norte",
        "Balde Del Rosario",
        "Baldecito",
        "Baldes D L Chilca",
        "Baldes Del Sud",
        "Cabeza Del Toro",
        "El Puerto",
        "La Colonia",
        "La Escandinava",
        "La Majadita",
        "La Ramada",
        "Las Higueritas",
        "Loma Negra",
        "Lomas Blancas",
        "Los Baldecitos",
        "Los Barriales",
        "Los Chaves",
        "Los Molles",
        "Los Rincones",
        "Medano Colorado",
        "Papagayos",
        "Rincones",
        "San Antonio",
        "Sierra De Chaves",
        "Sierra De Rivero",
        "Usno",
        "Valle Fertil",
        "Villa Carlota",
        "Yoca",
        "Colonia Fiscal Sarmiento",
        "Chacritas",
        "Las Flores",
        "Huaco",
        "Agua D Ls Caballos",
        "Agua De La Zorra",
        "Algarrobo Del Cura",
        "Aschichusca",
        "B De Los Loros",
        "Barrancas Blancas",
        "Bella Vista",
        "C De Los Caballos",
        "C La C Amarilla",
        "C Negro Del Corral",
        "Campo Las Liebres",
        "Campo Los Pozos",
        "Casa Vieja",
        "Cerro Aspero",
        "Cerro Caballo Anca",
        "Cerro Colorado",
        "Cerro El Durazno",
        "Cerro Iman",
        "Cerro Lajitas",
        "Cerro Negro",
        "Cerro Potrero",
        "Chaqar Pintado",
        "Chepical",
        "Colanqui",
        "Cruz De Piedra",
        "Dique Cauquenes",
        "El Chacrero",
        "El Chamizudo",
        "El Corralito",
        "El Jaboncito",
        "El Medano",
        "El Quemado",
        "El Salitre",
        "El Tapon",
        "El Treinta",
        "Est Niquivil",
        "Finca El Molino",
        "Guachipampa",
        "Hosteria El Balde",
        "Indio Muerto",
        "Jachal",
        "La C De Cumillango",
        "La Estaca",
        "La Overa",
        "La Toma",
        "La Valentina",
        "Las Cienagas Vdes",
        "Las Espinas",
        "Las Puestas",
        "Los Hornos",
        "Los Puestos",
        "Los Ranchos",
        "Los Terremotos",
        "Mina De Guachi",
        "Mina El Algarrobo",
        "Mina El Pescado",
        "Mina Escondida",
        "Mina G Belgrano",
        "Mina La Abundancia",
        "Mina La Delfina",
        "Mina La Esperanza",
        "Mina La Salamanta",
        "Mina Los Caballos",
        "Mina Montosa",
        "Mina San Antonio",
        "Otra Banda",
        "P El Arbol Ligudo",
        "P Portezuelo Hondo",
        "Pasleam",
        "Paso De Otarola",
        "Piedra Parada",
        "Po De Usno",
        "Porton Grande",
        "Pto A De Abajo",
        "Pto Ag Del Burro",
        "Pto Aguadita",
        "Pto Anjulio",
        "Pto Cumillango",
        "Pto Durazno",
        "Pto El Sarco",
        "Pto El Toro",
        "Pto Figueroa",
        "Pto La Chilca",
        "Pto La Cortadera",
        "Pto La Espina",
        "Pto La Represa",
        "Pto La Tuna",
        "Pto La Virgencita",
        "Pto Los Alamos",
        "Pto Los Pozos",
        "Pto Majadita",
        "Pto Pajarito",
        "Pto Pantanito",
        "Pto Perico",
        "Pto Pescado",
        "Pto Pimpa",
        "Pto Potrerillo",
        "Pto Punilla",
        "Pto Recreo",
        "Pto Sabato",
        "Pto Segovia",
        "Pto Trapiche",
        "Pto Vallecito",
        "Pto Varejon",
        "Rincon",
        "Rincon Colorado",
        "Rincon Del Gato",
        "Rio Palo",
        "Sierra Billicum",
        "T. Agua Hedionda",
        "T. De Agua Negra",
        "Tap Gallardo",
        "Trancas",
        "Travesia De Mogna",
        "Tuminico",
        "Villa",
        "Volcan",
        "A Dl Rio Jachal",
        "Aguada De La Peqa",
        "Aguaditas",
        "Aguas Del Pajaro",
        "Alcaucha",
        "Boca De La Q",
        "El Buen Retiro",
        "El Fical",
        "Entre Rios",
        "Gran China",
        "Guaja",
        "Huerta De Guachi",
        "La Falda",
        "Los Quimbaletes",
        "Ojos De Agua",
        "Panacan",
        "Pimpa",
        "Villa Mercedes",
        "Carolina",
        "Alto Huaco",
        "La Cienaga",
        "Paso Del Lamar",
        "Punta De Agua",
        "Baqos Pismanta",
        "Colola",
        "Guaqizul",
        "La Caqada",
        "La Maral",
        "La Moral",
        "Rodeo",
        "Totoralito",
        "La Chimbera",
        "Acerillos",
        "Agua De La Zanja",
        "Angualasto",
        "Arrequintin",
        "Baqos Centenario",
        "Baqos De Los Dpbl2",
        "Baqos De S Crispin",
        "Buena Esperanza",
        "C  D  L  Baqitos",
        "C Alto Dl Dmiento",
        "C D Cuest D Viento",
        "C De La Sepultura",
        "C Del Cachiyuyal",
        "C Del Coquimbito",
        "C Dl Agua D L Vcas",
        "C Negro De Chita",
        "C Pata De Indio",
        "C Prito D Agua Bco",
        "Cacho Ancho",
        "Campanario Nuevo",
        "Caqada",
        "Carrizalito",
        "Cerro Amarillo",
        "Cerro Boleadora",
        "Cerro Bravo",
        "Cerro Caballo Bayo",
        "Cerro Cortadera",
        "Cerro De Conconta",
        "Cerro De Dolores",
        "Cerro Del Alumbre",
        "Cerro Del Guanaco",
        "Cerro Del Salado",
        "Cerro El Bronce",
        "Cerro El Cepo",
        "Cerro El Frances",
        "Cerro Espantajo",
        "Cerro Joaquin",
        "Cerro La Bolsa",
        "Cerro La Ortiga",
        "Cerro Lagunita",
        "Cerro Las Mulitas",
        "Cerro Las Raices",
        "Cerro Las Yeguas",
        "Cerro Lavaderos",
        "Cerro Los Mogotes",
        "Cerro Nico",
        "Cerro Ocucaros",
        "Cerro Pintado",
        "Cerro Senda Azul",
        "Cerro Silvio",
        "Cerro Silvo",
        "Champones",
        "Chaparro",
        "Chigua De Abajo",
        "Chisnasco",
        "Cienaguillos",
        "Cjon D Ls Tmbillos",
        "Colanguil",
        "Concota",
        "D D La Mjer Helada",
        "El Carrizal",
        "El Chinguillo",
        "El Retiro",
        "El Salado",
        "Fierro",
        "Fierro Nuevo",
        "Fierro Viejo",
        "Finca El Toro",
        "Hachango",
        "Huaqizuil",
        "Hueso Quebrado",
        "Iglesia",
        "Jaguelito",
        "Jarillito",
        "Jtas De La Jarilla",
        "Junta De S Rosa",
        "Juntas De La Sal",
        "Juntas Del Frio",
        "La Angostura",
        "La Chigua",
        "La Estrechura",
        "Las Casitas",
        "Las Cuevas",
        "Las Higueras",
        "Las Peqitas",
        "Los Cogotes",
        "Los Loros",
        "Los Penitentes",
        "Los Sapitos",
        "Maclacasto",
        "Maipirinqui",
        "Majadita",
        "Maliman",
        "Maliman Arriba",
        "Maliman De Abajo",
        "Manantiales",
        "Mna D Las Carachas",
        "Mondaca",
        "P Del Agua Negra",
        "P Las Caracachas",
        "P Los Amadores",
        "Peqasco Colorado",
        "Peqasquito",
        "Piedras Blancas",
        "Pircas Blancas",
        "Pircas Negras",
        "Pismania",
        "Po Cjon D La Brea",
        "Po Del Chollay",
        "Po Del Inca",
        "Po Las Tortolas",
        "Port De La Punilla",
        "Port San Guillermo",
        "Port Santa Rosa",
        "Pta Dl Infiernillo",
        "Pto Gen",
        "Quilinquil",
        "R. De La Ollita",
        "R.Ls Chinchilleros",
        "Rincon De La Brea",
        "Ruinas Indigenas",
        "Terma Pismanta",
        "Termas Centenario",
        "Tocota",
        "Tres Quebraditas",
        "Tutianca",
        "Valle Del Cura",
        "Venillo",
        "Pampa Del Chaqar",
        "Pampa Del Chañar",
        "Pampa Vieja",
        "Abra Verde",
        "Agua De Piedra",
        "Alto Bayo",
        "Bayo Muerto",
        "Caqada Honda",
        "Chepes Viejos",
        "El Alto",
        "El Barreal",
        "El Cincuenta",
        "El Divisadero",
        "Illisca",
        "La Aguada",
        "La Carrizana",
        "La Consulta",
        "La Pintada",
        "La Primavera",
        "Las Tuscas",
        "Los Olmos",
        "Los Oros",
        "Punta Del Cerro",
        "Represa Del Monte",
        "San Vicente",
        "Santa Cruz",
        "Villa Santa Rosa",
        "El Algarrobal",
        "La Hullera",
        "Empalme Frontera",
        "Zanjon Amarillo",
        "Las Leñas",
        "La Marzolina",
        "Las C Negras",
        "Pueblo Luna",
    ],
    Mendoza: [
        "Campo De Batalla",
        "C D Ls Potrerillos",
        "Cerro Aconcagua",
        "Mendoza",
        "P De Las Vacas",
        "Plaza De Mulas",
        "Godoy Cruz",
        "9 De Julio",
        "San Francisco Del Monte",
        "Carrodilla",
        "Carrodilla La Puntilla",
        "Chacras De Coria",
        "La Puntilla",
        "Lujan De Cuyo",
        "Agrelo",
        "Anchoris",
        "El Carrizal De Abajo",
        "Perdriel",
        "Ugarteche",
        "Vistalba",
        "Pareditas",
        "General Gutierrez",
        "San Carlos",
        "Barrio Jardin Luzuriaga",
        "Coquimbito",
        "Luzuriaga",
        "Sarmiento",
        "Tres Esquinas",
        "Maipu",
        "Barrancas",
        "Russell",
        "Leandro N Alem",
        "Dorrego",
        "San Jose",
        "San Jose De Guaymallen",
        "Barrio Quimicos M.D.C.1",
        "Guaymallen",
        "Villa Nueva",
        "Villa Nueva De Guaymallen",
        "Buena Nueva",
        "Capilla Del Rosario",
        "C.Los Andes",
        "Colonia Cano",
        "Colonia Segovia",
        "Rodeo De La Cruz",
        "Los Campamentos",
        "La Primavera",
        "Los Corralitos",
        "Colonia Bombal",
        "Rodeo Del Medio",
        "El Paraiso",
        "Fray Luis Beltran",
        "Minelli",
        "Algarrobo",
        "Bermejo",
        "Colonia Italiana",
        "El Chilcal",
        "El Sauce",
        "Jocoli Viejo",
        "La Pega",
        "Lavalle",
        "Tulumaya",
        "Mayor Drummond",
        "Costa De Araujo",
        "Mundo Nuevo",
        "Arroyito",
        "El Retamo",
        "San Miguel",
        "Philipps",
        "Phillips",
        "Arenales",
        "B De La Pampa Seca",
        "B Pajaro Muerto",
        "Buitrera",
        "C De Los Burros",
        "C Del Rincon Bayo",
        "C Los Dientitos",
        "C Pan De Azucar",
        "C Punta De Agua",
        "C Puntilla Negra",
        "C Riquitipanche",
        "Ca Del Diablo",
        "Casilla",
        "Cerrillos Negros",
        "Cerro Agua Salada",
        "Cerro Aguadita",
        "Cerro Alojamiento",
        "Cerro Angostura",
        "Cerro Aspero",
        "Cerro Barauca",
        "Cerro Bay",
        "Cerro Blanco",
        "Cerro Bravo",
        "Cerro Catedral",
        "Cerro Chiquero",
        "Cerro Cielo",
        "Cerro Cienaga",
        "Cerro Clementino",
        "Cerro Color",
        "Cerro Cortaderas",
        "Cerro Cuerno",
        "Cerro Cupula",
        "Cerro De Las Leqas",
        "Cerro De Los Dedos",
        "Cerro Del Medio",
        "Cerro Durazno",
        "Cerro El Guanaco",
        "Cerro Fundicion",
        "Cerro Grande",
        "Cerro Horqueta",
        "Cerro Invernada",
        "Cerro Juan Pobre",
        "Cerro L Corrales",
        "Cerro La Mano",
        "Cerro Lagaqoso",
        "Cerro Manantial",
        "Cerro Masillas",
        "Cerro Melocoton",
        "Cerro Mexico",
        "Cerro Montura",
        "Cerro Pampa Seca",
        "Cerro Panta",
        "Cerro Ponderado",
        "Cerro Pozo",
        "Cerro Puntudo",
        "Cerro Puquios",
        "Cerro San Lorenzo",
        "Cerro Santa Maria",
        "Cerro Sapo",
        "Cerro Tigre",
        "Cerro Tolosa",
        "Cerro Tunduquera",
        "Cerro Yareta",
        "Cerros Colorados",
        "Cto Del Tigre",
        "E Casa De Piedra",
        "E Cueva Del Toro",
        "E El Carrizal",
        "E San Martin",
        "El Challao",
        "El Infierno",
        "El Puestito",
        "El Zapallar",
        "Empalme Resguardo",
        "Espejo",
        "Espejo Resguardado",
        "Estacion Uspallata",
        "Estancia Jocoli",
        "Estancia Uspallata",
        "Estancia Vvicencio",
        "Estancia Yalguaraz",
        "Garganta Del Leon",
        "La Angostura",
        "La Boveda",
        "La Casa Del Tigre",
        "La Fundicion",
        "La Horqueta",
        "La Jaula",
        "Las Canteras",
        "Las Heras",
        "Loma Colorada",
        "Loma De Los Burros",
        "Loma Sola",
        "Lomas Bayas",
        "Los Chacayes",
        "Los Tamarindos",
        "Monte Bayo",
        "Monumento Al Ejer",
        "P Agua De Zanjon",
        "P D Contrabandista",
        "P D L Coloradas",
        "P D L Lagrima Viva",
        "P Las Higueras",
        "P Los Alojamientos",
        "P Los Pajaritos",
        "P Riquitipanche",
        "P S C De Arriba",
        "P San Ignacio",
        "Pampa Yalguaraz",
        "Panqueua",
        "Po De La Cumbre",
        "Po De La Q Honda",
        "Po Del Rubio",
        "Po Valle Hermoso",
        "Portillo D Quemado",
        "Portillo De Indio",
        "Portillo Del Medio",
        "Portillo Del Norte",
        "Portillo Del Tigre",
        "Portillo La Pampa",
        "Puesto Carrizalito",
        "Puesto Chambon",
        "Puesto El Peral",
        "Puesto El Totoral",
        "Puesto Escondido",
        "Puesto Guamparito",
        "Puesto La Gruta",
        "Puesto La Mojada",
        "Rodeo Grande",
        "San Ignacio",
        "Santa Elena",
        "Schez D Bustamante",
        "Sierra Ansilta",
        "Sierra Del Tontal",
        "Tropero Sosa",
        "Tte B Matienzo",
        "V D Los C De Araya",
        "Vega De Los Burros",
        "Villavicencio",
        "Vra De Las Vacas",
        "Algarrobal Abajo",
        "Algarrobal Arriba",
        "E Plumerillo",
        "Paso Hondo",
        "S J Bautista Usno",
        "El Plumerillo",
        "3 De Mayo",
        "Alto Grande",
        "Capdeville",
        "Colonia 3 De Mayo",
        "Colonia Alemana",
        "El Caqito",
        "El Resguardo",
        "Hornito Del Gringo",
        "Hornos De Moyano",
        "Jocoli",
        "Mathieu Norte",
        "Moyano",
        "Benegas",
        "Gobernador Benegas",
        "Cerro Bonete",
        "Colon Sandalho",
        "La Cortadera",
        "Las Cortaderas",
        "Minas Salagasta",
        "P Agua De Toro",
        "S De Las Higueras",
        "San Alberto",
        "Termas Vvicencio",
        "Uspallata",
        "Valle De Uspallata",
        "V Hipodromo",
        "Villa Hipodromo",
        "Alvarez Condarco",
        "Blanco Encalada",
        "Cacheuta",
        "Cerro Pelado",
        "El Salto",
        "Guido",
        "Compuertas",
        "Polvareda",
        "Potrerillos",
        "La Pirata",
        "Punta De Vacas",
        "Rio Blanco",
        "Barrio Rivadavia",
        "Puente Del Inca",
        "Carrizal De Abajo",
        "Caracoles",
        "Cristo Redentor",
        "La Cueva",
        "Las Cuevas",
        "La Libertad",
        "Los Arboles",
        "Tunuyan",
        "Zapata",
        "Cordon Del Plata",
        "El Peral",
        "El Zampal",
        "Tupungato",
        "Villa San Jose",
        "Villa Bastias",
        "Los Arboles De Villegas",
        "Villa Seca",
        "Barrio Lencina",
        "Campo De Los Andes",
        "Colonia Las Rosas",
        "Vista Flores",
        "La Colonia",
        "La Consulta",
        "El Mirador",
        "Eugenio Bustos",
        "Paso De Las Carretas",
        "Alto Del Salvador",
        "Liber Gral S M",
        "Libertador Gral San Martin",
        "San Martin",
        "Los Olmos",
        "Monte Caseros",
        "Charras",
        "Ciudad De Junin",
        "Junin",
        "La Central Rivadavia",
        "Andrade",
        "Santa Maria De Oro",
        "El Alto",
        "La Ventana",
        "Rivadavia",
        "Vizcacheras",
        "Barrio San Eduardo",
        "Campamentos",
        "La Central",
        "El Central",
        "Ingeniero Giagnoni",
        "Ricardo Lavalle",
        "El Divisadero",
        "Palmira",
        "Barriales",
        "Medrano",
        "Cruz De Piedra",
        "Barcala",
        "Isla Chica",
        "Isla Grande",
        "San Roque",
        "Valle Hermoso",
        "Chapanay",
        "Espinillo",
        "A Viuda De Perez",
        "Adrian Maturano",
        "Alejandro Perez",
        "Alfredo Lucero",
        "Alto De Los Perros",
        "Alto De Los Sapos",
        "Alvarez",
        "Ana De Donaire",
        "Andres Perez",
        "Antonio Sosa",
        "B Elena",
        "Bajada Del Perro",
        "Becerra",
        "Blas Panelo",
        "Boyeros",
        "C Gonzales Videla",
        "Cadetes De Chile",
        "Campo El Toro",
        "Chacras De Lima",
        "Chamuscao",
        "Chaqaral Redondo",
        "Cirilo Mahona",
        "Clarentino Roldan",
        "Colonia El Regadio",
        "Corral De Cuero",
        "Corral Del Molle",
        "Cruz Del Yugo",
        "Cruz Ledesma",
        "Dalmiro Zapata",
        "Daniel Lucero",
        "Daniel Morgan",
        "Delgadillo",
        "Dionisio Ordoqez",
        "Dionisio Ortubia",
        "Domingo Gimenez",
        "Domingo Lara",
        "Domingo Oga",
        "Domingo Real",
        "Donato Ojeda",
        "Doroteo Ordoqez",
        "Dulce",
        "E La Salcedina",
        "E La Vizcachera",
        "E Las Vizcacheras",
        "E Rosales",
        "E Viuda De Gomez",
        "El Caranchito",
        "El Cavado Chico",
        "El Cercado",
        "El Chalet",
        "El Consuelo",
        "El Gonzano",
        "El Guerrino",
        "El Jilguero",
        "El Lechucito",
        "El Perino",
        "El Regadio",
        "El Vaquero",
        "El Zapatino",
        "Eloy Funes",
        "Emilio Nieta",
        "Epifanio Fernandez",
        "Ernesto Alcaraz",
        "Estanislao Ordoqez",
        "Evaristo Acevedo",
        "Evaristo Salas",
        "Fabriciano Rojas",
        "Felipe Garro",
        "Fermin Perez",
        "Florencio Garro",
        "Florencio Molina",
        "Florencio Ordoqez",
        "Francisco Molina",
        "Francisco Rojas",
        "Fructuoso Diaz",
        "German Maturano",
        "Gertrudis De Ojeda",
        "Gilberto Perez",
        "Gregorio Zapata",
        "Guillermo Donaire",
        "H De Los Tordillos",
        "H Garzala",
        "Hermenegildo Diaz",
        "Honorio Zapata",
        "Huaicos De Rufino",
        "Ignacio Villegas",
        "Irineo Zapata",
        "Isla Retamito",
        "J Ortubia",
        "Jose Diaz",
        "Jose Fernandez",
        "Jose Lucero",
        "Jose R Molina",
        "Jose Suarez",
        "Juan B Dufau",
        "Juan H Ortiz",
        "Juan Millan",
        "Juan Zapata",
        "Julio Comeglio",
        "Junta De Los Rios",
        "Km 935 Dvio Fcgsm",
        "La Caqada",
        "La Cautiva",
        "La Chapina",
        "La Cola",
        "La Esquina",
        "La Estancia",
        "La Fortuna",
        "La Isla",
        "La Leona",
        "La Paz",
        "Ladislao",
        "Ladislao Campos",
        "Las Cruces",
        "Las Rosas",
        "Las Viscacheras",
        "Las Vistas",
        "Las Vizcachas",
        "Lino Perez",
        "Lisandro Escudero",
        "Los Algarrobos",
        "Los Altamiques",
        "Los Colorados",
        "Los Horconcitos",
        "Los Ramblones",
        "Los Roseti",
        "Los Verdes",
        "Los Villegas",
        "Lucas Donaire",
        "Luis Marquez",
        "M Escudero",
        "M Quiroga",
        "M Viuda De Donaire",
        "Maquinista Levet",
        "Maravilla",
        "Maria Garcia",
        "Mario Olguin",
        "Matias Garro",
        "Mauricio Calderon",
        "Medardo Miranda",
        "Mosmota",
        "Natalia Donaire",
        "Necto Sosa",
        "Nestor Aguilera",
        "Nicolas Ordoqez",
        "Onotre Puebla",
        "P De Las Carretas",
        "P De Las Tropas",
        "P Del Chaqacal",
        "Pascual Sosa",
        "Paso De Las Canoas",
        "Paulino Matura",
        "Pedro Castelu",
        "Pedro Pablo Perez",
        "Pquita Emb Cgsm",
        "Puente Viejo",
        "Puerta De La Isla",
        "Puesto De Garro",
        "Puesto De Olguin",
        "Puesto De Orozco",
        "Puesto De Petra",
        "Puesto De Sosa",
        "Puesto El Retamito",
        "Puesto Nueras",
        "Puesto Zampal",
        "Puntos De Agua",
        "R Bebedera",
        "R De La Pampa",
        "Ramblon Grande",
        "Ramon Donaire",
        "Ramon Gimenez",
        "Regino Ojeda",
        "Retamo",
        "Retamo Partido",
        "Rosario Gatica",
        "Rufino Gomez",
        "S Cortis",
        "San Antonio",
        "San Pedro",
        "Santiago Romero",
        "Saturnino Romero",
        "Serviliano Ojeda",
        "Sixto Ledesma",
        "Teodoro Garro",
        "Teodoro Villaruel",
        "Teofila Acevedo",
        "Teofilo Ruben",
        "Teofilo Zapata",
        "Tila",
        "Tilio Alcaraz",
        "Tomas Mercado",
        "Travesia",
        "Trino Rosaleso",
        "Vicente Muqoz",
        "Vicente Peletay",
        "Villa Antigua",
        "Villa La Paz",
        "Villa Vieja",
        "Viuda De Orozco",
        "Zanon Canal",
        "Alpatacal",
        "Circunvalacion",
        "El Gigantillo",
        "La Favorita",
        "La Jacintita",
        "La Porteqa",
        "Las Totoritas",
        "Sopanta",
        "La Dormida",
        "Lunlunta",
        "Gobernador Civit",
        "Las Catitas",
        "Comandante Salas",
        "El Colorado",
        "Ñacuñan",
        "La Costa",
        "Santa Rosa",
        "12 De Octubre",
        "Baqado Verde",
        "Bañado Verde",
        "Desaguadero",
        "P Emb Fcgsm",
        "Pampita Embarcadero Fcgsm",
        "Tapon",
        "San Rafael",
        "Capitan Montoya",
        "El Toledano",
        "Resolana",
        "Cañada Seca",
        "Colonia Elena",
        "Colonia Rusa",
        "Ingeniero Ballofet",
        "La Llave",
        "Pedro Vargas",
        "Rincon Del Atuel",
        "Salto De Las Rosas",
        "Nihuil",
        "Santa Teresa",
        "General Ortega",
        "El Algarrobal",
        "Colonia Española",
        "Cuadro Nacional",
        "Llave Nueva",
        "Llave Vieja",
        "Aristides Villanueva",
        "Gaspar Campos",
        "Goico",
        "Guadales",
        "Monte Coman",
        "Cerro Nevado",
        "Bardas Blancas",
        "Coihueco",
        "El Alambrado",
        "El Manzano",
        "El Sosneado",
        "Ex Fortin Malargue",
        "La Valenciana",
        "Los Molles",
        "Ranquil Norte",
        "Las Flores",
        "Valle De Las Leñas",
        "Agua Botada",
        "Buta Billon",
        "El Chacay",
        "El Vatro",
        "La Junta",
        "Las Vegas",
        "Lonco Vacas",
        "Las Loicas",
        "Malargue",
        "Mechanquil",
        "Los Cavaos",
        "25 De Mayo",
        "Pueblo Echevarrieta",
        "Rama Caida",
        "El Juncalito",
        "El Nevado",
        "General Alvear",
        "Kilometro 56",
        "Kilometro 882",
        "La Pomona",
        "Villa Comparto",
        "Agua De Torre",
        "Agua Escondida",
        "Caqadon De Bonilla",
        "Cerro Del Chacay",
        "Cerro Yalguaras",
        "Cochico",
        "El Desvio",
        "El Ventarron",
        "Estancia Chacaico",
        "La California",
        "Los Tordillos",
        "P La Caldenada",
        "Posta De Hierro",
        "Puesto Lunina",
        "San Pedro De Atuel",
        "Tambito",
        "Villa Atuel",
        "Jaime Prats",
        "Soitue",
        "Real Del Padre",
        "Villa Veinticinco De Mayo",
        "Coronel Dorrego",
        "Punta Del Agua",
        "Rodolfo Iselin",
        "El Tropezon",
        "Ingeniero Balloffet",
        "Goudge",
        "C Alvear Oeste",
        "Colonia Alvear",
        "Colonia Alvear Oeste",
        "Colonia Bouquet",
        "Compuertas Negras",
        "El Retiro",
        "Las Compuertas",
        "Ochenta Y Cuatro",
        "Bowen",
        "El Banderon",
        "El Buen Pastor",
        "Estancia La Varita",
        "Kilometro 884",
        "La Montilla",
        "Los Angeles",
        "Los Huarpes",
        "Poste De Fierro",
        "Vuelta Del Zanjon",
        "Las Malvinas",
        "Canalejas",
        "El Aguila",
        "El Arbolito",
        "Favelli",
        "La Mora",
        "Medanos Colorado",
        "Mojon Ocho",
        "Nueva Constitucion",
        "P De La Corona",
        "P De Los Gauchos",
        "P Del Buen Pastor",
        "P Vuelta Dl Zanjon",
        "Pto Los Amarillos",
        "Puesto La Seqa",
        "Toscal",
        "Costa Del Diamante",
        "El Plumerito",
        "La Lechuga",
        "Plumerito",
        "Treinta De Octubre",
        "Tres Porteñas",
        "Usiyal",
        "Corral De Lorca",
        "Ovejeria",
        "Jesus Nazareno",
        "Primavera",
        "Malena",
        "M De Los Gauchos",
        "El Ceibo",
        "Reduccion",
        "La Escandinava",
        "Los Compartos",
        "Colonia Santa Teresa",
        "Pedregal",
        "Chilecito",
        "Colonia Lambare",
        "El Alto Salvador",
        "Santa Blanca",
        "Los Alamos",
        "Espino",
        "Cartellone",
        "Las Colonias",
        "Cordon De Plata",
        "Los Sauces",
        "Lavalle V Tulumaya",
        "Lavalle Villa Tulumaya",
        "El Borbollon",
        "San Jose De Tupungato",
        "Los Barriales",
        "Rodriguez Peña",
        "Buen Orden",
        "Villa Santa Isabel",
        "Quinto Cuartel",
        "El Medanito",
        "Medano De Oro",
        "Paraje Bebida",
        "Colonia Richet",
        "La Legua",
        "Las Lagunas",
        "Astica",
        "San Juan Bautista Usno",
        "Ingeniero Gustavo Andre",
        "Iglesia",
        "Tudcum",
        "Alto Blanco",
        "Pozo Escondido",
        "Santo Domingo",
    ],
    "La Pampa": [
        "Algarrobo Grande",
        "Alto Verde",
        "Colonia Monte Caseros",
        "Los Burgos",
        "Carmensa",
        "Colonia Japonesa",
        "Ciudad Oeste",
        "E El Balderon",
        "E La Cortadera",
        "Kilometro 84",
        "La Adelina",
        "La Caldenada",
        "La Corona",
        "La Lunina",
        "La Seqa",
        "La Varita",
        "Los Amarillos",
        "Los Campamentos",
        "Pampa Del Tigre",
        "Santa Elena",
        "Realico",
        "Chanilao",
        "Santa Rosa",
        "Embajador Martini",
        "Ingeniero Luiggi",
        "Alta Italia",
        "Ojeda",
        "Pueblo Alassa",
        "Maisonnave",
        "Adolfo Van Praet",
        "Quetrequen",
        "Simson",
        "Parera",
        "Chamaico",
        "Rancul",
        "Falucho",
        "Bajada Nueva",
        "La Donostia",
        "La Escondida",
        "La Josefa",
        "La Juanita",
        "La Linda",
        "La Maravilla",
        "La Margarita",
        "La Tigra",
        "Las Cortaderas",
        "Las Gitanas",
        "Las Lagunas",
        "Los Dos Rios",
        "Los Duraznos",
        "Los Lobos",
        "Monte Cochequingan",
        "Nueva Galia",
        "Bagual",
        "Bernardo Larroude",
        "Intendente Alvear",
        "Ceballos",
        "Coronel Hilario Lagos",
        "Mariano Miro",
        "San Jose",
        "Sarah",
        "Drabble",
        "Francisco Casal",
        "Los Caldenes",
        "Los Laureles",
        "Moores",
        "Cuenca",
        "Pradere Juan A",
        "Hereford",
        "Cerrito",
        "Meridiano Vo",
        "Santa Aurelia",
        "Colonia Boero",
        "Melideo",
        "Nazca",
        "Watt",
        "Antonio Catalano",
        "Campo San Juan",
        "Costa Del Rio 5:",
        "La Luz",
        "La Perlita",
        "Tomas Echenique",
        "El Pampero",
        "Modestino Pizarro",
        "Caqada Verde",
        "La Nacional",
        "Larsen",
        "Los Alfalfares",
        "Villa Moderna",
        "Ataliva Roca",
        "Cereales",
        "Miguel Riglos",
        "Riglos",
        "Cachirulo",
        "Calchahue",
        "Chapalco",
        "El Volante",
        "La Baya Muerta",
        "La Vanguardia",
        "Lindo Ver",
        "Nerre Co",
        "Oficial E Segura",
        "Toay",
        "Atreuco",
        "Bella Vista",
        "C Guiburg N 2",
        "Doblas",
        "El Deslinde",
        "El Destino  Rolon",
        "El Palomar",
        "Hipolito Yrigoyen",
        "L Pampita  Hidalgo",
        "La Catalinita",
        "La Espza  Hidalgo",
        "La Manuelita",
        "La Nueva Provincia",
        "La Sarita",
        "Las Felicitas",
        "Los Dos Hermanos",
        "Ojo De Agua",
        "Rolón",
        "S Gdes Hidalgo",
        "San Felipe",
        "San Pedro  Rolon",
        "Santa Stella",
        "Rolon",
        "3 Hnos  Macachin",
        "Colonia La Oracion",
        "El Centenario",
        "Hidalgo",
        "La Antonia",
        "La Esmer  Macachin",
        "La Espza  Macachin",
        "La Josefina",
        "La Oracion",
        "Macachin",
        "Santo Tomas",
        "Valle Argentino",
        "Alpachiri",
        "C Las Vizcacheras",
        "Campo Urdaniz",
        "Colonia Anasagasti",
        "Colonia La Chispa",
        "Colonia Santa Ana",
        "La Maria Rosa",
        "Monte Ralo",
        "S Mari Manuel",
        "Santa Ana",
        "Gral Manuel Campos",
        "C La Esperanza",
        "Campo De Los Toros",
        "Campo La Florida",
        "Colonia Los Toros",
        "Colonia Luna",
        "Guatrache",
        "La Nueva",
        "La Piedad",
        "Las Quintas",
        "Los Toros",
        "Remeco",
        "C Santa Teresa",
        "Colonia Santa Teresa",
        "Ricardo Lavalle",
        "Bjo D Las Palomas",
        "C Espiga De Oro",
        "C Santa Elena",
        "Colonia La Paz",
        "Colonia San Felipe",
        "El Destino",
        "El Furlong",
        "El G  Winifreda",
        "La Delfina",
        "Lote 12",
        "Lote 13 E 173",
        "Lote 21 C S Elena",
        "Lote 23 E 221",
        "Winifreda",
        "Colonia San Jose",
        "Colonia Baron",
        "Colonia La Carlota",
        "Ines Y Carlota",
        "Los Pirineos",
        "Lote 25 E 178",
        "Lote 6 Escuela 171",
        "Lote 9 Escuela 140",
        "Villa San Jose",
        "Z Rural De Mirasol",
        "Mauricio Mayer",
        "La Florencia",
        "Lote 5 Luan Toro",
        "Loventuel",
        "Luan Toro",
        "Villa Mirasol",
        "C De Victorica",
        "Chacras De Victorica",
        "El Durazno",
        "El E Carro Quemado",
        "Guadaloza",
        "La Morocha",
        "Labal",
        "Lote 8 Escuela 179",
        "Poitague",
        "San Francisco",
        "Victorica",
        "Carro Quemado",
        "C El Porvenir",
        "Caichue",
        "Costa Del Salado",
        "Dos Amigos",
        "El Mate",
        "El Odre",
        "El Refugio",
        "El Retiro",
        "El Silencio",
        "J Del Esquinero",
        "Jaguel Del Monte",
        "Juzgado Viejo",
        "La Catalina",
        "La Cienaga",
        "La Constancia",
        "La Elenita",
        "La Elia",
        "La Elina",
        "La Esmeralda",
        "La Estrella",
        "La Eulogia",
        "La Fe",
        "La Guadalosa",
        "La Isabel",
        "La Laurentina",
        "La Marcela",
        "La Pencosa",
        "La Razon",
        "La Tinajera",
        "La Union",
        "La Verde",
        "La Zota",
        "Leona Redonda",
        "Loma Redonda",
        "Lomas De Gatica",
        "Lomas Ombu",
        "Los Manantiales",
        "Los Tres Pozos",
        "Manantiales",
        "Mayaco",
        "Nahuel Napa",
        "Nanquel Huitre",
        "Pichi Merico",
        "San Emilio",
        "Telen",
        "T M De Anchorena",
        "Algar Del Aguila",
        "Algarrobo Del Aguila",
        "Arbol De La Espza",
        "Arbol Solo",
        "Butalo",
        "C La Pastoril",
        "Chicalco",
        "Curru Mahuida",
        "E El Centinela",
        "El Centinela",
        "Emilio Mitre",
        "La Esperanza",
        "La Humada",
        "La Imarra",
        "La Pastoril",
        "La Primavera",
        "La Puqalada",
        "La Razon S Isabel",
        "La Soledad",
        "La Veintitres",
        "Los Tajamares",
        "Los Turcos",
        "Medanos Negros",
        "P D Ls Algarrobos",
        "P De Los Puntanos",
        "Paso De Los Algarrobos",
        "Paso La Razon",
        "S F De La Ramada",
        "Santa Isabel",
        "Vista Alegre",
        "C Ministro Lobos",
        "Colonia Devoto",
        "Colonia La Amarga",
        "El Chillen",
        "La Avanzada",
        "La Esther",
        "Los Alamos",
        "Naico",
        "Parque Luro",
        "San Humberto",
        "Santiago Orellano",
        "Anguil",
        "Colonia San Juan",
        "Colonia Torello",
        "La Carola",
        "La Ctancia  Anguil",
        "La Elvira",
        "La Espza  Anguil",
        "La Florida",
        "La Reserva  Anguil",
        "La Verde  Anguil",
        "San Carlos",
        "San Jose  Anguil",
        "Arturo Almaraz",
        "Campo Ludueqa",
        "Catrilo",
        "Cayupan",
        "Ivanowsky",
        "La Blanca",
        "La Leqa",
        "La Puna",
        "La Rebeca",
        "La Unida",
        "San Eduardo",
        "San Joaquin",
        "San Justo",
        "San Pedro",
        "Colonia Beaufort",
        "Colonia Giusti",
        "Curilco",
        "El Belgica",
        "El Eucalipto",
        "El Parque",
        "El Sauce",
        "La Elsa",
        "La Maria",
        "Las Tres Hermanas",
        "Miguel Cane",
        "Pavon",
        "Relmo",
        "Rucahue",
        "San Alberto",
        "San Benito",
        "San Miguel",
        "Zona Rural",
        "Alfredo Peqa",
        "C La Abundancia",
        "C Santa Cecilia",
        "Colonia La Sara",
        "Huelen",
        "La Cautiva",
        "La Celina",
        "La Delicia",
        "La Enriqueta",
        "La Olla",
        "Mari Mari",
        "Quemu Quemu",
        "Santa Elvira",
        "Sol De Mayo",
        "Trili",
        "Graciarena",
        "Maria P Moreno",
        "Cailomuta",
        "Estacion Caiomuta",
        "Colonia Naviera",
        "Francisco Murature",
        "Carpinteria",
        "Chapi Talo",
        "El Malacate",
        "La Matilde",
        "La Pala",
        "La R Ivanowsky",
        "Los Gauchos",
        "Maza",
        "La Bilbaina",
        "La Gloria",
        "Colonia  La India",
        "Colonia San Miguel",
        "El Brillante",
        "El D  Lonquimay",
        "El Guaicuru",
        "El Rubi",
        "El Salitral",
        "El Triunfo",
        "La Atalaya",
        "La Celia",
        "La Paz",
        "La Perla",
        "La Segunda",
        "La Violeta",
        "Lonquimay",
        "Pueblo Quintana",
        "Quintana",
        "San Manuel",
        "La Luisa",
        "La Victoria",
        "San Juan",
        "Uriburu",
        "Barrio El Molino",
        "Carlos Berg",
        "General Pico",
        "La Gueqita",
        "La Puma",
        "Mocovi",
        "Agustoni",
        "Caimi",
        "La Gavenita",
        "La Teresita",
        "Trebolares",
        "Azteazu",
        "C Santa Elvira",
        "Dorila",
        "La Barrancosa",
        "La Espza Vertiz",
        "La Maria Vertiz",
        "San Ildefonso",
        "Santa Catalina",
        "Speluzzi",
        "Vertiz",
        "Z Rural De Vertiz",
        "Zona Rural Dorila",
        "Arg Belvedere",
        "Colonia Migliori",
        "Metileo",
        "Ministro Orlando",
        "S Joaquin Metileo",
        "Campo Salusso",
        "Lote 8 Escuela 141",
        "Trenel",
        "Boeuf",
        "C San Lorenzo",
        "Chacra 16",
        "Eduardo Castex",
        "El Guanaco",
        "Lote 17 Escuela 95",
        "Lote 2 Escuela 185",
        "Lote 20 La Carlota",
        "Lote 8 Escuela 184",
        "Nicolas Vera",
        "Z Rural  Metileo",
        "Zona Urbana Norte",
        "Campo Caretto",
        "Campo Pico",
        "Colonia El Destino",
        "Colonias Drysdale",
        "Colonias Murray",
        "Conhelo",
        "El Peludo",
        "Kilometro 619",
        "Las Chacras",
        "Loo Co",
        "Lote 25 Conhelo",
        "Lote 25 E 146",
        "Tte Gral E Mitre",
        "Rucanelo",
        "C Moises Sec. 1A",
        "Lote 24 Seccion 1A",
        "Monte Nievas",
        "San Ramon",
        "Sec. Pra Conhello",
        "Arata",
        "C Las Piedritas",
        "Colonia El Tigre",
        "Ingeniero Foster",
        "La Maruja",
        "Caleufu",
        "Caraman",
        "Las Piedritas",
        "Lote 15 Escuela 18",
        "Lote 4",
        "Pichi  Huinca",
        "Alegria",
        "El Rodeo",
        "La Travesia",
        "La Vaca",
        "B Indio Trompa",
        "Laguna Redonda",
        "Las Guasquitas",
        "Lertora",
        "Martin Fierro",
        "Valentin Gomez",
        "Badano",
        "Colonia El Balde",
        "Fcisco De Vitoria",
        "Albariqo",
        "Duhau",
        "La Porteqa",
        "Tronge",
        "Villa Branda",
        "Jose Maria Blanco",
        "Pehuelches",
        "Bravo Del Dos",
        "Papin",
        "V De La Plaza",
        "Fortin Paunero",
        "Roosv P Rivadavia",
        "Adolfo Alsina",
        "C Santa Mariana",
        "Colonia Murature",
        "Juan V Cilley",
        "Pocito",
        "Rolito E Fcgb",
        "V Cast Est Erize",
        "Villa Sauri",
        "La Rosa",
        "La Adela",
        "Juan A Pradere",
        "C La Estrella",
        "Campo La Zulema",
        "Colonia Leven",
        "San Antonio",
        "Villa Margarita",
        "S Miguel Arcangel",
        "General Acha",
        "Padre Buodo",
        "25 De Mayo",
        "B Coloradas",
        "Belgrano",
        "C San Ignacio",
        "Cerro Azul",
        "Cerro Bayo",
        "Cerro Del Aigre",
        "Cerro La Bota",
        "Colonia Los Piojos",
        "Colonia Veinticinco De Mayo",
        "El Cinco",
        "El Diez",
        "El Diez Y Siete",
        "El Escabel",
        "El Huitru",
        "El Nueve",
        "El Tartagal",
        "El Trece",
        "El Uno",
        "Euskadi",
        "Gobernador Ayala",
        "Guaraco",
        "Julian A Mansilla",
        "La Asturiana",
        "La Chita Puelches",
        "La Clelia",
        "La L La Reforma",
        "La Limpia",
        "La Reforma Vieja",
        "Legasa",
        "Limay Mahuida",
        "M De La Pampa",
        "San Roberto",
        "San Salvador",
        "Lihue Calel",
        "Puelches",
        "Chacharramendi",
        "La Chirlandia",
        "La Sin Nombre",
        "Los Olivos",
        "Quehue",
        "San Ernesto",
        "Santo Domingo",
        "Utracan",
        "Bernasconi",
        "C 17 De Agosto",
        "C Las Tres Piedras",
        "Dos Chaqares",
        "G Ortiz De Rosas",
        "Lote 20",
        "Maria",
        "Narciso Leven",
        "San Bernardo",
        "San Fernando",
        "Colonia Espaqa",
        "Colonia Helvecia",
        "Colonia Villa Alba",
        "El Trigo",
        "El Vasquito",
        "General San Martin",
        "La Colorada Chica",
        "La Colorada Grande",
        "La Primera",
        "Lote 17 E 121",
        "Lote 18 E 158",
        "Lote 23 E 264",
        "Lote 7 Escuela 270",
        "Lote 8  E 179",
        "Minas De Sal",
        "Traico",
        "Villa Alba",
        "C San Rosario",
        "Campo Cicare",
        "Campo De Salas",
        "Campo Nicholson",
        "Colonia Beatriz",
        "Colonia Vascongada",
        "Jacinto Arauz",
        "Traico Grande",
        "Villa Mencuelle",
        "Abramo",
        "Cona Lauquen",
        "Cotita",
        "Dos De Ipiqa",
        "Dos Violetas",
        "El Lucero",
        "El Mirador",
        "Hucal",
        "Ipiqa",
        "L Estrella Dl Sud",
        "La Administracion",
        "La Catita",
        "La Elva",
        "La Maria Elena",
        "La Maria Elisa",
        "Lote 11 Bernasconi",
        "Lote 22 Ipiqa",
        "Lote 8",
        "Luna",
        "Peru",
        "Piche Cona Lauquen",
        "San Aquilino",
        "San Diego",
        "Tres Botones",
        "Tres Naciones",
        "Tribuluci",
        "Trubuluco",
        "Puelen",
        "C Medano Colorado",
        "C Santa Clara",
        "C Santa Maria",
        "Colonia Cazaux",
        "Colonia La Mutua",
        "Colonia Santa Maria",
        "Cuchillo  Co",
        "Cuchillo Co",
        "El Descanso",
        "El Pimia",
        "El Porvenir",
        "El Puma",
        "Epu Pel",
        "La Torera",
        "Santa Clara",
        "Santa Maria",
        "Unanue",
        "Las Perlas",
        "Loma De La Lata",
        "Portezuelo Grande",
        "Rincon De Emilio",
        "A De Los Pajaritos",
        "Alanitos",
        "C Valentina Sur",
        "Contralm Cordero",
        "Cuenca Vidal",
        "Michi Honoca",
        "Planicie Banderita",
        "Colonia El Manzano",
        "Coronel Vidal",
        "Kilometro 1218",
        "Lago Pellegrini",
        "Los Chihuidos",
        "Los Chinitos",
        "Punta De Sierra",
        "Tratayen",
        "S P Del Chaqar",
        "Aguara",
        "C Alte Guerrico",
        "C Gobernador Ayala",
        "Catriel",
        "Cos Zaures",
        "La Bota",
        "La Copelina",
        "Los Sauces",
        "Paso La Balsa",
        "Peqas Blancas",
        "Valle D Los Alamos",
        "Aqelo",
        "Arroyito Challaco",
        "Cerro Del Leon",
        "Naupa Huen",
        "Pantanitos",
        "Bajada Colorada",
        "Carran Cara",
        "Carri Lauquen",
        "Costa Limay",
        "La Pintada",
        "Nogueira",
        "Piedra Pintada",
        "Saqico",
        "T Piedra Dl Aguila",
        "Zaina Yegua",
        "Balsa Senillosa",
        "China Muerta",
        "Campamento Sol",
        "P H  Baterias",
        "R. De Los Sauces",
        "Sauzal Bonito",
        "Barrio Peligroso",
        "Pueblo Nuevo",
        "Cuatro Esquinas",
        "Iris",
        "La Alianza",
        "La Emilia",
        "La Lucinda",
        "San Jorge",
        "G Fernandez Oro",
        "Gobernador Duval",
        "Bjda De Los Molles",
        "Covunco Arriba",
        "El  Tropezon",
        "La  Pochola",
        "Laguna Miranda",
        "Los Muchachos",
        "Portada Covunco",
    ],
    "Río Negro": [
        "Ferri",
        "B San Antonio",
        "Balneario Chapalco",
        "C Los Alfalfares",
        "C San Enrique",
        "Cabeza De Buey",
        "Las Isletas",
        "Juan De Garay",
        "Coronel Eugenio Del Busto",
        "Anzoategui",
        "Balo Los Morros",
        "Caleu Caleu",
        "Coronel E Dl Busto",
        "El Aguila",
        "Gaviotas",
        "La Adela",
        "La Maria Ines",
        "Pichi Mahuida",
        "Rio Colorado",
        "S Gdes  Anzoategui",
        "San Leon",
        "C Julia Y Echarren",
        "Colonia Julia Y Echarren",
        "Buena Parada",
        "Esteban A Gascon",
        "Cinco Saltos",
        "Villa Manzano",
        "Sargento Vidal",
        "Cipolletti",
        "General Francisco Oro",
        "La Estancia Vieja",
        "General Fernandez Oro",
        "Cervantes",
        "Chichinales",
        "Mainque",
        "Allen",
        "Contralmirante M Guerrico",
        "El Cuy",
        "Paso Cordova",
        "General Roca",
        "Aguada Guzman",
        "Cerro De Policia",
        "Coronel J.J.Gomez",
        "Ingeniero Huergo",
        "Padre Alejandro Stefanelli",
        "Valle Azul",
        "Villa Regina",
        "General Enrique Godoy",
        "Espinazo Del Zorro",
        "La Arboleda",
        "Laja",
        "Lapachal",
        "Paso Cata Tun",
        "Quinineliu",
        "Carri Lil",
        "Haras Patria",
        "Kilca Casa",
        "La A De Icalma",
        "Lagotera",
        "Litran",
        "Lonco Luan",
        "Lonco Mula",
        "P De Lonco Luan",
        "Quila Chanquil",
        "Quilca",
        "Rchorroy Arriba",
        "Sainuco",
        "Codihue",
        "Cuchillo Cura",
        "El Atravesado",
        "Huillilon",
        "Las Tres Lagunas",
        "Benjamin Zorrilla",
        "Fortin Uno",
        "Choele Choel",
        "Luis Beltran",
        "Lamarque",
        "Chimpay",
        "Darwin",
        "Chelforo",
        "Pomona",
        "Coronel Belisle",
        "Cerro De Los Pinos",
        "Chacabuco",
        "Chinchina",
        "Bariloche",
        "El Condor Estancia",
        "Isla Victoria",
        "Laguna Los Juncos",
        "P Moreno E Fcgr",
        "P Tigre I Victoria",
        "Peninsula Huemul",
        "Puerto Anchorena",
        "Ñirihuao E Fcgr",
        "Cerro Catedral",
        "El Foyel",
        "Nahuel Huapi",
        "Santa Maria",
        "Arroyo Chacay",
        "Cullin Manzano",
        "E Tequel Malal",
        "Estancia Newbery",
        "La Araucaria",
        "La Estacada",
        "La Lipela",
        "Paso Chacabuco",
        "Paso Coihue",
        "Puerto Ojo De Agua",
        "Rincon Chico",
        "Rincon Grande",
        "Rio Villegas",
        "Villa Llanquin",
        "Villa Mascardi",
        "Dina Huapi",
        "Corralito",
        "Llao Llao",
        "C Los Cantaros",
        "Laguna Fria",
        "Laguna Frias",
        "Puerto Blest",
        "Las Bayas",
        "Pilcaniyeu Viejo",
        "San Pedro",
        "Casa Quemada",
        "Cañadon Del Corral",
        "Corral D Los Pinos",
        "La Quebrada",
        "Pilcaniyeu",
        "Chacay Huarruca",
        "Mamuel Choique",
        "Ñorquinco",
        "Rio Chico",
        "Clemente Onelli",
        "Neneo Ruca",
        "Anecon Grande",
        "Cañadon Camallo",
        "Comallo",
        "Comallo Abajo",
        "Coquelen",
        "Ing Z Resta",
        "Onelli",
        "Perito Moreno",
        "Pilqui Niyeu",
        "Quinta Panal",
        "Tres Ojos De Aguas",
        "Cañadon Chileno",
        "Pilahue",
        "Chasico",
        "Canllequin",
        "Carri Yegua",
        "Cura Lauquen",
        "El Cacique",
        "El Camaruro",
        "El Gaucho Pobre",
        "El Jardinero",
        "Hua Miche",
        "Jita Rusia",
        "Kili Malal",
        "La Angostura",
        "La Chilena",
        "La Criollita",
        "La Estrella",
        "La Excurra",
        "La Mimosa",
        "La Porteña",
        "La Rubia",
        "La Vencedora",
        "Lanquiñeo",
        "Las Mellizas",
        "Lonco Vaca",
        "Los Costeros",
        "Los Pirineos",
        "Los Quebrachos",
        "Mencue",
        "Michihuao",
        "Mulanillo",
        "Palenque Niyeu",
        "Santa Elena",
        "El Moligue",
        "Ojo De Agua Embarcadero Fcgb",
        "Anecon Chico",
        "Atraico",
        "Carrilauquen",
        "Chaiful",
        "Coli Toro",
        "El Cheiful",
        "Empalme Km 648",
        "Futa Ruin",
        "Huan Luan",
        "Ing Jacobacci",
        "Ingeniero Jacobacci",
        "Ojo De Agua E Fcgb",
        "Quetrequile",
        "Yuquinche",
        "El Cain",
        "Los Juncos",
        "Aguada De Piedra",
        "Barril Niyeo",
        "Chichihuao",
        "Los Manantiales",
        "Mancullique",
        "Maquinchao",
        "Niluan",
        "Rucu Luan",
        "Tromeniyeu",
        "Vaca Lauquen",
        "Aguada De Guerra",
        "Caltrauna",
        "Cerro Abanico",
        "Comi C",
        "Ganzu Lauquen",
        "La Rinconada",
        "Lagunita",
        "Lenzaniyen",
        "Loma Blanca",
        "Los Menucos",
        "Menucos",
        "Prahuaniyeu",
        "Costa Del Rio Azul",
        "El Bolson",
        "El Manso",
        "Los Repollos",
        "Mallin Ahogado",
        "Villa Turismo",
        "B El Condor",
        "Balneario El Condor",
        "General Liborio Bernal",
        "El Dique",
        "G Liborio Bernal",
        "La Meseta",
        "Mata Negra",
        "Viedma",
        "C General Frias",
        "La Granja",
        "La Loberia",
        "Playa Bonita",
        "San Javier",
        "Segunda Angostura",
        "Tte Gral E Frias",
        "Coronel Francisco Sosa",
        "San Lorenzo",
        "Chocori",
        "Colonia La Luisa",
        "Colonia San Juan",
        "Coronel F Sosa",
        "El Porvenir",
        "General Conesa",
        "Ingenio S Lorenzo",
        "La Carolina",
        "La Flecha",
        "Luis M Zagaglia",
        "Nueva Carolina",
        "Puesto Gaviña",
        "Rincon De Gastre",
        "San Juan",
        "San Simon",
        "Travesia Castro",
        "C. De Patagones",
        "Cantera Villalonga",
        "Carmen De Patagones",
        "El Bagual",
        "Faro S Barrancosa",
        "Las Cortaderas",
        "Termas Los Gauchos",
        "Ambrosio P Lezica",
        "B De La Travesia",
        "China Muerta",
        "Emilio Lamarca",
        "Jarrilla",
        "Jose B Casas",
        "Primera Angostura",
        "Pto Tres Bonetes",
        "Sauce Blanco",
        "Guardia Mitre",
        "Bahia San Blas",
        "Cardenal Cagliero",
        "Puerto Wassermann",
        "Salina De Piedra",
        "Los Pozos",
        "General Lorenzo Vintter",
        "Laguna Del Barro",
        "Laguna Del Monte",
        "Nuevo Leon",
        "Pozo Salado",
        "Saco Viejo",
        "Cinco Chañares",
        "Percy H Scott",
        "Aguada Del Loro",
        "Bajo Del Gualicho",
        "Barrio Laguna",
        "Jag?El Campo Monte",
        "La Bombilla",
        "La Primavera",
        "Lag De La Prueba",
        "Laguna Cortes",
        "Las Maquinas",
        "Mancha Blanca",
        "Pozo Moro",
        "San Antonio Oeste",
        "Arroyo La Ventana",
        "Sierra Paileman",
        "Arroyo Los Berros",
        "Arroyo Tembrado",
        "Arroyo Verde",
        "B Las Grutas",
        "Balneario Las Grutas",
        "Las Grutas",
        "Sierra De La V",
        "Cona Niyeu",
        "Sierra Grande",
        "Aguada Cecilio",
        "Falckner",
        "Janinue",
        "La Esperanza",
        "Mi Ramos Mexia",
        "Ministro Ramos Mexia",
        "Nahuel Niyeu",
        "Sierra Colorada",
        "Teniente M E Fcgr",
        "Treneta",
        "Chipauquil",
        "El Salado",
        "Musters",
        "Pajalta",
        "Punta De Agua",
        "Valcheta",
        "Kilometro 11",
        "Pampa Salamanca",
        "Pico Salamanca",
        "Cañadon Ferrais",
        "La Salamanca",
        "Pampa Pelada",
        "S O Chicas Y Gdes",
        "Sierra Cuadrada",
        "Aguada Escondida",
        "Cerro Moro",
        "Arroyo Quilla",
        "Cañadon Carril",
        "Cañadon Tacho",
        "Colonia Germania",
        "Costa Rio Chico",
        "Enrique Hermitte",
        "Kilometro 191",
        "Laguna Del Mate",
        "Laguna Palacio",
        "Las Pulgas",
        "Manantial Grande",
        "Paso De Torres",
        "Sierra Corrientes",
        "Sierra Victoria",
        "Lago Musters",
        "S Nevada Buen Past",
        "Bajo La Cancha",
        "Los Tamariscos",
        "Arroyo Gato",
        "El Maiten",
        "Chonque",
        "El Guadal",
        "La Barreta",
        "La Julia",
        "La Leona",
        "La Pigmea",
        "Las Mercedes",
        "C Luis Piedrabuena",
    ],
    Chubut: [
        "Cushamen",
        "El Hoyo",
        "Lago Puelo",
        "Las Golondrinas",
        "Pampa Del Castillo",
        "Cerro Dragon",
        "Comodoro Rivadavia",
        "Rada Tilly",
        "Bahia Solano",
        "Barrio Astra",
        "Caleta Cordova",
        "Don Bosco",
        "Empalme A Astra",
        "Escalante",
        "Manatiales Bher",
        "General Mosconi",
        "Garayalde",
        "Uzcudum",
        "C P Ex V Hermoso",
        "Cañadon Lagarto",
        "Cañadon Lopez",
        "Diadema Argentina",
        "Holdich",
        "Valle Hermoso",
        "Sarmiento",
        "Colhue Huapi",
        "Buen Pasto",
        "Rio Mayo",
        "Facundo",
        "Aldea Apeleg",
        "Alto Rio Senguer",
        "El Coyte",
        "El Porvenir",
        "La Laurita",
        "La Pepita",
        "Lago Fontana",
        "Paso Moreno",
        "Pastos Blancos",
        "Rio Frias",
        "Arroyo Chalia",
        "Dr Ricardo Rojas",
        "Dr.Ricardo Rojas",
        "Aldea Beleiro",
        "Alto Rio Mayo",
        "El Triana",
        "Lago Blanco",
        "El Huemul",
        "Hito 45",
        "Hito 50",
        "La Cancha",
        "La Nicolasa",
        "La Siberia",
        "Valle Huemules",
        "Aguada La Oveja",
        "La Violeta",
        "Aguada Grande",
        "Cerro Alto",
        "Cerro Negro",
        "Desamparados",
        "El Barbucho",
        "Floradora",
        "Cerro Santa Ana",
        "Dos Pozos",
        "F Ameghino",
        "Trelew",
        "Uzcudun",
        "Alto De Las Plumas",
        "B A Almirante Zar",
        "Bajada Del Diablo",
        "Cabeza De Buey",
        "D F Ameghino",
        "El Mirasol",
        "Las Plumas",
        "Puente Hendre",
        "Bajo De Los Huesos",
        "Casa Blanca",
        "Charque Chico",
        "Playa Union",
        "Punta Ninfas",
        "Rawson",
        "Sol De Mayo",
        "Angostura",
        "Angostura Segunda",
        "Betesta",
        "Bryn Brown",
        "Bryn Gwyn",
        "Cabaña Del Valle",
        "El Argentino",
        "Gaiman",
        "Glasfryn",
        "Loma Redonda",
        "Maesteg",
        "Treorki",
        "Valle Los Martires",
        "Villa Ines",
        "Boca De La Zanja",
        "Dolavon",
        "28 De Julio",
        "Las Chapas",
        "Bahia Bustamante",
        "Cabo Raso",
        "Camarones",
        "El Jaguel",
        "La Castellana",
        "Malaspina",
        "R 3 Kilometro 1646",
        "R 3 Kilometro 1711",
        "Sierra Colorada",
        "Florentino Ameghino",
        "Bahia Cracher",
        "Cerro Pichalao",
        "El Desempeño",
        "Puerto Madryn",
        "Punta Quiroga",
        "Telsen",
        "Puerto Piramides",
        "Aguad De Las Tejas",
        "Aguada Del Pito",
        "Bajada Moreno",
        "Bajo Bartolo",
        "Bajo Del Gualicho",
        "Bajo Las Damajas",
        "Caleta Valdez",
        "Carhue Niyeo",
        "Cataico",
        "Cañadon Bagual",
        "Cañadon Blanco",
        "Cañadon Chileno",
        "Chacay Este",
        "Chacay Oeste",
        "Chasico",
        "Colelache",
        "El Alamo",
        "El C  Dpto Gastre",
        "El Escorial",
        "El Pastizal",
        "El Piquillin",
        "El Quilimuay",
        "El Ruano",
        "El Salitral",
        "Estancia El Moro",
        "Gan Gan",
        "Gastre",
        "La Corona",
        "La Rosilla",
        "Laguna De Vacas",
        "Laguna Fria",
        "Lagunita Salada",
        "Larralde",
        "Loreto",
        "M Gde Corcobado",
        "Mallin Grande",
        "Medanos",
        "Painaluf",
        "Pirre Mahuida",
        "Plancuntre",
        "Puerto San Roman",
        "Punta Bajos",
        "Punta Delgada",
        "Punta Norte",
        "Sacanana",
        "Salinas Chicas",
        "Salinas Grandes",
        "San Jose",
        "Seprucal",
        "Sierra Chata",
        "Sierra Chica",
        "Sierra Rosada",
        "Talagapa",
        "Tatuen",
        "V Del Rio Chubut",
        "Arroyo Verde",
        "Arroyo Pescado",
        "C 16 De Octubre",
        "Cerro Mallaco",
        "Chacra De Austin",
        "Esquel",
        "La Lancha",
        "Laguna Terraplen",
        "Matucana",
        "Mayoco",
        "Nahuel Pan E Fcgr",
        "Sierra De Tecka",
        "Sunica",
        "Villa Futalaufquen",
        "Gualjaina",
        "Parque Nacional Los Alerces",
        "Languiñeo",
        "C De Ginebra Chico",
        "C De Ginebra Gde",
        "C Lonco Trapial",
        "Cachel",
        "Carrenleufu",
        "Colanconhue",
        "Colonia Epulief",
        "E Pampa Chica",
        "El Calafate",
        "El Cronometro",
        "El Cuche",
        "El Kaquel",
        "El Mirador",
        "El Pajarito",
        "El Portezuelo",
        "El Poyo",
        "Estancia La Mimosa",
        "La Primavera",
        "Las Salinas",
        "Mallin Blanco",
        "P De Quichaura",
        "P Nac Los Alerces",
        "Pampa De Agnia",
        "Pampa Tepuel",
        "Paso Del Sapo",
        "Piedra Parada",
        "Taquetren",
        "Tecka",
        "Valle Del Tecka",
        "Valle Garin",
        "Aldea Escolar",
        "Arroyo Percy",
        "Cerro Centinela",
        "Futaleufu",
        "Lago Rosario",
        "Legua 24",
        "Los Cipreces",
        "Rio Corinto",
        "Trevelin",
        "Valle Frio",
        "Corcovado",
        "Cerro Condor",
        "El Sombrero",
        "Paso De Indios",
        "Arroyo Guilaia",
        "Cañada Bagual",
        "El Canquel",
        "El Chalet",
        "La Bombilla",
        "Lag R. Del Moro",
        "Las Cortaderas",
        "Las Horquetas",
        "Los Altares",
        "Los Manantiales",
        "S Nevada P De Ind",
        "Toro Hosco",
        "Buenos Aires Chico",
        "Costa Chubut",
        "Costa Del Lepa",
        "Cuesta Del Ternero",
        "El Boquete",
        "Fithen Verin",
        "Fitirhuin",
        "Ing Bruno J Thomae",
        "El Coihue",
        "Epuyen",
        "Cerro Fofocahuel",
        "Colonia Cushamen",
        "Fotocahuel",
        "Leleque",
        "Ranquil Huao",
        "Siempre Viva",
        "Cañadon Caliente",
        "Cañadon Grande",
        "Cholila",
        "El Cajon",
        "Lago C Pellegrini",
        "Lago Lezama",
        "Lago Rivadavia",
        "Cañadon La Madera",
        "Corralitos",
        "El Molle",
        "El Tropezon",
        "Jose De San Martin",
        "Laguna Blanca",
        "Laguna Verde",
        "Lenzanilleo",
        "Los Corralitos",
        "Mata Grande",
        "Tres Picos",
        "Valle Hondo",
        "Cañadon Chacay",
        "El Cherque",
        "El Shaman",
        "Gobernador Costa",
        "Niriguao",
        "Niriguce Pampa",
        "Putrachoique",
        "Lago Vintter",
        "Alto Rio Pico",
        "Arenoso",
        "F De Rio Pico",
        "Hito 43",
        "Lago Paz",
        "Lago Verde",
        "Las Mulas",
        "Las Pampas",
        "Rio Pico",
        "Chilquenilahue",
        "Nueva Lubecka",
        "Piedra Shotel",
        "C De Las Vacas",
        "Ca De Las Vacas",
        "Cañadon Del Rancho",
        "Guardap Fitz Roy",
        "La Federica",
        "La Florida",
        "Lago San Martin",
        "Lago Tar",
        "Paso Rio La Leona",
        "Peninsula Maipu",
        "Pta D Lago Viedma",
        "Cañadon Del Toro",
        "Cerro Redondo",
        "El Baile",
        "El Pan De Azucar",
        "El Paso",
        "Garminue",
        "La Porteña",
        "Paso Ibañez",
        "S De La Ventana",
        "Tauel Aike",
        "P Del Rio S Cruz",
        "Bajo Fuego",
        "C 11 De Setiembre",
        "Lago Pueyrredon",
        "Yacim C Vanguardia",
        "Cañadon Leon",
        "Cañadon Molinari",
        "G Gregores",
        "La Manchuria",
        "La Peninsula",
        "Lago Strobel",
        "Paso Del Aguila",
        "Faro Cabo Guardian",
        "Faro Campana",
        "Punta Mercedes",
        "Htel Las Horquetas",
        "Paso Roballo",
        "An Aike",
        "Cabo D L Virgenes",
        "Camuzu Aike",
        "Cap",
        "Caqadon Fabre",
        "Cerro Palique",
        "Chimen Aike",
        "Condor",
        "Coronel Guarumba",
        "E Ing Atilio Cappa",
        "Gobernador Mayer",
        "Ing Atilio Cappa",
        "Laguna Colorada",
        "Monte Aymond",
        "Palermo Aike",
        "Pali Aike",
        "Paso Del Medio",
        "Punta Del Monte",
        "La Esperanza",
    ],
    Neuquén: [
        "Neuquén",
        "Colonia Valentina",
        "Contralmirante Cordero",
        "Añelo",
        "Barda Del Medio",
        "Auca Mahuida",
        "San Patricio Del Chañar",
        "Centenario",
        "Villa El Chocon",
        "Picun Leufu",
        "Arroyito",
        "El Sauce",
        "Limay Centro",
        "Paso Aguerre",
        "Vista Alegre Norte",
        "Piedra Del Aguila",
        "Achico",
        "Sañico",
        "Santo Tomas",
        "Plottier",
        "Senillosa",
        "Vista Alegre Sur",
        "Paso De Los Indios",
        "Challaco",
        "Plaza Huincul",
        "Puesto Hernandez  Baterias",
        "Rincon De Los Sauces",
        "Mari Menuco",
        "Cutral Co",
        "El Manzano",
        "Barrio Norte",
        "Chacras De Allen",
        "Contral M Guerrico",
        "Coronel J J Gomez",
        "Colonia Rusa",
        "Tricaco",
        "Barda Colorada",
        "P De Jaguelito",
        "Quempu Niyeu",
        "Padre A Stefanelli",
        "Colonia Regina",
        "La Japonesa",
        "Tercera Zona",
        "Villa Alberdi",
        "G Enrique Godoy",
        "Aguada Florencio",
        "Covunco",
        "Las Cortaderas",
        "Bajada Del Marucho",
        "Barda Anguil",
        "Bardas Negras",
        "Caichihue",
        "El Overo",
        "Goqi Co",
        "La Fria",
        "La Patagonia",
        "La Patria",
        "La Susana",
        "La Teresa",
        "Laguna Blanca",
        "Las Barditas",
        "Los Catutos",
        "Puente Picun Leufu",
        "Qireco Sud",
        "Taqui Nileu",
        "Tres Piedras",
        "Zapala",
        "Arroyo Quillen",
        "Chacayco",
        "Charahuilla",
        "El Dormido",
        "El Gato",
        "Fortin 1 De Mayo",
        "La Ofelia",
        "Lapa",
        "Los Rodillos",
        "M De Las Yeguas",
        "Malaico",
        "Ojo De Agua",
        "Quillen",
        "San Juan Rahue",
        "Santa Isabel",
        "Catan Lil",
        "Las Coloradas",
        "Rahue",
        "Taquimilan",
        "Churriaca",
        "Bajada Del Agrio",
        "Moquehua",
        "Moquehue",
        "Villa Pehuenia",
        "Alumine",
        "Caviahue",
        "La Buitrera",
        "Primeros Pinos",
        "Arroyo Cahunco",
        "Arroyo Ranquilco",
        "Cajon Del Toro",
        "Calihue",
        "Carreri",
        "Cerro Colorado",
        "Cerro De La Grasa",
        "Chacay",
        "Cochico",
        "Corral De Piedra",
        "El Chocon",
        "El Escorial",
        "El Palao",
        "Haichol",
        "La Porteqa",
        "La Verdad",
        "Las Lajas",
        "Las Lajitas",
        "Las Toscas",
        "Liu Cullin",
        "Llamuco",
        "Los Galpones",
        "Mallin Blanco",
        "Mallin Chileno",
        "Mallin De La Cueva",
        "Mallin De Mena",
        "Mallin Del Rubio",
        "Mallin Quemado",
        "Paso Ancho",
        "Piche Ponon",
        "Piedras Bayas",
        "Pino Hachado",
        "Pino Solo",
        "Pozo Hualiches",
        "Quebrada Honda",
        "Ramichal",
        "Salquico",
        "San Demetrio",
        "Copahue",
        "El Huecu",
        "Hualcupen",
        "Mulichinco",
        "Aguas De Las Mulas",
        "Cajon De Almaza",
        "Cajon De Los Patos",
        "Cajon De Manzano",
        "Cerro De La Parva",
        "Chenquecura",
        "Chochoy Mallin",
        "Colipili",
        "El Bosque",
        "El Pino Andino",
        "Haycu",
        "Huarinchenque",
        "La Argentina",
        "Loncopue",
        "Mallin Del Toro",
        "Nalay Cullin",
        "Qorquin",
        "Ranqueles",
        "Ranquilco",
        "Ranquilon",
        "Richoique",
        "Tralatue",
        "Villa Mallin",
        "Huncal",
        "Mariano Moreno",
        "Quili Malal",
        "Trahuncura",
        "Agrio Balsa",
        "B Del Rio Agrio",
        "Balsa Dl Rio Agrio",
        "Coihueco",
        "Coli Malal",
        "Conflu Del Aguijon",
        "Costa Del A Salado",
        "Covunco Abajo",
        "Covunco Centro",
        "El Peralito",
        "El Salado",
        "Franhucura",
        "Nau Nauco",
        "Pampa Del Salado",
        "Pichaihue",
        "Pichi Neuquen",
        "Pilmatue",
        "Quintuco",
        "Rio Agrio",
        "Vaca Muerta",
        "Butalon",
        "Caepe Malal",
        "El Cholar",
        "El Durazno",
        "Guañacos",
        "La Salada",
        "Lileo",
        "Los Bolillos",
        "Los Carrizos",
        "Los Menucos",
        "Los Miches",
        "Los Molles",
        "Manzano Amargo",
        "Nireco",
        "Tres Chorros",
        "Aguada Chacay Co",
        "Anquinco",
        "Arroyo Blanco",
        "Batre Lauquen",
        "Bella Vista",
        "Buta Co",
        "Buta Mallin",
        "Buta Ranquil",
        "C De Curi Leuvu",
        "Cajon Grande",
        "Camaleones",
        "Cancha Huiganco",
        "Caqada Seca",
        "Cayanta",
        "Cerro Negro Chapua",
        "Cerro Negro Tricao",
        "Chacay Co",
        "Chacay Melehue",
        "Chapua",
        "Chapua Abajo",
        "Chos Malal",
        "Coyuco",
        "Curu  Leuvu",
        "El Alamito",
        "El Chingue",
        "El Curileo",
        "El Porton",
        "El Tromen",
        "Filmatue",
        "Flores",
        "Fortin Guaqacos",
        "Guaqacos",
        "Huaraco",
        "Huitrin",
        "Humigamio",
        "Invernada Vieja",
        "Jecanasco",
        "Juaranco",
        "La Cienaga",
        "La Cieneguita",
        "La Primavera",
        "Las Abejas",
        "Las Chacras",
        "Las Lagunas",
        "Las Saladas",
        "Leuto Caballo",
        "Los Chacales",
        "Los Cisnes",
        "Los Entierros",
        "Los Tres Chorros",
        "Luicoco",
        "Luin Coco",
        "Machico",
        "Mayan Mahuida",
        "Milla",
        "Mina Carrascosa",
        "Mina Lileo",
        "Nahueve",
        "Nereco Norte",
        "Palau",
        "Pampa De Tril",
        "Pampa Ferreira",
        "Paso Barda",
        "Quempu Leufu",
        "Ranquil Vega",
        "Reqileo",
        "Rio Barrancas",
        "San Eduardo",
        "Taquimillan Abajo",
        "Tierras Blancas",
        "Tihue",
        "Trili",
        "Casa De Piedra",
        "Andacollo",
        "Las Ovejas",
        "Barrancas",
        "Tricao Malal",
        "Varvarco",
        "Bajo Rico",
        "La Elvira",
        "La Sara",
        "Los Molinos",
        "Maria Cristina",
        "Negro Muerto",
        "Paso Piedra",
        "Puesto Faria",
        "Rinconada",
        "Sauce Blanco",
        "Isla Grande",
        "Paso Lezcano",
        "Colonia Josefa",
        "E Las Julias",
        "Isla Chica",
        "La Julia",
        "Salitral Negro",
        "Santa Genoveva",
        "Santa Gregoria",
        "Santa Nicolasa",
        "Huinganco",
        "Buena Esperanza",
        "Caminera",
        "Chapelco",
        "El Cerrito",
        "El Oasis",
        "El Porvenir",
        "Filo Hua Hum",
        "Hua Hum",
        "La Fortuna",
        "Lago Log Log",
        "Las Bandurrias",
        "Lascar",
        "Log Log",
        "Meliquina",
        "Quila Quina",
        "Quita Quina",
        "S M De Los Andes",
        "San Martin De Los Andes",
        "Trompul",
        "Villa Raur",
        "Chiquillihuin",
        "Collun Co",
        "Huechulafquen",
        "Junin De Los Andes",
        "La Atalaya",
        "La Union",
        "Lubeca",
        "Mamul Malal",
        "Nahuel Mape",
        "Palitue",
        "Piedra Mala",
        "Quila Quehue",
        "Quilquihue",
        "S J J De Los Andes",
        "Tres Picos",
        "Tromen",
        "Pampa Del Malleo",
        "Pilo Lil",
        "Auca Pan",
        "Caleofu",
        "Chacayal",
        "Chichiguay",
        "Chimehuin",
        "Las Mercedes",
        "Peqa Colorada",
        "Quentrenquen",
        "Quinquimitreo",
        "Tipiliuke",
        "El Salitral",
        "San Ignacio",
        "La Negra",
        "C De Los Indios",
        "Caqadon Del Indio",
        "Cerro Gato",
        "Huechahue",
        "La Rinconada",
        "Pampa Collon Cura",
        "Paso De S Ignacio",
        "Zingone Y Cia M",
        "Zulemita",
        "Alianza",
        "Gente Grande",
        "Traful",
        "Alicura",
        "Caminera Traful",
        "Cerro Alto",
        "Clemente Onelli",
        "E La Primavera",
        "Huinca Lu",
        "Paso Del Limay",
        "Paso Flores",
        "Paso Miranda",
        "Dina Huapi",
        "Villa Los Colihues",
        "Correntoso",
        "El Arbolito",
        "El Machete",
        "Puerto Manzano",
        "Villa La Angostura",
        "Villa Traful",
        "Tunquelen",
        "C Del Pichi Leufu",
        "Carhue",
        "Chenqueniyeu",
        "Churquiñeo",
        "El Pantanoso",
        "Menuco Vaca Muerta",
        "Panquehuao",
        "Paso De Los Molles",
        "Pichi Leufu",
        "Pichi Leufu Abajo",
        "Pichi Leufu Arriba",
        "Rayhuao",
        "Arroyo Las Minas",
        "Cerro Mesa",
        "Chacalhua Ruca",
        "Fitalancao",
        "Fitamiche",
        "Fitatimen",
        "Portezuelo",
        "Repollos",
        "Ñorquinco Sud",
        "San Ramon",
    ],
    "Tierra del Fuego": [
        "Puerto S Ant Este",
        "Arroyo Salado",
        "Arroyo Verde",
        "Campana Mahuida",
        "Empalme P Lobos",
        "Puerto Lobos",
        "Puerto Argentino",
        "Ushuaia",
        "Antartida E Islas Del Sur",
        "Barrio San Vicente De Paul",
        "Tolhuin",
        "Aserradero Arroyo",
        "C L Chorrillos Y P",
        "Camp Central Y P F",
        "Estancia Costancia",
        "Estancia Despedida",
        "Estancia El Roble",
        "Estancia Laura",
        "Estancia Libertad",
        "Estancia Pirinaica",
        "Estancia Rolito",
        "Estancia Rosita",
        "Estancia San Jose",
        "Estancia Santa Ana",
        "Estancia Viamonte",
        "Frigorifico Cap",
        "Rio Grande",
        "San Sebastian",
        "Santa Ines",
        "Cerro Radal",
    ],
    "Santa Cruz": [
        "Bahia Langara",
        "Caleta Olivia",
        "Cerro Mangrullo",
        "La Esther",
        "Cañadon Seco",
        "Alma Grande",
        "Jelaina",
        "La Antonia",
        "La Guardia",
        "La Rosa",
        "Minerales",
        "Pico Truncado",
        "Zanjon Del Pescado",
        "El Pluma",
        "Piedra Clavada",
        "C C Pellegrini",
        "Cameron",
        "Cañadon Pluma",
        "Cerro La Setenta",
        "Cerro Renzel",
        "Cerro Silva",
        "Cueva De Las Manos",
        "India Muerta",
        "La Argentina",
        "La Maria",
        "Lago Buenos Aires",
        "Las Heras",
        "Las Masitas",
        "Las Piramides",
        "Leandro N Alem",
        "Mata Magallanes",
        "Meseta Guengue",
        "Pampa Verdum",
        "Pellegrini",
        "Yegua Muerta",
        "Fitz Roy",
        "Tehuelches",
        "Koluel Kaike",
        "Monte Verde",
        "C Leandro N Alem",
        "Cañadon Botello",
        "El Portezuelo",
        "Ing Pallavicini",
        "La Asturiana",
        "Monte Ceballos",
        "Nacntos Del Pluma",
        "Perito Moreno",
        "Rio Fenix",
        "Tellier",
        "Tres Cerros",
        "Gobernador Moyano",
        "Kilometro 8",
        "Paso Gregores",
        "Puerto Deseado",
        "Cabo Blanco",
        "El Hueco",
        "Aguada A Pique",
        "Cabo Tres Puntas",
        "Cerro Puntudo",
        "Cerro Redondo",
        "El Chara",
        "El Loro",
        "El Polvorin",
        "La Aguada",
        "La Central",
        "La Estela",
        "La Fecundidad",
        "La Madrugada",
        "La Margarita",
        "La Protegida",
        "La Rosada",
        "La Victoria",
        "Mazaredo",
        "Sarai",
        "Tres Puntas",
        "Jaramillo",
        "Boca Zanja Sud",
        "Camp Villegas",
        "Ebenecer",
        "Laguna Grande",
        "Toma De Los Ces",
        "Puerto Santa Cruz",
        "Lago Cardiel",
        "Tres Lagos",
        "El Chalten",
        "Rio Chico",
        "Paso De Los Indios",
        "Comandante Luis Piedrabuena",
        "Cañada De Las Vacas",
        "Aguada Alegre",
        "Bahia Laura",
        "Cerro Vanguardia",
        "Puerto San Julian",
        "Tucu Tucu",
        "Gobernador Gregores",
        "Cara Mala",
        "El Salado",
        "Tamel Aike",
        "Bajo Caracoles",
        "Lago Posadas",
        "Rospentek",
        "Cancha Carrera",
        "Punta Loyola",
        "Rio Gallegos",
        "Bella Vista",
        "El Zurdo",
        "Fortaleza",
        "Fuentes De Coyle",
        "Guer Aike",
        "Punta Bandera",
        "Bahia Tranquila",
        "Condor Clif",
        "El Calafate",
        "El Cerrito",
        "Lago Argentino",
        "Lago Roca",
        "Paso Charles Fhur",
        "Quien Sabe",
        "Rio Bote",
        "Rio Calafate",
        "Rio Mitre",
        "Ventisquero Moreno",
        "Julia Dufour",
        "Campamento Dorotea",
        "Cnel M Irigoyen",
        "El Turbio",
        "Gaypon",
        "Glencross",
        "La Dorotea",
        "Mina 3",
        "Morro Chico",
        "Pueblo Nuevo",
        "Puente Blanco",
        "Rincon D L Morros",
        "Rio Turbio",
        "Rospentek Aike",
        "28 De Noviembre",
        "Isla Gran Malvina",
        "Isla Soledad",
        "Bahia Lapataia",
        "Estancia Harberton",
        "Hosteria Kaiken",
        "I De Los Estados",
        "Mision S M Fagnan",
        "B Aerea T Matienzo",
        "B Aerea V Marambio",
        "B E Gral Belgran 3",
        "B E Gral Belgrano",
        "B E Gral S M",
        "B E Primavera",
        "B Ejercito Espza",
        "B Ejercito Sobral",
        "D Melchior",
        "E C Alte Brown",
        "I Shetland Del Sur",
        "Isla Joinville",
        "Islas Georgias",
        "Islas Orcadas",
        "Tapi Aike",
        "Cabaqa Ruby",
        "Cabo San Pablo",
        "Comisaria Radman",
        "E Buenos Aires",
        "E Dos Hemanas",
        "E El Salvador",
        "E Jose Menendez",
        "E La Criolla",
        "E La Fueguina",
        "E La Indiana",
        "E La Porteqa",
        "E Las Violetas",
        "E Los Cerros",
        "E Los Flamencos",
        "E Maria Behety",
        "E Maria Cristina",
        "E Maria Luisa",
        "E Rio Irigoyen",
        "E San Martin",
        "El Paramo",
        "Estacion Aeronaval",
        "Estacion Osn",
        "Estancia Aurelia",
        "Estancia Carmen",
        "Estancia Cauchico",
        "Estancia Cullen",
        "Estancia El Rodeo",
        "Estancia Guazu Cue",
        "Estancia Herminita",
        "Estancia Ines",
        "Estancia Las Hijas",
        "Estancia Marina",
        "Estancia Miramonte",
        "Estancia Policarpo",
        "Estancia Rio Claro",
        "Estancia Rio Ewan",
        "Estancia Rivadavia",
        "Estancia Ruby",
        "Estancia San Julio",
        "Estancia San Justo",
        "Estancia San Pablo",
        "Estancia Sara",
        "Estancia Tepi",
        "Lago Khami",
        "Punta Maria",
        "Sec. Aviles  E S J",
    ],
};
